import { Grid } from "@mui/material";
import BaseDetails from "components/basicComponents/details/BaseDetails";
import AppItemView from "components/basicComponents/others/AppItemView";
import { removeHTMLTags } from "helpers";
import { useIntl } from "react-intl";
import { useGetByIdService } from "services";
import { AppUrls } from "services/appUrls";
import { IExamDetailsDto } from "types/entitiesTypes/exams";
import ExamQuestionsPage from "../examQuestion/ExamQuestionsPage";
import ExamResultsPage from "../examResults/ExamResultsPage";

interface IExamDetailsProps {
  setShowDetails: React.Dispatch<React.SetStateAction<boolean>>;
  showDetails: boolean;
  id: string;
}

const ExamDetails = (props: IExamDetailsProps) => {
  const { id, setShowDetails, showDetails } = props;
  const { messages, locale } = useIntl();
  const {
    data: examItem,
    error,
    isLoading,
  } = useGetByIdService<IExamDetailsDto>(AppUrls.Exam, id);

  return (
    <>
      <BaseDetails
        itemName={
          locale == "en-US" && examItem?.nameEn
            ? `- ${examItem?.nameEn!}`
            : `- ${examItem?.nameAr!}`
        }
        label="exams.details"
        open={showDetails}
        onClose={() => setShowDetails(false)}
        loading={isLoading}
        errorMessage={error}
        moreDetails={examItem as IExamDetailsDto}
        maxWidth="md"
        columns={12}
        tabs={[
          {
            id: "1",
            label: "details",
            component: (
              <Grid container item spacing={5}>
                <AppItemView
                  label="common.nameAr"
                  value={examItem?.nameAr}
                  sm={3}
                />
                <AppItemView
                  label="common.nameEn"
                  value={examItem?.nameEn}
                  sm={3}
                />
                <AppItemView
                  label="exams.date"
                  value={examItem?.date}
                  type="dateTime"
                  sm={3}
                />
                <AppItemView
                  label="exams.duration"
                  value={examItem?.durationInMin}
                  type="text"
                  sm={3}
                />
                <AppItemView
                  label="exams.totalMarks"
                  value={examItem?.totalMarks}
                  sm={3}
                />
                <AppItemView
                  label="exams.passingScore"
                  value={examItem?.passingScore}
                  sm={3}
                />

                <AppItemView
                  label="exams.type"
                  value={
                    examItem?.type === 1
                      ? messages["exams.type.first"]
                      : messages["exams.type.second"]
                  }
                  sm={3}
                />

                <AppItemView
                  label="exams.instructionsAr"
                  value={removeHTMLTags(examItem?.instructionsAr!)}
                  sm={6}
                />
                <AppItemView
                  label="exams.instructionsEn"
                  value={removeHTMLTags(examItem?.instructionsEn!)}
                  sm={6}
                />
              </Grid>
            ),
          },
          {
            id: "2",
            label: "exams.questions",
            component: (
              <ExamQuestionsPage
                examItem={examItem!}
                setShowDetails={setShowDetails}
              />
            ),
          },
          {
            id: "3",
            label: "exams.result.student",
            component: <ExamResultsPage examItem={examItem!} />,
          },
        ]}
      ></BaseDetails>
    </>
  );
};

export default ExamDetails;
