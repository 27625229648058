import AppTextField from "@crema/core/AppFormComponents/AppTextField";
import IntlMessages from "@crema/utility/IntlMessages";
import { Grid } from "@mui/material";
import AppDateTimePicker from "components/basicComponents/inputs/dateTimePickers/AppDateTimePicker";
import AppEditModal from "components/basicComponents/modals/AppEditModal";
import AppUploadDocument from "components/basicComponents/uploadFile/uploadDocument/AppUploadDocument";
import dayjs from "dayjs";
import { Form, Formik, FormikProps } from "formik";
import { getValueBasedOnLang } from "helpers";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useGetByIdService, useUpdateService } from "services";
import { useCustomUpdateService } from "services/additionalServices";
import { AppUrls } from "services/appUrls";
import { ISelectedRow } from "types/appTypes/baseEntity/baseDto";

import {
  ISessionsDetailsDto,
  IUpdateSessionDto,
} from "types/entitiesTypes/session";
import * as yup from "yup";

interface IEditsessionProps {
  showEditModal: boolean;
  setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  label: string;
  selectedRow: ISelectedRow;
  onSuccess?: () => void;
  onFailed?: () => void;
}
const Editsession: React.FC<IEditsessionProps> = (props) => {
  const { showEditModal, setShowEditModal, label, selectedRow } = props;

  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);

  const { messages } = useIntl();
  const refForm = React.useRef<FormikProps<IUpdateSessionDto>>(null);
  const {
    data: sessionItem,
    error,
    isLoading,
  } = useGetByIdService<ISessionsDetailsDto>(AppUrls.Session, selectedRow?.id);

  const mutation = useCustomUpdateService<IUpdateSessionDto, string>(
    AppUrls.SessionUpdate,
    AppUrls.Session,
    "updateSuccess",
    false,
    "list"
  );
  const validationSchema = yup.object({
    nameAr: yup.string().required(String(messages["validation.required"])),
    nameEn: yup.string().required(String(messages["validation.required"])),
    date: yup
      .date()
      .typeError(String(messages["validation.required"]))
      .required(String(messages["validation.required"]))
      .min(
        moment.utc().utcOffset(3).toDate(),
        String(messages["validation.timeSessionGreaterThanFrom"])
      ),
  });

  // useEffect(() => {
  //   if (sessionItem && sessionItem.files)
  //     setUploadedFiles([
  //       {
  //         preview: BaseFileUrl + sessionItem.files,
  //         originalUrl: sessionItem.files,
  //       },
  //     ]);
  // }, [sessionItem]);

  // useEffect(() => {
  //   if (uploadedFiles) refForm.current?.setFieldValue("file", uploadedFiles);
  // }, [uploadedFiles]);

  const submit = () => {
    if (refForm.current) {
      refForm.current.submitForm();
    }
  };

  return (
    <>
      <AppEditModal
        label={label}
        itemName={getValueBasedOnLang(
          ` - ${selectedRow?.nameAr}`,
          selectedRow?.nameEn ? ` - ${selectedRow?.nameEn}` : ""
        )}
        open={showEditModal}
        onClose={() => {
          setShowEditModal(false);
        }}
        handleSubmit={submit}
        loading={isLoading}
        errorMessage={(error as any) || null}
        loadingForEdit={
          (refForm.current?.isSubmitting && !refForm.current?.isValidating) ||
          false
        }
        closeModalState={setShowEditModal}
        successClose={mutation.isSuccess}
        maxWidth={"sm"}
      >
        <Formik
          validateOnChange={true}
          initialValues={{
            id: selectedRow?.id || "",
            nameAr: sessionItem?.nameAr || "",
            nameEn: sessionItem?.nameEn || "",
            date: sessionItem?.date || "",
            duration: sessionItem?.duration || 0,
          }}
          innerRef={refForm}
          validationSchema={validationSchema}
          onSubmit={async (data, { setSubmitting }) => {
            let dataToSend = {
              ...data,
              date: moment
                .utc(data?.date)
                .subtract(3, "hours")
                .local()
                .format(),
            };
            mutation.submitData(dataToSend);
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <AppTextField
                    label={<IntlMessages id="common.nameAr" />}
                    name="nameAr"
                    value={values?.nameAr}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <AppTextField
                    label={<IntlMessages id="common.nameEn" />}
                    name="nameEn"
                    value={values?.nameEn}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AppDateTimePicker
                    value={values?.date}
                    label={"session.date"}
                    name="date"
                    onChange={setFieldValue}
                    disablePast={true}
                    minTime={false}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </AppEditModal>
    </>
  );
};

export default Editsession;
