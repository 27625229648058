import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Pagination,
  Typography,
} from "@mui/material";
import { AxiosError } from "axios";
import SubscriptionStatusBadge from "components/basicComponents/badge/SubscriptionStatusBadge";
import DataItem from "components/basicComponents/mobile/DataItem";
import AppIconButton from "components/basicComponents/others/AppIconButton";
import LoadingAndErrorWrapper from "components/basicComponents/others/LoadingAndErrorWrapper";
import moment from "moment";
import { FC } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import { IStudentsSubscriptionListDto } from "types/entitiesTypes/studentSubscription";

interface IStudentsSubscriptionCardProps {
  StudentsSubscriptionList: Array<IStudentsSubscriptionListDto>;
  pageNumber?: number;
  setPage?: (event: React.ChangeEvent<unknown>, page: number) => void;
  page?: number;
  loading: boolean;
  errorMessage: AxiosError<any, any> | null;
}
const StudentsSubscriptionCard: FC<IStudentsSubscriptionCardProps> = (
  props
) => {
  const { studentIdParams, diplomaIdParams } = useParams();

  const {
    StudentsSubscriptionList,
    pageNumber,
    setPage,
    page,
    loading,
    errorMessage,
  } = props;
  const { messages, locale } = useIntl();
  return (
    <Grid container spacing={4}>
      <LoadingAndErrorWrapper
        isLoading={loading}
        errorMessage={errorMessage?.response?.data.messages[0]}
      >
        {StudentsSubscriptionList?.length != undefined &&
        StudentsSubscriptionList?.length >= 1 ? (
          StudentsSubscriptionList?.map((item, index) => (
            <Grid key={`StudentsSubscriptionList${index}`} item xs={12}>
              <Card sx={{ width: "100%" }}>
                {/* <CardHeader title={} /> */}
                <Box
                  marginX={4}
                  marginTop={3}
                  display={"flex"}
                  justifyContent={"flex-end"}
                >
                  {/* Details */}
                  <AppIconButton
                    label="table.actions.details"
                    withLink={true}
                    toPathLink={`/managePayments/installments/profile/${item?.id}`}
                    childComp={
                      <RemoveRedEyeIcon
                        fontSize="small"
                        sx={{ color: "#007991" }}
                      />
                    }
                    // For Permissions
                    schemaName={SchemaName.SystemConfigurations}
                    entityName={EntityName.Countries}
                    action={PermissionActionsTypes.Details}
                  />
                </Box>
                <CardContent
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: 3,
                  }}
                >
                  {!diplomaIdParams && (
                    <DataItem
                      label={messages["diplomas.name"] as string}
                      value={
                        locale === "ar-SA"
                          ? item.diploma.nameAr
                          : item.diploma.nameEn
                      }
                    />
                  )}
                  {!studentIdParams && (
                    <DataItem
                      label={messages["student.name"] as string}
                      value={item.student.fullName}
                    />
                  )}

                  <DataItem
                    label={messages["student.subscriptionDate"] as string}
                    value={moment(item?.subscriptionDate).format(
                      "YYYY-MM-DD HH:mm"
                    )}
                  />
                  <DataItem
                    label={messages["student.subscriptionType"] as string}
                    value={
                      messages[
                        item.subscriptionType === 1
                          ? "student.subscriptionType.onePayment"
                          : "student.subscriptionType.multiPayment"
                      ] as string
                    }
                  />

                  <DataItem
                    label={messages["student.subscriptionStatus"] as string}
                    value={
                      <SubscriptionStatusBadge
                        subscriptionStatus={item?.subscriptionStatus}
                      />
                    }
                  />
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            item
            xs={12}
            marginTop={10}
          >
            <Typography variant="h5" component={"span"}>
              {messages["common.dataFound"]}
            </Typography>
          </Grid>
        )}
        {pageNumber! > 1 && (
          <Grid
            display={"flex"}
            alignItems={"cneter"}
            justifyContent={"center"}
            marginTop={5}
            item
            xs={12}
          >
            <Pagination
              count={pageNumber}
              page={page}
              color="primary"
              onChange={(event, page) => {
                setPage && setPage(event, page);
              }}
            />
          </Grid>
        )}
      </LoadingAndErrorWrapper>
    </Grid>
  );
};

export default StudentsSubscriptionCard;
