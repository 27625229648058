import DiplomaAddPage from "./diplomas/DiplomaAddPage";
import DiplomaEditPage from "./diplomas/DiplomaEditPage";
import DiplomaProfile from "./diplomas/DiplomaProfile";
import DiplomasPages from "./diplomas/DiplomasPages";

export const scientificContentManagement = [
  {
    path: "/scientificContentManagement/diplomas",
    element: <DiplomasPages />,
  },
  {
    path: "/scientificContentManagement/diplomas/profile/:diplomaIdParams/*",
    element: <DiplomaProfile />,
  },
  {
    path: "/scientificContentManagement/diplomas/edit/:diplomaIdParams",
    element: <DiplomaEditPage />,
  },
  {
    path: "/scientificContentManagement/diplomas/add/",
    element: <DiplomaAddPage />,
  },
  {
    path: "/scientificContentManagement/diplomas/add/educationalCenter/:educationalCenterIdParams",
    element: <DiplomaAddPage />,
  },
  {
    path: "/scientificContentManagement/diplomas/add/instructor/:instructorIdParams",
    element: <DiplomaAddPage />,
  },
];
