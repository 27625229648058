import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { GridColDef } from "@mui/x-data-grid";
import StatusBadge from "components/basicComponents/badge/StatusBadge";
import AddDataGridActivation from "components/basicComponents/dataGrid/AddDataGridActivation";
import AppDataGridCell from "components/basicComponents/dataGrid/AppDataGridCell";
import AppIconButton from "components/basicComponents/others/AppIconButton";
import { useIntl } from "react-intl";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import { IStudentListDto } from "types/entitiesTypes/student";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AppIsExistView from "components/basicComponents/others/AppIsExistView";

export const StudentColumns = (
  handleToggleActive = (row: IStudentListDto) => {},
  handleDetails = (row: IStudentListDto) => {},
  handleUpdate = (row: IStudentListDto) => {}
): GridColDef[] => {
  const { messages } = useIntl();

  const StudentColumns: GridColDef[] = [
    {
      field: "fullName",
      headerName: messages["student.fullName"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => <AppDataGridCell value={params.row?.fullName} />,
    },
    {
      field: "lastName",
      headerName: messages["student.lastName"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => <AppDataGridCell value={params.row?.lastName} />,
    },
    {
      field: "nationalNumber",
      headerName: messages["common.nationalNumber"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell value={params.row?.nationalNumber} />
      ),
    },
    {
      field: "phoneNumber",
      headerName: messages["common.phone"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell value={params.row?.phoneNumber} />
      ),
    },

    {
      field: "email",
      headerName: messages["common.email"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => <AppDataGridCell value={params.row?.email} />,
    },
    {
      field: "academicNumber",
      headerName: messages["student.academicNumber"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell value={params.row?.academicNumber} />
      ),
    },

    {
      field: "isActive",
      headerName: messages["common.isActive"] as string,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",

      align: "center",
      renderCell: (params) => {
        return <StatusBadge isActive={params?.row?.isActive} />;
      },
    },
    {
      field: "actions",
      headerName: messages["common.actions"] as string,
      sortable: false,
      align: "center",
      filterable: false,
      headerAlign: "center",
      flex: 0.1,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div>
            {/* Details */}
            <AppIconButton
              label="table.actions.details"
              withLink={true}
              toPathLink={`/usersManagement/students/profile/${params?.row?.id}`}
              childComp={
                <RemoveRedEyeIcon fontSize="small" sx={{ color: "#007991" }} />
              }
              // For Permissions
              schemaName={SchemaName.SystemConfigurations}
              entityName={EntityName.Countries}
              action={PermissionActionsTypes.Details}
            />
            {/* Activation */}
            <AddDataGridActivation
              value={params.row.isActive}
              handleClick={() => {
                handleToggleActive(params.row);
              }}
              // For Permissions
              schemaName={SchemaName.ClientsManagement}
              entityName={EntityName.Departments}
              action={PermissionActionsTypes.Activate}
            />
            {/* Edit */}
            <AppIconButton
              label="table.actions.edit"
              handleClick={() => {
                handleUpdate(params.row);
              }}
              childComp={
                <EditOutlinedIcon fontSize="small" sx={{ color: "#A0CCDA" }} />
              }
              // For Permissions
              schemaName={SchemaName.SystemConfigurations}
              entityName={EntityName.Countries}
              action={PermissionActionsTypes.Edit}
            />
          </div>
        );
      },
    },
  ];

  return StudentColumns;
};
