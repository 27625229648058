import NotificationPages from "pages/notifications/NotificationPages";
import AdvertisementSlidersPages from "./advertisementSliders/AdvertisementSlidersPages";
import CategoriesPages from "./categories/CategoriesPages";
import RegionsPages from "./regions/RegionsPages";
import SystemSettingsPages from "./systemSettings/SystemSettingsPages";

export const systemConfigurationsPages = [
  {
    path: "/systemConfigurations/categories",
    element: <CategoriesPages />,
  },
  {
    path: "/systemConfigurations/regions",
    element: <RegionsPages />,
  },
  {
    path: "/systemConfigurations/advertisementSliders",
    element: <AdvertisementSlidersPages />,
  },

  {
    path: "/systemConfigurations/systemSettings",
    element: <SystemSettingsPages />,
  },

  {
    path: "/systemConfigurations/notifications",
    element: <NotificationPages />,
  },
];
