import { useEffect, useState } from "react";
import { useAutocompleteService } from "services";

interface IGeneralAutoComplete<T> {
  url?: string;
}

const useGeneralAutoComplete = <T,>({ url }: IGeneralAutoComplete<T>) => {
  const [keyword, setKeyword] = useState<string>();
  const [pageNumber, setPageNumber] = useState(1);

  // Get Auto CompleteList
  const {
    data: dataList,
    isFetching,
    refetch,
  } = useAutocompleteService<T>(url!, {
    isActive: true,
    keyword: keyword,
    pageSize: 10,
    pageNumber: pageNumber,
  });

  useEffect(() => {
    refetch();
  }, [pageNumber]);

  return {
    keyword,
    setKeyword,
    pageNumber,
    setPageNumber,
    dataList,
    isFetching,
  };
};

export default useGeneralAutoComplete;
