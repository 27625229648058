import { Grid } from "@mui/material";
import BaseDetails from "components/basicComponents/details/BaseDetails";
import AppItemView from "components/basicComponents/others/AppItemView";
import {
  IDiplomaInstallmentDetailsDto,
  IDiplomaInstallmentListDto,
} from "types/entitiesTypes/DiplomaInstallment";

interface IDiplomaInstallmentDetailsProps {
  DiplomaInstallmentItem: IDiplomaInstallmentListDto;
  setShowDetails: React.Dispatch<React.SetStateAction<boolean>>;
  showDetails: boolean;
}

const DiplomaInstallmentDetails = (props: IDiplomaInstallmentDetailsProps) => {
  const { DiplomaInstallmentItem, setShowDetails, showDetails } = props;

  return (
    <>
      <BaseDetails
        itemName={""}
        label="diplomaInstallment.details.label"
        open={showDetails}
        onClose={() => setShowDetails(false)}
        loading={false}
        errorMessage={""}
        maxWidth="sm"
        columns={12}
        withoutSecoundTab={false}
      >
        <Grid container spacing={5} item xs={12}>
          <AppItemView
            label="diplomaInstallment.dueDate"
            value={DiplomaInstallmentItem.dueDate}
            sm={4}
            type="dateTime"
          />
          <AppItemView
            label="diplomaInstallment.amount"
            value={DiplomaInstallmentItem?.amount}
            sm={4}
          />
          <AppItemView
            label="diplomaInstallment.daysMargin"
            value={DiplomaInstallmentItem?.daysMargin}
            sm={4}
          />
        </Grid>
      </BaseDetails>
    </>
  );
};

export default DiplomaInstallmentDetails;
