import LoadingAndErrorWrapper from "components/basicComponents/others/LoadingAndErrorWrapper";
import AppProfile, {
  IProfileComponents,
  IProfileRoutes,
} from "components/basicComponents/profile/AppProfile";
import InvoiceDetails from "components/managePayments/invoices/InvoiceDetails";
import { HiOutlineInformationCircle } from "react-icons/hi";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useGetByIdService } from "services";
import { AppUrls } from "services/appUrls";
import { IInvoiceDetailsDto } from "types/entitiesTypes/invoices";
import { EntityName, SchemaName } from "../../../shared/constants/AppConst";
import { PermissionActionsTypes } from "../../../types/entitiesTypes/enums";
import PaymentPages from "./payment/PaymentPages";
import PaidIcon from "@mui/icons-material/Paid";
const InvoiceProfile: React.FC = () => {
  const { messages } = useIntl();
  const { invoiceIdParams } = useParams();
  const {
    data: invoiceItem,
    error,
    isLoading,
  } = useGetByIdService<IInvoiceDetailsDto>(
    AppUrls.Invoice,
    invoiceIdParams || ""
  );
  const sidebarRoutes: IProfileRoutes[] = [
    {
      path: "/",
      routeComponent: <InvoiceDetails invoiceItem={invoiceItem!} />,
    },
    {
      path: "/payment",
      routeComponent: <PaymentPages />,
    },
    //InvoicesPage
  ];

  const childrenComponents: IProfileComponents[] = [
    {
      toPath: "",
      icon: <HiOutlineInformationCircle size={20} />,
      label: "details",
      schemaName: SchemaName.SystemConfigurations,
      entityName: EntityName.States,
      action: PermissionActionsTypes.Details,
    },
    {
      toPath: "payment",
      icon: <PaidIcon fontSize="small" />,
      label: "sidebar.payment",
      schemaName: SchemaName.SystemConfigurations,
      entityName: EntityName.States,
      action: PermissionActionsTypes.Details,
    },
  ];

  return (
    <>
      <LoadingAndErrorWrapper
        isLoading={isLoading}
        errorMessage={error?.response?.data.messages[0]}
      >
        <AppProfile
          error={error}
          breadcrumbPaths={[
            { name: messages["sidebar.managePayments.title"] },
            {
              name: messages["sidebar.invoices"],
              path: "/managePayments/invoices",
            },
          ]}
          currentBreadcrumbPath="details"
          sidebarRoutes={sidebarRoutes}
          childrenComponents={childrenComponents}
        />
      </LoadingAndErrorWrapper>
    </>
  );
};

export default InvoiceProfile;
