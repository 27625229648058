import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Pagination,
  Typography,
} from "@mui/material";
import { AxiosError } from "axios";
import DataItem from "components/basicComponents/mobile/DataItem";
import AppIconButton from "components/basicComponents/others/AppIconButton";
import LoadingAndErrorWrapper from "components/basicComponents/others/LoadingAndErrorWrapper";
import { getValueBasedOnLang } from "helpers";
import { dateTimeFormatter } from "helpers/string/dateFormatter";
import { FC } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import { IPaymentListDto } from "types/entitiesTypes/payment";
import { enumPaymentStatus, enumPaymentType } from "./PaymentColumns";
import moment from "moment";

interface IPaymentCardProps {
  handleApprove: (row: IPaymentListDto) => void;
  paymentList: Array<IPaymentListDto>;
  pageNumber?: number;
  setPage?: (event: React.ChangeEvent<unknown>, page: number) => void;
  page?: number;
  loading: boolean;
  errorMessage: AxiosError<any, any> | null;
  handleDetails: (row: IPaymentListDto) => void;
}
const PaymentCard: FC<IPaymentCardProps> = (props) => {
  const {
    handleDetails,
    paymentList,
    pageNumber,
    setPage,
    page,
    loading,
    errorMessage,
    handleApprove,
  } = props;
  const { messages, locale } = useIntl();
  const { invoiceIdParams, subscriptionIdParams } = useParams();
  return (
    <Grid container spacing={4}>
      <LoadingAndErrorWrapper
        isLoading={loading}
        errorMessage={errorMessage?.response?.data.messages[0]}
      >
        {paymentList?.length != undefined && paymentList?.length >= 1 ? (
          paymentList?.map((item, index) => (
            <Grid key={`paymentList${index}`} item xs={12}>
              <Card sx={{ width: "100%" }}>
                <Box
                  marginX={4}
                  marginTop={3}
                  display={"flex"}
                  justifyContent={"flex-end"}
                  gap={3}
                >
                  {Boolean(
                    !invoiceIdParams &&
                      !subscriptionIdParams &&
                      item?.paymentStatus === 0
                  ) && (
                    <AppIconButton
                      label="table.actions.approve"
                      handleClick={() => {
                        handleApprove(item);
                      }}
                      childComp={
                        <CheckCircleIcon
                          fontSize="small"
                          sx={{ color: "#0c8700bd" }}
                        />
                      }
                      // For Permissions
                      schemaName={SchemaName.SystemConfigurations}
                      entityName={EntityName.Countries}
                      action={PermissionActionsTypes.Details}
                    />
                  )}

                  {/* Details */}
                  <AppIconButton
                    label="table.actions.details"
                    handleClick={() => {
                      handleDetails(item);
                    }}
                    childComp={
                      <RemoveRedEyeIcon
                        fontSize="small"
                        sx={{ color: "#007991" }}
                      />
                    }
                    // For Permissions
                    schemaName={SchemaName.SystemConfigurations}
                    entityName={EntityName.Countries}
                    action={PermissionActionsTypes.Details}
                  />
                </Box>
                <CardContent
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: 3,
                    paddingTop: "0 !important",
                  }}
                >
                  <DataItem
                    label={messages["diplomas.name"] as string}
                    value={
                      item.subscription?.diploma
                        ? getValueBasedOnLang(
                            item?.subscription?.diploma?.nameAr,
                            item?.subscription?.diploma?.nameEn
                          )
                        : "-"
                    }
                  />
                  <DataItem
                    label={messages["educationalCenter.name"] as string}
                    value={
                      item.subscription?.diploma?.educationalCenter
                        ? getValueBasedOnLang(
                            item?.subscription?.diploma?.educationalCenter
                              .nameAr,
                            item?.subscription?.diploma?.educationalCenter
                              .nameEn
                          )
                        : "-"
                    }
                  />
                  <DataItem
                    label={messages["invoices.payment.amount"] as string}
                    value={item?.amount}
                  />
                  {/* <DataItem
                    label={
                      messages["invoices.payment.paymentNumberS"] as string
                    }
                    value={item?.paymentNumberS}
                  /> */}
                  <DataItem
                    label={messages["diplomas.installments.dueDate"] as string}
                    value={moment(item.dueDate).format("yyyy-MM-DD")}
                  />
                  <DataItem
                    label={messages["invoices.payment.paymentStatus"] as string}
                    value={String(
                      messages[enumPaymentStatus(item?.paymentStatus)]
                    )}
                  />
                  <DataItem
                    label={messages["invoices.payment.paymentType"] as string}
                    value={String(messages[enumPaymentType(item?.paymentType)])}
                  />
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            item
            xs={12}
            marginTop={10}
          >
            <Typography variant="h5" component={"span"}>
              {messages["common.dataFound"]}
            </Typography>
          </Grid>
        )}
        {pageNumber! > 1 && (
          <Grid
            display={"flex"}
            alignItems={"cneter"}
            justifyContent={"center"}
            marginTop={5}
            item
            xs={12}
          >
            <Pagination
              count={pageNumber}
              page={page}
              color="primary"
              onChange={(event, page) => {
                setPage && setPage(event, page);
              }}
            />
          </Grid>
        )}
      </LoadingAndErrorWrapper>
    </Grid>
  );
};

export default PaymentCard;
