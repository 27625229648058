import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Divider, Grid, Typography } from "@mui/material";
import AppIconButton from "components/basicComponents/others/AppIconButton";
import AppItemView from "components/basicComponents/others/AppItemView";
import { FC } from "react";
import { Fonts } from "shared/constants/AppEnums";

import { removeHTMLTags } from "helpers";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import type { ISystemSetting } from "types/entitiesTypes/systemSettings";
import { useIntl } from "react-intl";

interface ISystemSettingsItemProps {
  SystemSettingsList: Array<ISystemSetting>;
  handleUpdate: (row: ISystemSetting) => void;
}
const SystemSettingsItem: FC<ISystemSettingsItemProps> = (props) => {
  const { SystemSettingsList, handleUpdate } = props;
  const { messages } = useIntl();
  return (
    <Grid
      sx={{ boxShadow: " 0px 10px 10px 4px rgba(0, 0, 0, 0.04)" }}
      width={"100%"}
      bgcolor={"#FFF"}
      borderRadius={3}
      marginTop={5}
      item
      xl={16}
      padding={1}
    >
      {SystemSettingsList?.map((item, index) => (
        <>
          <Grid item key={`SystemSettingsList-${index}`} xs={12}>
            <Grid container padding={3} spacing={2}>
              <Grid item sm={6} xs={12}>
                <Typography sx={{ fontSize: 14, fontWeight: Fonts.SEMI_BOLD }}>
                  {item.key.toLowerCase() === "privacy policy"
                    ? messages[
                        "systemConfigurations.systemSettings.privacyPolicy"
                      ]
                    : item.key.toLowerCase() === "refund period"
                    ? messages[
                        "systemConfigurations.systemSettings.refundPeriod"
                      ]
                    : item.key.toLowerCase() === "tax"
                    ? messages["systemConfigurations.systemSettings.tax"]
                    : item.key.toLowerCase() === "terms"
                    ? messages["systemConfigurations.systemSettings.terms"]
                    : item.key}
                </Typography>
                <Typography sx={{ fontSize: 14, fontWeight: Fonts.REGULAR }}>
                  {removeHTMLTags(item.value)}
                </Typography>
              </Grid>

              <AppItemView
                label="systemConfigurations.systemSettings.description"
                value={item.description}
                sm={5.5}
              />
              <Grid item sm={0.5} xs={12}>
                {/* Edit */}
                <AppIconButton
                  label="table.actions.edit"
                  handleClick={() => {
                    handleUpdate(item);
                  }}
                  childComp={
                    <EditOutlinedIcon
                      fontSize="small"
                      sx={{ color: "#A0CCDA" }}
                    />
                  }
                  // For Permissions
                  schemaName={SchemaName.SystemConfigurations}
                  entityName={EntityName.States}
                  action={PermissionActionsTypes.Edit}
                />
              </Grid>
            </Grid>
          </Grid>
          <Divider key={`dividerSystemSettingsList-${index}`} />
        </>
      ))}
    </Grid>
  );
};

export default SystemSettingsItem;
