import { Grid, Hidden, MenuItem } from "@mui/material";
import { GridSelectionModel } from "@mui/x-data-grid";
import AppBreadcrumb from "components/basicComponents/breadcrumb/AppBreadcrumb";
import AppDataGrid from "components/basicComponents/dataGrid/AppDataGrid";
import AppAdvanceFilterAutoComplete from "components/basicComponents/filters/AppAdvanceFilterAutoComplete";
import AppFilters from "components/basicComponents/filters/AppFilters";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import {
  useAdvanceFilterAutocompleteService,
  useGetListService,
} from "services";
import { AppUrls } from "services/appUrls";
import {
  DEBOUNCE_TIME,
  EntityName,
  SchemaName,
} from "shared/constants/AppConst";
import { IDiplomasListDto } from "types/entitiesTypes/diplomas";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import { IStudentListDto } from "types/entitiesTypes/student";
import {
  IStudentsSubscriptionListDto,
  IStudentSubscriptionSearch,
} from "types/entitiesTypes/studentSubscription";
import { VirtualSceneRequestsColumns } from "./VirtualSceneRequestsColumns";
import { IPdfHeader } from "types/appTypes/baseEntity/baseDto";
import ImportDocumentVert from "components/trainStructure/attachments/ImportDocumentVert";
import VirtualSceneRequestsCard from "./VirtualSceneRequestsCard";
import EditvirtualSceneFile from "./EditvirtualSceneFile";
import {
  IStudentAttachmentsList,
  IStudentAttachmentsSearch,
} from "types/entitiesTypes/studentAttachments";
import { IEducationalCenterListDto } from "types/entitiesTypes/educationalCenter";
import { getValueBasedOnLang } from "helpers";

const VirtualSceneRequestsPage = () => {
  const { messages, locale } = useIntl();
  const { studentIdParams, diplomaIdParams } = useParams();

  const [search, setSearch] = useState<IStudentAttachmentsSearch>({
    pageNumber: 1,
    pageSize: 10,
    studentId: studentIdParams || null,
    diplomaId: diplomaIdParams || null,
    type: 1,
  });
  const [showImportModal, setShowImportModal] = useState<boolean>(false);

  const [studentKeyword, setStudentKeyword] = useState<string>("");
  const [studentPageNumber, setStudentPageNumber] = useState(1);

  const [diplomaKeyword, setDiplomaKeyword] = useState<string>("");
  const [diplomaPageNumber, setDiplomaPageNumber] = useState(1);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const [selectedRow, setSelectedRow] = useState<IStudentAttachmentsList>();
  useState<boolean>(false);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  // For hide breadcrumb in country profile
  // For Advance Filters
  const [advancedFilter, setAdvancedFilter] = useState(null);
  const [isOpenAdvanceFilters, setIsOpenAdvanceFilters] =
    useState<boolean>(false);
  const [educationalCenterKeyword, setEducationalCenterKeyword] =
    useState<string>("");
  const [educationalCenterPageNumber, setEducationalCenterPageNumber] =
    useState(1);

  const {
    data: educationalCentersList,
    isFetching: isFetchingEducationalCenters,
    refetch: refetchEducationalCenters,
  } = useAdvanceFilterAutocompleteService<IEducationalCenterListDto>(
    AppUrls.EducationalCenter,
    {
      keyword: educationalCenterKeyword,
      pageSize: 10,
      pageNumber: educationalCenterPageNumber,
    }
  );
  const {
    resultData: StudentsSubscriptionList,
    isFetching,
    error,
  } = useGetListService<IStudentAttachmentsList>(AppUrls.StudentDocuments, {
    ...search,
    advancedFilter,
  });

  const {
    data: diplomasList,
    isFetching: isFetchingDiplomas,
    refetch: refetchDiplomas,
  } = useAdvanceFilterAutocompleteService<IDiplomasListDto>(
    AppUrls.Diplomas,
    {
      keyword: diplomaKeyword,
      isActive: true,
      pageSize: 10,
      pageNumber: diplomaPageNumber,
    },
    Boolean(!diplomaIdParams)
  );

  const {
    data: studentsList,
    isFetching: isFetchingStudents,
    refetch: refetchStudents,
  } = useAdvanceFilterAutocompleteService<IStudentListDto>(
    AppUrls.Student,
    {
      keyword: studentKeyword,
      isActive: true,
      pageSize: 10,
      pageNumber: studentPageNumber,
    },
    Boolean(!studentIdParams)
  );

  useEffect(() => {
    if (educationalCenterPageNumber > 1) refetchEducationalCenters();
  }, [educationalCenterPageNumber]);

  useEffect(() => {
    if (Boolean(!diplomaIdParams)) refetchDiplomas();
  }, [diplomaPageNumber, diplomaIdParams]);

  useEffect(() => {
    if (Boolean(!studentIdParams)) refetchStudents();
  }, [studentPageNumber, studentIdParams]);
  // For edit
  const handleUpdate = useCallback((selectedRow: IStudentAttachmentsList) => {
    setSelectedRow(selectedRow);
    setShowEditModal(true);
  }, []);
  const columns = VirtualSceneRequestsColumns(handleUpdate);

  const pdfHeaders: IPdfHeader[] = [
    {
      key: "subscription.student.id",
      value: String(messages["student.id"]),
    },
    {
      key: "subscription.diploma.nameAr",
      value: String(messages["diploma.nameAr"]),
    },
    {
      key: "subscription.diploma.nameEn",
      value: String(messages["diploma.nameEn"]),
    },
    {
      key: "subscription.diploma.startDate",
      value: String(messages["common.startDate"]),
    },
    {
      key: "subscription.diploma.durationInHours",
      value: String(messages["diplomas.hoursNumber"]),
    },

    {
      key: "subscription.student.fullName",
      value: String(messages["student.name"]),
    },
    {
      key: "subscription.student.lastName",
      value: String(messages["common.lastName"]),
    },
    {
      key: "subscription.student.nationalNumber",
      value: String(messages["common.id"]),
    },
    {
      key: "subscription.student.phoneNumber",
      value: String(messages["common.phone"]),
    },
    {
      key: "subscription.subscriptionDate",
      value: String(messages["student.subscriptionDate"]),
    },
  ];

  return (
    <>
      <Grid container spacing={3}>
        {!studentIdParams && !diplomaIdParams && (
          <AppBreadcrumb
            paths={[{ name: messages["sidebar.trainStructure.title"] }]}
            currentPathName={"sidebar.virtualSceneRequests"}
          />
        )}

        <Grid item xs={12}>
          <AppFilters
            //excel pdf
            filterAll={{
              ...search,
              advancedFilter,
            }}
            onChangeKeywordValue={(
              event: React.ChangeEvent<HTMLInputElement>
            ) => {
              setSearch({ ...search, keyword: event.target.value });
            }}
            showSearchInput={false}
            selectionModel={selectionModel as string[]}
            setSelectionModel={setSelectionModel}
            successUrl={AppUrls.StudentDocuments}
            keyword={search?.keyword}
            advancedFilter={advancedFilter}
            showAddButton={false}
            totalCount={StudentsSubscriptionList?.totalCount || 0}
            // For Permissions
            schemaName={SchemaName.SystemConfigurations}
            entityName={EntityName.Countries}
            action={PermissionActionsTypes.Add}
            // Advance Filters
            showAdvanceFiltersButton={true}
            isOpenAdvanceFilters={isOpenAdvanceFilters}
            setIsOpenAdvanceFilters={setIsOpenAdvanceFilters}
            setAdvancedFilter={setAdvancedFilter}
            pdfHeaders={pdfHeaders}
            showImportButton={true}
            setImportModal={setShowImportModal}
            excelFileTitle={String(messages["sidebar.virtualSceneRequests"])}
            withExportExcelButton
            customQueries={{
              ...search,
            }}
            advanceFiltersInputs={[
              Boolean(!diplomaIdParams) && (
                <AppAdvanceFilterAutoComplete
                  label="diplomas.name"
                  fieldName="diplomaId"
                  options={diplomasList?.data}
                  isLoading={isFetchingDiplomas}
                  searchText={diplomaKeyword}
                  setSearchText={setDiplomaKeyword}
                  onChangeTextField={debounce((event) => {
                    setDiplomaKeyword(event.target.value);
                  }, DEBOUNCE_TIME)}
                  //pagination
                  pageNumber={diplomaPageNumber}
                  setPageNumber={setDiplomaPageNumber}
                  hasNextPage={diplomasList?.hasNextPage}
                  setAdvancedFilter={setSearch}
                  advancedFilter={search}
                />
              ),
              <AppAdvanceFilterAutoComplete
                label="diplomas.educationalCenter"
                fieldName="educationalCenterId"
                options={educationalCentersList?.data}
                isLoading={isFetchingEducationalCenters}
                searchText={educationalCenterKeyword}
                setSearchText={setEducationalCenterKeyword}
                onChangeTextField={debounce((event) => {
                  setEducationalCenterKeyword(event.target.value);
                }, DEBOUNCE_TIME)}
                //pagination
                pageNumber={educationalCenterPageNumber}
                setPageNumber={setEducationalCenterPageNumber}
                hasNextPage={educationalCentersList?.hasNextPage}
                advancedFilter={search}
                getOptionLabel={(option) =>
                  getValueBasedOnLang(option.nameAr, option.nameEn)
                }
                setAdvancedFilter={setSearch}
              />,
            ]}
          />
        </Grid>
        <Hidden mdDown>
          <Grid item xs={12}>
            <AppDataGrid
              rowCount={StudentsSubscriptionList?.totalCount ?? 0}
              pageNumber={search?.pageNumber ?? 0}
              //for pagination
              onPageSizeChange={(pageSize: number) => {
                setSearch({ ...search, pageSize: pageSize });
              }}
              onPageChange={(pageNumber: number) => {
                setSearch({ ...search, pageNumber: pageNumber + 1 });
              }}
              pageSize={search?.pageSize ?? 10}
              columns={columns}
              rows={StudentsSubscriptionList?.data ?? []}
              loading={isFetching}
              //for selection
              onSelectionModelChange={(
                newSelectionModel: GridSelectionModel
              ) => {
                setSelectionModel(newSelectionModel);
              }}
              checkboxSelection={false}
              selectionModel={selectionModel}
              //for sorting
              onSortModelChange={(result: string[] | null) => {
                setSearch({
                  ...search,
                  orderBy: result,
                });
              }}
            />
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid item xs={12}>
            <VirtualSceneRequestsCard
              virtualSceneRequestsList={StudentsSubscriptionList?.data!}
              page={search?.pageNumber}
              pageNumber={Math.ceil(
                (StudentsSubscriptionList?.totalCount || 0) / 10
              )}
              loading={isFetching}
              errorMessage={error}
              handleUpdate={handleUpdate}
            />
          </Grid>
        </Hidden>
      </Grid>

      {showImportModal && (
        <ImportDocumentVert
          label="student.attachments"
          showAddModal={showImportModal}
          setShowAddModal={setShowImportModal}
        />
      )}

      {showEditModal && (
        <EditvirtualSceneFile
          showEditModal={showEditModal}
          setShowEditModal={setShowEditModal}
          label="virtualSceneFile.edit"
          selectedRow={selectedRow!}
        />
      )}
    </>
  );
};

export default VirtualSceneRequestsPage;
