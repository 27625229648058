import { GridColDef } from "@mui/x-data-grid";
import SubscriptionStatusBadge from "components/basicComponents/badge/SubscriptionStatusBadge";
import AppDataGridCell from "components/basicComponents/dataGrid/AppDataGridCell";
import AppIconButton from "components/basicComponents/others/AppIconButton";
import { getValueBasedOnLang } from "helpers";
import moment from "moment";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import { IInvoicesListDto } from "types/entitiesTypes/invoices";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { enumPaymentType } from "./payment/PaymentColumns";

export const InvoicesColumns = (): GridColDef[] => {
  const { messages, locale } = useIntl();
  const { studentIdParams, diplomaIdParams } = useParams();

  const InvoicesColumns: GridColDef[] = [
    {
      field: "invoiceNumber",
      headerName: messages["invoices.invoiceNumber"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      hide: Boolean(studentIdParams),
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell value={`#${params.row?.invoiceNumber}`} />
      ),
    },
    {
      field:
        locale === "ar-SA"
          ? "subscription.diploma.nameAr"
          : "subscription.diploma.nameEn",
      headerName: messages["diplomas.name"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      hide: Boolean(diplomaIdParams),
      sortable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell
          value={getValueBasedOnLang(
            params.row?.subscription?.diploma?.nameAr,
            params.row?.subscription?.diploma?.nameEn
          )}
        />
      ),
    },
    {
      field: "student.fullName",
      headerName: messages["student.name"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      hide: Boolean(studentIdParams),
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => (
        <AppDataGridCell value={params.row?.subscription?.student?.fullName} />
      ),
    },

    {
      field: "diplomaCost",
      headerName: messages["invoices.diplomaCost"] as string,
      headerAlign: "center",
      align: "center",

      flex: 0.2,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell value={params.row?.diplomaCost} />
      ),
    },

    {
      field: "totalCost",
      headerName: messages["invoices.totalCost"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => <AppDataGridCell value={params.row?.totalCost} />,
    },
    // {
    //   field: "subscription.subscriptionDate",
    //   headerName: messages["student.subscriptionDate"] as string,
    //   headerAlign: "center",
    //   align: "center",
    //   flex: 0.2,
    //   minWidth: 130,
    //   headerClassName: "super-app-theme--header",
    //   renderCell: (params) => (
    //     <AppDataGridCell
    //       value={moment(params?.row?.subscription?.subscriptionDate).format(
    //         "YYYY-MM-DD HH:mm"
    //       )}
    //     />
    //   ),
    // },
    {
      field: "issueDate",
      headerName: messages["invoices.issueDate"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell
          value={
            params?.row.issueDate
              ? moment(params?.row?.issueDate).format("YYYY-MM-DD HH:mm")
              : "-"
          }
        />
      ),
    },
    {
      field: "subscription.subscriptionType",
      headerName: messages["invoices.payment.paymentType"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      sortable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell
          value={
            messages[
              params.row?.subscription?.subscriptionType === 1
                ? "student.subscriptionType.onePayment"
                : "student.subscriptionType.multiPayment"
            ] as string
          }
        />
      ),
    },

    {
      field: "lastPaymentType",
      headerName: messages["invoices.lastPaymentType"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      sortable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell
          value={String(messages[enumPaymentType(params.row?.lastPaymentType)])}
        />
      ),
    },
    {
      field: "subscriptionStatus",
      headerName: messages["student.subscriptionStatus"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      sortable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <SubscriptionStatusBadge
          subscriptionStatus={params?.row?.subscription?.subscriptionStatus}
        />
      ),
    },

    {
      field: "actions",
      headerName: messages["common.actions"] as string,
      sortable: false,
      align: "center",
      filterable: false,
      headerAlign: "center",
      flex: 0.1,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div>
            {/* Details */}
            <AppIconButton
              label="table.actions.details"
              withLink={true}
              toPathLink={`/managePayments/invoices/profile/${params?.row?.id}`}
              childComp={
                <RemoveRedEyeIcon fontSize="small" sx={{ color: "#007991" }} />
              }
              // For Permissions
              schemaName={SchemaName.SystemConfigurations}
              entityName={EntityName.Countries}
              action={PermissionActionsTypes.Details}
            />
          </div>
        );
      },
    },
  ];

  return InvoicesColumns;
};
