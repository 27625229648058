import { Grid } from "@mui/material";
import BaseDetails from "components/basicComponents/details/BaseDetails";
import AppItemView from "components/basicComponents/others/AppItemView";
import { useIntl } from "react-intl";
import { useGetByIdService } from "services";
import { AppUrls } from "services/appUrls";
import { IRegionsDetailsDto } from "types/entitiesTypes/regions";

interface IRegionsDetailsProps {
  setShowDetails: React.Dispatch<React.SetStateAction<boolean>>;
  showDetails: boolean;
  id: string;
}

const RegionsDetails = (props: IRegionsDetailsProps) => {
  const { showDetails, setShowDetails, id } = props;
  const { locale } = useIntl();

  const {
    data: regionItem,
    error,
    isLoading,
  } = useGetByIdService<IRegionsDetailsDto>(AppUrls.Regions, id);

  return (
    <>
      <BaseDetails
        itemName={
          locale == "en-US" && regionItem?.nameEn
            ? `- ${regionItem?.nameEn!}`
            : `- ${regionItem?.nameAr!}`
        }
        label="systemConfigurations.regions.details.label"
        open={showDetails}
        onClose={() => setShowDetails(false)}
        loading={isLoading}
        errorMessage={error}
        moreDetails={regionItem as IRegionsDetailsDto}
        maxWidth="sm"
        columns={12}
      >
        <Grid container spacing={5} item xs={12}>
          <AppItemView label="common.nameAr" value={regionItem?.nameAr} />
          <AppItemView label="common.nameEn" value={regionItem?.nameEn} />
          <AppItemView
            label="common.isActive"
            value={regionItem?.isActive}
            type="isActive"
            sm={4}
          />
        </Grid>
      </BaseDetails>
    </>
  );
};

export default RegionsDetails;
