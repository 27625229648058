import IntlMessages from "@crema/utility/IntlMessages";
import { Button, Card, CardContent, Grid } from "@mui/material";
import AppAdvanceFilterAutoComplete from "components/basicComponents/filters/AppAdvanceFilterAutoComplete";
import { getValueBasedOnLang } from "helpers";
import { debounce } from "lodash";
import { FC, useEffect, useState } from "react";
import { useAdvanceFilterAutocompleteService } from "services";
import { AppUrls } from "services/appUrls";
import { DEBOUNCE_TIME } from "shared/constants/AppConst";
import { IAttendanceSearch } from "types/entitiesTypes/attendance";
import { ICoursesListDto } from "types/entitiesTypes/courses";
import { IEducationalCenterListDto } from "types/entitiesTypes/educationalCenter";
import { ISessionsListDto } from "types/entitiesTypes/session";
import { IStudentListDto } from "types/entitiesTypes/student";
interface IAttendanceFilterProps {
  search: IAttendanceSearch;
  setSearch: React.Dispatch<React.SetStateAction<IAttendanceSearch>>;
  setIsSelected: React.Dispatch<React.SetStateAction<boolean>>;
  isSelected: boolean;
}
const AttendanceFilter: FC<IAttendanceFilterProps> = (props) => {
  const { search, setSearch, isSelected, setIsSelected } = props;
  const [sessionKeyword, setSessionKeyword] = useState<string>("");
  const [sessionValue, setSessionValue] = useState<string>("");
  const [studentValue, setStudentValue] = useState<string>("");
  const [courseValue, setCourseValue] = useState<string>("");
  const [educationalCenterValue, setEducationalCenterValue] =
    useState<string>("");
  const [sessionPageNumber, setSessionPageNumber] = useState(1);
  const [educationalCenterKeyword, setEducationalCenterKeyword] =
    useState<string>("");
  const [educationalCenterPageNumber, setEducationalCenterPageNumber] =
    useState(1);

  const [studentKeyword, setStudentKeyword] = useState<string>("");

  const [studentPageNumber, setStudentPageNumber] = useState(1);

  const [courseKeyword, setCourseKeyword] = useState<string>("");

  const [coursePageNumber, setCoursePageNumber] = useState(1);
  const [showButton, setShowButton] = useState(false);
  const {
    data: educationalCentersList,
    isFetching: isFetchingEducationalCenters,
    refetch: refetchEducationalCenters,
  } = useAdvanceFilterAutocompleteService<IEducationalCenterListDto>(
    AppUrls.EducationalCenter,
    {
      keyword: educationalCenterKeyword,
      pageSize: 10,
      pageNumber: educationalCenterPageNumber,
    }
  );

  const {
    data: studentList,
    isFetching: isFetchingStudents,
    refetch: refetchStudents,
  } = useAdvanceFilterAutocompleteService<IStudentListDto>(AppUrls.Student, {
    keyword: studentKeyword,
    pageSize: 10,
    pageNumber: studentPageNumber,
  });

  const {
    data: sessionList,
    isFetching: isFetchingSessionList,
    refetch: refetchSession,
  } = useAdvanceFilterAutocompleteService<ISessionsListDto>(AppUrls.Session, {
    keyword: sessionKeyword,
    pageSize: 10,
    pageNumber: sessionPageNumber,
  });

  const {
    data: courseList,
    isFetching: isFetchingCourseList,
    refetch: refetchCourse,
  } = useAdvanceFilterAutocompleteService<ICoursesListDto>(AppUrls.Courses, {
    keyword: courseKeyword,
    pageSize: 10,
    pageNumber: coursePageNumber,
  });
  useEffect(() => {
    refetchEducationalCenters();
  }, [educationalCenterPageNumber]);

  useEffect(() => {
    refetchStudents();
  }, [studentPageNumber]);

  useEffect(() => {
    refetchCourse();
  }, [coursePageNumber]);

  useEffect(() => {
    refetchSession();
  }, [sessionPageNumber]);

  useEffect(() => {
    if (educationalCenterValue || courseValue || studentValue || sessionValue)
      setShowButton(true);
    else {
      setShowButton(false);
    }
  }, [educationalCenterValue, courseValue, studentValue, sessionValue]);

  useEffect(() => {
    if (
      search.courseId !== undefined ||
      search.educationalCenterId !== undefined ||
      search.sessionId !== undefined ||
      search.studentId !== undefined
    ) {
      setIsSelected(true);
    }
  }, [search]);

  const handleSearch = () => {
    setSearch({
      ...search,
      courseId: courseValue || null!,
      educationalCenterId: educationalCenterValue || null!,
      sessionId: sessionValue || null!,
      studentId: studentValue || null!,
    });
  };

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={2.7}>
            <AppAdvanceFilterAutoComplete
              label="session.courseName"
              fieldName="courseId"
              options={courseList?.data}
              isLoading={isFetchingCourseList}
              searchText={courseKeyword}
              setSearchText={setCourseKeyword}
              onChangeTextField={debounce((event) => {
                setCourseKeyword(event.target.value);
              }, DEBOUNCE_TIME)}
              //pagination
              pageNumber={coursePageNumber}
              setPageNumber={setCoursePageNumber}
              hasNextPage={courseList?.hasNextPage}
              advancedFilter={search}
              getOptionLabel={(option) =>
                getValueBasedOnLang(option.nameAr, option.nameEn)
              }
              setStateValue={setCourseValue}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2.7}>
            <AppAdvanceFilterAutoComplete
              label="session.name"
              fieldName="sessionId"
              options={sessionList?.data}
              isLoading={isFetchingSessionList}
              searchText={sessionKeyword}
              setSearchText={setSessionKeyword}
              onChangeTextField={debounce((event) => {
                setSessionKeyword(event.target.value);
              }, DEBOUNCE_TIME)}
              //pagination
              pageNumber={sessionPageNumber}
              setPageNumber={setSessionPageNumber}
              hasNextPage={sessionList?.hasNextPage}
              advancedFilter={search}
              getOptionLabel={(option) =>
                getValueBasedOnLang(option.nameAr, option.nameEn)
              }
              setStateValue={setSessionValue}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2.7}>
            <AppAdvanceFilterAutoComplete
              label="student.name"
              fieldName="studentId"
              options={studentList?.data}
              isLoading={isFetchingStudents}
              searchText={studentKeyword}
              setSearchText={setStudentKeyword}
              onChangeTextField={debounce((event) => {
                setStudentKeyword(event.target.value);
              }, DEBOUNCE_TIME)}
              //pagination
              pageNumber={studentPageNumber}
              setPageNumber={setStudentPageNumber}
              hasNextPage={studentList?.hasNextPage}
              advancedFilter={search}
              getOptionLabel={(option) => option.fullName}
              filterKey="fullName"
              setStateValue={setStudentValue}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2.7}>
            <AppAdvanceFilterAutoComplete
              label="diplomas.educationalCenter"
              fieldName="educationalCenterId"
              options={educationalCentersList?.data}
              isLoading={isFetchingEducationalCenters}
              searchText={educationalCenterKeyword}
              setSearchText={setEducationalCenterKeyword}
              onChangeTextField={debounce((event) => {
                setEducationalCenterKeyword(event.target.value);
              }, DEBOUNCE_TIME)}
              //pagination
              pageNumber={educationalCenterPageNumber}
              setPageNumber={setEducationalCenterPageNumber}
              hasNextPage={educationalCentersList?.hasNextPage}
              advancedFilter={search}
              getOptionLabel={(option) =>
                getValueBasedOnLang(option.nameAr, option.nameEn)
              }
              setStateValue={setEducationalCenterValue}
            />
          </Grid>

          <Grid item xs={12} md={1}>
            <Button
              fullWidth
              variant="contained"
              sx={{
                borderRadius: 8,
                "& .MuiSvgIcon-root": {
                  fontSize: 26,
                },
                width: "fit-content",
              }}
              onClick={handleSearch}
              disabled={!showButton} // Disable button if no filter is filled
            >
              <IntlMessages id="common.search" />
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AttendanceFilter;
