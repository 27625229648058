import { Grid } from "@mui/material";
import BaseDetails from "components/basicComponents/details/BaseDetails";
import AppItemView from "components/basicComponents/others/AppItemView";
import { useIntl } from "react-intl";
import { useGetByIdService } from "services";
import { AppUrls } from "services/appUrls";
import { ICategoriesDetailsDto } from "types/entitiesTypes/categories";

interface ICategoryDetailsProps {
  setShowDetails: React.Dispatch<React.SetStateAction<boolean>>;
  showDetails: boolean;
  id: string;
}

const CategoryDetails = (props: ICategoryDetailsProps) => {
  const { showDetails, setShowDetails, id } = props;
  const { locale } = useIntl();

  const {
    data: categoryItem,
    error,
    isLoading,
  } = useGetByIdService<ICategoriesDetailsDto>(AppUrls.Categories, id);

  return (
    <>
      <BaseDetails
        itemName={
          locale == "en-US" && categoryItem?.nameEn
            ? `- ${categoryItem?.nameEn!}`
            : `- ${categoryItem?.nameAr!}`
        }
        label="systemConfigurations.categories.details.label"
        open={showDetails}
        onClose={() => setShowDetails(false)}
        loading={isLoading}
        errorMessage={error}
        moreDetails={categoryItem as ICategoriesDetailsDto}
        columns={12}
        maxWidth={"sm"}
      >
        <Grid container spacing={5} item xs={12}>
          <AppItemView label="common.nameAr" value={categoryItem?.nameAr} />
          <AppItemView label="common.nameEn" value={categoryItem?.nameEn} />
        </Grid>
      </BaseDetails>
    </>
  );
};

export default CategoryDetails;
