import AppSelectField from "@crema/core/AppFormComponents/AppSelectField";
import AppTextField from "@crema/core/AppFormComponents/AppTextField";
import IntlMessages from "@crema/utility/IntlMessages";
import { Grid, MenuItem } from "@mui/material";
import AppEditor from "components/basicComponents/inputs/AppEditor";
import AppDateTimePicker from "components/basicComponents/inputs/dateTimePickers/AppDateTimePicker";
import AppTimePicker from "components/basicComponents/inputs/dateTimePickers/AppTimePicker";
import AppEditModal from "components/basicComponents/modals/AppEditModal";
import dayjs from "dayjs";
import { Form, Formik, FormikProps } from "formik";
import { getValueBasedOnLang } from "helpers";
import moment from "moment";
import React from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useGetByIdService, useUpdateService } from "services";
import { AppUrls } from "services/appUrls";
import { ISelectedRow } from "types/appTypes/baseEntity/baseDto";
import {
  IExamDetailsDto,
  IExamsListDto,
  IUpdateExamDto,
} from "types/entitiesTypes/exams";

import {
  IRegionsDetailsDto,
  IUpdateIRegionDto,
} from "types/entitiesTypes/regions";
import * as yup from "yup";

interface IEditExamProps {
  showEditModal: boolean;
  setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  label: string;
  selectedRow: IExamsListDto;
  onSuccess?: () => void;
  onFailed?: () => void;
}
const EditExam: React.FC<IEditExamProps> = (props) => {
  const { showEditModal, setShowEditModal, label, selectedRow } = props;
  const { courseIdParams } = useParams();

  const { messages } = useIntl();
  const refForm = React.useRef<FormikProps<IUpdateExamDto>>(null);
  const {
    data: examItem,
    error,
    isLoading,
  } = useGetByIdService<IExamDetailsDto>(AppUrls.Exam, selectedRow?.id);

  const mutation = useUpdateService<IUpdateExamDto, string>(
    AppUrls.Exam,
    () => {},
    false
  );
  const validationSchema = yup.object({
    nameAr: yup.string().required(String(messages["validation.required"])),
    nameEn: yup.string().required(String(messages["validation.required"])),
    // date: yup
    //   .date()
    //   .typeError(String(messages["validation.required"]))
    //   .required(String(messages["validation.required"]))
    //   .min(new Date(), String(messages["validation.timeGreaterThanFrom"])),

    date: yup
      .date()
      .typeError(String(messages["validation.required"]))
      .required(String(messages["validation.required"]))
      .min(
        moment.utc().utcOffset(3).toDate(),
        String(messages["validation.timeGreaterThanFrom"])
      ),
    // duration: yup
    //   .string()
    //   .matches(
    //     /^(0[0-9]|1[0-2]):([0-5][0-9])$/,
    //     String(messages["validation.timesSpan.required"])
    //   )
    //   .typeError(String(messages["validation.required"]))
    //   .required(String(messages["validation.required"])),
    durationInMin: yup
      .number()
      .typeError(String(messages["validation.required"]))
      .required(String(messages["validation.required"])),
    totalMarks: yup
      .number()
      .typeError(String(messages["validation.required"]))
      .required(String(messages["validation.required"])),
    passingScore: yup
      .number()
      .typeError(String(messages["validation.required"]))
      .required(String(messages["validation.required"]))
      .when("totalMarks", (totalMarks, schema) => {
        return totalMarks
          ? schema.lessThan(
              totalMarks,
              String(messages["validation.passingScore"])
            )
          : schema;
      }),
  });
  const submit = () => {
    if (refForm.current) {
      refForm.current.submitForm();
    }
  };

  return (
    <>
      <AppEditModal
        label={label}
        itemName={getValueBasedOnLang(
          ` - ${selectedRow?.nameAr}`,
          selectedRow?.nameEn ? ` - ${selectedRow?.nameEn}` : ""
        )}
        open={showEditModal}
        onClose={() => {
          setShowEditModal(false);
        }}
        handleSubmit={submit}
        loading={isLoading}
        errorMessage={(error as any) || null}
        loadingForEdit={
          (refForm.current?.isSubmitting && !refForm.current?.isValidating) ||
          false
        }
        closeModalState={setShowEditModal}
        successClose={mutation.isSuccess}
        maxWidth={"md"}
      >
        <Formik
          validateOnChange={true}
          initialValues={{
            id: selectedRow?.id || "",
            nameAr: examItem?.nameAr || "",
            nameEn: examItem?.nameEn || "",
            instructionsAr: examItem?.instructionsAr || "",
            instructionsEn: examItem?.instructionsEn || "",
            courseId: courseIdParams || selectedRow?.course?.id || "",
            date: moment.utc(examItem?.date).local().format() || "",

            durationInMin: examItem?.durationInMin || null,
            passingScore: examItem?.passingScore || null,
            totalMarks: 100,
            type: examItem?.type || 0,
          }}
          innerRef={refForm}
          validationSchema={validationSchema}
          onSubmit={async (data, { setSubmitting }) => {
            let dataToSend = {
              ...data,
              date: moment
                .utc(data?.date)
                .subtract(3, "hours")
                .local()
                .format(),
            };

            mutation.submitData(dataToSend);
          }}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={3}>
                  <AppTextField
                    label={<IntlMessages id="common.nameAr" />}
                    name="nameAr"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <AppTextField
                    label={<IntlMessages id="common.nameEn" />}
                    name="nameEn"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <AppDateTimePicker
                    value={values?.date}
                    label={"exams.date"}
                    name="date"
                    onChange={setFieldValue}
                    disablePast={true}
                    // minTime={
                    //   (
                    //     values?.date
                    //       ? dayjs(values.date).isSame(dayjs(), "day")
                    //       : false
                    //   )
                    //     ? dayjs()
                    //     : false
                    // }
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <AppTextField
                    label={<IntlMessages id="exams.duration" />}
                    name="durationInMin"
                    variant="outlined"
                    size="small"
                    type="number"
                    fullWidth
                  />
                </Grid>

                {/* <Grid item xs={12} sm={3}>
                  <AppTextField
                    label={<IntlMessages id="exams.totalMarks" />}
                    name="totalMarks"
                    variant="outlined"
                    size="small"
                    fullWidth
                    type="number"
                  />
                </Grid> */}
                <Grid item xs={12} sm={3}>
                  <AppTextField
                    label={<IntlMessages id="exams.passingScore" />}
                    name="passingScore"
                    variant="outlined"
                    size="small"
                    fullWidth
                    type="number"
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <AppSelectField
                    label={<IntlMessages id="exams.type" />}
                    name="type"
                    variant="outlined"
                    size="small"
                    fullWidth
                    defaultValue={1}
                  >
                    <MenuItem value={1}>
                      <IntlMessages id="exams.type.first" />
                    </MenuItem>
                    <MenuItem value={2}>
                      <IntlMessages id="exams.type.second" />
                    </MenuItem>
                  </AppSelectField>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <AppEditor
                    label="exams.instructionsAr"
                    name="instructionsAr"
                    setFieldValue={setFieldValue}
                    value={values?.instructionsAr || ""}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AppEditor
                    label="exams.instructionsEn"
                    name="instructionsEn"
                    setFieldValue={setFieldValue}
                    value={values?.instructionsEn}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </AppEditModal>
    </>
  );
};

export default EditExam;
