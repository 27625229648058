import IntlMessages from "@crema/utility/IntlMessages";
import AddModeratorIcon from "@mui/icons-material/AddModerator";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import {
  Collapse,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import BadgeIcon from "@mui/icons-material/Badge";
import ArchiveIcon from "@mui/icons-material/Archive";
import AppBreadcrumb from "components/basicComponents/breadcrumb/AppBreadcrumb";
import LoadingAndErrorWrapper from "components/basicComponents/others/LoadingAndErrorWrapper";
import { getValueBasedOnLang } from "helpers";
import React, { useState } from "react";
import { FaDotCircle } from "react-icons/fa";
import { useIntl } from "react-intl";
import { Link, Route, Routes, useLocation, useParams } from "react-router-dom";
import { useGetByIdService, useGetListService } from "services";
import { AppUrls } from "services/appUrls";
import {
  IAllPermissionsSchemaListDto,
  IAllPermissionsSchemaSearch,
} from "types/entitiesTypes/allPermissionsSchema";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import AppPermissionsGate from "components/basicComponents/permessions/AppPermissionsGate";
import { IUserDetailsDto } from "types/entitiesTypes/users";
import { useJWTAuth } from "@crema/services/auth/jwt-auth/JWTAuthProvider";
import UserDetails from "components/usersManagement/users/UserDetails";
import RolesPage from "../roles/RolesPage";
import TablePermissionsUser from "components/usersManagement/users/TablePermissionsUser";
import { useGetCustomService } from "services/additionalServices";
import { HiOutlineDocumentDownload } from "react-icons/hi";

const UserPersonalProfile: React.FC = () => {
  const { messages } = useIntl();
  const { userIdParams } = useParams();
  const location = useLocation();
  const path = location.pathname.split("/");
  const activePath = !location.pathname?.includes("my-profile")
    ? path[5]
    : path[2];
  const permissionsSchemaPath = !location.pathname?.includes("my-profile")
    ? path[6]
    : path[3];
  const [openPermissionsSchema, setOpenPersonalPermissionsSchema] =
    useState<boolean>(false);

  const { user } = useJWTAuth();
  const search: IAllPermissionsSchemaSearch = {
    userId: userIdParams ? userIdParams : user?.id || "",
  };

  const {
    data: userItem,
    error,
    isLoading,
  } = useGetCustomService<IUserDetailsDto>(
    AppUrls.PersonalProfile,
    userIdParams ? userIdParams : user?.id || ""
  );

  // Get All Permissions Schema
  const { resultData: permissionsSchemaList, isFetching } =
    useGetListService<IAllPermissionsSchemaListDto>(
      AppUrls.AllPermissionsSchemaUser,
      search,
      false
    );

  const handleClick = (setPath, path) => {
    setPath(!path);
  };
  return (
    <Grid container spacing={4}>
      <LoadingAndErrorWrapper
        isLoading={isLoading}
        errorMessage={error?.response?.data.messages[0]}
      >
        <AppBreadcrumb
          paths={[
            { name: messages["sidebar.usersManagement"] },
            {
              name: messages["sidebar.users"],
              path: "/usersManagement/users",
            },
            {
              name: getValueBasedOnLang(
                userItem?.fullNameAr || "",
                userItem?.fullNameEn || ""
              ),
            },
          ]}
          currentPathName={"details"}
        />
        {/* Sidebar Links */}
        <Grid item xs={3}>
          <List
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            {/* Details */}
            <AppPermissionsGate
              schemaName={SchemaName.SystemConfigurations}
              entityName={EntityName.Personal}
              action={PermissionActionsTypes.Profile}
            >
              <Link to={``} style={{ textDecoration: "none", color: "black" }}>
                <List component="div" disablePadding>
                  <ListItemButton selected={!activePath}>
                    <ListItemIcon style={{ minWidth: 30 }}>
                      <InfoIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary={<IntlMessages id="details" />} />
                  </ListItemButton>
                </List>
              </Link>
            </AppPermissionsGate>

            {/* roles */}
            <AppPermissionsGate
              schemaName={SchemaName.UsersManagement}
              entityName={EntityName.Roles}
              action={PermissionActionsTypes.List}
            >
              <Link
                to={`roles`}
                style={{ textDecoration: "none", color: "black" }}
              >
                <List component="div" disablePadding>
                  <ListItemButton selected={activePath == "roles"}>
                    <ListItemIcon style={{ minWidth: 30 }}>
                      <AddModeratorIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText
                      primary={<IntlMessages id="sidebar.roles" />}
                    />
                  </ListItemButton>
                </List>
              </Link>
            </AppPermissionsGate>

            <AppPermissionsGate
              schemaName={SchemaName.UsersManagement}
              entityName={EntityName.UserRoles}
              action={PermissionActionsTypes.List}
            >
              {/* Permessions */}
              <ListItemButton
                onClick={() =>
                  handleClick(
                    setOpenPersonalPermissionsSchema,
                    openPermissionsSchema
                  )
                }
              >
                <ListItemIcon style={{ minWidth: 35 }}>
                  <AddModeratorIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary={<IntlMessages id="permissions.allSchema.title" />}
                />
                {openPermissionsSchema ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openPermissionsSchema} timeout="auto" unmountOnExit>
                {/* Dynamic Schema */}
                {permissionsSchemaList &&
                  permissionsSchemaList?.data?.length > 0 &&
                  permissionsSchemaList?.data?.map((item, index) => (
                    <div key={index}>
                      <Link
                        to={`permissions/${item?.schema}`}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <List component="div" disablePadding>
                          <ListItemButton
                            sx={{ pl: 10 }}
                            selected={permissionsSchemaPath == item?.schema}
                          >
                            <ListItemIcon style={{ minWidth: 30 }}>
                              <FaDotCircle size={14} />
                            </ListItemIcon>
                            <ListItemText
                              secondary={
                                <IntlMessages
                                  id={`permission.schema.${item?.schema?.toLowerCase()}`}
                                />
                              }
                            />
                          </ListItemButton>
                        </List>
                      </Link>
                    </div>
                  ))}
              </Collapse>
            </AppPermissionsGate>
          </List>
        </Grid>
        {/* Components */}
        <Grid item xs={9}>
          <Routes>
            <Route path="" element={<UserDetails userItem={userItem!} />} />
            <Route path="/roles" element={<RolesPage />} />

            <Route
              path="/permissions/:moduleParams"
              element={<TablePermissionsUser />}
            />
          </Routes>
        </Grid>
      </LoadingAndErrorWrapper>
    </Grid>
  );
};

export default UserPersonalProfile;
