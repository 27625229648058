import IntlMessages from "@crema/utility/IntlMessages";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import * as React from "react";

interface IAppAdvanceFilterSelectProps {
  fieldName?: string;
  label?: string;
  labelForView?: string;
  children?: React.ReactNode;
  setAdvancedFilter: React.Dispatch<React.SetStateAction<any>>;
  advancedFilter: any;
}

const AppAdvanceFilterSelect: React.FC<IAppAdvanceFilterSelectProps> = (
  props
) => {
  const {
    label,
    fieldName,
    children,
    labelForView = "advance-filte",
    setAdvancedFilter,
    advancedFilter,
  } = props;
  const [state, setState] = React.useState<any>();

  return (
    <>
      <FormControl size="small" fullWidth>
        <InputLabel id={`advance-filter-select-${fieldName}`}>
          <IntlMessages id={label} />
        </InputLabel>
        <Select
          labelId={`advance-filter-select-${fieldName}`}
          label={labelForView}
          variant="outlined"
          size="small"
          fullWidth
          onChange={(e) => {
            setAdvancedFilter({
              ...advancedFilter,
              [`${fieldName}`]:
                e.target.value == "All"
                  ? null
                  : e.target.value == "false"
                  ? false
                  : e.target.value == "true"
                  ? true
                  : e.target.value,
            });
            setState(e.target.value == "All" ? null : e.target.value);
          }}
        >
          <MenuItem value={"All"}>
            <IntlMessages id="common.all" />
          </MenuItem>
          {children}
        </Select>
        <input
          className="support-advance-search"
          name={fieldName}
          value={state || ""}
        />
      </FormControl>
    </>
  );
};

export default AppAdvanceFilterSelect;
