import { Grid } from "@mui/material";
import AppDataGridCellLink from "components/basicComponents/dataGrid/AppDataGridCellLink";
import BaseDetails from "components/basicComponents/details/BaseDetails";
import AppItemView from "components/basicComponents/others/AppItemView";
import { generatePresignedUrl, getValueBasedOnLang } from "helpers";
import {
  enumPaymentStatus,
  enumPaymentType,
} from "pages/managePayments/invoices/payment/PaymentColumns";
import { useIntl } from "react-intl";
import { useGetByIdService } from "services";
import { AppUrls } from "services/appUrls";
import { IPaymentDetailsDto } from "types/entitiesTypes/payment";

interface IPaymentDetailsProps {
  PaymentId: string;
  setShowDetails: React.Dispatch<React.SetStateAction<boolean>>;
  showDetails: boolean;
}

const PaymentDetails = (props: IPaymentDetailsProps) => {
  const { PaymentId, setShowDetails, showDetails } = props;
  const {
    data: PaymentItem,
    error,
    isLoading,
  } = useGetByIdService<IPaymentDetailsDto>(AppUrls.Payment, PaymentId);
  const { messages } = useIntl();
  return (
    <>
      <BaseDetails
        itemName={""}
        label="invoices.payment.details.label"
        open={showDetails}
        onClose={() => setShowDetails(false)}
        withoutSecoundTab={false}
        maxWidth="sm"
        loading={isLoading}
        errorMessage={error}
      >
        <Grid container spacing={3}>
          <AppItemView
            label="diploma.name"
            value={getValueBasedOnLang(
              PaymentItem?.subscription?.diploma?.nameAr!,
              PaymentItem?.subscription?.diploma?.nameEn!
            )}
            sm={4}
          />
          <AppItemView
            label="educationalCenter.name"
            value={getValueBasedOnLang(
              PaymentItem?.subscription?.diploma?.educationalCenter?.nameAr!,
              PaymentItem?.subscription?.diploma?.educationalCenter?.nameEn!
            )}
            sm={4}
          />
          <AppItemView
            label="invoices.invoiceNumber"
            value={PaymentItem?.invoice?.invoiceNumber}
            sm={4}
          />
          {/* <AppItemView
            label="invoices.payment.paymentNumberS"
            value={PaymentItem?.paymentNumberS}
            sm={4}
          /> */}
          <AppItemView
            label="invoices.payment.amount"
            value={PaymentItem?.amount}
            sm={4}
          />

          <AppItemView
            label="invoices.payment.paymentStatus"
            value={String(
              messages[enumPaymentStatus(PaymentItem?.paymentStatus)]
            )}
            sm={4}
          />
          <AppItemView
            label="invoices.payment.paymentType"
            value={String(messages[enumPaymentType(PaymentItem?.paymentType)])}
            sm={4}
          />
          <AppItemView
            label="invoices.payment.paymentOrder"
            value={PaymentItem?.paymentOrder}
            sm={4}
          />

          <AppItemView
            label="invoices.paymentNoticePath"
            value={
              <AppDataGridCellLink
                // value={`${BaseFileUrl}${params.row?.documentFileKey}`}
                value={generatePresignedUrl(
                  PaymentItem?.paymentNoticePath!,
                  process.env.NODE_ENV
                )}
                label="session.attachments.actions.open"
              />
              // PaymentItem?.paymentNoticePath ? (
              //   <a
              //     href={`${BaseFileUrl}${PaymentItem?.paymentNoticePath}`}
              //     target="_blank"
              //   >
              //     {messages["session.sessionLink.href"]}
              //   </a>
              // ) : (
              //   "-"
              // )
            }
            type="text"
            sm={4}
          />
          <AppItemView
            label="diplomas.installments.dueDate"
            value={PaymentItem?.dueDate}
            type="date"
            sm={4}
          />
        </Grid>
      </BaseDetails>
    </>
  );
};

export default PaymentDetails;
