import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Pagination,
  Typography,
} from "@mui/material";
import defaultImage from "assets/appDefaultImages/branch.png";
import { AxiosError } from "axios";
import StatusAttendance from "components/basicComponents/badge/StatusAttendance";
import StatusBadge from "components/basicComponents/badge/StatusBadge";
import AddDataGridActivation from "components/basicComponents/dataGrid/AddDataGridActivation";
import DataItem from "components/basicComponents/mobile/DataItem";
import AppIconButton from "components/basicComponents/others/AppIconButton";
import AppViewImage from "components/basicComponents/others/AppViewImage";
import LoadingAndErrorWrapper from "components/basicComponents/others/LoadingAndErrorWrapper";
import { getValueBasedOnLang } from "helpers";
import { dateTimeFormatter } from "helpers/string/dateFormatter";
import { FC } from "react";
import { useIntl } from "react-intl";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { attendanceStatus } from "shared/constants/AppEnums";
import { IAttendanceList } from "types/entitiesTypes/attendance";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";

interface IAttendanceCardProps {
  AttendanceList: Array<IAttendanceList>;
  pageNumber?: number;
  setPage?: (event: React.ChangeEvent<unknown>, page: number) => void;
  page?: number;
  loading: boolean;
  errorMessage: AxiosError<any, any> | null;
}
const AttendanceCard: FC<IAttendanceCardProps> = (props) => {
  const { AttendanceList, loading, errorMessage } = props;
  const { messages, locale } = useIntl();
  return (
    <Grid container spacing={4}>
      <LoadingAndErrorWrapper
        isLoading={loading}
        errorMessage={errorMessage?.response?.data.messages[0]}
      >
        {AttendanceList?.length != undefined && AttendanceList?.length >= 1 ? (
          AttendanceList?.map((item, index) => (
            <>
              <Grid key={`AttendanceList${index}`} item xs={12}>
                <DataItem
                  label={messages["session.studentName"] as string}
                  value={item.studentName}
                />
                <DataItem
                  label={messages["educationalCenter.name"] as string}
                  value={getValueBasedOnLang(
                    item?.educationalCenterNameEn,
                    item?.educationalCenterNameAr
                  )}
                />
                <DataItem
                  label={messages["diploma.name"] as string}
                  value={getValueBasedOnLang(
                    item?.diplomaNameAr,
                    item?.diplomaNameEn
                  )}
                />

                <DataItem
                  label={messages["session.name"] as string}
                  value={item?.sessionName}
                  marginY={2}
                />

                <DataItem
                  label={messages["session.courseName"] as string}
                  value={item?.courseName}
                  marginY={2}
                />
                <DataItem
                  label={messages["session.date"] as string}
                  value={dateTimeFormatter(item?.sessionDate)}
                  marginY={2}
                />
                <DataItem
                  label={messages["columns.status"] as string}
                  value={<StatusAttendance status={item.status} />}
                  marginY={2}
                />
              </Grid>

              <Grid key={`DividerHorizontal${index}`} xs={12}>
                <Divider orientation="horizontal" />
              </Grid>
            </>
          ))
        ) : (
          <Grid
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            item
            xs={12}
            marginTop={10}
          >
            <Typography variant="h5" component={"span"}>
              {messages["common.dataFound"]}
            </Typography>
          </Grid>
        )}
        {/* {pageNumber! > 1 && (
          <Grid
            display={"flex"}
            alignItems={"cneter"}
            justifyContent={"center"}
            marginTop={5}
            item
            xs={12}
          >
            <Pagination
              count={pageNumber}
              page={page}
              color="primary"
              onChange={(event, page) => {
                setPage && setPage(event, page);
              }}
            />
          </Grid>
        )} */}
      </LoadingAndErrorWrapper>
    </Grid>
  );
};

export default AttendanceCard;
