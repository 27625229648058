import IntlMessages from "@crema/utility/IntlMessages";
import { Grid, Typography } from "@mui/material";
import defaultImage from "assets/appDefaultImages/state.png";
import BaseDetails from "components/basicComponents/details/BaseDetails";
import AppItemView from "components/basicComponents/others/AppItemView";
import AppViewImage from "components/basicComponents/others/AppViewImage";
import { getValueBasedOnLang } from "helpers";
import React from "react";
import { useGetByIdService } from "services";
import { AppUrls } from "services/appUrls";
import { Fonts } from "shared/constants/AppEnums";
import { ISelectedRow } from "types/appTypes/baseEntity/baseDto";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import {
  INotificationPersonalDetailsDto,
  INotificationPersonalListDto,
} from "types/entitiesTypes/notification";
import { useIntl } from "react-intl";
import {
  IInstanceNotificationsDetailsDto,
  IInstanceNotificationsListDto,
} from "types/entitiesTypes/instanceNotifications";

interface INotificationDetailsModalProps {
  showDetails: boolean;
  setShowDetails: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRow: IInstanceNotificationsListDto;
}
const NotificationDetailsModal = (props: INotificationDetailsModalProps) => {
  const { showDetails, setShowDetails, selectedRow } = props;
  const { messages } = useIntl();
  const {
    data: notificationItem,
    error,
    isLoading,
  } = useGetByIdService<IInstanceNotificationsDetailsDto>(
    AppUrls.InstanceNotifications,
    selectedRow?.id
  );

  return (
    <>
      <BaseDetails
        itemName={""}
        label="notification.details"
        open={showDetails}
        onClose={() => setShowDetails(false)}
        loading={isLoading}
        errorMessage={error}
        moreDetails={notificationItem as IInstanceNotificationsDetailsDto}
        withoutSecoundTab={true}
        maxWidth="md"
        columns={12}
      >
        <Grid container spacing={3} item>
          <AppItemView
            label="notification.titleAr"
            value={notificationItem?.titleAr}
            sm={4}
          />
          <AppItemView
            label="notification.titleEn"
            value={notificationItem?.titleEn}
            sm={4}
          />
          <AppItemView
            label="notification.notifyDate"
            value={notificationItem?.notifyDate}
            type="dateTime"
            sm={4}
          />

          <AppItemView
            label="common.to"
            value={`${notificationItem?.user?.firstName} ${notificationItem?.user?.lastName}`}
            sm={4}
          />

          <Grid item xs={4} sm={4}>
            <Typography sx={{ fontSize: 14, fontWeight: Fonts.SEMI_BOLD }}>
              <IntlMessages id={"notification.canceledNotification"} />
            </Typography>

            <Typography>
              {notificationItem?.canceledNotification ? (
                <IntlMessages id={`common.yes`} />
              ) : (
                <IntlMessages id={`common.no`} />
              )}
            </Typography>
          </Grid>
          <AppItemView
            label="notification.notificationStatus"
            value={
              notificationItem?.isRead
                ? messages["notification.notificationStatus.Read"]
                : messages["notification.notificationStatus.New"]
            }
            sm={4}
          />

          <Grid item xs={12} />

          <AppItemView
            label="notification.messageAr"
            value={notificationItem?.messageAr}
            sm={6}
          />
          <AppItemView
            label="notification.messageEn"
            value={notificationItem?.messageEn}
            sm={6}
          />
        </Grid>
      </BaseDetails>
    </>
  );
};

export default NotificationDetailsModal;
