import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { GridColDef } from "@mui/x-data-grid";
import StatusBadge from "components/basicComponents/badge/StatusBadge";
import AddDataGridActivation from "components/basicComponents/dataGrid/AddDataGridActivation";
import AppDataGridCell from "components/basicComponents/dataGrid/AppDataGridCell";
import AppIconButton from "components/basicComponents/others/AppIconButton";
import { useIntl } from "react-intl";
import { IRoleListDto } from "types/entitiesTypes/roles";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import DataSaverOnIcon from "@mui/icons-material/DataSaverOn";
import { useLocation, useParams } from "react-router-dom";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useJWTAuth } from "@crema/services/auth/jwt-auth/JWTAuthProvider";
export const RolesColumns = (
  handleUpdate = (row: IRoleListDto) => {},
  handleDelete = (row: IRoleListDto) => {},
  handleToggleActive = (row: IRoleListDto) => {},
  handleAssignRoleToUser = (row: IRoleListDto) => {},
  handleDeAssignRole = (row: IRoleListDto) => {}
): GridColDef[] => {
  const { messages } = useIntl();
  const { userIdParams } = useParams();
  const { user } = useJWTAuth();

  const location = useLocation();

  const rolesColumns: GridColDef[] = [
    {
      field: "nameAr",
      headerName: messages["common.nameAr"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => <AppDataGridCell value={params.row?.nameAr} />,
    },
    {
      field: "name",
      headerName: messages["common.nameEn"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => <AppDataGridCell value={params.row?.name} />,
    },
    {
      field: "isActive",
      headerName: messages["common.isActive"] as string,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <StatusBadge isActive={params?.row?.isActive} />;
      },
    },
    {
      field: "actions",
      headerName: messages["common.actions"] as string,
      sortable: false,
      align: "center",
      filterable: false,
      headerAlign: "center",
      flex: 0.1,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div>
            {/* Details */}
            <AppIconButton
              label="table.actions.details"
              withLink={true}
              toPathLink={`/usersManagement/roles/profile/${params?.row?.id}`}
              childComp={
                <RemoveRedEyeIcon fontSize="small" sx={{ color: "#007991" }} />
              }
              // For Permissions
              schemaName={SchemaName.UsersManagement}
              entityName={EntityName.Roles}
              action={PermissionActionsTypes.Details}
            />

            {!location.pathname?.includes("my-profile") &&
              params?.row?.canBeDeleted && (
                <>
                  {/* Edit */}
                  <AppIconButton
                    label="table.actions.edit"
                    handleClick={() => {
                      handleUpdate(params.row);
                    }}
                    childComp={
                      <EditOutlinedIcon
                        fontSize="small"
                        sx={{ color: "#A0CCDA" }}
                      />
                    }
                    // For Permissions
                    schemaName={SchemaName.UsersManagement}
                    entityName={EntityName.Roles}
                    action={PermissionActionsTypes.Edit}
                  />

                  {/* De Assign Role */}
                  {userIdParams &&
                    userIdParams != user?.id &&
                    location.pathname.includes("profile") && (
                      <AppIconButton
                        label="table.actions.deleteUserFromRole"
                        handleClick={() => {
                          handleDeAssignRole(params.row);
                        }}
                        childComp={
                          <RemoveCircleOutlineIcon
                            fontSize="small"
                            sx={{ color: "#D72638" }}
                          />
                        }
                        // For Permissions
                        schemaName={SchemaName.UsersManagement}
                        entityName={EntityName.UserRoles}
                        action={PermissionActionsTypes.DeAssignUserToRole}
                      />
                    )}

                  {/* Add Uer To Role */}
                  {!userIdParams && (
                    <>
                      {(params?.row?.name != "Employee" ||
                        params?.row?.canBeDeleted) && (
                        <AppIconButton
                          label="table.actions.assignRoleToUser"
                          handleClick={() => {
                            handleAssignRoleToUser(params.row);
                          }}
                          childComp={
                            <DataSaverOnIcon
                              fontSize="small"
                              sx={{ color: "#f39862" }}
                            />
                          }
                          // For Permissions
                          schemaName={SchemaName.UsersManagement}
                          entityName={EntityName.UserRoles}
                          action={PermissionActionsTypes.AssignUserToRole}
                        />
                      )}

                      {/* Activation */}
                      {params?.row?.canBeDeleted && (
                        <AddDataGridActivation
                          value={params.row.isActive}
                          handleClick={() => {
                            handleToggleActive(params.row);
                          }}
                          // For Permissions
                          schemaName={SchemaName.UsersManagement}
                          entityName={EntityName.Roles}
                          action={PermissionActionsTypes.Activate}
                        />
                      )}

                      {/* Delete */}
                      {params?.row?.canBeDeleted && (
                        <AppIconButton
                          label="table.actions.delete"
                          handleClick={() => {
                            handleDelete(params.row);
                          }}
                          childComp={
                            <DeleteOutlinedIcon
                              fontSize="small"
                              sx={{ color: "#D72638" }}
                            />
                          }
                          // For Permissions
                          schemaName={SchemaName.UsersManagement}
                          entityName={EntityName.Roles}
                          action={PermissionActionsTypes.Delete}
                        />
                      )}
                    </>
                  )}
                </>
              )}
          </div>
        );
      },
    },
  ];

  return rolesColumns;
};
