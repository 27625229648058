import AppTextField from "@crema/core/AppFormComponents/AppTextField";
import IntlMessages from "@crema/utility/IntlMessages";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import moment from "moment";
import * as React from "react";

interface IAppDateTimePickerProps {
  label: string;
  value: string;
  onChange: (field: string, value: any) => void;
  name: string;
  disablePast?: boolean;
  minTime?: any;
}

const AppDateTimePicker: React.FC<IAppDateTimePickerProps> = (props) => {
  const { label, value, onChange, name, disablePast = false, minTime } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        label={<IntlMessages id={label} />}
        inputFormat="MM/DD/YYYY HH:mm A"
        value={value}
        disablePast={disablePast}
        shouldDisableDate={(date) => {
          // Disable past dates
          return disablePast && dayjs(date).isBefore(dayjs(), "day");
        }}
        // minTime={minTime}
        onChange={(value: any) =>
          onChange(name, moment(value?.$d).toISOString())
        }
        PopperProps={{
          placement: "left",
        }}
        shouldDisableTime={(time) => {
          if (
            (value ? dayjs(value).isSame(dayjs(), "day") : false) &&
            minTime
          ) {
            const currentTime = dayjs().hour(time).minute(0); // Adjust as needed for your time granularity
            return currentTime.isBefore(dayjs()); // Disable times before now if today
          }
          return false; // Allow all times for future dates
        }}
        // PaperProps={{
        //   translate: "yes",
        // }}
        renderInput={(params: any) => (
          <AppTextField
            fullWidth
            name={name}
            size="small"
            autoComplete="off"
            params={params}
            {...params}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default AppDateTimePicker;
