import IntlMessages from "@crema/utility/IntlMessages";
import { Grid, Step, StepLabel, Stepper } from "@mui/material";
import { ColorLibConnector } from "components/basicComponents/modals/stepper/ColorLibConnector";
import { Form, Formik, FormikProps } from "formik";
import { useEffect, useRef, useState } from "react";
import { useUpdateService } from "services";
import { AppUrls } from "services/appUrls";
import {
  IDiplomasDetailsDto,
  IUpdateDiplomasDto,
} from "types/entitiesTypes/diplomas";
import { DiplomasType } from "types/entitiesTypes/enums";
import { ColorLibStepIcon } from "../ColorLibStepIcon";
import { FirstStep, SecondStep, ThirdStep } from "../EditSteps";
import useValidationSchema from "../validationSchemas";
import AppEditDiploma from "./AppEditDiploma";
import { useNavigate, useParams } from "react-router-dom";
import { generatePresignedUrl } from "helpers";
interface IEditDiplomasProps {
  onSuccess?: () => void;
  onFailed?: () => void;
  isLoading: boolean;
  diplomaItem: IDiplomasDetailsDto;
}

const EditDiplomas = (props: IEditDiplomasProps) => {
  const { isLoading, diplomaItem } = props;
  const refForm = useRef<FormikProps<IUpdateDiplomasDto>>(null);
  const { validationSchemas } = useValidationSchema();
  const [activeStep, setActiveStep] = useState(0);
  const { educationalCenterIdParams, instructorIdParams } = useParams();

  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const steps = [
    "diplomas.BasicInformation",
    "diplomas.CourseDetails",
    "diplomas.FinancialDetails",
  ];
  const navigate = useNavigate();
  const submit = () => {
    if (refForm.current) {
      refForm.current.submitForm();
    }
  };

  const mutation = useUpdateService<IUpdateDiplomasDto, string>(
    AppUrls.Diplomas,
    () => {
      navigate("/scientificContentManagement/diplomas");
    }
  );

  useEffect(() => {
    if (diplomaItem && diplomaItem.coverPhoto)
      setUploadedFiles([
        {
          preview: generatePresignedUrl(
            diplomaItem.coverPhoto,
            process.env.NODE_ENV
          ),
          // BaseFileUrl + diplomaItem.coverPhoto,
          originalUrl: diplomaItem.coverPhoto,
        },
      ]);
  }, [diplomaItem]);

  useEffect(() => {
    if (uploadedFiles)
      refForm.current?.setFieldValue("coverPhoto", uploadedFiles);
  }, [uploadedFiles]);

  const currentValidationSchema = validationSchemas[activeStep];

  const handleNext = async () => {
    if (refForm.current) {
      await refForm.current.validateForm();
      const errors = refForm.current.errors;

      if (Object.keys(errors).length > 0) {
        return;
      }
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

  const handleSubmit = async (data: IUpdateDiplomasDto) => {
    if (activeStep === steps.length - 1) {
      if (uploadedFiles) {
        data = { ...data, coverPhoto: uploadedFiles[0] };
      }
      await mutation.submitData(data);
    } else {
      handleNext();
    }
  };
  return (
    <>
      <AppEditDiploma
        handleSubmit={submit}
        handleBack={activeStep > 0 ? () => handleBack() : undefined}
        labelForEditBtn={
          activeStep === steps.length - 1 ? "common.save" : "common.next"
        }
        loadingForEdit={
          (refForm.current?.isSubmitting && !refForm.current?.isValidating) ||
          false
        }
      >
        <Formik
          validationSchema={currentValidationSchema}
          validateOnChange={true}
          initialValues={{
            id: diplomaItem?.id || "",
            nameAr: diplomaItem?.nameAr || "",
            nameEn: diplomaItem?.nameEn || "",
            isFree: diplomaItem?.isFree || false,
            isActive: diplomaItem?.isActive!,
            descriptionAr: diplomaItem?.descriptionAr || "",
            descriptionEn: diplomaItem?.descriptionEn || "",
            categoryId: diplomaItem?.category?.id || "",
            regionId: diplomaItem?.region?.id || "",
            instructorId:
              instructorIdParams || diplomaItem?.instructor?.id || "",
            educationalCenterId:
              educationalCenterIdParams ||
              diplomaItem?.educationalCenter?.id ||
              "",
            whatsappChatLink: diplomaItem?.whatsappChatLink || "",
            category: diplomaItem?.category,
            educationalCenter: diplomaItem?.educationalCenter,
            region: diplomaItem?.region,
            instructor: diplomaItem?.instructor,
            instructorSalary: diplomaItem?.instructorSalary || null,
            durationInHours: diplomaItem?.durationInHours || null,
            isCertified: diplomaItem?.isCertified || true,
            outcomes: diplomaItem?.outcomes || "",
            coursesCount: diplomaItem?.coursesCount || null,
            startDate: diplomaItem?.startDate || "",
            singlePaymentCost: diplomaItem?.singlePaymentCost || null,
            multiplePaymentsCost: diplomaItem?.multiplePaymentsCost || null,
            discountCost: diplomaItem?.discountCost || null,
            expenses: diplomaItem?.expenses || null,
            expensesType: diplomaItem?.expensesType || DiplomasType.FixedValue,
            educationalCenterFees: diplomaItem?.educationalCenterFees || null,
            educationalCenterFeesType:
              diplomaItem?.educationalCenterFeesType || DiplomasType.FixedValue,
            coverPhoto: diplomaItem?.coverPhoto || "",
            tax: diplomaItem?.tax || null,
            //   installments: (() => {
            //     const installments = diplomaItem?.installments;
            //     return Array.isArray(installments) && installments.length > 0
            //       ? installments
            //       : [
            //           {
            //             dueDate: "2024-08-15T08:51:40.692Z",
            //             installmentOrder: null,
            //             amount: null,
            //             daysMargin: null,
            //           },
            //         ];
            //   })(),
          }}
          innerRef={refForm}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, errors, touched, values }) => (
            <Form>
              <Grid
                container
                direction="column"
                justifyContent="center"
                spacing={4}
              >
                <Grid item>
                  <Stepper
                    activeStep={activeStep}
                    sx={{ py: 3 }}
                    alternativeLabel
                    connector={<ColorLibConnector />}
                  >
                    {steps.map((label, index) => (
                      <Step key={`stepsEdit-${index}`}>
                        <StepLabel StepIconComponent={ColorLibStepIcon}>
                          <IntlMessages id={label} />
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Grid>
                <Grid item>
                  {activeStep === 0 && (
                    <FirstStep
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      values={values}
                      uploadedFiles={uploadedFiles}
                      setUploadedFiles={setUploadedFiles}
                    />
                  )}
                  {activeStep === 1 && (
                    <SecondStep
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      values={values}
                    />
                  )}
                  {activeStep === 2 && <ThirdStep />}
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </AppEditDiploma>
    </>
  );
};

export default EditDiplomas;
