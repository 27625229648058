import { useIntl } from "react-intl";
import * as yup from "yup";

const useValidationSchema = () => {
  const { messages } = useIntl();

  //   First Step Validation Schema
  const firstStepValidationSchema = yup.object({
    nameAr: yup.string().required(String(messages["validation.required"])),
    nameEn: yup.string().required(String(messages["validation.required"])),
    educationalCenterId: yup
      .string()
      .required(String(messages["validation.required"])),
    regionId: yup.string().required(String(messages["validation.required"])),
    coverPhoto: yup
      .array()
      .min(1, String(messages["validation.required"]))
      .required(String(messages["validation.required"])),
  });

  //   Second Step Validation Schema
  const secondStepValidationSchema = yup.object({
    instructorId: yup
      .string()
      .required(String(messages["validation.required"])),
    categoryId: yup.string().required(String(messages["validation.required"])),
    instructorSalary: yup
      .number()
      .typeError(String(messages["validation.required"]))
      .required(String(messages["validation.required"])),
    durationInHours: yup
      .number()
      .typeError(String(messages["validation.required"]))
      .required(String(messages["validation.required"])),
    startDate: yup.string().required(String(messages["validation.required"])),
    isCertified: yup.string().required(String(messages["validation.required"])),
    coursesCount: yup
      .number()
      .typeError(String(messages["validation.required"]))
      .required(String(messages["validation.required"])),
    // outcomes: yup.string().required(String(messages["validation.required"])),
  });

  //   Third Step Validation Schema
  const thirdStepValidationSchema = yup.object({
    isFree: yup.boolean(),
    singlePaymentCost: yup.number().when("isFree", {
      is: (isFree) => !isFree,
      then: yup
        .number()
        .required(String(messages["validation.required"]))
        .typeError(String(messages["validation.required"])),
      otherwise: yup.number().nullable().notRequired(),
    }),

    multiplePaymentsCost: yup.number().when("isFree", {
      is: (isFree) => !isFree,
      then: yup
        .number()
        .required(String(messages["validation.required"]))
        .typeError(String(messages["validation.required"])),
      otherwise: yup.number().nullable().notRequired(),
    }),

    expenses: yup.number().when("isFree", {
      is: (isFree) => !isFree,
      then: yup
        .number()
        .required(String(messages["validation.required"]))
        .typeError(String(messages["validation.required"])),
      otherwise: yup.number().nullable().notRequired(),
    }),
    expensesType: yup.string().when("isFree", {
      is: (isFree) => !isFree,
      then: yup
        .string()
        .required(String(messages["validation.required"]))
        .typeError(String(messages["validation.required"])),
      otherwise: yup.string().nullable().notRequired(),
    }),
    educationalCenterFees: yup
      .number()
      .typeError(String(messages["validation.required"]))
      .required(String(messages["validation.required"])),
    educationalCenterFeesType: yup
      .string()
      .required(String(messages["validation.required"])),
  });

  const InstallmentsValidationSchema = yup.object({
    installments: yup.array().of(
      yup.object().shape({
        dueDate: yup.string().required(String(messages["validation.required"])),
        amount: yup
          .number()
          .typeError(String(messages["validation.required"]))
          .required(String(messages["validation.required"])),
        daysMargin: yup
          .number()
          .typeError(String(messages["validation.required"]))
          .required(String(messages["validation.required"])),
      })
    ),
  });

  const validationSchemas = [
    firstStepValidationSchema,
    secondStepValidationSchema,
    thirdStepValidationSchema,
  ];
  return { validationSchemas };
};

export default useValidationSchema;
