import IntlMessages from "@crema/utility/IntlMessages";
import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import * as React from "react";
import { useIntl } from "react-intl";
import AppPhoneNumber from "../inputs/AppPhoneNumber";

// const useStyles = makeStyles({
//   input: {
//     "& input[type=number]": {
//       "-moz-appearance": "textfield",
//     },
//     "& input[type=number]::-webkit-outer-spin-button": {
//       "-webkit-appearance": "none",
//       margin: 0,
//     },
//     "& input[type=number]::-webkit-inner-spin-button": {
//       "-webkit-appearance": "none",
//       margin: 0,
//     },
//   },
// });

const useStyles = makeStyles({
  input: {
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "-moz-appearance": "textfield",
  },
});

interface IAppAdvanceFilterTextFieldProps {
  fieldType?: "text" | "date" | "number" | "mobile";
  fieldName?: string;
  label?: string;
  setAdvancedFilter: React.Dispatch<React.SetStateAction<any>>;
  advancedFilter: any;
}

const AppAdvanceFilterTextField: React.FC<IAppAdvanceFilterTextFieldProps> = (
  props
) => {
  const {
    fieldType = "text",
    label,
    fieldName,
    setAdvancedFilter,
    advancedFilter,
  } = props;
  const [state, setState] = React.useState<any>();
  const { messages, locale } = useIntl();
  const classes = useStyles();

  const validateDate = (date) => {
    return moment(date, "YYYY-MM-DD", true).isValid();
  };

  return (
    <>
      {fieldType == "date" ? (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label={<IntlMessages id={label} />}
            inputFormat="dd/MM/yyyy"
            value={state || null}
            onChange={(value) => {
              if (value && value != "Invalid date") {
                setAdvancedFilter({
                  ...advancedFilter,
                  [`${fieldName}`]: value,
                });
                setState(moment(value).format("YYYY-MM-DD"));
              } else {
                setState(null);
                setAdvancedFilter({
                  ...advancedFilter,
                  [`${fieldName}`]: null,
                });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                fullWidth
                error={state && !validateDate(state)}
                helperText={
                  state && !validateDate(state)
                    ? String(messages["validation.date"])
                    : ""
                }
              />
            )}
          />
        </LocalizationProvider>
      ) : fieldType == "number" ? (
        <TextField
          variant="outlined"
          label={<IntlMessages id={label} />}
          type={"number"}
          onChange={(e) =>
            setAdvancedFilter({
              ...advancedFilter,
              [`${fieldName}`]: e.target.value,
            })
          }
          size="small"
          fullWidth
          onWheel={(e: any) => e.target.blur()}
          InputProps={{
            classes: {
              input: classes.input,
            },
            onKeyDown: (event) => {
              const keyCode = event.keyCode || event.which;

              if (
                keyCode === 190 ||
                keyCode === 69 ||
                keyCode === 46 ||
                keyCode === 110 ||
                keyCode === 38 ||
                keyCode === 40
              ) {
                event.preventDefault();
              }
            },

            // disableUnderline: true,
          }}
        />
      ) : fieldType == "mobile" ? (
        <AppPhoneNumber
          label={label!}
          keyName={fieldName!}
          // error={!!(touched.mobile && errors.mobile)}
          variant="outlined"
          onChange={(field, value) =>
            setAdvancedFilter({
              ...advancedFilter,
              [`${fieldName}`]: value,
            })
          }
          value={state || null}
          size="small"
        />
      ) : (
        <TextField
          variant="outlined"
          label={<IntlMessages id={label} />}
          type={fieldType}
          onChange={(e) =>
            setAdvancedFilter({
              ...advancedFilter,
              [`${fieldName}`]: e.target.value,
            })
          }
          size="small"
          fullWidth
          className={`
          ${
            locale === "ar-SA" && state && state[0]! === "+"
              ? "add-number-style"
              : ""
          }`}
        />
      )}
      <input
        className={`support-advance-search`}
        name={fieldName}
        value={state ?? ""}
      />
    </>
  );
};

export default AppAdvanceFilterTextField;
