const REQUEST_PERSONAL_PERMISSIONS = "REQUEST_PERSONAL_PERMISSIONS";
export const REQUEST_PERSONAL_PERMISSIONS_SUCCESS =
  "REQUEST_PERSONAL_PERMISSIONS_SUCCESS";
const REQUEST_PERSONAL_PERMISSIONS_FAILED =
  "REQUEST_PERSONAL_PERMISSIONS_FAILED";

const initialState = {
  personalPermissionsList: [],
  isLoadingPersonalPermissionsList: false,
  personalPermissionsListErrorMessage: null,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_PERSONAL_PERMISSIONS:
      return {
        ...state,
        isLoadingPersonalPermissionsList: true,
      };
    case REQUEST_PERSONAL_PERMISSIONS_SUCCESS:
      return {
        ...state,
        personalPermissionsList: action.personalPermissionsList,
        isLoadingPersonalPermissionsList: false,
        personalPermissionsListErrorMessage: null,
      };
    case REQUEST_PERSONAL_PERMISSIONS_FAILED:
      return {
        ...state,
        isLoadingPersonalPermissionsList: false,
        personalPermissionsListErrorMessage:
          action.personalPermissionsListErrorMessage,
      };
    default:
      return state;
  }
};

export const requestPersonalPermissionsList = () => {
  return {
    type: REQUEST_PERSONAL_PERMISSIONS,
  };
};
export const requestPersonalPermissionsListSuccess = (
  personalPermissionsList
) => {
  return {
    type: REQUEST_PERSONAL_PERMISSIONS_SUCCESS,
    personalPermissionsList: personalPermissionsList,
  };
};
export const requestPersonalPermissionsListFailed = (
  personalPermissionsListErrorMessage
) => {
  return {
    type: REQUEST_PERSONAL_PERMISSIONS_FAILED,
    personalPermissionsListErrorMessage: personalPermissionsListErrorMessage,
  };
};
