import AppTextField from "@crema/core/AppFormComponents/AppTextField";
import IntlMessages from "@crema/utility/IntlMessages";
import { Grid } from "@mui/material";
import { GridSelectionModel } from "@mui/x-data-grid";
import AppAddModal from "components/basicComponents/modals/AppAddModal";
import { Form, Formik, FormikProps } from "formik";
import React, { useRef } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useDownloadFileTableService } from "services/additionalServices";
import { AppUrls } from "services/appUrls";
import { ICreateExportedFile } from "types/entitiesTypes/exportedFiles";
import * as yup from "yup";

interface IExportedFileProps {
  showAddModal: boolean;
  setShowAddModal: React.Dispatch<React.SetStateAction<boolean>>;
  entityToExport: string;
  exportedExtension: string;
  filter?: any;
  filedIds?: GridSelectionModel;
  onSuccess?: () => void;
  onFailed?: () => void;
}
const ExportedFile: React.FC<IExportedFileProps> = (props) => {
  const {
    showAddModal,
    setShowAddModal,
    entityToExport,
    exportedExtension,
    filter,
    filedIds,
  } = props;
  const { messages } = useIntl();
  const { indicatorIdParams } = useParams();

  const formRef = useRef<FormikProps<ICreateExportedFile>>(null);
  const mutation = useDownloadFileTableService<ICreateExportedFile, string>(
    AppUrls.sharedExport,
    null
  );

  const validationSchema = yup.object({
    title: yup
      .string()
      .matches(
        /^[^\/:*?"<>|\u005C\u061F]{1,30}$/,
        String(messages["title.exportedFile"])
      )
      .required(String(messages["validation.required"])),
  });

  const submit = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  return (
    <>
      <AppAddModal
        label={"common.exportFile"}
        open={showAddModal}
        onClose={() => setShowAddModal(false)}
        closeModalState={setShowAddModal}
        successClose={mutation.isSuccess}
        handleSubmit={submit}
        loadingForAdd={
          (formRef.current?.isSubmitting && !formRef.current?.isValidating) ||
          false
        }
        maxWidth={"sm"}
      >
        <Formik
          validateOnChange={true}
          initialValues={{
            title: "",
            entityToExport: entityToExport || "",
            exportedExtension: exportedExtension || "",
            filter: filter,
            ids: filedIds,
          }}
          validationSchema={validationSchema}
          innerRef={formRef}
          onSubmit={async (data, { setSubmitting }) => {
            let dataToSend = {
              ...data,
              filter:
                data?.ids && data?.ids?.length > 0
                  ? null
                  : {
                      ...data?.filter,
                      pageSize: -1,
                      indicatorId: indicatorIdParams,
                    },
              ids: data?.ids ? data?.ids : null,
            };
            mutation.submitData(dataToSend);
          }}
        >
          {() => (
            <Form>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <AppTextField
                    label={<IntlMessages id="common.title" />}
                    name="title"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </AppAddModal>
    </>
  );
};

export default ExportedFile;
