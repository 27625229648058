const REQUEST_NOTIFICATIONS = "REQUEST_NOTIFICATIONS";
const REQUEST_NOTIFICATIONS_SUCCESS = "REQUEST_NOTIFICATIONS_SUCCESS";
const REQUEST_NOTIFICATIONS_FAILED = "REQUEST_NOTIFICATIONS_FAILED";
export const NEW_NOTIFICATION = "NEW_NOTIFICATION";

const REQUEST_CHANGE_NOTIFICATION_ITEM_TO_IS_READ =
  "REQUEST_CHANGE_NOTIFICATION_ITEM_TO_IS_READ";
const initialState = {
  notificationsList: [],
  progressNotificationsList: [],
  notificationsCount: 0,
  isLoadingNotificationsList: false,
  notificationsListErrorMessage: null,
};

// Notifications Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_NOTIFICATIONS:
      return {
        ...state,
        isLoadingNotificationsList: true,
      };
    case REQUEST_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notificationsList: action.notificationsList,
        notificationsCount: action.notificationsCount,
        isLoadingNotificationsList: false,
        notificationsListErrorMessage: null,
      };
    case REQUEST_NOTIFICATIONS_FAILED:
      return {
        ...state,
        isLoadingNotificationsList: false,
        notificationsListErrorMessage: action.notificationsListErrorMessage,
      };
    case NEW_NOTIFICATION:
      action.payload.isDone = action.payload === 100;
      return {
        ...state,
        notificationsList: [action.payload, ...state.notificationsList],
        notificationsCount: state.notificationsCount + 1,
      };

    case REQUEST_CHANGE_NOTIFICATION_ITEM_TO_IS_READ:
      return {
        ...state,
        notificationsCount: state.notificationsCount - 1,
      };
    default:
      return state;
  }
};

export const requestNotificationsList = () => {
  return {
    type: REQUEST_NOTIFICATIONS,
  };
};
export const requestNotificationsListSuccess = (
  notificationsList,
  notificationsCount
) => {
  return {
    type: REQUEST_NOTIFICATIONS_SUCCESS,
    notificationsList: notificationsList,
    notificationsCount: notificationsCount,
  };
};
export const requestNotificationsListFailed = (
  notificationsListErrorMessage
) => {
  return {
    type: REQUEST_NOTIFICATIONS_FAILED,
    notificationsListErrorMessage: notificationsListErrorMessage,
  };
};

export const requsetChangeNotificationItemToIsRead = () => {
  return {
    type: REQUEST_CHANGE_NOTIFICATION_ITEM_TO_IS_READ,
  };
};
