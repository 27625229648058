import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import jwtAxios, { setAuthToken } from "./index";
import { fetchError, fetchStart, fetchSuccess } from "redux/actions";
import { AxiosError } from "axios";
import { useIntl } from "react-intl";
import { ILoggedInUserDto } from "types/entitiesTypes/loggedInUser";
import axiosInstance, { setToken } from "services/axiosInstance";

interface JWTAuthContextProps {
  user: ILoggedInUserDto | null | undefined;
  isAuthenticated: boolean;
  isLoading: boolean;
}

interface SignUpProps {
  name: string;
  email: string;
  password: string;
}

interface SignInProps {
  email: string;
  // userId: string;
  password: string;
  language: string;
  tenant: string;
}

interface JWTAuthActionsProps {
  signUpUser: (data: SignUpProps) => void;
  signInUser: (data: SignInProps) => void;
  logout: () => void;
  updatePermissions: (permissions: string[]) => void;
}

const JWTAuthContext = createContext<JWTAuthContextProps>({
  user: null,
  isAuthenticated: false,
  isLoading: true,
});
const JWTAuthActionsContext = createContext<JWTAuthActionsProps>({
  signUpUser: () => {},
  signInUser: () => {},
  logout: () => {},
  updatePermissions: () => {},
});

export const useJWTAuth = () => useContext(JWTAuthContext);

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);

interface JWTAuthAuthProviderProps {
  children: ReactNode;
}

const JWTAuthAuthProvider: React.FC<JWTAuthAuthProviderProps> = ({
  children,
}) => {
  const [firebaseData, setJWTAuthData] = useState<JWTAuthContextProps>({
    user: null,
    isAuthenticated: false,
    isLoading: true,
  });

  const dispatch = useDispatch();
  const { messages } = useIntl();

  useEffect(() => {
    const getAuthUser = () => {
      const token = localStorage.getItem("token");

      if (!token) {
        setJWTAuthData({
          user: undefined,
          isLoading: false,
          isAuthenticated: false,
        });
        return;
      }

      setAuthToken(token);
      setToken(token);
      const userObj = JSON.parse(localStorage.getItem("userObj") || "");
      setJWTAuthData({
        user: userObj,
        isLoading: false,
        isAuthenticated: true,
      });
    };

    getAuthUser();
  }, []);

  const signInUser = async ({
    email,
    password,
    language,
    tenant,
  }: {
    email: string;
    password: string;
    language: string;
    tenant: string;
  }) => {
    dispatch(fetchStart());
    try {
      const { data } = await jwtAxios.post(
        "",
        {
          email,
          password,
          fcmToken: localStorage.getItem("fcmToken"),
        },
        {
          headers: {
            "Accept-Language": language,
            // tenant
          },
        }
      );

      localStorage.setItem("token", data.data.token);

      setAuthToken(data.data.token);
      setToken(data.data.token);

      localStorage.setItem("userObj", JSON.stringify(data.data.user));
      setJWTAuthData({
        user: data.data.user,
        isAuthenticated: true,
        isLoading: false,
      });
      dispatch(fetchSuccess());
    } catch (error) {
      const axiosError = error as AxiosError<any>;

      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });

      dispatch(
        fetchError(
          axiosError?.response?.data?.messages[0] ?? messages["unknownError"]
        )
      );
    }
  };

  const signUpUser = async ({
    name,
    email,
    password,
  }: {
    name: string;
    email: string;
    password: string;
  }) => {
    dispatch(fetchStart());
    try {
      const { data } = await axiosInstance.post("users", {
        name,
        email,
        password,
      });
      localStorage.setItem("token", data.token);
      setAuthToken(data.token);
      const res = await jwtAxios.get("/auth");
      setJWTAuthData({
        user: res.data,
        isAuthenticated: true,
        isLoading: false,
      });
      dispatch(fetchSuccess());
    } catch (error) {
      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch(fetchError("Something went wrong"));
    }
  };

  const logout = async () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userObj");
    setAuthToken();
    setJWTAuthData({
      user: null,
      isLoading: false,
      isAuthenticated: false,
    });
  };

  const updatePermissions = (permissions: string[]) => {
    setJWTAuthData({
      ...firebaseData,
      user: { ...firebaseData.user!, permissions: permissions },
    });
  };

  return (
    <JWTAuthContext.Provider
      value={{
        ...firebaseData,
      }}
    >
      <JWTAuthActionsContext.Provider
        value={{
          signUpUser,
          signInUser,
          logout,
          updatePermissions,
        }}
      >
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};
export default JWTAuthAuthProvider;
