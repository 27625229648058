import LoadingAndErrorWrapper from "components/basicComponents/others/LoadingAndErrorWrapper";
import AppProfile, {
  IProfileComponents,
  IProfileRoutes,
} from "components/basicComponents/profile/AppProfile";
import { getValueBasedOnLang } from "helpers/index";
import { HiOutlineInformationCircle } from "react-icons/hi";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useGetByIdService } from "services";
import { AppUrls } from "services/appUrls";
import { EntityName, SchemaName } from "../../../shared/constants/AppConst";
import { PermissionActionsTypes } from "../../../types/entitiesTypes/enums";
import { ICourseDetailsDto } from "types/entitiesTypes/courses";
import CourseDetails from "components/trainStructure/courses/CourseDetails";
import SessionsPages from "./session/SessionsPages";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import ExamsPages from "./exams/ExamsPages";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
const CourseProfile: React.FC = () => {
  const { messages } = useIntl();
  const { courseIdParams } = useParams();
  const {
    data: courseItem,
    error,
    isLoading,
  } = useGetByIdService<ICourseDetailsDto>(
    AppUrls.Courses,
    courseIdParams || ""
  );
  const columnsToShow = ["nameEn", "nameAr", "imageUrl"];
  const sidebarRoutes: IProfileRoutes[] = [
    {
      path: "/",
      routeComponent: <CourseDetails courseItem={courseItem!} />,
    },
    {
      path: "/exams",
      routeComponent: <ExamsPages />,
    },
    {
      path: "/session",
      routeComponent: <SessionsPages />,
    },
  ];

  const childrenComponents: IProfileComponents[] = [
    {
      toPath: "",
      icon: <HiOutlineInformationCircle size={20} />,
      label: "details",
      schemaName: SchemaName.SystemConfigurations,
      entityName: EntityName.States,
      action: PermissionActionsTypes.Details,
    },

    {
      toPath: "exams",
      icon: <ReceiptLongIcon fontSize="small" />,
      label: "sidebar.exams",
      schemaName: SchemaName.UsersManagement,
      entityName: EntityName.Users,
      action: PermissionActionsTypes.List,
    },
    {
      toPath: "session",
      icon: <VideoCallIcon fontSize="small" />,
      label: "sidebar.session",
      schemaName: SchemaName.UsersManagement,
      entityName: EntityName.Users,
      action: PermissionActionsTypes.List,
    },
  ];

  return (
    <>
      <LoadingAndErrorWrapper
        isLoading={isLoading}
        errorMessage={error?.response?.data.messages[0]}
      >
        <AppProfile
          error={error}
          breadcrumbPaths={[
            { name: messages["sidebar.scientificContentManagement.title"] },
            {
              name: messages["sidebar.courses"],
              path: "/trainStructure/courses",
            },
            {
              name: getValueBasedOnLang(
                courseItem?.nameAr || "",
                courseItem?.nameEn || ""
              ),
            },
          ]}
          currentBreadcrumbPath="details"
          sidebarRoutes={sidebarRoutes}
          childrenComponents={childrenComponents}
        />
      </LoadingAndErrorWrapper>
    </>
  );
};

export default CourseProfile;
