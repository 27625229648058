import { Grid, Hidden } from "@mui/material";

import { GridSelectionModel } from "@mui/x-data-grid";
import AppDataGrid from "components/basicComponents/dataGrid/AppDataGrid";
import AppFilters from "components/basicComponents/filters/AppFilters";
import { FC, useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { useDeleteService, useGetListService } from "services";
import { AppUrls } from "services/appUrls";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";

import AddStudentAttachments from "components/trainStructure/student/studentAttachments/AddStudentAttachments";
import { useParams } from "react-router-dom";
import {
  IStudentAttachmentsList,
  IStudentAttachmentsSearch,
} from "types/entitiesTypes/studentAttachments";
import { StudentAttachmentsColumns } from "./StudentAttachmentsColumns";
import AppConfirmModal from "components/basicComponents/modals/AppConfirmModal";
import StudentAttachmentsCard from "./StudentAttachmentsCard";
import AppBreadcrumb from "components/basicComponents/breadcrumb/AppBreadcrumb";
import { IPdfHeader } from "types/appTypes/baseEntity/baseDto";
interface IStudentAttachmentsPageProps {
  diplomaId?: string;
  studentId?: string;
}
const StudentAttachmentsPage: FC<IStudentAttachmentsPageProps> = (props) => {
  const { diplomaId, studentId } = props;
  const { messages } = useIntl();
  const { studentIdParams } = useParams();
  const [search, setSearch] = useState<IStudentAttachmentsSearch>({
    pageNumber: 1,
    pageSize: 10,
    studentId: studentIdParams! || studentId! || null,
    diplomaId: diplomaId! || null,
  });
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);

  const [selectedRow, setSelectedRow] = useState<IStudentAttachmentsList>();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showActivationModal, setShowActivationModal] =
    useState<boolean>(false);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  // For hide breadcrumb in country profile
  const show = window.location.href;
  // For Advance Filters
  const [advancedFilter, setAdvancedFilter] = useState(null);
  const [isOpenAdvanceFilters, setIsOpenAdvanceFilters] =
    useState<boolean>(false);

  const {
    resultData: studentAttachmentsList,
    isFetching,
    error,
  } = useGetListService<IStudentAttachmentsList>(AppUrls.StudentDocuments, {
    ...search,
    advancedFilter,
  });

  // For details
  const handleDetails = useCallback((selectedRow: IStudentAttachmentsList) => {
    setSelectedRow(selectedRow);
    setShowDetailsModal(true);
  }, []);

  // For edit
  const handleUpdate = useCallback((selectedRow: IStudentAttachmentsList) => {
    setSelectedRow(selectedRow);
    setShowEditModal(true);
  }, []);

  // for delete
  const handleDelete = useCallback((selectedRow: IStudentAttachmentsList) => {
    setSelectedRow(selectedRow);
    setShowDeleteModal(true);
  }, []);

  const mutationForDelete = useDeleteService<string>(
    AppUrls.StudentDocuments,
    selectedRow?.id || "",
    () => setShowDeleteModal(false)
  );

  const pdfHeaders: IPdfHeader[] = [
    {
      key: "certificateNumber",
      value: String(messages["student.certificateNumber"]),
    },
    // {
    //   key: "type",
    //   value: String(messages["student.typeDocument"]),
    // },
    {
      key: "subscription.diploma.nameAr",
      value: String(messages["diploma.nameAr"]),
    },
    {
      key: "subscription.diploma.nameEn",
      value: String(messages["diploma.nameEn"]),
    },

    {
      key: "subscription.student.fullName",
      value: String(messages["student.name"]),
    },
  ];

  //for Activation

  const columns = StudentAttachmentsColumns(handleDelete);

  return (
    <>
      <Grid container spacing={3}>
        {!diplomaId && !studentIdParams && !studentId && (
          <AppBreadcrumb
            paths={[
              { name: messages["sidebar.scientificContentManagement.title"] },
            ]}
            currentPathName={"sidebar.requestsEducationalDocuments"}
          />
        )}
        <Grid item xs={12}>
          <AppFilters
            //excel pdf
            filterAll={{
              ...search,
              advancedFilter,
            }}
            addTitle="student.attachments.add"
            setShowAddModal={setShowAddModal}
            onChangeKeywordValue={(
              event: React.ChangeEvent<HTMLInputElement>
            ) => {
              setSearch({ ...search, keyword: event.target.value });
            }}
            selectionModel={selectionModel as string[]}
            setSelectionModel={setSelectionModel}
            successUrl={AppUrls.StudentDocuments}
            keyword={search?.keyword}
            isActive={search?.isActive}
            advancedFilter={advancedFilter}
            excelFileTitle={String(
              messages["sidebar.requestsEducationalDocuments"]
            )}
            withExportExcelButton={false}
            totalCount={studentAttachmentsList?.totalCount || 0}
            pdfHeaders={pdfHeaders}
            // For Permissions
            schemaName={SchemaName.SystemConfigurations}
            entityName={EntityName.Countries}
            action={PermissionActionsTypes.Add}
            // Advance Filters
            showAdvanceFiltersButton={false}
            isOpenAdvanceFilters={isOpenAdvanceFilters}
            setIsOpenAdvanceFilters={setIsOpenAdvanceFilters}
            setAdvancedFilter={setAdvancedFilter}
          />
        </Grid>
        <Hidden mdDown>
          <Grid item xs={12}>
            <AppDataGrid
              rowCount={studentAttachmentsList?.totalCount ?? 0}
              pageNumber={search?.pageNumber ?? 0}
              //for pagination
              onPageSizeChange={(pageSize: number) => {
                setSearch({ ...search, pageSize: pageSize });
              }}
              onPageChange={(pageNumber: number) => {
                setSearch({ ...search, pageNumber: pageNumber + 1 });
              }}
              pageSize={search?.pageSize ?? 10}
              columns={columns}
              rows={studentAttachmentsList?.data ?? []}
              loading={isFetching}
              //for selection
              onSelectionModelChange={(
                newSelectionModel: GridSelectionModel
              ) => {
                setSelectionModel(newSelectionModel);
              }}
              checkboxSelection={false}
              selectionModel={selectionModel}
              //for sorting
              onSortModelChange={(result: string[] | null) => {
                setSearch({
                  ...search,
                  orderBy: result,
                });
              }}
            />
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid item xs={12}>
            <StudentAttachmentsCard
              handleDelete={handleDelete}
              studentAttachmentsList={studentAttachmentsList?.data!}
              setPage={(event, page) => {
                setSearch({
                  ...search,
                  pageNumber: page,
                });
              }}
              pageNumber={Math.ceil(
                (studentAttachmentsList?.totalCount || 0) / 10
              )}
              page={search?.pageNumber}
              loading={isFetching}
              errorMessage={error}
            />
          </Grid>
        </Hidden>
      </Grid>

      {/* Add State */}
      {showAddModal && (
        <AddStudentAttachments
          label="student.attachments.add"
          showAddModal={showAddModal}
          setShowAddModal={setShowAddModal}
          diplomaId={diplomaId}
          studentId={studentId}
        />
      )}

      {showDeleteModal && (
        <AppConfirmModal
          dialogTitle="attachment.actions.delete.dialog.title"
          open={showDeleteModal}
          setOpen={setShowDeleteModal}
          loading={mutationForDelete.isLoading}
          title="attachment.actions.delete.title"
          onConfirm={() => {
            mutationForDelete.mutate();
          }}
        />
      )}
    </>
  );
};

export default StudentAttachmentsPage;
