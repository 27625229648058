import IntlMessages from "@crema/utility/IntlMessages";
import { Grid, Hidden, MenuItem } from "@mui/material";
import { GridSelectionModel } from "@mui/x-data-grid";
import AppBreadcrumb from "components/basicComponents/breadcrumb/AppBreadcrumb";
import AppDataGrid from "components/basicComponents/dataGrid/AppDataGrid";
import AppAdvanceFilterAutoComplete from "components/basicComponents/filters/AppAdvanceFilterAutoComplete";
import AppAdvanceFilterSelect from "components/basicComponents/filters/AppAdvanceFilterSelect";
import AppFilters from "components/basicComponents/filters/AppFilters";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import {
  useAdvanceFilterAutocompleteService,
  useGetListService,
} from "services";
import { AppUrls } from "services/appUrls";
import {
  DEBOUNCE_TIME,
  EntityName,
  SchemaName,
} from "shared/constants/AppConst";
import { IDiplomasListDto } from "types/entitiesTypes/diplomas";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import { IStudentListDto } from "types/entitiesTypes/student";
import {
  IStudentsSubscriptionListDto,
  IStudentSubscriptionSearch,
} from "types/entitiesTypes/studentSubscription";
import StudentsSubscriptionCard from "./StudentsSubscriptionCard";
import { StudentsSubscriptionColumns } from "./StudentsSubscriptionColumns";
import { IPdfHeader } from "types/appTypes/baseEntity/baseDto";

const StudentsSubscriptionPage = () => {
  const { messages, locale } = useIntl();
  const { studentIdParams, diplomaIdParams } = useParams();

  const [search, setSearch] = useState<IStudentSubscriptionSearch>({
    pageNumber: 1,
    pageSize: 10,
    studentId: studentIdParams || null,
    diplomaId: diplomaIdParams || null,
  });
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);

  const [studentKeyword, setStudentKeyword] = useState<string>("");
  const [studentPageNumber, setStudentPageNumber] = useState(1);

  const [diplomaKeyword, setDiplomaKeyword] = useState<string>("");
  const [diplomaPageNumber, setDiplomaPageNumber] = useState(1);

  const [selectedRow, setSelectedRow] =
    useState<IStudentsSubscriptionListDto>();
  useState<boolean>(false);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  // For hide breadcrumb in country profile
  // For Advance Filters
  const [advancedFilter, setAdvancedFilter] = useState(null);
  const [isOpenAdvanceFilters, setIsOpenAdvanceFilters] =
    useState<boolean>(false);

  const {
    resultData: StudentsSubscriptionList,
    isFetching,
    error,
  } = useGetListService<IStudentsSubscriptionListDto>(AppUrls.Subscription, {
    ...search,
    advancedFilter,
  });

  const {
    data: diplomasList,
    isFetching: isFetchingDiplomas,
    refetch: refetchDiplomas,
  } = useAdvanceFilterAutocompleteService<IDiplomasListDto>(
    AppUrls.Diplomas,
    {
      keyword: diplomaKeyword,
      pageSize: 10,
      pageNumber: diplomaPageNumber,
    },
    Boolean(!diplomaIdParams)
  );

  const {
    data: studentsList,
    isFetching: isFetchingStudents,
    refetch: refetchStudents,
  } = useAdvanceFilterAutocompleteService<IStudentListDto>(
    AppUrls.Student,
    {
      keyword: studentKeyword,
      pageSize: 10,
      pageNumber: studentPageNumber,
    },
    Boolean(!studentIdParams)
  );

  useEffect(() => {
    if (Boolean(!diplomaIdParams)) refetchDiplomas();
  }, [diplomaPageNumber, diplomaIdParams]);

  useEffect(() => {
    if (Boolean(!studentIdParams)) refetchStudents();
  }, [studentPageNumber, studentIdParams]);

  const columns = StudentsSubscriptionColumns();

  const pdfHeaders: IPdfHeader[] = [
    { key: "diploma.nameAr", value: String(messages["diploma.nameAr"]) },
    {
      key: "diploma.nameEn",
      value: String(messages["diploma.nameEn"]),
    },
    { key: "student.fullName", value: String(messages["student.name"]) },
    {
      key: "subscriptionDate",
      value: String(messages["student.subscriptionDate"]),
    },
  ];

  return (
    <>
      <Grid container spacing={3}>
        {!studentIdParams && !diplomaIdParams && (
          <AppBreadcrumb
            paths={[{ name: messages["sidebar.managePayments.title"] }]}
            currentPathName={"sidebar.installments"}
          />
        )}

        <Grid item xs={12}>
          <AppFilters
            //excel pdf
            filterAll={{
              ...search,
              advancedFilter,
            }}
            onChangeKeywordValue={(
              event: React.ChangeEvent<HTMLInputElement>
            ) => {
              setSearch({ ...search, keyword: event.target.value });
            }}
            selectionModel={selectionModel as string[]}
            setSelectionModel={setSelectionModel}
            successUrl={AppUrls.Subscription}
            keyword={search?.keyword}
            advancedFilter={advancedFilter}
            showAddButton={false}
            totalCount={StudentsSubscriptionList?.totalCount || 0}
            // For Permissions
            schemaName={SchemaName.SystemConfigurations}
            entityName={EntityName.Countries}
            action={PermissionActionsTypes.Add}
            // Advance Filters
            showAdvanceFiltersButton={true}
            isOpenAdvanceFilters={isOpenAdvanceFilters}
            setIsOpenAdvanceFilters={setIsOpenAdvanceFilters}
            setAdvancedFilter={setAdvancedFilter}
            pdfHeaders={pdfHeaders}
            excelFileTitle={String(messages["sidebar.studentSubscription"])}
            withExportExcelButton
            customQueries={{
              ...search,
            }}
            advanceFiltersInputs={[
              Boolean(!diplomaIdParams) && (
                <AppAdvanceFilterAutoComplete
                  label="diplomas.name"
                  fieldName="diplomaId"
                  options={diplomasList?.data}
                  isLoading={isFetchingDiplomas}
                  searchText={diplomaKeyword}
                  setSearchText={setDiplomaKeyword}
                  onChangeTextField={debounce((event) => {
                    setDiplomaKeyword(event.target.value);
                  }, DEBOUNCE_TIME)}
                  //pagination
                  pageNumber={diplomaPageNumber}
                  setPageNumber={setDiplomaPageNumber}
                  hasNextPage={diplomasList?.hasNextPage}
                  setAdvancedFilter={setSearch}
                  advancedFilter={search}
                />
              ),

              Boolean(!studentIdParams) && (
                <AppAdvanceFilterAutoComplete
                  label="session.studentName"
                  fieldName="studentId"
                  options={studentsList?.data}
                  isLoading={isFetchingStudents}
                  searchText={studentKeyword}
                  filterKey="fullName"
                  getOptionLabel={(option) => option.fullName}
                  setSearchText={setStudentKeyword}
                  onChangeTextField={debounce((event) => {
                    setStudentKeyword(event.target.value);
                  }, DEBOUNCE_TIME)}
                  //pagination
                  pageNumber={studentPageNumber}
                  setPageNumber={setStudentPageNumber}
                  hasNextPage={studentsList?.hasNextPage}
                  setAdvancedFilter={setSearch}
                  advancedFilter={search}
                />
              ),
              <AppAdvanceFilterSelect
                fieldName="subscriptionType"
                label={"student.subscriptionType"}
                labelForView={
                  locale == "en-US" ? "subscriptionType" : "roleLevelsSD"
                }
                advancedFilter={search}
                setAdvancedFilter={setSearch}
              >
                <MenuItem value={1}>
                  <IntlMessages id="student.subscriptionType.onePayment" />
                </MenuItem>
                <MenuItem value={2}>
                  <IntlMessages id="student.subscriptionType.multiPayment" />
                </MenuItem>
              </AppAdvanceFilterSelect>,
              <AppAdvanceFilterSelect
                fieldName="status"
                label={"student.subscriptionStatus"}
                labelForView={
                  locale == "en-US" ? "subscriptionStatus" : "roleLevelsSD"
                }
                advancedFilter={search}
                setAdvancedFilter={setSearch}
              >
                <MenuItem value={1}>
                  <IntlMessages id="student.subscriptionStatus.Pending" />
                </MenuItem>
                <MenuItem value={2}>
                  <IntlMessages id="student.subscriptionStatus.Enrolled" />
                </MenuItem>
                <MenuItem value={3}>
                  <IntlMessages id="student.subscriptionStatus.Dropped" />
                </MenuItem>

                <MenuItem value={4}>
                  <IntlMessages id="student.subscriptionStatus.Withdrawn" />
                </MenuItem>

                <MenuItem value={5}>
                  <IntlMessages id="student.subscriptionStatus.Graduated" />
                </MenuItem>

                <MenuItem value={6}>
                  <IntlMessages id="student.subscriptionStatus.Suspended" />
                </MenuItem>
              </AppAdvanceFilterSelect>,

              <AppAdvanceFilterSelect
                fieldName="isFree"
                label={"diplomas.isFree"}
                labelForView={locale == "en-US" ? "isFreee" : "isFre"}
                advancedFilter={search}
                setAdvancedFilter={setSearch}
              >
                <MenuItem value={"false"}>
                  <IntlMessages id="common.no" />
                </MenuItem>
                <MenuItem value={"true"}>
                  <IntlMessages id="common.yes" />
                </MenuItem>
              </AppAdvanceFilterSelect>,
            ]}
          />
        </Grid>
        <Hidden mdDown>
          <Grid item xs={12}>
            <AppDataGrid
              rowCount={StudentsSubscriptionList?.totalCount ?? 0}
              pageNumber={search?.pageNumber ?? 0}
              //for pagination
              onPageSizeChange={(pageSize: number) => {
                setSearch({ ...search, pageSize: pageSize });
              }}
              onPageChange={(pageNumber: number) => {
                setSearch({ ...search, pageNumber: pageNumber + 1 });
              }}
              pageSize={search?.pageSize ?? 10}
              columns={columns}
              rows={StudentsSubscriptionList?.data ?? []}
              loading={isFetching}
              //for selection
              onSelectionModelChange={(
                newSelectionModel: GridSelectionModel
              ) => {
                setSelectionModel(newSelectionModel);
              }}
              checkboxSelection={false}
              selectionModel={selectionModel}
              //for sorting
              // onSortModelChange={(result: string[] | null) => {
              //   setSearch({
              //     ...search,
              //     orderBy: result,
              //   });
              // }}
            />
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid item xs={12}>
            <StudentsSubscriptionCard
              StudentsSubscriptionList={StudentsSubscriptionList?.data!}
              page={search?.pageNumber}
              pageNumber={Math.ceil(
                (StudentsSubscriptionList?.totalCount || 0) / 10
              )}
              loading={isFetching}
              errorMessage={error}
            />
          </Grid>
        </Hidden>
      </Grid>
    </>
  );
};

export default StudentsSubscriptionPage;
