import React from "react";
import { useSelector } from "react-redux";
import { AppState } from "redux/store";

const usePermissionsHook = () => {
  const { personalPermissionsList } = useSelector<
    AppState,
    AppState["personal"]
  >((state) => state.personal);

  // Get Entity Permissions
  const getEntityPermissions = (entityName: string) => {
    const entityPermissions = personalPermissionsList?.filter((element) => {
      return element.table == entityName;
    });

    return entityPermissions;
  };

  // Check Entity Method Permissions
  const checkPermissionCode = (perList: any[], perCode: string) => {
    const result = perList?.filter((element) => {
      return element.permissions.includes(perCode);
    });

    if (result?.length !== 0) return true;
    return false;
  };

  return {
    getEntityPermissions: getEntityPermissions,
    checkPermissionCode: checkPermissionCode,
  };
};

export default usePermissionsHook;
