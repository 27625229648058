import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  Box,
  Card,
  CardMedia,
  Grid,
  Link,
  Pagination,
  Typography,
} from "@mui/material";
import { AxiosError } from "axios";
import AppIconButton from "components/basicComponents/others/AppIconButton";
import LoadingAndErrorWrapper from "components/basicComponents/others/LoadingAndErrorWrapper";
import { generatePresignedUrl } from "helpers";
import { FC } from "react";
import { useIntl } from "react-intl";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { IAdvertisementSlidersListDto } from "types/entitiesTypes/advertisementSlider";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import defaultImage from "assets/appDefaultImages/branch.png";

interface IAdvertisementSlidersCardProps {
  handleDelete: (row: IAdvertisementSlidersListDto) => void;
  AdvertisementSlidersList: Array<IAdvertisementSlidersListDto>;
  pageNumber?: number;
  setPage?: (event: React.ChangeEvent<unknown>, page: number) => void;
  page?: number;
  loading: boolean;
  errorMessage: AxiosError<any, any> | null;
}
const AdvertisementSlidersCard: FC<IAdvertisementSlidersCardProps> = (
  props
) => {
  const {
    handleDelete,
    AdvertisementSlidersList,
    loading,
    pageNumber,
    setPage,
    page,
    errorMessage,
  } = props;
  const { messages, locale } = useIntl();

  return (
    <Grid container spacing={4}>
      <LoadingAndErrorWrapper
        isLoading={loading}
        errorMessage={errorMessage?.response?.data.messages[0]}
      >
        {AdvertisementSlidersList?.length != undefined &&
        AdvertisementSlidersList?.length >= 1 ? (
          AdvertisementSlidersList?.map((item, index) => (
            <Grid key={`AdvertisementSlidersList${index}`} item xs={12}>
              <Card>
                <Box sx={{ position: "relative" }}>
                  <CardMedia
                    component="img"
                    height="200"
                    // image={`${BaseFileUrl}${item.file}`}
                    image={
                      item.file
                        ? `${generatePresignedUrl(
                            item.file,
                            process.env.NODE_ENV
                          )}`
                        : defaultImage
                    }
                    sx={{ aspectRatio: "9/6" }}
                  />
                  <Box
                    position={"absolute"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    width={"100%"}
                    padding={5}
                    margin={0}
                    zIndex={2}
                    gap={2}
                    bgcolor="rgba(0, 0, 0, 0.54)"
                    color="white"
                    bottom={0}
                    left={0}
                  >
                    <Link
                      href={item.link}
                      color={"ButtonText"}
                      underline="none"
                      target="_blank"
                    >
                      <RemoveRedEyeIcon
                        fontSize="small"
                        sx={{ color: "#007991" }}
                      />
                    </Link>

                    {/* Delete */}
                    <AppIconButton
                      label="table.actions.delete"
                      handleClick={() => {
                        handleDelete(item);
                      }}
                      childComp={
                        <DeleteOutlinedIcon
                          fontSize="small"
                          sx={{ color: "#D72638" }}
                        />
                      }
                      // For Permissions
                      schemaName={SchemaName.SystemConfigurations}
                      entityName={EntityName.Countries}
                      action={PermissionActionsTypes.Delete}
                    />
                  </Box>
                </Box>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            item
            xs={12}
            marginTop={10}
          >
            <Typography variant="h5" component={"span"}>
              {messages["common.dataFound"]}
            </Typography>
          </Grid>
        )}
        {pageNumber! > 1 && (
          <Grid
            display={"flex"}
            alignItems={"cneter"}
            justifyContent={"center"}
            marginTop={5}
            item
            xs={12}
          >
            <Pagination
              count={pageNumber}
              page={page}
              color="primary"
              onChange={(event, page) => {
                setPage && setPage(event, page);
              }}
            />
          </Grid>
        )}
      </LoadingAndErrorWrapper>
    </Grid>
  );
};

export default AdvertisementSlidersCard;
