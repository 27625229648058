import { enUS } from "@mui/material/locale";
import enMessages from "../locales/en_US.json";
import siteMangemenEnMessages from "../locales/siteMangemen_en_US.json";

const EnLang = {
  messages: {
    ...enMessages,
    ...siteMangemenEnMessages,
  },
  muiLocale: enUS,
  locale: "en-US",
};
export default EnLang;
