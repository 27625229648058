import { refundStatus } from "shared/constants/AppEnums";
import AppBoxBadge from "./AppBoxBadge";
interface IRefundStatusBadgeProps {
  refundStatusProps: string | number;
  transform?: string;
  label: string;
  colors?: any;
}
const RefundStatusBadge: React.FC<IRefundStatusBadgeProps> = (props) => {
  const {
    colors = {
      PendingApprovalColor: "#053C5E",
      ApprovedColor: "#358600",
      RejectedColor: "red",
    },
    label,
    refundStatusProps,
    transform,
  } = props;

  return (
    <>
      <AppBoxBadge
        backgroundColor={`${colors[`${refundStatusProps}Color`]}`}
        label={label!}
        transform={transform}
      />
    </>
  );
};
export default RefundStatusBadge;
