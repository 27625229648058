import { Grid } from "@mui/material";
import AppItemView from "components/basicComponents/others/AppItemView";
import BaseProfileDetails from "components/basicComponents/profile/BaseProfileDetails";
import { removeHTMLTags } from "helpers";
import { useIntl } from "react-intl";
import { ICourseDetailsDto } from "types/entitiesTypes/courses";

interface IUserDetailsProps {
  courseItem: ICourseDetailsDto;
}

const CourseDetails = (props: IUserDetailsProps) => {
  const { courseItem } = props;
  const { messages } = useIntl();

  return (
    <>
      <BaseProfileDetails
        columns={12}
        moreDetails={courseItem as ICourseDetailsDto}
      >
        <Grid container item spacing={5}>
          <AppItemView
            label="common.nameAr"
            value={courseItem?.nameAr}
            sm={4}
          />
          <AppItemView
            label="common.nameEn"
            value={courseItem?.nameEn}
            sm={4}
          />
          <AppItemView
            label="course.semester"
            value={
              courseItem?.termId === 1
                ? messages["course.semester.first"]
                : messages["course.semester.second"]
            }
            sm={4}
          />

          <AppItemView
            label="common.startDate"
            value={courseItem?.startDate}
            sm={4}
            type="date"
          />
          <AppItemView
            label="common.endDate"
            value={courseItem?.endDate}
            sm={4}
            type="date"
          />

          <AppItemView
            label="common.contentAr"
            value={removeHTMLTags(courseItem?.contentAr)}
            sm={6}
          />
          <AppItemView
            label="common.contentEn"
            value={removeHTMLTags(courseItem?.contentEn)}
            sm={6}
          />
          <AppItemView
            label="common.isActive"
            value={courseItem?.isActive}
            type="isActive"
            sm={6}
          />
        </Grid>
      </BaseProfileDetails>
    </>
  );
};

export default CourseDetails;
