import AppTextField from "@crema/core/AppFormComponents/AppTextField";
import IntlMessages from "@crema/utility/IntlMessages";
import { Grid } from "@mui/material";
import AppDateTimePicker from "components/basicComponents/inputs/dateTimePickers/AppDateTimePicker";
import AppEditModal from "components/basicComponents/modals/AppEditModal";
import { Form, Formik, FormikProps } from "formik";
import React from "react";
import { useIntl } from "react-intl";
import { useGetByIdService, useUpdateService } from "services";
import { AppUrls } from "services/appUrls";
import { ISelectedRow } from "types/appTypes/baseEntity/baseDto";

import type {
  IDiplomaInstallmentDetailsDto,
  IDiplomaInstallmentListDto,
  IUpdateDiplomaInstallmentDto,
} from "types/entitiesTypes/DiplomaInstallment";
import * as yup from "yup";

interface IEditDiplomaInstallmentProps {
  showEditModal: boolean;
  setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  label: string;
  selectedRow: IDiplomaInstallmentListDto;
  onSuccess?: () => void;
  onFailed?: () => void;
}
const EditDiplomaInstallment: React.FC<IEditDiplomaInstallmentProps> = (
  props
) => {
  const { showEditModal, setShowEditModal, label, selectedRow } = props;

  const { messages } = useIntl();
  const refForm = React.useRef<FormikProps<IUpdateDiplomaInstallmentDto>>(null);

  const mutation = useUpdateService<IUpdateDiplomaInstallmentDto, string>(
    AppUrls.DiplomaInstallment,
    () => {},
    false
  );
  const validationSchema = yup.object({
    dueDate: yup.string().required(String(messages["validation.required"])),
    amount: yup.number().required(String(messages["validation.required"])),
    daysMargin: yup.number().required(String(messages["validation.required"])),
  });

  const submit = () => {
    if (refForm.current) {
      refForm.current.submitForm();
    }
  };

  return (
    <>
      <AppEditModal
        label={label}
        itemName={""}
        open={showEditModal}
        onClose={() => {
          setShowEditModal(false);
        }}
        handleSubmit={submit}
        loading={false}
        errorMessage={null!}
        loadingForEdit={
          (refForm.current?.isSubmitting && !refForm.current?.isValidating) ||
          false
        }
        closeModalState={setShowEditModal}
        successClose={mutation.isSuccess}
        maxWidth={"md"}
      >
        <Formik
          validateOnChange={true}
          initialValues={{
            id: selectedRow?.id || "",
            diplomaInstallmentOrder: selectedRow?.diplomaInstallmentOrder || 1,
            dueDate: selectedRow?.dueDate || "",
            amount: selectedRow?.amount || 0,
            daysMargin: selectedRow?.daysMargin || 0,
          }}
          innerRef={refForm}
          validationSchema={validationSchema}
          onSubmit={async (data, { setSubmitting }) => {
            mutation.submitData(data);
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={4}>
                  <AppDateTimePicker
                    value={values?.dueDate}
                    label={"diplomaInstallment.dueDate"}
                    name="dueDate"
                    onChange={setFieldValue}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <AppTextField
                    label={<IntlMessages id="diplomaInstallment.amount" />}
                    name={`amount`}
                    variant="outlined"
                    value={values?.amount}
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <AppTextField
                    label={<IntlMessages id="diplomaInstallment.daysMargin" />}
                    name={`daysMargin`}
                    variant="outlined"
                    size="small"
                    value={values?.daysMargin}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </AppEditModal>
    </>
  );
};

export default EditDiplomaInstallment;
