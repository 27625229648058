import { ColorLibStepIconRoot } from "components/basicComponents/modals/stepper/ColorLibStepIconRoot";
import { BiDetail } from "react-icons/bi";
import { MdOutlineAttachMoney, MdOutlineInfo } from "react-icons/md";

export function ColorLibStepIcon(props) {
  const { active, completed, className } = props;
  const color = !active && !completed ? "#ffffff88" : "#ffffff";

  const icons = {
    1: <MdOutlineInfo size={24} color={color} />,
    2: <BiDetail size={20} color={color} />,
    3: <MdOutlineAttachMoney size={24} color={color} />,
  };

  return (
    <ColorLibStepIconRoot ownerState={{ completed, active }} theme={className}>
      {icons[String(props.icon)]}
    </ColorLibStepIconRoot>
  );
}
