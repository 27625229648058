import React from "react";
import RoleProfile from "./roles/RoleProfile";
import UserProfileWithRole from "./users/UserProfileWithRole";
import StudentPages from "pages/trainStructure/student/StudentPages";
import StudentProfile from "pages/trainStructure/student/StudentProfile";
import InstructorProfile from "pages/trainStructure/instructor/InstructorProfile";
import InstructorPages from "pages/trainStructure/instructor/InstructorPages";

const UsersPage = React.lazy(() => import("./users/UsersPage"));
const RolesPage = React.lazy(() => import("./roles/RolesPage"));

export const usersManagementPages = [
  // {
  //   path: "/usersManagement/users",
  //   element: <UsersPage />,
  // },
  // {
  //   path: "/usersManagement/users/profile/:userIdParams/*",
  //   element: <UserProfileWithRole />,
  // },
  // // Roles
  // {
  //   path: "/usersManagement/roles",
  //   element: <RolesPage />,
  // },
  // {
  //   path: "/usersManagement/roles/profile/:roleIdParams/*",
  //   element: <RoleProfile />,
  // },

  {
    path: "/usersManagement/instructors",
    element: <InstructorPages />,
  },
  {
    path: "/usersManagement/instructors/profile/:instructorIdParams/*",
    element: <InstructorProfile />,
  },

  {
    path: "/usersManagement/students",
    element: <StudentPages />,
  },

  {
    path: "/usersManagement/students/profile/:studentIdParams/*",
    element: <StudentProfile />,
  },
];
