export enum OperationTypeForSetAll {
  SetAsActive = "SetAsActive",
  SetAsInActive = "SetAsInActive",
  SetAsDeleted = "SetAsDeleted",
  SetChecked = "SetChecked",
}

export enum RoleLevel {
  Employee = "Employee",
  Manager = "Manager",
  GeneralManager = "GeneralManager ",
}

export enum PaymentMethod {
  Cash = "Cash",
  EPayment = "EPayment",
}
export enum NotificationMethod {
  Notification = "Notification",
  SMS = "SMS",
  Email = "Email",
}

export enum UserType {
  Student = "Student",
  Teacher = "Teacher",
  Employee = "Employee",
  Manager = "Manager",
  GeneralManager = "GeneralManager",
  User = "User",
}

export enum Currency {
  SyrianPound = "SyrianPound",
  Euro = "Euro",
  UnitedStatesDollar = "UnitedStatesDollar",
}

export enum Language {
  Arabic = "Arabic",
  English = "English",
}

export enum ApexTypes {
  LineCharts = "line",
  AreaCharts = "area",
  ColumnAndBarCharts = "bar",
  TimeLineCharts = "rangeBar",
  BubbleCharts = "bubble",
  PieCharts = "pie",
  RadarCharts = "radar",
  PolarAreaCharts = "polarArea",
  RadialBarCharts = "radialBar",
  DonutPieChart = "donut",
}

export enum GendersTypes {
  All = "All",
  Male = "Male",
  Female = "Female",
}

export enum MonthOfYear {
  January = "January",
  February = "February",
  March = "March",
  April = "April",
  May = "May",
  June = "June",
  July = "July",
  August = "August",
  September = "September",
  October = "October",
  November = "November",
  December = "December",
}

export enum ReportType {
  Annual = "Annual",
  Weekly = "Weekly",
  All = "All",
  Monthly = "Monthly",
}
export enum FileType {
  Excel = "Excel",
  Pdf = "Pdf",
}

export enum PermissionActionsTypes {
  List = "List",
  ListOutputs = "ListOutputs",
  Add = "Add",
  Edit = "Edit",
  Details = "Details",
  Delete = "Delete",
  Activate = "Activate",
  Profile = "Profile",
  IsRead = "IsRead",
  Download = "Download",
  Cancel = "Cancel",
  Resend = "Resend",
  Unlock = "Unlock",
  MyRequests = "MyRequests",
  DownloadCompressedFile = "DownloadCompressedFile",
  DownloadInputFilesAsZip = "DownloadInputFilesAsZip",
  DownloadOutputFilesAsZip = "DownloadOutputFilesAsZip",
  CompleteTasks = "CompleteTasks",
  UploadAttachment = "UploadAttachment",
  UploadOutput = "UploadOutput",
  IndicatorValueCheckAll = "IndicatorValueCheckAll",
  CalculateIndicatorValues = "CalculateIndicatorValues",
  ResultsIndicatorValueEquations = "ResultsIndicatorValueEquations",
  CalculateIndicatorValueEquations = "CalculateIndicatorValueEquations",
  ImportIndicatorValuesFile = "UploadIndicatorValuesFile",
  GenerateIndicatorValuesFile = "GenerateIndicatorValuesFile",
  IndicatorValueCheck = "IndicatorValueCheck",
  IndicatorValuePublicValue = "IndicatorValuePublicValue",
  AssignUserToPublicAgency = "AssignUserToPublicAgency",
  DeAssignUserFromPublicAgency = "DeAssignUserFromPublicAgency",
  UserPublicAgenciesList = "UserPublicAgenciesList",
  UserMinistryList = "UserMinistryList",
  SetArchiveDocumentStatus = "SetArchiveDocumentStatus",
  ResetUserPassword = "ResetUserPassword",
  AssignUserToRole = "AssignUserToRole",
  DeAssignUserToRole = "DeAssignUserToRole",
  EditRolePermissions = "SaveChanges",
  FreePermissions = "FreePermissions",
}

export enum ApplicationMessageType {
  TechnicalSupport = "TechnicalSupport",
  Message = "Message",
  Appreciate = "Appreciate",
  Consulting = "Consulting",
  Inquiry = "Inquiry",
  Compliant = "Compliant",
  Note = "Note",
  Other = "Other",
}

export enum CurrentStatus {
  Pending = "Pending",
  InProgress = "InProgress",
  Approved = "Approved",
  Rejected = "Rejected",
  Canceled = "Canceled",
}

export enum DiplomasType {
  FixedValue = 1,
  PercentageValue = 2,
}
export enum typeDocument {
  VirtualFile = 1,
  AcademicRecord = 2,
  Statement = 3,
  Certificate = 4,
}
