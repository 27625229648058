import { getEntityPermissionCode } from "helpers/permessions";
import * as React from "react";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import usePermissionsHook from "./usePermissionsHook";

export interface IAppPermissionsProps {
  schemaName: string;
  entityName: string;
  action: PermissionActionsTypes;
}

interface IAppPermissionsGateProps extends IAppPermissionsProps {
  children: React.ReactNode;
}

const AppPermissionsGate: React.FC<IAppPermissionsGateProps> = (props) => {
  const { children, schemaName, entityName, action } = props;
  const { getEntityPermissions, checkPermissionCode } = usePermissionsHook();

  let hasPermission: boolean = false;

  const entityListPer = getEntityPermissions(entityName);

  const permissionCode = getEntityPermissionCode(
    schemaName,
    entityName,
    action
  );

  hasPermission = checkPermissionCode(entityListPer, permissionCode);
  //to edit when work on permission
  // if (hasPermission) return <>{children}</>;

  return <>{children}</>;
};

export default AppPermissionsGate;

// export function permissionsGateBoolean(
//   schemaName: string,
//   entityName: string,
//   action: PermissionActionsTypes
// ) {
//   const { getEntityPermissions, checkPermissionCode } = usePermissionsHook();

//   const entityListPer = getEntityPermissions(entityName);
//   const permissionCode = getEntityPermissionCode(
//     schemaName,
//     entityName,
//     action
//   );
//   const hasPermission = checkPermissionCode(entityListPer, permissionCode);

//   return hasPermission;
// }
