import { useAuthMethod } from "@crema/utility/AuthHooks";
import { Grid } from "@mui/material";
import AppPasswordField from "components/basicComponents/inputs/AppPasswordField";
import AppAddModal from "components/basicComponents/modals/AppAddModal";
import { Form, Formik, FormikProps } from "formik";
import React from "react";
import { useIntl } from "react-intl";
import { useCreateService, useUpdateService } from "services";
import { AppUrls } from "services/appUrls";
import { ISelectedRow } from "types/appTypes/baseEntity/baseDto";
import { IChangeUserPasswordDto } from "types/entitiesTypes/users";
import * as yup from "yup";

interface IChangeUserPasswordProps {
  showChangePasswordModal: boolean;
  setShowChnagePasswordModal: React.Dispatch<React.SetStateAction<boolean>>;
  label: string;
  onSuccess?: () => void;
  onFailed?: () => void;
}

const ChangeUserPassword: React.FC<IChangeUserPasswordProps> = (props) => {
  const { showChangePasswordModal, setShowChnagePasswordModal, label } = props;
  const { messages } = useIntl();
  const { logout } = useAuthMethod();

  const refForm = React.useRef<FormikProps<IChangeUserPasswordDto>>(null);
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = React.useState<boolean>(false);

  const [showConfirmPassword, setShowConfirmPassword] =
    React.useState<boolean>(false);

  const mutation = useUpdateService<IChangeUserPasswordDto, string>(
    AppUrls.ChangeUserPassword,
    () => {
      logout();
    },
    false,
    null,
    false
  );

  const validationSchema = yup.object({
    password: yup
      .string()
      .required(String(messages["validation.required"]))
      .min(6, String(messages["common.password.lengthValidation"])),
    newPassword: yup
      .string()
      .required(String(messages["validation.required"]))
      .min(6, String(messages["common.password.lengthValidation"])),
    confirmNewPassword: yup
      .string()
      .required(String(messages["validation.required"]))
      .oneOf(
        [yup.ref("newPassword"), null],
        String(messages["common.cofirmNewPassword.validation"])
      ),
  });

  const submit = () => {
    if (refForm.current) {
      refForm.current.submitForm();
    }
  };

  return (
    <>
      <AppAddModal
        label={label}
        open={showChangePasswordModal}
        onClose={() => setShowChnagePasswordModal(false)}
        closeModalState={setShowChnagePasswordModal}
        successClose={mutation.isSuccess}
        handleSubmit={submit}
        labelForAddBtn="common.save"
        loadingForAdd={
          (refForm.current?.isSubmitting && !refForm.current?.isValidating) ||
          false
        }
        maxWidth="sm"
      >
        <Formik
          validateOnChange={true}
          initialValues={{
            password: "",
            newPassword: "",
            confirmNewPassword: "",
          }}
          validationSchema={validationSchema}
          innerRef={refForm}
          onSubmit={async (data, { setSubmitting }) => {
            mutation.submitData(data);
          }}
        >
          {({ setFieldValue, values, errors }) => (
            <Form>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <AppPasswordField
                    label="common.oldpassword"
                    name="password"
                    showPassword={showPassword}
                    setShowPassword={setShowPassword}
                  />
                </Grid>
                <Grid item xs={6}>
                  <AppPasswordField
                    label="common.newPassword"
                    name="newPassword"
                    showPassword={showNewPassword}
                    setShowPassword={setShowNewPassword}
                  />
                </Grid>
                <Grid item xs={6}>
                  <AppPasswordField
                    label="common.confirmNewPassword"
                    name="confirmNewPassword"
                    showPassword={showConfirmPassword}
                    setShowPassword={setShowConfirmPassword}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </AppAddModal>
    </>
  );
};

export default ChangeUserPassword;
