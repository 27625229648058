import { Grid, Hidden } from "@mui/material";
import { GridSelectionModel } from "@mui/x-data-grid";
import AppDataGrid from "components/basicComponents/dataGrid/AppDataGrid";
import AppConfirmModal from "components/basicComponents/modals/AppConfirmModal";
import { getValueBasedOnLangForConfirmModal } from "helpers";
import { FC, useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { useDeleteService, useGetByIdService } from "services";
import { AppUrls } from "services/appUrls";

import AppFilters from "components/basicComponents/filters/AppFilters";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { IAttendanceList } from "types/entitiesTypes/attendance";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import { AttendanceColumns } from "./AttendanceColumns";
import AttendanceCard from "./AttendanceCard";
interface IAttendancePageProps {
  sessionId: string;
}
const AttendancePage: FC<IAttendancePageProps> = (props) => {
  const { sessionId } = props;
  const { messages } = useIntl();

  const {
    data: AttendanceList,
    isFetching,
    refetch,
    error,
  } = useGetByIdService<Array<IAttendanceList>>(
    AppUrls.Session,
    `${sessionId}/Attendance`
  );

  const columns = AttendanceColumns();

  return (
    <>
      <Grid container spacing={3}>
        <Hidden smDown>
          <Grid item xs={12}>
            <AppDataGrid
              rowCount={AttendanceList?.length ?? 0}
              getRowId={(option) => option.sessionId}
              pageNumber={1}
              //for pagination
              onPageSizeChange={() => {}}
              onPageChange={() => {}}
              pageSize={AttendanceList?.length ?? 0}
              columns={columns}
              rows={AttendanceList ?? []}
              loading={isFetching}
              //for selection

              checkboxSelection={false}
              //for sorting
              onSortModelChange={() => {}}
            />
          </Grid>
        </Hidden>
        <Hidden smUp>
          <Grid item xs={12}>
            <AttendanceCard
              AttendanceList={AttendanceList!}
              loading={isFetching}
              errorMessage={error!}
            />
          </Grid>
        </Hidden>
      </Grid>
    </>
  );
};

export default AttendancePage;
