import { GridColDef } from "@mui/x-data-grid";
import AppDataGridCell from "components/basicComponents/dataGrid/AppDataGridCell";
import AppIconButton from "components/basicComponents/others/AppIconButton";
import moment from "moment";
import { useIntl } from "react-intl";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";

import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DoDisturbOffIcon from "@mui/icons-material/DoDisturbOff";
import NotificationsIcon from "@mui/icons-material/Notifications";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

import { IInstanceNotificationsListDto } from "types/entitiesTypes/instanceNotifications";
import { dateTimeComparison } from "helpers/string/dateFormatter";
import { getValueBasedOnLang } from "helpers";
export const NotificationColumns = (
  handleCancelSendNotification = (row: IInstanceNotificationsListDto) => {},
  handleResendNotification = (row: IInstanceNotificationsListDto) => {},
  handleDelete = (row: IInstanceNotificationsListDto) => {},
  handleGetDetails = (row: IInstanceNotificationsListDto) => {}
): GridColDef[] => {
  const { messages, locale } = useIntl();

  const NotificationColumns: GridColDef[] = [
    {
      field: "titleAr",
      headerName: messages["notification.titleAr"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => <AppDataGridCell value={params.row?.titleAr} />,
    },

    {
      field: "titleEn",
      headerName: messages["notification.titleEn"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => <AppDataGridCell value={params.row?.titleEn} />,
    },

    {
      field: locale === "ar-SA" ? "messageAr" : "messageEn",
      headerName: messages["notification.message"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell
          value={
            getValueBasedOnLang(params.row?.messageAr, params.row?.messageEn) ||
            "-"
          }
          style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}
          // noWrap={false}
        />
      ),
    },

    {
      field: "notifyDate",
      headerName: messages["notification.notifyDate"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <>
            {params.row?.notifyDate
              ? moment
                  .utc(params.row?.notifyDate)
                  .local()
                  .format("YYYY-DD-MM HH:mm")
              : "-"}
          </>
        );
      },
    },

    {
      field: "isRead",
      headerName: messages["notification.notificationStatus"] as string,
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <>
            {params.row?.isRead
              ? messages["notification.notificationStatus.Read"]
              : messages["notification.notificationStatus.New"]}
          </>
        );
      },
    },

    {
      field: "actions",
      headerName: messages["common.actions"] as string,
      sortable: false,
      align: "center",
      filterable: false,
      headerAlign: "center",
      // hide: clientIdParams ? true : false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div>
            {/* Details */}
            <AppIconButton
              label="table.actions.details"
              childComp={
                <NotificationsIcon fontSize="small" sx={{ color: "#007991" }} />
              }
              handleClick={async () => {
                handleGetDetails(params.row);
              }}
              // For Permissions
              schemaName={SchemaName.SystemConfigurations}
              entityName={EntityName.Branches}
              action={PermissionActionsTypes.Details}
            />
            {/* resend */}
            {!params?.row?.canceledNotification && !params?.row?.notifyDate && (
              <AppIconButton
                label="table.actions.resend"
                handleClick={() => {
                  handleResendNotification(params.row);
                }}
                childComp={
                  <RestartAltIcon fontSize="small" sx={{ color: "#a57e10" }} />
                }
                // For Permissions
                schemaName={SchemaName.NotificationConfigurations}
                entityName={EntityName.InstanceNotifications}
                action={PermissionActionsTypes.Resend}
              />
            )}

            {/* cancel */}
            {dateTimeComparison(params?.row?.notifyDate) &&
              !params?.row?.canceledNotification && (
                <AppIconButton
                  label="table.actions.cancel"
                  handleClick={() => {
                    handleCancelSendNotification(params.row);
                  }}
                  childComp={
                    <DoDisturbOffIcon
                      fontSize="small"
                      sx={{ color: "#cb3d3d" }}
                    />
                  }
                  // For Permissions
                  schemaName={SchemaName.NotificationConfigurations}
                  entityName={EntityName.InstanceNotifications}
                  action={PermissionActionsTypes.Cancel}
                />
              )}

            {/* Delete */}
            {
              <AppIconButton
                label="table.actions.delete"
                handleClick={() => {
                  handleDelete(params.row);
                }}
                childComp={
                  <DeleteOutlinedIcon
                    fontSize="small"
                    sx={{ color: "#D72638" }}
                  />
                }
                // For Permissions
                schemaName={SchemaName.NotificationConfigurations}
                entityName={EntityName.InstanceNotifications}
                action={PermissionActionsTypes.Delete}
              />
            }
          </div>
        );
      },
    },
  ];

  return NotificationColumns;
};
