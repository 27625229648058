import IntlMessages from "@crema/utility/IntlMessages";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Card, CardContent, Grid, Tab } from "@mui/material";
import * as React from "react";
import { IBaseEntity } from "types/appTypes/baseEntity/baseEntity";
import AppMoreDetails from "../details/AppMoreDetails";

interface IAppDetailsModalProps {
  moreDetails: IBaseEntity;
  columns?: number;
  withMoreDetails?: boolean;
}

const BaseProfileDetails: React.FC<IAppDetailsModalProps> = (props) => {
  const {
    children,
    moreDetails,
    columns = undefined,
    withMoreDetails = false,
  } = props;
  const [value, setValue] = React.useState<string>("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Card>
        <CardContent>
          <Grid container spacing={8} rowSpacing={8}>
            <Grid item xs={12}>
              <TabContext value={value}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label={<IntlMessages id="details" />} value="1" />
                  {withMoreDetails && (
                    <Tab
                      label={<IntlMessages id="additionalInfo" />}
                      value="2"
                    />
                  )}
                </TabList>
                {/* Details Tab */}
                <TabPanel value="1">
                  <Grid columns={columns} container spacing={8}>
                    {children}
                  </Grid>
                </TabPanel>
                {withMoreDetails && (
                  <TabPanel value="2">
                    <AppMoreDetails moreDetails={moreDetails} />
                  </TabPanel>
                )}
              </TabContext>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default BaseProfileDetails;
