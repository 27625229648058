import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import logo from "../../../../../assets/icon/logo.png";

interface AppLogoProps {
  color?: string;
  withTitle?: boolean;
  appLogoPadding?: number | string;
}

const AppLogo: React.FC<AppLogoProps> = ({
  withTitle = true,
  appLogoPadding = 2.5,
}) => {
  return (
    <Box
      sx={{
        padding: appLogoPadding,
        display: "flex",
        flexDirection: "row",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
      }}
      className="app-logo"
    >
      <Grid
        position={"relative"}
        color={"#004A92"}
        fontSize="18px"
        fontWeight="bold"
        item
        xs={12}
      >
        <img
          style={{
            objectFit: "contain",
            height: "100%",
            width: "100%",
            marginBottom: "1.5rem",
          }}
          src={logo}
        />
        {withTitle && (
          <Grid item xs={12}>
            <Typography
              fontSize={{ xs: "14px", sm: "18px", lg: "20px" }}
              variant="h2"
              component="h2"
            >
              <IntlMessages id="mainPageTitle" />
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default AppLogo;
