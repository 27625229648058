import axios from "axios";
import { BaseApiUrl } from "./appUrls";
import { store } from "App";
import { fetchError } from "redux/actions";
import { IBackendErrorResponse } from "types/appTypes/response/baseResponse";

// For Language
const localeLanguage = localStorage.getItem("lan")
  ? JSON.parse(localStorage.getItem("lan")!)
  : "ar";
const currenLanguage = localeLanguage?.locale || "ar";

const axiosInstance = axios.create({
  baseURL: BaseApiUrl,
  headers: {
    "Accept-Language": currenLanguage,
  },
});

// For Handle Errors
axiosInstance.interceptors.response.use(
  (res) => res,
  async (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        localStorage.removeItem("token");
        window.location.reload();
      } else {
        store.dispatch(
          fetchError(
            (error.response.data as IBackendErrorResponse).messages?.join(
              ","
            ) ?? ""
          )
        );
      }
    }
    throw error;
  }
);

// For Get And Set Token
export const setToken = (token?: string) => {
  if (token) {
    axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + token;
    localStorage.setItem("token", token);
  } else {
    delete axiosInstance.defaults.headers.common["Authorization"];
    localStorage.removeItem("token");
  }
};

export const setLang = (lang: string) => {
  axiosInstance.defaults.headers["Accept-Language"] = lang;
};

export default axiosInstance;
