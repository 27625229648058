import { Grid } from "@mui/material";
import { IBaseEntity } from "types/appTypes/baseEntity/baseEntity";
import AppItemView from "../others/AppItemView";
import { useLocation } from "react-router-dom";

interface IAppMoreDetailsProps {
  spacing?: number;
  rowSpacing?: number;
  column?: number;
  isShowLogs?: boolean;
  moreDetails: IBaseEntity;
}
const AppMoreDetails = (props: IAppMoreDetailsProps) => {
  const {
    spacing = 8,
    rowSpacing = 8,
    column = 3,
    moreDetails,
    isShowLogs = false,
  } = props;
  const location = useLocation();
  return (
    <>
      <Grid container spacing={spacing} rowSpacing={rowSpacing}>
        <AppItemView
          label={"createdBy"}
          value={moreDetails?.createdByName}
          type="text"
          column={column}
        />
        <AppItemView
          label="modifiedBy"
          value={moreDetails?.lastModifiedByName}
          type="text"
          column={column}
        />

        <AppItemView
          label="activatedBy"
          value={moreDetails?.activationStatusChangedByName}
          type="text"
          column={column}
        />
        <AppItemView
          label="deletedBy"
          value={moreDetails?.deletedByName}
          type="text"
          column={column}
        />
        <AppItemView
          label={"creationDate"}
          value={moreDetails?.createdOn}
          type="dateTime"
          column={column}
        />

        {location.pathname?.includes("documentManagement/archiveDocuments") && (
          <AppItemView
            label={"archiveDocumant.archivedOn"}
            value={moreDetails?.archiveStatusModifyDate}
            type="dateTime"
            column={column}
          />
        )}

        {/* archiveStatusModifyDate */}

        <AppItemView
          label="modificationDate"
          value={moreDetails?.lastModifiedOn}
          type="dateTime"
          column={column}
        />

        <AppItemView
          label="activationDate"
          value={moreDetails?.activationStatusChangedOn}
          type="dateTime"
          column={column}
        />
        <AppItemView
          label="deletionDate"
          value={moreDetails?.deletedOn}
          type="dateTime"
          column={column}
        />
        {isShowLogs === true ? (
          <Grid item xs={12}>
            {/* <Logs selectedId={selectedRow?.id} /> */}
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    </>
  );
};

export default AppMoreDetails;
