import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

interface IAppIsExistViewProps {
  isExist: boolean;
}

const AppIsExistView: React.FC<IAppIsExistViewProps> = (props) => {
  const { isExist } = props;

  return (
    <>{isExist ? <CheckIcon color="info" /> : <CloseIcon color="error" />}</>
  );
};

export default AppIsExistView;
