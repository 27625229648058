import { AxiosError } from "axios";
import {
  requestEmptyExcelData,
  requestExcelData,
  requestExcelDataSuccess,
  requestExcelPage,
  requestExcelPageFailed,
  requestExcelPageSuccess,
} from "redux/reducers/exporting";
import { getPdfAndExcelList } from "services/additionalServices";

export const getExcelData = (
  url,
  options = {},
  isFirstCall,
  isLastCall,
  onSuccess
) => {
  return async (dispatch, getState) => {
    if (isFirstCall) dispatch(requestExcelData());
    dispatch(requestExcelPage());
    try {
      const res = await getPdfAndExcelList(url, options);
      dispatch(requestExcelPageSuccess(res.data));
      if (onSuccess) onSuccess();
      if (isLastCall) dispatch(requestExcelDataSuccess());
    } catch (error) {
      const axiosError = error as AxiosError<any>;
      dispatch(requestExcelPageFailed(axiosError.message));
    }
  };
};

export const emptyExcelData = () => {
  return (dispatch) => {
    dispatch(requestEmptyExcelData());
  };
};

export const getHeaders = (token) => {
  const localeInfo = JSON.parse(localStorage.getItem("lan") || "{}");
  const currentLocale = localeInfo?.locale || "ar";

  return {
    headers: {
      Authorization: "Bearer " + token,
      "Accept-Language": currentLocale,
    },
  };
};
