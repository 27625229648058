import React, { ReactNode, useState } from "react";

import { Menu, MenuItem, Zoom } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Fonts } from "../../../shared/constants/AppEnums";
import AppPermissionsGate from "components/basicComponents/permessions/AppPermissionsGate";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import IntlMessages from "@crema/utility/IntlMessages";
import styled from "@emotion/styled";
import { useIntl } from "react-intl";
import { alpha } from "@mui/material/styles";

interface AppDropDownButtonProps {
  entityName: string;
  schemaName: string;
  action: PermissionActionsTypes;
  titleDropDownButton: string;
  menuItemsDropDown: Array<MenuItem>;
}

interface MenuItem {
  setState: React.Dispatch<React.SetStateAction<boolean>>;
  labelMenuItem: string;
}

const AppDropDownButton: React.FC<AppDropDownButtonProps> = ({
  entityName,
  schemaName,
  action,
  titleDropDownButton,
  menuItemsDropDown,

  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState<
    EventTarget | HTMLButtonElement | null
  >(null);
  const open = Boolean(anchorEl);
  const { locale, messages } = useIntl();

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: locale == "en-US" ? "left" : "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: locale == "en-US" ? "left" : "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));
  return (
    <AppPermissionsGate
      entityName={entityName!}
      schemaName={schemaName!}
      action={action}
    >
      <Zoom in style={{ transitionDelay: "300ms" }}>
        <span>
          <Button
            id="demo-customized-button"
            aria-controls="demo-customized-menu"
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="contained"
            color="primary"
            disableElevation
            onClick={(event) => setAnchorEl(event.currentTarget!)}
            endIcon={<KeyboardArrowDownIcon />}
            sx={{
              padding: "8px 20px",
              borderRadius: 30,
              "& .MuiSvgIcon-root": {
                fontSize: 20,
              },
            }}
          >
            <IntlMessages id={titleDropDownButton} />
          </Button>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
          >
            {menuItemsDropDown?.map((item) => (
              <MenuItem
                onClick={() => {
                  item.setState(true);
                  setAnchorEl(null);
                }}
                disableRipple
              >
                <IntlMessages id={item.labelMenuItem} />
              </MenuItem>
            ))}
          </StyledMenu>
        </span>
      </Zoom>
    </AppPermissionsGate>
  );
};
export default AppDropDownButton;
