import AppBoxBadge from "./AppBoxBadge";
interface ISubscriptionStatusBadgeProps {
  subscriptionStatus: string;
  transform?: string;
}
const SubscriptionStatusBadge: React.FC<ISubscriptionStatusBadgeProps> = (
  props
) => {
  const { subscriptionStatus, transform } = props;

  const getStatusText = (status) => {
    switch (status) {
      case 1:
        return "Pending";
      case 2:
        return "Enrolled";
      case 3:
        return "Dropped";
      case 4:
        return "Withdrawn";
      case 5:
        return "Graduated";
      case 6:
        return "Suspended";
      default:
        return "Unknown Status";
    }
  };

  const colors = {
    PendingColor: "#053C5E",
    EnrolledColor: "#358600",
    DroppedColor: "#53131E",
    WithdrawnColor: "red",
    GraduatedColor: "#000",
    SuspendedColor: "#FAA916",
  };
  return (
    <>
      <AppBoxBadge
        backgroundColor={`${
          colors[`${getStatusText(subscriptionStatus)}Color`]
        }`}
        label={`student.subscriptionStatus.${getStatusText(
          subscriptionStatus
        )}`}
        transform={transform}
      />
    </>
  );
};
export default SubscriptionStatusBadge;
