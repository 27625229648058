import IntlMessages from "@crema/utility/IntlMessages";
import { Grid, Typography } from "@mui/material";
import defaultImage from "assets/appDefaultImages/client.png";
import AppItemView from "components/basicComponents/others/AppItemView";
import AppViewImage from "components/basicComponents/others/AppViewImage";
import BaseProfileDetails from "components/basicComponents/profile/BaseProfileDetails";
import { useIntl } from "react-intl";
import { Fonts } from "shared/constants/AppEnums";
import { IUserDetailsDto } from "types/entitiesTypes/users";

interface IUserDetailsProps {
  userItem: IUserDetailsDto;
}

const UserDetails = (props: IUserDetailsProps) => {
  const { userItem } = props;
  const { locale } = useIntl();

  return (
    <>
      <BaseProfileDetails
        columns={16}
        moreDetails={userItem as IUserDetailsDto}
      >
        <Grid item xs={2}>
          <AppViewImage
            image={userItem?.imageUrl || null}
            defaultImage={defaultImage}
            sxStyle={{ width: "70px", height: "70px" }}
          />
        </Grid>
        <Grid container item spacing={4} xs={14}>
          {/* <AppItemView
            label="common.fullNameAr"
            value={userItem?.fullNameAr}
            marginX={6}
          />
          <AppItemView
            label="common.fullNameEn"
            value={userItem?.fullNameEn}
            marginX={12}
          /> */}

          <AppItemView
            label="common.firstNameAr"
            value={userItem?.firstNameAr}
            marginX={4}
          />

          <AppItemView
            label="common.firstNameEn"
            value={userItem?.firstNameEn}
            marginX={8}
          />

          <AppItemView
            label="common.lastNameAr"
            value={userItem?.lastNameAr}
            marginX={4}
          />

          <AppItemView
            label="common.lastNameEn"
            value={userItem?.lastNameEn}
            marginX={8}
          />
          <AppItemView
            label="common.fatherNameAr"
            value={userItem?.fatherNameAr}
            marginX={4}
          />

          <AppItemView
            label="common.fatherNameEn"
            value={userItem?.fatherNameEn}
            marginX={8}
          />

          <Grid marginX={4} item xs={6}>
            <Typography sx={{ fontSize: 14, fontWeight: Fonts.SEMI_BOLD }}>
              <IntlMessages id="common.language" />
            </Typography>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: Fonts.REGULAR,
              }}
            >
              <IntlMessages id={`common.language.${userItem?.language}`} />
            </Typography>
          </Grid>

          <Grid marginX={8} item xs={6}>
            <Typography sx={{ fontSize: 14, fontWeight: Fonts.SEMI_BOLD }}>
              <IntlMessages id="roles.titles" />
            </Typography>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: Fonts.REGULAR,
                textAlign: "justify",
                wordBreak: "break-word",
                whiteSpace: "pre-line",
              }}
            >
              {/* {userItem?.roles
                ?.map((item, index) =>
                  locale == "en-US" ? item?.nameEn : item?.nameAr
                )
                .join("-")} */}

              {userItem?.roles?.map((item, index) =>
                locale == "ar-SA" && item?.nameAr
                  ? `${item?.nameAr}\n`
                  : `${item?.name}\n`
              )}
            </Typography>
          </Grid>

          {/* <AppItemView
            label="branch.title"
            value={
              locale == "ar-SA" && userItem?.branchDepartment?.branch?.nameAr
                ? userItem?.branchDepartment?.branch?.nameAr
                : userItem?.branchDepartment?.branch?.nameEn
            }
            marginX={4}
          />

          <AppItemView
            label="department.title"
            value={
              locale == "ar-SA" &&
              userItem?.branchDepartment?.department?.nameAr
                ? userItem?.branchDepartment?.department?.nameAr
                : userItem?.branchDepartment?.department?.nameEn
            }
            marginX={8}
          /> */}

          <AppItemView
            label="common.email"
            value={userItem?.email}
            marginX={4}
          />

          {/* <AppItemView
            label="common.employmentCode"
            value={userItem?.userName}
            marginX={8}
          /> */}

          <AppItemView
            label="common.phone"
            value={userItem?.phoneNumber}
            marginX={8}
            type="isPhoneNumber"
          />
          <AppItemView
            label="users.lock"
            value={!userItem?.isLocked}
            marginX={4}
            type="isExist"
          />
          <AppItemView
            label="passwordExpirationAt"
            value={userItem?.passwordExpirationAt}
            marginX={8}
            type="date"
          />
          <AppItemView
            label="securityConfiguration.loginAttempts"
            value={userItem?.loginAttempts}
            marginX={4}
          />

          <AppItemView
            label="common.staticIdentifier"
            value={userItem?.staticIdentifier}
            marginX={8}
          />

          <AppItemView
            label="columns.status"
            value={userItem?.isActive}
            type="isActive"
            marginX={4}
          />
        </Grid>
      </BaseProfileDetails>
    </>
  );
};

export default UserDetails;
