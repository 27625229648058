import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Pagination,
  Typography,
} from "@mui/material";
import defaultImage from "assets/appDefaultImages/branch.png";
import { AxiosError } from "axios";
import StatusBadge from "components/basicComponents/badge/StatusBadge";
import AddDataGridActivation from "components/basicComponents/dataGrid/AddDataGridActivation";
import AddDataGridRead from "components/basicComponents/dataGrid/AddDataGridRead";
import DataItem from "components/basicComponents/mobile/DataItem";
import AppIconButton from "components/basicComponents/others/AppIconButton";
import AppViewImage from "components/basicComponents/others/AppViewImage";
import LoadingAndErrorWrapper from "components/basicComponents/others/LoadingAndErrorWrapper";
import { FC } from "react";
import { useIntl } from "react-intl";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { suggestType } from "shared/constants/AppEnums";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import { IWebsiteContactUsListDto } from "types/entitiesTypes/websiteContactUs";

interface IWebsiteContactUsCardProps {
  handleDetails: (row: IWebsiteContactUsListDto) => void;
  handleIsRead: (row: IWebsiteContactUsListDto) => void;
  handleDelete: (row: IWebsiteContactUsListDto) => void;
  websiteContactUsList: Array<IWebsiteContactUsListDto>;
  pageNumber?: number;
  setPage?: (event: React.ChangeEvent<unknown>, page: number) => void;
  page?: number;
  loading: boolean;
  errorMessage: AxiosError<any, any> | null;
}
const WebsiteContactUsCard: FC<IWebsiteContactUsCardProps> = (props) => {
  const {
    handleDelete,
    websiteContactUsList,
    pageNumber,
    setPage,
    page,
    loading,
    errorMessage,
    handleIsRead,
    handleDetails,
  } = props;
  const { messages, locale } = useIntl();
  return (
    <Grid container spacing={4}>
      <LoadingAndErrorWrapper
        isLoading={loading}
        errorMessage={errorMessage?.response?.data.messages[0]}
      >
        {websiteContactUsList?.length != undefined &&
        websiteContactUsList?.length >= 1 ? (
          websiteContactUsList?.map((item, index) => (
            <Grid key={`websiteContactUsList${index}`} item xs={12}>
              <Card sx={{ width: "100%" }}>
                <Box
                  marginX={4}
                  marginTop={3}
                  display={"flex"}
                  justifyContent={"flex-end"}
                  gap={3}
                >
                  {/* Details */}
                  <AppIconButton
                    label="table.actions.details"
                    handleClick={() => {
                      handleDetails(item);
                    }}
                    childComp={
                      <RemoveRedEyeIcon
                        fontSize="small"
                        sx={{ color: "#007991" }}
                      />
                    }
                    // For Permissions
                    schemaName={SchemaName.SystemConfigurations}
                    entityName={EntityName.Countries}
                    action={PermissionActionsTypes.Details}
                  />

                  {/* Delete */}
                  <AppIconButton
                    label="table.actions.delete"
                    handleClick={() => {
                      handleDelete(item);
                    }}
                    childComp={
                      <DeleteOutlinedIcon
                        fontSize="small"
                        sx={{ color: "#D72638" }}
                      />
                    }
                    // For Permissions
                    schemaName={SchemaName.SystemConfigurations}
                    entityName={EntityName.Countries}
                    action={PermissionActionsTypes.Delete}
                  />
                </Box>
                <CardContent
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: 3,
                    paddingTop: "0 !important",
                  }}
                >
                  <DataItem
                    label={
                      messages[
                        "siteManagement.WebsiteContactUs.fullName"
                      ] as string
                    }
                    value={item?.fullName}
                  />

                  <DataItem
                    label={messages["common.phone"] as string}
                    value={item?.phoneNumber}
                  />
                  <DataItem
                    label={
                      messages["siteManagement.websiteNeeds.read"] as string
                    }
                    value={
                      <AddDataGridRead
                        value={item.isRead}
                        handleClick={() => {
                          if (!item.isRead) handleIsRead(item);
                        }}
                        sxProp={{
                          padding: 0.3,
                          cursor: item.isRead ? "default" : "pointer",
                        }}
                        // For Permissions
                        schemaName={SchemaName.UsersManagement}
                        entityName={EntityName.Roles}
                        action={PermissionActionsTypes.IsRead}
                      />
                    }
                  />
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            item
            xs={12}
            marginTop={10}
          >
            <Typography variant="h5" component={"span"}>
              {messages["common.dataFound"]}
            </Typography>
          </Grid>
        )}
        {pageNumber! > 1 && (
          <Grid
            display={"flex"}
            alignItems={"cneter"}
            justifyContent={"center"}
            marginTop={5}
            item
            xs={12}
          >
            <Pagination
              count={pageNumber}
              page={page}
              color="primary"
              onChange={(event, page) => {
                setPage && setPage(event, page);
              }}
            />
          </Grid>
        )}
      </LoadingAndErrorWrapper>
    </Grid>
  );
};

export default WebsiteContactUsCard;
