import DomainIcon from "@mui/icons-material/Domain";
import EmailIcon from "@mui/icons-material/Email";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import { ReactNode } from "react";
import { BiCog, BiHome } from "react-icons/bi";
import { FaUsersCog } from "react-icons/fa";
import { MdOutlineNotificationsNone } from "react-icons/md";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { getEntityPermissionCode } from "../helpers/permessions";
import { PermissionActionsTypes } from "../types/entitiesTypes/enums";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
export interface RouterConfigData {
  id: string;
  title?: string;
  messageId: string;
  icon?: string | ReactNode;
  type: "item" | "group" | "collapse" | "divider";
  children?: RouterConfigData[];
  // permittedRole?: RoutePermittedRole;
  permittedRole?: string | string[];
  color?: string;
  url?: string;
  exact?: boolean;
  count?: number;
  // auth?: string;
}

const routesConfig: RouterConfigData[] = [
  {
    id: "dashboard",
    messageId: "sidebar.dashboard",
    type: "item",
    icon: <BiHome />,
    url: "/adminDashboard",
  },

  // System Configurations systemConfigurations/regions
  {
    id: "systemConfigurations",
    messageId: "sidebar.systemConfigurations.title",
    type: "collapse",
    icon: <BiCog />,
    // For Show And Hide Collapse Menu

    children: [
      {
        id: "regions",
        messageId: "sidebar.regions",
        type: "item",
        url: "/systemConfigurations/regions",
      },
      {
        id: "categories",
        messageId: "sidebar.categories",
        type: "item",
        url: "/systemConfigurations/categories",
      },
      {
        id: "advertisementSliders",
        messageId: "sidebar.advertisementSliders",
        type: "item",
        url: "/systemConfigurations/advertisementSliders",
      },

      {
        id: "systemssSettings",
        messageId: "sidebar.systemSettings",
        type: "item",
        url: "/systemConfigurations/systemSettings",
      },
      {
        id: "notifications",
        messageId: "sidebar.messageEngine",
        type: "item",
        url: "/systemConfigurations/notifications",
      },
    ],
  },

  //train structure

  {
    id: "trainStructure",
    messageId: "sidebar.trainStructure.title",
    type: "collapse",
    icon: <BiCog />,
    // For Show And Hide Collapse Menu

    children: [
      {
        id: "educationalCenters",
        messageId: "sidebar.educationalCenter",
        type: "item",
        url: "/trainStructure/educationalCenters",
      },
      // {
      //   id: "instructors",
      //   messageId: "sidebar.instructors",
      //   type: "item",
      //   url: "/trainStructure/instructors",
      // },
      // {
      //   id: "studentSubscription",
      //   messageId: "sidebar.studentSubscription",
      //   type: "item",
      //   url: "/trainStructure/subscriptions",
      // },
      // {
      //   id: "studsssents",
      //   messageId: "sidebar.students",
      //   type: "item",
      //   url: "/trainStructure/student",
      // },

      {
        id: "coupons",
        messageId: "sidebar.coupons",
        type: "item",
        url: "/trainStructure/coupons",
      },
    ],
  },

  // Scientific Content Management
  {
    id: "scientificContentManagement",
    messageId: "sidebar.scientificContentManagement.title",
    type: "collapse",
    icon: <DomainIcon />,
    // For Show And Hide Collapse Menu

    children: [
      {
        id: "diplomas",
        messageId: "sidebar.diplomas",
        type: "item",
        url: "/scientificContentManagement/diplomas",
      },
      {
        id: "courses",
        messageId: "sidebar.courses",
        type: "item",
        url: "/trainStructure/courses",
      },

      {
        id: "exams",
        messageId: "sidebar.exams",
        type: "item",
        url: "/trainStructure/exams",
      },
      {
        id: "attendance",
        messageId: "sidebar.attendance",
        type: "item",
        url: "/trainStructure/attendance",
      },
      {
        id: "virtualSceneRequests",
        messageId: "sidebar.virtualSceneRequests",
        type: "item",
        url: "/trainStructure/virtualSceneRequests",
      },
      {
        id: "requestsEducationalDocuments",
        messageId: "sidebar.requestsEducationalDocuments",
        type: "item",
        url: "/trainStructure/requestsEducationalDocuments",
      },
    ],
  },

  // Site Management
  {
    id: "siteManagement",
    messageId: "sidebar.SiteManagement.title",
    type: "collapse",
    icon: <GridViewOutlinedIcon />,
    children: [
      {
        id: "WebSiteNeeds",
        messageId: "sidebar.EducationalNeeds",
        type: "item",
        url: "/siteManagement/webSiteNeeds",
      },
      {
        id: "WebsiteContactUs",
        messageId: "sidebar.websiteContactUs",
        type: "item",
        url: "/siteManagement/websiteContactUs",
      },
    ],
  },

  // ManagePayments
  {
    id: "ManagePayments",
    messageId: "sidebar.managePayments.title",
    type: "collapse",
    icon: <AccountBalanceIcon />,
    children: [
      {
        id: "invoices",
        messageId: "sidebar.invoices",
        type: "item",
        url: "/managePayments/invoices",
      },

      {
        id: "requestPayments",
        messageId: "sidebar.requestPayments",
        type: "item",
        url: "/managePayments/payments",
      },

      {
        id: "refundablePayments",
        messageId: "sidebar.refundablePayments",
        type: "item",
        url: "/managePayments/refundablePayments",
      },
      {
        id: "installments",
        messageId: "sidebar.installments",
        type: "item",
        url: "/managePayments/installments",
      },
    ],
  },

  // Users Management
  {
    id: "usersManagement",
    messageId: "sidebar.usersManagement",
    type: "collapse",
    icon: <FaUsersCog />,
    // For Show And Hide Collapse Menu
    children: [
      {
        id: "trainers",
        messageId: "sidebar.trainers",
        type: "item",
        url: "/usersManagement/instructors",
      },
      {
        id: "students",
        messageId: "sidebar.students",
        type: "item",
        url: "/usersManagement/students",
      },
      {
        id: "managers",
        messageId: "sidebar.managers",
        type: "item",
        url: "/usersManagement/managers",
      },
      {
        id: "roles",
        messageId: "sidebar.roles",
        type: "item",
        url: "/usersManagement/roles",
      },
    ],
  },
];
export default routesConfig;
