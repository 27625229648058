import { Typography } from "@mui/material";
import * as React from "react";
import { useIntl } from "react-intl";

interface IAppDataTableCellProps {
  value: string;
  variant?: any;
  style?: React.CSSProperties;
}

const AppDataGridCell: React.FC<IAppDataTableCellProps> = (props) => {
  const { locale } = useIntl();
  var arabic = /[\u0600-\u06FF]/;
  const {
    variant = "span",
    value,
    style = {
      textAlign: "center",
      direction: arabic.test(value) ? "rtl" : "ltr",
      // transform: locale !== "en-US" ? "rotateY(180deg)" : "none",
    },
  } = props;

  return (
    <Typography noWrap={true} variant={variant} style={style}>
      {value ? value : "-"}
    </Typography>
  );
};

export default AppDataGridCell;
