// Actions Types for Ba Years
const REQUEST_EXCEL_DATA = "REQUEST_EXCEL_DATA";
const REQUEST_EXCEL_PAGE = "REQUEST_EXCEL_PAGE";
const REQUEST_EXCEL_PAGE_SUCCESS = "REQUEST_EXCEL_PAGE_SUCCESS";
const REQUEST_EXCEL_PAGE_FAILED = "REQUEST_EXCEL_PAGE_FAILED";
const REQUEST_EXCEL_DATA_SUCCESS = "REQUEST_EXCEL_DATA_SUCCESS";
const REQUEST_EXCEL_DATA_FAILED = "REQUEST_EXCEL_DATA_FAILED";
const REQUEST_CLEAR_DATA = "REQUEST_CLEAR_DATA";

// Initial State
const initialState = {
  excelPageData: [],
  totalDataCount: null,
  isLoadingExcelPage: false,
  isLoadingExcelData: false,
  excelDataErrorMessage: null,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_EXCEL_DATA:
      return {
        ...state,
        isLoadingExcelData: true,
      };
    case REQUEST_EXCEL_PAGE:
      return {
        ...state,
        isLoadingExcelPage: true,
      };
    case REQUEST_EXCEL_PAGE_SUCCESS:
      return {
        ...state,
        excelPageData: [...state.excelPageData, ...action.excelPageData],
        isLoadingExcelPage: false,
        excelDataErrorMessage: null,
      };
    case REQUEST_EXCEL_PAGE_FAILED:
      return {
        ...state,
        excelPageData: [],
        isLoadingExcelPage: false,
        excelDataErrorMessage: action.excelDataErrorMessage,
      };
    case REQUEST_EXCEL_DATA_SUCCESS:
      return {
        ...state,
        isLoadingExcelData: false,
        excelDataErrorMessage: null,
      };
    case REQUEST_EXCEL_DATA_FAILED:
      return {
        ...state,
        isLoadingExcelData: false,
        excelDataErrorMessage: action.excelDataErrorMessage,
      };
    case REQUEST_CLEAR_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const requestExcelData = () => {
  return {
    type: REQUEST_EXCEL_DATA,
  };
};

export const requestExcelDataSuccess = () => {
  return {
    type: REQUEST_EXCEL_DATA_SUCCESS,
  };
};

export const requestExcelDataFailed = (excelDataErrorMessage) => {
  return {
    type: REQUEST_EXCEL_DATA_FAILED,
    excelDataErrorMessage,
  };
};

export const requestExcelPage = () => {
  return {
    type: REQUEST_EXCEL_PAGE,
  };
};

export const requestExcelPageSuccess = (excelPageData) => {
  return {
    type: REQUEST_EXCEL_PAGE_SUCCESS,
    excelPageData: excelPageData,
  };
};

export const requestExcelPageFailed = (excelDataErrorMessage) => {
  return {
    type: REQUEST_EXCEL_PAGE_FAILED,
    excelDataErrorMessage,
  };
};

export const requestEmptyExcelData = () => {
  return {
    type: REQUEST_CLEAR_DATA,
  };
};
