import IntlMessages from "@crema/utility/IntlMessages";
import { IconButton, SxProps, Theme, Tooltip } from "@mui/material";
import AppPermissionsGate, {
  IAppPermissionsProps,
} from "components/basicComponents/permessions/AppPermissionsGate";
import * as React from "react";
import { Link } from "react-router-dom";

interface IAppIconButtonProps extends IAppPermissionsProps {
  label: string;
  childComp: React.ReactNode;
  handleClick?: () => void;
  sxProp?: SxProps<Theme>;
  withLink?: boolean;
  toPathLink?: string;
  // For Permissions Gate
  hasActionPermissions?: boolean;
}

const AppIconButton: React.FC<IAppIconButtonProps> = (props) => {
  const {
    label,
    childComp,
    handleClick,
    sxProp = { padding: 0.3 },
    withLink = false,
    toPathLink = "",
    // For Permissions Gate
    hasActionPermissions = true,
    schemaName,
    entityName,
    action,
  } = props;

  if (hasActionPermissions) {
    return (
      <>
        <AppPermissionsGate
          schemaName={schemaName}
          entityName={entityName}
          action={action}
        >
          {withLink ? (
            <Tooltip title={<IntlMessages id={label} />}>
              <Link to={toPathLink}>
                <IconButton sx={sxProp}>{childComp}</IconButton>
              </Link>
            </Tooltip>
          ) : (
            <Tooltip title={<IntlMessages id={label} />}>
              <IconButton sx={sxProp} onClick={handleClick}>
                {childComp}
              </IconButton>
            </Tooltip>
          )}
        </AppPermissionsGate>
      </>
    );
  }

  return (
    <>
      {withLink ? (
        <Tooltip title={<IntlMessages id={label} />}>
          <Link to={toPathLink}>
            <IconButton sx={sxProp}>{childComp}</IconButton>
          </Link>
        </Tooltip>
      ) : (
        <Tooltip title={<IntlMessages id={label} />}>
          <IconButton sx={sxProp} onClick={handleClick}>
            {childComp}
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};

export default AppIconButton;
