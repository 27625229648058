import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { GridColDef } from "@mui/x-data-grid";
import AppDataGridCell from "components/basicComponents/dataGrid/AppDataGridCell";
import AppIconButton from "components/basicComponents/others/AppIconButton";
import { useIntl } from "react-intl";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { IExamQuestionsListDto } from "types/entitiesTypes/examQuestions";
import moment from "moment";
import { dateTimeComparison } from "helpers/string/dateFormatter";

export const ExamQuestionColumns = (
  examItemDate = "",
  handleDetails = (row: IExamQuestionsListDto) => {},
  handleUpdate = (row: IExamQuestionsListDto) => {},
  handleDelete = (row: IExamQuestionsListDto) => {}
): GridColDef[] => {
  const { messages } = useIntl();
  const now = moment();
  const ExamQuestionColumns: GridColDef[] = [
    {
      field: "textAr",
      headerName: messages["exams.question.textAr"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => <AppDataGridCell value={params.row?.textAr} />,
    },
    {
      field: "textEn",
      headerName: messages["exams.question.textEn"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => <AppDataGridCell value={params.row?.textEn} />,
    },

    {
      field: "type",
      headerName: messages["exam.questions.type"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell
          value={
            params.row?.type === 2
              ? (messages["exam.questions.type.single"] as string)
              : (messages["exam.questions.type.multi"] as string)
          }
        />
      ),
    },

    {
      field: "actions",
      headerName: messages["common.actions"] as string,
      sortable: false,
      align: "center",
      filterable: false,
      headerAlign: "center",
      flex: 0.1,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div>
            {/* Details */}
            <AppIconButton
              label="table.actions.details"
              handleClick={() => {
                handleDetails(params.row);
              }}
              childComp={
                <RemoveRedEyeIcon fontSize="small" sx={{ color: "#007991" }} />
              }
              // For Permissions
              schemaName={SchemaName.SystemConfigurations}
              entityName={EntityName.Countries}
              action={PermissionActionsTypes.Details}
            />

            {/* Edit FaQuestion */}
            {dateTimeComparison(examItemDate) && (
              <>
                <AppIconButton
                  label="table.actions.edit"
                  handleClick={() => {
                    handleUpdate(params.row);
                  }}
                  childComp={
                    <EditOutlinedIcon
                      fontSize="small"
                      sx={{ color: "#A0CCDA" }}
                    />
                  }
                  // For Permissions
                  schemaName={SchemaName.SystemConfigurations}
                  entityName={EntityName.Countries}
                  action={PermissionActionsTypes.Edit}
                />

                {/* Delete */}
                <AppIconButton
                  label="table.actions.delete"
                  handleClick={() => {
                    handleDelete(params.row);
                  }}
                  childComp={
                    <DeleteOutlinedIcon
                      fontSize="small"
                      sx={{ color: "#D72638" }}
                    />
                  }
                  // For Permissions
                  schemaName={SchemaName.SystemConfigurations}
                  entityName={EntityName.Countries}
                  action={PermissionActionsTypes.Delete}
                />
              </>
            )}
          </div>
        );
      },
    },
  ];

  return ExamQuestionColumns;
};
