import { Grid } from "@mui/material";
import defaultImage from "assets/appDefaultImages/user.png";
import AppItemView from "components/basicComponents/others/AppItemView";
import AppViewImage from "components/basicComponents/others/AppViewImage";
import BaseProfileDetails from "components/basicComponents/profile/BaseProfileDetails";
import { useIntl } from "react-intl";
import { IInstructorsDetailsDto } from "types/entitiesTypes/instructors";

interface IInstructorDetailsProps {
  instructorsItem: IInstructorsDetailsDto;
}

const InstructorDetails = (props: IInstructorDetailsProps) => {
  const { instructorsItem } = props;
  const { locale } = useIntl();

  return (
    <>
      <BaseProfileDetails
        columns={12}
        moreDetails={instructorsItem as IInstructorsDetailsDto}
      >
        <Grid item xs={12} sm={4}>
          <AppViewImage
            image={instructorsItem?.profilePic || null}
            defaultImage={defaultImage}
            sxStyle={{ width: "70px", height: "70px" }}
            label="instructor.profilePic"
            marginTop={2}
          />
        </Grid>

        <AppItemView
          label="common.firstNameAr"
          value={instructorsItem?.firstNameAr}
          sm={4}
        />
        <AppItemView
          label="common.lastNameAr"
          value={instructorsItem?.lastNameAr}
          sm={4}
        />
        <AppItemView
          label="common.firstNameEn"
          value={instructorsItem?.firstNameEn}
          sm={4}
        />
        <AppItemView
          label="common.lastNameEn"
          value={instructorsItem?.lastNameEn}
          sm={8}
        />

        <AppItemView
          label="common.email"
          value={instructorsItem?.email}
          sm={4}
        />
        <AppItemView
          label="common.phone"
          value={instructorsItem?.phoneNumber}
          sm={8}
          type="isPhoneNumber"
        />

        <AppItemView
          label="common.bioAr"
          value={instructorsItem?.bioAr}
          sm={4}
        />
        <AppItemView
          label="common.bioEn"
          value={instructorsItem?.bioEn}
          sm={6}
        />
        <AppItemView
          label="common.isActive"
          value={instructorsItem?.isActive}
          type="isActive"
          sm={6}
        />
      </BaseProfileDetails>
    </>
  );
};

export default InstructorDetails;
