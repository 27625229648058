import { GridColDef } from "@mui/x-data-grid";
import StatusAttendance from "components/basicComponents/badge/StatusAttendance";
import AppDataGridCell from "components/basicComponents/dataGrid/AppDataGridCell";
import { getValueBasedOnLang } from "helpers";
import { dateTimeFormatter } from "helpers/string/dateFormatter";
import { useIntl } from "react-intl";
import { attendanceStatus } from "shared/constants/AppEnums";

export const AttendanceColumns = (): GridColDef[] => {
  const { messages, locale } = useIntl();

  const AttendanceColumns: GridColDef[] = [
    {
      field: "studentName",
      headerName: messages["session.studentName"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell value={params.row?.studentName} />
      ),
    },
    {
      field:
        locale == "en-US"
          ? "educationalCenterNameEn"
          : "educationalCenterNameAr",
      headerName: messages["educationalCenter.name"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell
          value={getValueBasedOnLang(
            params.row?.educationalCenterNameEn || "-",
            params.row?.educationalCenterNameAr || "-"
          )}
        />
      ),
    },
    {
      field: locale == "en-US" ? "diplomaNameEn" : "diplomaNameAr",
      headerName: messages["diploma.name"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell
          value={getValueBasedOnLang(
            params.row?.diplomaNameAr || "-",
            params.row?.diplomaNameEn || "-"
          )}
        />
      ),
    },
    {
      field: "courseName",
      headerName: messages["session.courseName"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell value={params.row?.courseName} />
      ),
    },
    {
      field: "sessionName",
      headerName: messages["session.name"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell value={params.row?.sessionName} />
      ),
    },

    {
      field: "sessionDate",
      headerName: messages["session.date"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell value={dateTimeFormatter(params.row?.sessionDate)} />
      ),
    },

    {
      field: "status",
      headerName: messages["columns.status"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => <StatusAttendance status={params.row?.status} />,
    },
  ];

  return AttendanceColumns;
};
