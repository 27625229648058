// import CouponDetails from "components/coupons/CouponDetails";
// import GiftDetails from "components/gifts/GiftDetails";
// import ProductDetails from "components/products/ProductDetails";
import { createContext, useContext, useEffect, useState } from "react";

export interface ModalsType {
  //   isOpen: boolean;
  handleOpen: (id: string, type: string) => void;
  //   handleClose: () => void;
}

const defaultValue: ModalsType = {
  //   isOpen: false,
  handleOpen: (id: string, type: string): void => {},
  //   handleClose: (): void => {},
};

const ModalsContext = createContext<ModalsType>(defaultValue);
export const useModals = () => useContext(ModalsContext);

const ModalsProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [modalPayload, setModalPayload] = useState<{
    id: string;
    type: string;
  }>({
    id: "",
    type: "",
  });

  const handleOpen = (id: string, type: string) => {
    setModalPayload({
      id,
      type,
    });
  };

  useEffect(() => {
    setIsOpen(true);
  }, [modalPayload]);

  const getContent = () => {
    switch (modalPayload.type) {
      //   case "NewGift":
      //     return (
      //       <GiftDetails
      //         id={modalPayload.id}
      //         setShowDetails={setIsOpen}
      //         showDetails={isOpen}
      //       />
      //     );

      //   case "NewOffer":
      //     return (
      //       <CouponDetails
      //         id={modalPayload.id}
      //         setShowDetails={setIsOpen}
      //         showDetails={isOpen}
      //       />
      //     );
      //   case "AddNewProductMessage":
      //     return (
      //       <ProductDetails
      //         id={modalPayload.id}
      //         setShowDetails={setIsOpen}
      //         showDetails={isOpen}
      //       />
      //     );

      default:
        return null;
    }
  };

  return (
    <ModalsContext.Provider
      value={{
        // isOpen,
        handleOpen,
        // handleClose,
      }}
    >
      {children}
      {getContent()}
    </ModalsContext.Provider>
  );
};

export default ModalsProvider;
