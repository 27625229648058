import { Card, CardContent, Grid } from "@mui/material";
import AppBreadcrumb from "components/basicComponents/breadcrumb/AppBreadcrumb";
import LoadingAndErrorWrapper from "components/basicComponents/others/LoadingAndErrorWrapper";
import EditDiplomas from "components/scientificContentManagement/diplomas/editDiploma/EditDiplomas";
import { getValueBasedOnLang } from "helpers/index";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useGetByIdService } from "services";
import { AppUrls } from "services/appUrls";
import { IDiplomasDetailsDto } from "types/entitiesTypes/diplomas";

const DiplomaEditPage: React.FC = () => {
  const { messages } = useIntl();
  const { diplomaIdParams } = useParams();
  const {
    data: diplomasItem,
    error,
    isLoading,
  } = useGetByIdService<IDiplomasDetailsDto>(
    AppUrls.Diplomas,
    diplomaIdParams || ""
  );

  return (
    <Grid container spacing={3}>
      <LoadingAndErrorWrapper
        isLoading={isLoading}
        errorMessage={error?.response?.data.messages[0]}
      >
        <AppBreadcrumb
          paths={[
            { name: messages["sidebar.scientificContentManagement.title"] },
            {
              name: messages["sidebar.diplomas"],
              path: "/scientificContentManagement/diplomas",
            },
            {
              name: messages["diplomas.edit"],
            },
            {
              name: getValueBasedOnLang(
                diplomasItem?.nameAr || "",
                diplomasItem?.nameEn || ""
              ),
            },
          ]}
          currentPathName={""}
        />

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <EditDiplomas isLoading={isLoading} diplomaItem={diplomasItem!} />
            </CardContent>
          </Card>
        </Grid>
      </LoadingAndErrorWrapper>
    </Grid>
  );
};

export default DiplomaEditPage;
