import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Pagination,
  Typography,
} from "@mui/material";
import { AxiosError } from "axios";
import DataItem from "components/basicComponents/mobile/DataItem";
import AppIconButton from "components/basicComponents/others/AppIconButton";
import LoadingAndErrorWrapper from "components/basicComponents/others/LoadingAndErrorWrapper";
import { getValueBasedOnLang } from "helpers";
import moment from "moment";
import { FC } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import { IInvoicesListDto } from "types/entitiesTypes/invoices";
import { enumPaymentType } from "./payment/PaymentColumns";

interface IInvoiceCardProps {
  Invoice: Array<IInvoicesListDto>;
  pageNumber?: number;
  setPage?: (event: React.ChangeEvent<unknown>, page: number) => void;
  page?: number;
  loading: boolean;
  errorMessage: AxiosError<any, any> | null;
}
const InvoiceCard: FC<IInvoiceCardProps> = (props) => {
  const { Invoice, pageNumber, setPage, page, loading, errorMessage } = props;
  const { messages, locale } = useIntl();
  return (
    <Grid container spacing={4}>
      <LoadingAndErrorWrapper
        isLoading={loading}
        errorMessage={errorMessage?.response?.data.messages[0]}
      >
        {Invoice?.length != undefined && Invoice?.length >= 1 ? (
          Invoice?.map((item, index) => (
            <Grid key={`Invoice${index}`} item xs={12}>
              <Card sx={{ width: "100%" }}>
                <Box
                  marginX={4}
                  marginTop={3}
                  display={"flex"}
                  justifyContent={"flex-end"}
                  gap={3}
                >
                  {/* Details */}
                  <AppIconButton
                    label="table.actions.details"
                    withLink={true}
                    toPathLink={`/managePayments/invoices/profile/${item?.id}`}
                    childComp={
                      <RemoveRedEyeIcon
                        fontSize="small"
                        sx={{ color: "#007991" }}
                      />
                    }
                    // For Permissions
                    schemaName={SchemaName.SystemConfigurations}
                    entityName={EntityName.Countries}
                    action={PermissionActionsTypes.Details}
                  />
                </Box>
                <CardContent
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: 3,
                    paddingTop: "0 !important",
                  }}
                >
                  <DataItem
                    label={messages["invoices.invoiceNumber"] as string}
                    value={`#${item.invoiceNumber}`}
                  />

                  <DataItem
                    label={messages["diplomas.name"] as string}
                    value={getValueBasedOnLang(
                      item?.subscription?.diploma?.nameAr,
                      item?.subscription?.diploma?.nameEn
                    )}
                  />

                  <DataItem
                    label={messages["student.name"] as string}
                    value={item?.subscription?.student?.fullName}
                  />
                  <DataItem
                    label={messages["invoices.diplomaCost"] as string}
                    value={item.diplomaCost}
                  />
                  <DataItem
                    label={messages["invoices.totalCost"] as string}
                    value={item.totalCost}
                  />
                  <DataItem
                    label={messages["invoices.issueDate"] as string}
                    value={
                      item.issueDate
                        ? moment(item.issueDate).format("YYYY-MM-DD HH:mm")
                        : "-"
                    }
                  />
                  <DataItem
                    label={messages["invoices.lastPaymentType"] as string}
                    value={messages[enumPaymentType(item?.lastPaymentType)]}
                  />
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            item
            xs={12}
            marginTop={10}
          >
            <Typography variant="h5" component={"span"}>
              {messages["common.dataFound"]}
            </Typography>
          </Grid>
        )}
        {pageNumber! > 1 && (
          <Grid
            display={"flex"}
            alignItems={"cneter"}
            justifyContent={"center"}
            marginTop={5}
            item
            xs={12}
          >
            <Pagination
              count={pageNumber}
              page={page}
              color="primary"
              onChange={(event, page) => {
                setPage && setPage(event, page);
              }}
            />
          </Grid>
        )}
      </LoadingAndErrorWrapper>
    </Grid>
  );
};

export default InvoiceCard;
