import React from "react";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { Box, ListItem, Typography } from "@mui/material";
import { Fonts } from "../../../shared/constants/AppEnums";
import { useDispatch } from "react-redux";
import { changeNotificationItemToIsRead } from "redux/actions/notifications";
import { Link } from "react-router-dom";
import { alpha } from "@material-ui/core";
import { Campaign } from "@mui/icons-material";
import { useIntl } from "react-intl";
import { useModals } from "@crema/utility/AppContextProvider/ModalsContextProvider";
import { removeHTMLTags } from "helpers";

interface NotificationItemProps {
  item: any;

  handleClick: () => void;
}

const NotificationItem: React.FC<NotificationItemProps> = ({
  item,
  handleClick,
}) => {
  const { locale } = useIntl();

  const { handleOpen } = useModals();

  const dispatch = useDispatch();

  const isRead = async (notificationId) => {
    dispatch(changeNotificationItemToIsRead(notificationId));
  };

  return (
    <>
      {item?.notificationType != "General" &&
      item?.notificationType != "BasicNotification" &&
      item?.notificationType != "NewGift" &&
      item?.notificationType != "NewOffer" ? (
        <Link
          to={item.fullPath}
          onClickCapture={() => {
            console.log("item.fullPath", item.fullPath);
            isRead(item?.id);
            handleClick();
          }}
          style={{ textDecoration: "none", width: "100%" }}
        >
          <ListItem
            // disabled={item?.entityType == "General"}
            sx={{
              padding: "8px 20px",
              "&:hover,&:focus,&.Mui-selected": {
                backgroundColor: (theme) =>
                  alpha(theme.palette.primary.main, 0.1),
                color: (theme) => theme.palette.primary.main,
              },
            }}
            className="item-hover"
          >
            <ListItemAvatar
              sx={{
                minWidth: 0,
                mr: 4,
              }}
            >
              <Avatar
                sx={{
                  width: 48,
                  height: 48,
                }}
                alt={item.notificationType}
              >
                <Campaign />
              </Avatar>
            </ListItemAvatar>

            <Box
              sx={{
                fontSize: 14,
                color: (theme) => theme.palette.text.secondary,
              }}
            >
              <Typography>
                <Box
                  component="span"
                  sx={{
                    fontSize: 14,
                    fontWeight: Fonts.MEDIUM,
                    mb: 0.5,
                    color: (theme) => theme.palette.text.primary,
                    mr: 1,
                    display: "inline-block",
                  }}
                >
                  {locale === "en-US"
                    ? removeHTMLTags(item?.titleEn || "")
                    : removeHTMLTags(item?.titleAr || "")}
                </Box>
              </Typography>
              <Typography>
                <Box
                  component="span"
                  sx={{
                    fontSize: 14,
                    fontWeight: Fonts.MEDIUM,
                    mb: 0.5,
                    color: (theme) => theme.palette.text.primary,
                    display: "inline-block",
                  }}
                ></Box>
                {locale === "en-US"
                  ? removeHTMLTags(item?.messageEn || "")
                  : removeHTMLTags(item?.messageAr || "")}
              </Typography>
            </Box>
          </ListItem>
        </Link>
      ) : (
        <ListItem
          // disabled={item?.entityType == "General"}
          sx={{
            padding: "8px 20px",
            "&:hover,&:focus,&.Mui-selected": {
              backgroundColor: (theme) =>
                alpha(theme.palette.primary.main, 0.1),
              color: (theme) => theme.palette.primary.main,
            },
            cursor: "pointer",
          }}
          onClick={async () => {
            if (
              item?.notificationType != "General" &&
              item?.notificationType != "BasicNotification"
            )
              handleOpen(item?.relatedEntityId, item?.notificationType);

            await isRead(item?.id);
            // handleClick();
          }}
          className="item-hover"
        >
          <ListItemAvatar
            sx={{
              minWidth: 0,
              mr: 4,
            }}
          >
            <Avatar
              sx={{
                width: 48,
                height: 48,
              }}
              alt={item.notificationType}
            >
              <Campaign />
            </Avatar>
          </ListItemAvatar>

          <Box
            sx={{
              fontSize: 14,
              color: (theme) => theme.palette.text.secondary,
            }}
          >
            <Typography>
              <Box
                component="span"
                sx={{
                  fontSize: 14,
                  fontWeight: Fonts.MEDIUM,
                  mb: 0.5,
                  color: (theme) => theme.palette.text.primary,
                  mr: 1,
                  display: "inline-block",
                }}
              >
                {locale === "en-US"
                  ? removeHTMLTags(item?.titleEn || "")
                  : removeHTMLTags(item?.titleAr || "")}
              </Box>
            </Typography>
            <Typography>
              <Box
                component="span"
                sx={{
                  fontSize: 14,
                  fontWeight: Fonts.MEDIUM,
                  mb: 0.5,
                  color: (theme) => theme.palette.text.primary,
                  display: "inline-block",
                }}
              ></Box>
              {locale === "en-US"
                ? removeHTMLTags(item?.messageEn || "")
                : removeHTMLTags(item?.messageAr || "")}
            </Typography>
          </Box>
        </ListItem>
      )}
    </>
  );
};

export default NotificationItem;
