import { Grid, Hidden } from "@mui/material";
import { GridSelectionModel } from "@mui/x-data-grid";
import AppDataGrid from "components/basicComponents/dataGrid/AppDataGrid";
import AppAdvanceFilterTextField from "components/basicComponents/filters/AppAdvanceFilterTextField";
import AppFilters from "components/basicComponents/filters/AppFilters";
import AppConfirmModal from "components/basicComponents/modals/AppConfirmModal";
import AddDiplomaInstallment from "components/scientificContentManagement/diplomas/diplomaInstallment/AddDiplomaInstallment";
import DiplomaInstallmentDetails from "components/scientificContentManagement/diplomas/diplomaInstallment/DiplomaInstallmentDetails";
import EditDiplomaInstallment from "components/scientificContentManagement/diplomas/diplomaInstallment/EditDiplomaInstallment";
import { useCallback, useState } from "react";
import { useParams } from "react-router";
import { useDeleteService, useGetListService } from "services";
import { AppUrls } from "services/appUrls";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import {
  IDiplomaInstallmentListDto,
  IDiplomaInstallmentSearch,
} from "types/entitiesTypes/DiplomaInstallment";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import { DiplomaInstallmentColumns } from "./DiplomaInstallmentColumns";
import DiplomaInstallmentCard from "./DiplomaInstallmentCard";
import { IPdfHeader } from "types/appTypes/baseEntity/baseDto";
import { useIntl } from "react-intl";

const DiplomaInstallmentPages = () => {
  const { diplomaIdParams } = useParams();
  const [search, setSearch] = useState<IDiplomaInstallmentSearch>({
    pageNumber: 1,
    pageSize: 10,
    diplomaId: diplomaIdParams!,
  });
  const { messages } = useIntl();
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);

  const [selectedRow, setSelectedRow] = useState<IDiplomaInstallmentListDto>();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  // For hide breadcrumb in country profile
  // For Advance Filters
  const [advancedFilter, setAdvancedFilter] = useState(null);
  const [isOpenAdvanceFilters, setIsOpenAdvanceFilters] =
    useState<boolean>(false);

  const {
    resultData: diplomaInstallmentList,
    isFetching,
    error,
  } = useGetListService<IDiplomaInstallmentListDto>(
    AppUrls.DiplomaInstallment,
    {
      ...search,
      advancedFilter,
    }
  );

  // For details
  const handleDetails = useCallback(
    (selectedRow: IDiplomaInstallmentListDto) => {
      setSelectedRow(selectedRow);
      setShowDetailsModal(true);
    },
    []
  );

  // For edit
  const handleUpdate = useCallback(
    (selectedRow: IDiplomaInstallmentListDto) => {
      setSelectedRow(selectedRow);
      setShowEditModal(true);
    },
    []
  );

  // for delete
  const handleDelete = useCallback(
    (selectedRow: IDiplomaInstallmentListDto) => {
      setSelectedRow(selectedRow);
      setShowDeleteModal(true);
    },
    []
  );

  const columns = DiplomaInstallmentColumns(
    handleDetails,
    handleUpdate,
    handleDelete
  );

  const mutationForDelete = useDeleteService<string>(
    AppUrls.DiplomaInstallment,
    selectedRow?.id! || "",
    () => setShowDeleteModal(false)
  );

  const pdfHeaders: IPdfHeader[] = [
    {
      key: "dueDate",
      value: String(messages["diplomaInstallment.dueDate"]),
    },
    {
      key: "amount",
      value: String(messages["diplomaInstallment.amount"]),
    },
    {
      key: "daysMargin",
      value: String(messages["diplomaInstallment.daysMargin"]),
    },
  ];

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <AppFilters
            //excel pdf
            filterAll={{
              ...search,
              advancedFilter,
            }}
            addTitle="diplomaInstallment.add"
            setShowAddModal={setShowAddModal}
            onChangeKeywordValue={(
              event: React.ChangeEvent<HTMLInputElement>
            ) => {
              setSearch({ ...search, keyword: event.target.value });
            }}
            selectionModel={selectionModel as string[]}
            setSelectionModel={setSelectionModel}
            successUrl={AppUrls.DiplomaInstallment}
            keyword={search?.keyword}
            isActive={search?.isActive}
            advancedFilter={advancedFilter}
            totalCount={diplomaInstallmentList?.totalCount || 0}
            pdfHeaders={pdfHeaders}
            withExportExcelButton
            excelFileTitle={messages["sidebar.DiplomaInstallment"] as string}
            customQueries={{
              ...search,
            }}
            // For Permissions
            schemaName={SchemaName.SystemConfigurations}
            entityName={EntityName.Countries}
            action={PermissionActionsTypes.Add}
            // Advance Filters
            showAdvanceFiltersButton={false}
            isOpenAdvanceFilters={isOpenAdvanceFilters}
            setIsOpenAdvanceFilters={setIsOpenAdvanceFilters}
            setAdvancedFilter={setAdvancedFilter}
            advanceFiltersInputs={[
              <AppAdvanceFilterTextField
                fieldName="nameAr"
                label="common.nameAr"
                setAdvancedFilter={setSearch}
                advancedFilter={search}
              />,
              <AppAdvanceFilterTextField
                fieldName="nameEn"
                label="common.nameEn"
                setAdvancedFilter={setSearch}
                advancedFilter={search}
              />,
            ]}
          />
        </Grid>
        <Hidden mdDown>
          <Grid item xs={12}>
            <AppDataGrid
              rowCount={diplomaInstallmentList?.totalCount ?? 0}
              pageNumber={search?.pageNumber ?? 0}
              //for pagination
              onPageSizeChange={(pageSize: number) => {
                setSearch({ ...search, pageSize: pageSize });
              }}
              onPageChange={(pageNumber: number) => {
                setSearch({ ...search, pageNumber: pageNumber + 1 });
              }}
              pageSize={search?.pageSize ?? 10}
              columns={columns}
              rows={diplomaInstallmentList?.data ?? []}
              loading={isFetching}
              //for selection
              onSelectionModelChange={(
                newSelectionModel: GridSelectionModel
              ) => {
                setSelectionModel(newSelectionModel);
              }}
              checkboxSelection={false}
              selectionModel={selectionModel}
              //for sorting
              onSortModelChange={(result: string[] | null) => {
                setSearch({
                  ...search,
                  orderBy: result,
                });
              }}
            />
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid item xs={12}>
            <DiplomaInstallmentCard
              handleDelete={handleDelete}
              diplomaInstallmentList={diplomaInstallmentList?.data!}
              setPage={(event, page) => {
                setSearch({
                  ...search,
                  pageNumber: page,
                });
              }}
              pageNumber={Math.ceil(
                (diplomaInstallmentList?.totalCount || 0) / 10
              )}
              page={search?.pageNumber}
              loading={isFetching}
              errorMessage={error}
              handleUpdate={handleUpdate}
              handleDetails={handleDetails}
            />
          </Grid>
        </Hidden>
      </Grid>

      {/* Add State */}
      {showAddModal && (
        <AddDiplomaInstallment
          label="diplomaInstallment.add"
          showAddModal={showAddModal}
          setShowAddModal={setShowAddModal}
        />
      )}

      {showEditModal && (
        <EditDiplomaInstallment
          showEditModal={showEditModal}
          setShowEditModal={setShowEditModal}
          label="diplomaInstallment.edit"
          selectedRow={selectedRow!}
        />
      )}

      {showDetailsModal && (
        <DiplomaInstallmentDetails
          DiplomaInstallmentItem={selectedRow!}
          setShowDetails={setShowDetailsModal}
          showDetails={showDetailsModal}
        />
      )}

      {showDeleteModal && (
        <AppConfirmModal
          dialogTitle={"diplomaInstallment.actions.delete.dialog.title"}
          open={showDeleteModal}
          setOpen={setShowDeleteModal}
          loading={mutationForDelete.isLoading}
          itemName={""}
          title={"diplomaInstallment.actions.delete.title"}
          onConfirm={() => {
            mutationForDelete.mutate();
          }}
        />
      )}
    </>
  );
};

export default DiplomaInstallmentPages;
