import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { GridColDef } from "@mui/x-data-grid";
import AppDataGridCell from "components/basicComponents/dataGrid/AppDataGridCell";
import AppIconButton from "components/basicComponents/others/AppIconButton";
import { useIntl } from "react-intl";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { IExamQuestionsListDto } from "types/entitiesTypes/examQuestions";
import { IExamResultsListDto } from "types/entitiesTypes/examResult";
import { getValueBasedOnLang } from "helpers";
import moment from "moment";
import AddDataGridRead from "components/basicComponents/dataGrid/AddDataGridRead";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

export const ExamResultsColumns = (
  handleDetails = (row: IExamResultsListDto) => {}
): GridColDef[] => {
  const { messages, locale } = useIntl();

  const ExamResultsColumns: GridColDef[] = [
    {
      field: "student.fullName",
      headerName: messages["student.name"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell value={params?.row?.student?.fullName} />
      ),
    },
    {
      field: locale === "ar-SA" ? "exam.nameAr" : "exam.nameEn",
      headerName: messages["exams.name"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell
          value={getValueBasedOnLang(
            params.row?.exam?.nameAr,
            params.row?.exam?.nameEn
          )}
        />
      ),
    },

    {
      field: "exam.score",
      headerName: messages["exams.score"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => <AppDataGridCell value={params.row?.score} />,
    },
    {
      field: "exam.passingScore",
      headerName: messages["exams.passingScore"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell value={params.row?.exam?.passingScore} />
      ),
    },
    {
      field: "exam.totalMarks",
      headerName: messages["exams.totalMarks"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell value={params.row?.exam?.totalMarks} />
      ),
    },
    {
      field: "exam.date",
      headerName: messages["exams.date"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell
          value={moment(params.row?.exam?.date).format("YYYY-MM-DD HH:mm")}
        />
      ),
    },

    {
      field: "exam.pass",
      headerName: messages["exams.pass"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <>
          {params?.row?.pass ? (
            <CheckIcon
              // sx={{
              //   transform: locale !== "en-US" ? "rotateY(180deg)" : "none",
              // }}
              color="success"
            />
          ) : (
            <CloseIcon color="error" />
          )}
        </>
      ),
    },
    {
      field: "actions",
      headerName: messages["common.actions"] as string,
      sortable: false,
      align: "center",
      filterable: false,
      headerAlign: "center",
      flex: 0.1,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div>
            {/* Details */}
            <AppIconButton
              label="table.actions.details"
              handleClick={() => {
                handleDetails(params.row);
              }}
              childComp={
                <RemoveRedEyeIcon fontSize="small" sx={{ color: "#007991" }} />
              }
              // For Permissions
              schemaName={SchemaName.SystemConfigurations}
              entityName={EntityName.Countries}
              action={PermissionActionsTypes.Details}
            />
          </div>
        );
      },
    },
  ];

  return ExamResultsColumns;
};
