import { AppConfirmDialog } from "@crema";
import AppTextField from "@crema/core/AppFormComponents/AppTextField";
import IntlMessages from "@crema/utility/IntlMessages";
import { Grid } from "@mui/material";
import AppCustomizedSwitch from "components/basicComponents/inputs/AppCustomizedSwitch";
import AppAddModal from "components/basicComponents/modals/AppAddModal";
import AppConfirmModal from "components/basicComponents/modals/AppConfirmModal";
import AppUploadPhoto from "components/basicComponents/uploadFile/uploadPhoto/AppUploadPhoto";
import { Form, Formik, FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useCreateService } from "services";
import { AppUrls } from "services/appUrls";
import { ICreateEducationalCenterDto } from "types/entitiesTypes/educationalCenter";
import * as yup from "yup";

interface IAddEducationalCenterProps {
  showAddModal: boolean;
  setShowAddModal: React.Dispatch<React.SetStateAction<boolean>>;
  label: string;
  onSuccess?: () => void;
  onFailed?: () => void;
}

const AddEducationalCenter: React.FC<IAddEducationalCenterProps> = (props) => {
  const { showAddModal, setShowAddModal, label } = props;
  const { messages } = useIntl();
  const refForm = React.useRef<FormikProps<ICreateEducationalCenterDto>>(null);
  const [openStamp, setOpenStamp] = useState(false);
  const [openSignature, setOpenSignature] = useState(false);
  const [openSignatureStemp, setOpenSignatureStemp] = useState(false);
  const mutation = useCreateService<ICreateEducationalCenterDto, string>(
    AppUrls.EducationalCenter
  );

  const validationSchema = yup.object({
    nameAr: yup.string().required(String(messages["validation.required"])),
    nameEn: yup.string().required(String(messages["validation.required"])),
    addressAr: yup.string().required(String(messages["validation.required"])),
    addressEn: yup.string().required(String(messages["validation.required"])),
    // managerSignature: yup
    //   .array()
    //   .min(1, String(messages["validation.file"]))
    //   .required("validation.file"),
    // stamp: yup
    //   .array()
    //   .min(1, String(messages["validation.file"]))
    //   .required("validation.file"),
  });

  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);

  const [uploadedStampFiles, setUploadedStampFiles] = useState<any[]>([]);

  const submit = () => {
    if (refForm.current) refForm.current.submitForm();
  };

  return (
    <>
      <AppAddModal
        label={label}
        open={showAddModal}
        onClose={() => setShowAddModal(false)}
        closeModalState={setShowAddModal}
        successClose={mutation.isSuccess}
        handleSubmit={submit}
        loadingForAdd={
          (refForm.current?.isSubmitting && !refForm.current?.isValidating) ||
          false
        }
        maxWidth={"md"}
      >
        <Formik
          validateOnChange={true}
          initialValues={{
            nameAr: "",
            nameEn: "",
            addressAr: "",
            addressEn: "",
            managerSignature: "",
            stamp: "",
            isActive: true,
          }}
          validationSchema={validationSchema}
          innerRef={refForm}
          onSubmit={async (data, { setSubmitting }) => {
            if (
              uploadedFiles?.length === 0 &&
              uploadedStampFiles?.length === 0
            ) {
              setOpenSignatureStemp(true);
            } else if (uploadedFiles?.length === 0) {
              console.log("dasdasd");
              setOpenSignature(true);
            } else if (uploadedStampFiles?.length === 0) {
              console.log("dasdasd");
              setOpenStamp(true);
            }
            // if (!uploadedFiles) setOpenSignature(true);
            // if (!uploadedStampFiles) setOpenStamp(true);
            else {
              data = {
                ...data,
                managerSignature: uploadedFiles[0],
                stamp: uploadedStampFiles[0],
              };

              mutation.submitData(data);
            }
          }}
        >
          {({ setFieldValue, errors, touched, values }) => (
            <>
              <Form>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6}>
                    <AppTextField
                      label={<IntlMessages id="common.nameAr" />}
                      name="nameAr"
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <AppTextField
                      label={<IntlMessages id="common.nameEn" />}
                      name="nameEn"
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <AppTextField
                      label={<IntlMessages id="common.addressAr" />}
                      name="addressAr"
                      variant="outlined"
                      size="small"
                      fullWidth
                      multiline
                      minRows={3}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <AppTextField
                      label={<IntlMessages id="common.addressEn" />}
                      name="addressEn"
                      variant="outlined"
                      size="small"
                      fullWidth
                      multiline
                      minRows={3}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    display={"flex"}
                    justifyContent={"flex-start"}
                  >
                    <AppCustomizedSwitch
                      keyName="isActive"
                      onChange={setFieldValue}
                    />
                  </Grid>
                  <AppUploadPhoto
                    uploadedFiles={uploadedFiles}
                    setUploadedFiles={setUploadedFiles}
                    keyName="managerSignature"
                    formRef={refForm}
                    // errors={
                    //   !!(errors.managerSignature && touched.managerSignature)
                    // }
                    uploadText="educationalCenter.managerSignature"
                    helperText="validation.file"
                    appGridXs={6}
                    uploadModernXs={12}
                  />

                  <AppUploadPhoto
                    uploadedFiles={uploadedStampFiles}
                    setUploadedFiles={setUploadedStampFiles}
                    keyName="stamp"
                    formRef={refForm}
                    // errors={!!(errors.stamp && touched.stamp)}
                    uploadText="educationalCenter.stamp"
                    helperText="validation.file"
                    appGridXs={6}
                    uploadModernXs={12}
                  />
                </Grid>
              </Form>
              {openSignatureStemp && (
                <AppConfirmModal
                  dialogTitle={"educationalCenter.Add.warning"}
                  open={openSignatureStemp}
                  setOpen={setOpenSignatureStemp}
                  loading={mutation.isLoading}
                  title={"educationalCenter.Add.SignatureStamp"}
                  onConfirm={async () => {
                    await refForm?.current?.validateForm();

                    const formValues = refForm?.current?.values;

                    if (formValues) {
                      const newValues: ICreateEducationalCenterDto = {
                        ...formValues,
                        stamp: "",
                        managerSignature: "",
                        nameEn: formValues.nameEn ?? "",
                        nameAr: formValues.nameAr ?? "",
                        addressEn: formValues.addressEn ?? "",
                        addressAr: formValues.addressAr ?? "",
                        isActive: formValues.isActive ?? true,
                      };

                      mutation.submitData(newValues);
                    }
                  }}
                />
              )}
              {openSignature && (
                <AppConfirmModal
                  dialogTitle={"educationalCenter.Add.warning"}
                  open={openSignature}
                  setOpen={setOpenSignature}
                  loading={mutation.isLoading}
                  title={"educationalCenter.Add.Signature"}
                  onConfirm={async () => {
                    await refForm?.current?.validateForm();

                    const formValues = refForm?.current?.values;

                    if (formValues) {
                      const newValues: ICreateEducationalCenterDto = {
                        ...formValues,
                        stamp:
                          uploadedStampFiles.length > 0
                            ? uploadedStampFiles[0]
                            : "",
                        managerSignature: "",
                        nameEn: formValues.nameEn ?? "",
                        nameAr: formValues.nameAr ?? "",
                        addressEn: formValues.addressEn ?? "",
                        addressAr: formValues.addressAr ?? "",
                        isActive: formValues.isActive ?? true,
                      };

                      mutation.submitData(newValues);
                    }
                  }}
                />
              )}
              {openStamp && (
                <AppConfirmModal
                  dialogTitle={"educationalCenter.Add.warning"}
                  open={openStamp}
                  setOpen={setOpenStamp}
                  loading={mutation.isLoading}
                  title={"educationalCenter.Add.stamp"}
                  onConfirm={async () => {
                    await refForm?.current?.validateForm();

                    const formValues = refForm?.current?.values;

                    if (formValues) {
                      const newValues: ICreateEducationalCenterDto = {
                        ...formValues,
                        stamp: "",
                        managerSignature:
                          uploadedFiles.length > 0 ? uploadedFiles[0] : "",
                        nameEn: formValues.nameEn ?? "",
                        nameAr: formValues.nameAr ?? "",
                        addressEn: formValues.addressEn ?? "",
                        addressAr: formValues.addressAr ?? "",
                        isActive: formValues.isActive ?? true,
                      };

                      mutation.submitData(newValues);
                    }
                  }}
                />
              )}
            </>
          )}
        </Formik>
      </AppAddModal>
    </>
  );
};

export default AddEducationalCenter;
