import { Grid, MenuItem } from "@mui/material";
import { GridSelectionModel } from "@mui/x-data-grid";
import AppBreadcrumb from "components/basicComponents/breadcrumb/AppBreadcrumb";
import AppDataGrid from "components/basicComponents/dataGrid/AppDataGrid";
import AppAdvanceFilterTextField from "components/basicComponents/filters/AppAdvanceFilterTextField";
import AppFilters from "components/basicComponents/filters/AppFilters";
import AppConfirmModal from "components/basicComponents/modals/AppConfirmModal";
import AddRole from "components/usersManagement/roles/AddRole";
import AddRoleToUser from "components/usersManagement/roles/AddRoleToUser";
import EditRole from "components/usersManagement/roles/EditRole";
import { getValueBasedOnLangForConfirmModal } from "helpers";
import { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import {
  useDeleteService,
  useGetListService,
  useToggleActiveService,
} from "services";
import { AppUrls } from "services/appUrls";
import { IRoleListDto, IRoleSearch } from "types/entitiesTypes/roles";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import {
  PermissionActionsTypes,
  RoleLevel,
  UserType,
} from "types/entitiesTypes/enums";
import { RolesColumns } from "./RolesColumns";
import { IPdfHeader } from "types/appTypes/baseEntity/baseDto";
import { useLocation, useParams } from "react-router-dom";
import { IAssignUserToRoleDto } from "types/entitiesTypes/users";
import { useDeleteParameterService } from "services/additionalServices";
import AddRoleToUserModal from "components/usersManagement/users/AddRoleToUserModal";
import { useJWTAuth } from "@crema/services/auth/jwt-auth/JWTAuthProvider";
import AppAdvanceFilterSelect from "components/basicComponents/filters/AppAdvanceFilterSelect";
import IntlMessages from "@crema/utility/IntlMessages";

const RolesPage = () => {
  const { messages, locale } = useIntl();
  const { userIdParams } = useParams();
  const location = useLocation();
  const { user } = useJWTAuth();

  const [search, setSearch] = useState<IRoleSearch>({
    pageNumber: 1,
    pageSize: 10,
    userId: userIdParams
      ? userIdParams
      : location.pathname?.includes("my-profile")
      ? user?.id
      : "" || "",
    userType: "User",
  });
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showAddSecondModalModal, setShowAddSecondModal] =
    useState<boolean>(false);

  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<IRoleListDto>();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showDeAssignModal, setShowDeAssignModal] = useState<boolean>(false);

  const [showActivationModal, setShowActivationModal] =
    useState<boolean>(false);
  const [showAssignRoleModal, setShowAssignRoleModal] =
    useState<boolean>(false);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  // For Advance Filters
  const [advancedFilter, setAdvancedFilter] = useState(null);
  const [isOpenAdvanceFilters, setIsOpenAdvanceFilters] =
    useState<boolean>(false);

  const { resultData: rolesList, isFetching } = useGetListService<IRoleListDto>(
    AppUrls.Roles,
    { ...search, advancedFilter }
  );

  // For edit
  const handleUpdate = useCallback((selectedRow: IRoleListDto) => {
    setSelectedRow(selectedRow);
    setShowEditModal(true);
  }, []);

  // For Add Role To User
  const handleAssignRoleToUser = useCallback((selectedRow: IRoleListDto) => {
    setSelectedRow(selectedRow);
    setShowAssignRoleModal(true);
  }, []);

  // For De Assign Role From User
  const handleDeAssignRole = useCallback((selectedRow: IRoleListDto) => {
    setSelectedRow(selectedRow);
    setShowDeAssignModal(true);
  }, []);

  // for delete
  const handleDelete = useCallback((selectedRow: IRoleListDto) => {
    setSelectedRow(selectedRow);
    setShowDeleteModal(true);
  }, []);

  //for Activation
  const handleToggleActive = useCallback((selectedRow: IRoleListDto) => {
    setSelectedRow(selectedRow);
    setShowActivationModal(true);
  }, []);

  const columns = RolesColumns(
    handleUpdate,
    handleDelete,
    handleToggleActive,
    handleAssignRoleToUser,
    handleDeAssignRole
  );

  const mutationForDeAssignRoleFromUser = useDeleteParameterService<
    string,
    IAssignUserToRoleDto
  >(
    AppUrls.DeAssignRoleFromUser,
    AppUrls.Roles,
    {
      userId: userIdParams || "",
      roleId: selectedRow?.id!,
    },
    () => setShowDeAssignModal(false)
  );

  const mutationForDelete = useDeleteService<string>(
    AppUrls.Roles,
    selectedRow?.id || "",
    () => setShowDeleteModal(false)
  );

  const mutationForToggleActive = useToggleActiveService<boolean>(
    AppUrls.Roles,
    selectedRow?.id || "",
    () => setShowActivationModal(false)
  );

  const pdfHeaders: IPdfHeader[] = [
    { key: "nameAr", value: String(messages["common.nameAr"]) },
    {
      key: "nameEn",
      value: String(messages["common.nameEn"]),
    },
    { key: "isActive", value: String(messages["common.isActive"]) },
  ];

  return (
    <>
      <Grid container spacing={3}>
        <AppBreadcrumb
          paths={[{ name: messages["sidebar.usersManagement"] }]}
          currentPathName={"sidebar.roles"}
          isShow={!userIdParams && !location.pathname?.includes("my-profile")}
        />

        <Grid item xs={12}>
          <AppFilters
            //excel pdf
            filterAll={{
              ...search,
              advancedFilter,
            }}
            addTitle="roles.add"
            setShowAddModal={setShowAddModal}
            onChangeKeywordValue={(
              event: React.ChangeEvent<HTMLInputElement>
            ) => {
              setSearch({ ...search, keyword: event.target.value });
            }}
            showAddButton={
              !userIdParams && !location.pathname.includes("/my-profile")
            }
            showAddSecondButton={userIdParams ? true : false}
            setShowAddSecondModal={setShowAddSecondModal}
            addSecondTitle="addRoleToUser"
            showMultipleDeactivation={false}
            showMultipleActivation={false}
            selectionModel={selectionModel as string[]}
            setSelectionModel={setSelectionModel}
            successUrl={AppUrls.Roles}
            keyword={search?.keyword}
            isActive={search?.isActive}
            totalCount={rolesList?.totalCount || 0}
            excelFileTitle={String(messages["sidebar.roles"])}
            pdfHeaders={pdfHeaders}
            // For Permissions
            schemaName={SchemaName.UsersManagement}
            entityName={EntityName.Roles}
            action={PermissionActionsTypes.Add}
            // For Permissions Second Button
            schemaNameForSecondButton={SchemaName.UsersManagement}
            entityNameForSecondButton={EntityName.UserRoles}
            actionForSecondButton={PermissionActionsTypes.AssignUserToRole}
            // Advance Filters
            showAdvanceFiltersButton={false}
            setAdvancedFilter={setAdvancedFilter}
            isOpenAdvanceFilters={isOpenAdvanceFilters}
            setIsOpenAdvanceFilters={setIsOpenAdvanceFilters}
            // advanceFiltersInputs={[
            //   <AppAdvanceFilterTextField
            //     fieldName="nameAr"
            //     label="common.nameAr"
            //     setAdvancedFilter={setAdvancedFilter}
            //     advancedFilter={advancedFilter}
            //   />,
            //   <AppAdvanceFilterTextField
            //     fieldName="name"
            //     label="common.nameEn"
            //     setAdvancedFilter={setAdvancedFilter}
            //     advancedFilter={advancedFilter}
            //   />,
            //   <AppAdvanceFilterSelect
            //     fieldName="userType"
            //     label={"users.userType"}
            //     labelForView={locale == "en-US" ? "roleLevel" : "roleLevelsSD"}
            //   >
            //     <MenuItem value={UserType.Teacher}>
            //       <IntlMessages id="users.userType.Teacher" />
            //     </MenuItem>
            //     <MenuItem value={UserType.Employee}>
            //       <IntlMessages id="users.userType.Employee" />
            //     </MenuItem>
            //     <MenuItem value={UserType.Student}>
            //       <IntlMessages id="users.userType.Student" />
            //     </MenuItem>
            //     <MenuItem value={UserType.User}>
            //       <IntlMessages id="users.userType.User" />
            //     </MenuItem>
            //   </AppAdvanceFilterSelect>,
            // ]}
          />
        </Grid>

        <Grid item xs={12}>
          <AppDataGrid
            rowCount={rolesList?.totalCount ?? 0}
            pageNumber={search?.pageNumber ?? 0}
            //for pagination
            onPageSizeChange={(pageSize: number) => {
              setSearch({ ...search, pageSize: pageSize });
            }}
            onPageChange={(pageNumber: number) => {
              setSearch({ ...search, pageNumber: pageNumber + 1 });
            }}
            pageSize={search?.pageSize ?? 10}
            columns={columns}
            rows={rolesList?.data ?? []}
            loading={isFetching}
            //for selection
            checkboxSelection={false}
            onSelectionModelChange={(newSelectionModel: GridSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            selectionModel={selectionModel}
            //for sorting
            onSortModelChange={(result: string[] | null) => {
              setSearch({
                ...search,
                orderBy: result,
              });
            }}
          />
        </Grid>
      </Grid>

      {/* Add  */}
      {showAddModal && (
        <AddRole
          label="roles.add"
          showAddModal={showAddModal}
          setShowAddModal={setShowAddModal}
        />
      )}

      {showEditModal && (
        <EditRole
          showEditModal={showEditModal}
          setShowEditModal={setShowEditModal}
          label="roles.edit"
          selectedRow={{
            id: selectedRow?.id || "",
            nameAr: selectedRow?.nameAr || "",
            nameEn: selectedRow?.name || "",
          }}
        />
      )}

      {showDeAssignModal && (
        <AppConfirmModal
          dialogTitle={"roles.deAssignUserToPublicAgency.dialogTitle"}
          open={showDeAssignModal}
          setOpen={setShowDeAssignModal}
          loading={mutationForDeAssignRoleFromUser.isLoading}
          title={"common.deAssign.title"}
          onConfirm={() => {
            mutationForDeAssignRoleFromUser.mutate();
          }}
        />
      )}

      {showAssignRoleModal && (
        <AddRoleToUser
          showAssignRoleModal={showAssignRoleModal}
          setShowAssignRoleModal={setShowAssignRoleModal}
          label="table.actions.assignRoleToUser"
          selectedRow={{
            id: selectedRow?.id || "",
            nameAr: selectedRow?.nameAr || "",
            nameEn: selectedRow?.name || "",
          }}
        />
      )}

      {showAddSecondModalModal && (
        <AddRoleToUserModal
          showAssignRoleModal={showAddSecondModalModal}
          setShowAssignRoleModal={setShowAddSecondModal}
          label="table.actions.assignRoleToUser"
        />
      )}

      {showDeleteModal && (
        <AppConfirmModal
          dialogTitle={"roles.deleteDialogTitle"}
          open={showDeleteModal}
          setOpen={setShowDeleteModal}
          loading={mutationForDelete.isLoading}
          itemName={getValueBasedOnLangForConfirmModal(
            selectedRow?.nameAr || "",
            selectedRow?.name || ""
          )}
          title={"roles.deleteTitle"}
          onConfirm={() => {
            mutationForDelete.mutate();
          }}
        />
      )}

      {showActivationModal && (
        <AppConfirmModal
          dialogTitle={
            selectedRow?.isActive
              ? "roles.deactivationTitle"
              : "roles.activationTitle"
          }
          open={showActivationModal}
          setOpen={setShowActivationModal}
          itemName={getValueBasedOnLangForConfirmModal(
            selectedRow?.nameAr || "",
            selectedRow?.name || ""
          )}
          loading={mutationForToggleActive.isLoading}
          title={
            selectedRow?.isActive
              ? "roles.deactivationConfirmation"
              : "roles.activationConfirmation"
          }
          onConfirm={() => {
            mutationForToggleActive.mutate();
          }}
        />
      )}
    </>
  );
};

export default RolesPage;
