import { FieldHookConfig, useField } from "formik";
import TextField from "@mui/material/TextField";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import { makeStyles } from "@mui/styles";
import { useIntl } from "react-intl";
import { FormControl, InputLabel, Typography } from "@mui/material";

const useStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});

const AppTextField = (
  props: TextFieldProps &
    FieldHookConfig<string> & {
      withDecemal?: boolean;
    }
) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  const classes = useStyles();
  const { locale } = useIntl();
  const { withDecemal = false } = props;

  return (
    <TextField
      {...props}
      {...field}
      helperText={errorText}
      error={!!errorText}
      className={classes.input}
      sx={{
        ...props.sx,
        "& .MuiOutlinedInput-input": {
          textAlign:
            locale === "ar-SA" &&
            props?.name &&
            props?.name?.toLowerCase().endsWith("en")
              ? "right !important"
              : locale === "ar-SA" &&
                props?.name &&
                props?.name?.toLowerCase().endsWith("ar")
              ? "left !important"
              : locale === "en-US" &&
                props?.name &&
                props?.name?.toLowerCase().endsWith("en")
              ? "left !important"
              : locale === "en-US" &&
                props?.name &&
                props?.name?.toLowerCase().endsWith("ar")
              ? "right !important"
              : "justify",
          direction:
            locale === "ar-SA" &&
            props?.name &&
            props?.name?.toLowerCase().endsWith("en")
              ? "rtl !important"
              : locale === "ar-SA" &&
                props?.name &&
                props?.name?.toLowerCase().endsWith("ar")
              ? "ltr !important"
              : locale === "en-US" &&
                props?.name &&
                props?.name?.toLowerCase().endsWith("en")
              ? "ltr !important"
              : locale === "en-US" &&
                props?.name &&
                props?.name?.toLowerCase().endsWith("ar")
              ? "rtl !important"
              : "justify",
        },
      }}
      onWheel={
        props?.type === "number" || props?.type === "costumNumber"
          ? (e: any) => e.target.blur()
          : undefined
      }
      InputProps={{
        ...props.InputProps,
        onKeyPress: (event) => {
          const keyCode = event.keyCode || event.which;
          // console.log(event);

          if (
            (props?.type === "number" || props?.type === "costumNumber") &&
            withDecemal &&
            // keyCode === 69 ||
            // keyCode === 46 ||
            // keyCode === 110 ||
            (keyCode === 38 || // arrow up
              keyCode === 40 || // arrow down
              keyCode === 187 || // "+" key
              keyCode === 189) // "-" key
          ) {
            event.preventDefault();
          } else if (
            (props?.type === "number" || props?.type === "costumNumber") &&
            !withDecemal &&
            (keyCode === 69 ||
              keyCode === 46 ||
              keyCode === 110 ||
              keyCode === 190 ||
              keyCode === 38 || // arrow up
              keyCode === 40 || // arrow down
              keyCode === 187 || // "+" key
              keyCode === 189) // "-" key
          ) {
            event.preventDefault();
          }
          if (props?.type === "costumNumber") {
            if (
              event.key === "1" ||
              event.key === "2" ||
              event.key === "3" ||
              event.key === "4" ||
              event.key === "5" ||
              event.key === "6" ||
              event.key === "7" ||
              event.key === "8" ||
              event.key === "9" ||
              event.key === "0" ||
              event.key === "Backspace" ||
              event.key === "Enter" ||
              event.key === "ArrowRight" ||
              event.key === "ArrowLeft"
            ) {
            } else {
              event.preventDefault();
            }
          }
        },

        // disableUnderline: true,
      }}
    />
  );
};

export default AppTextField;
