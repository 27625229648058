import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Pagination,
  Typography,
} from "@mui/material";
import { AxiosError } from "axios";
import AppDataGridCellLink from "components/basicComponents/dataGrid/AppDataGridCellLink";
import DataItem from "components/basicComponents/mobile/DataItem";
import AppIconButton from "components/basicComponents/others/AppIconButton";
import LoadingAndErrorWrapper from "components/basicComponents/others/LoadingAndErrorWrapper";
import { FC } from "react";
import { useIntl } from "react-intl";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import { IStudentAttachmentsList } from "types/entitiesTypes/studentAttachments";
import { enumTypeAttachments } from "./AttachmentsColumns";
import { generatePresignedUrl, getValueBasedOnLang } from "helpers";

interface IStudentAttachmentsCardProps {
  handleDelete: (row: IStudentAttachmentsList) => void;
  studentAttachmentsList: Array<IStudentAttachmentsList>;
  pageNumber?: number;
  setPage?: (event: React.ChangeEvent<unknown>, page: number) => void;
  page?: number;
  loading: boolean;
  errorMessage: AxiosError<any, any> | null;
}
const StudentAttachmentsCard: FC<IStudentAttachmentsCardProps> = (props) => {
  const {
    handleDelete,
    studentAttachmentsList,
    pageNumber,
    setPage,
    page,
    loading,
    errorMessage,
  } = props;
  const { messages, locale } = useIntl();
  return (
    <Grid container spacing={4}>
      <LoadingAndErrorWrapper
        isLoading={loading}
        errorMessage={errorMessage?.response?.data.messages[0]}
      >
        {studentAttachmentsList?.length != undefined &&
        studentAttachmentsList?.length >= 1 ? (
          studentAttachmentsList?.map((item, index) => (
            <Grid key={`studentAttachmentsList${index}`} item xs={12}>
              <Card sx={{ width: "100%" }}>
                <Box
                  marginX={4}
                  marginTop={3}
                  display={"flex"}
                  justifyContent={"flex-end"}
                  gap={3}
                >
                  {/* Delete */}
                  <AppIconButton
                    label="table.actions.delete"
                    handleClick={() => {
                      handleDelete(item);
                    }}
                    childComp={
                      <DeleteOutlinedIcon
                        fontSize="small"
                        sx={{ color: "#D72638" }}
                      />
                    }
                    // For Permissions
                    schemaName={SchemaName.SystemConfigurations}
                    entityName={EntityName.Countries}
                    action={PermissionActionsTypes.Delete}
                  />
                </Box>
                <CardContent
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: 3,
                    paddingTop: "0 !important",
                  }}
                >
                  <DataItem
                    label={messages["student.id"] as string}
                    value={item?.subscription?.student?.id}
                  />
                  <DataItem
                    label={messages["student.name"] as string}
                    value={item?.subscription?.student?.fullName}
                  />
                  <DataItem
                    label={messages["student.lastName"] as string}
                    value={item?.subscription?.student?.lastName}
                  />
                  <DataItem
                    label={messages["common.phone"] as string}
                    value={item?.subscription?.student?.phoneNumber}
                  />
                  <DataItem
                    label={messages["diplomas.name"] as string}
                    value={getValueBasedOnLang(
                      item?.subscription?.diploma?.nameAr ?? "-",
                      item?.subscription?.diploma?.nameEn ?? "-"
                    )}
                  />

                  {item.certificateNumber && (
                    <DataItem
                      label={messages["student.certificateNumber"] as string}
                      value={item.certificateNumber}
                    />
                  )}
                  <DataItem
                    label={messages["student.documentFileKey"] as string}
                    value={
                      <AppDataGridCellLink
                        value={
                          item.documentFileKey
                            ? generatePresignedUrl(
                                item.documentFileKey,
                                process.env.NODE_ENV
                              )
                            : ""
                        }
                        label="session.attachments.actions.open"
                      />
                    }
                  />
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            item
            xs={12}
            marginTop={10}
          >
            <Typography variant="h5" component={"span"}>
              {messages["common.dataFound"]}
            </Typography>
          </Grid>
        )}
        {pageNumber! > 1 && (
          <Grid
            display={"flex"}
            alignItems={"cneter"}
            justifyContent={"center"}
            marginTop={5}
            item
            xs={12}
          >
            <Pagination
              count={pageNumber}
              page={page}
              color="primary"
              onChange={(event, page) => {
                setPage && setPage(event, page);
              }}
            />
          </Grid>
        )}
      </LoadingAndErrorWrapper>
    </Grid>
  );
};

export default StudentAttachmentsCard;
