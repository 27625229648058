import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { GridColDef } from "@mui/x-data-grid";
import AddDataGridRead from "components/basicComponents/dataGrid/AddDataGridRead";
import AppDataGridCell from "components/basicComponents/dataGrid/AppDataGridCell";
import AppIconButton from "components/basicComponents/others/AppIconButton";
import { useIntl } from "react-intl";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { suggestType } from "shared/constants/AppEnums";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import { IWebsiteContactUsListDto } from "types/entitiesTypes/websiteContactUs";

export const WebsiteContactUsColumns = (
  handleIsRead = (row: IWebsiteContactUsListDto) => {},
  handleDetails = (row: IWebsiteContactUsListDto) => {},
  handleDelete = (row: IWebsiteContactUsListDto) => {}
): GridColDef[] => {
  const { messages } = useIntl();

  const WebsiteContactUsColumns: GridColDef[] = [
    {
      field: "fullName",
      headerName: messages[
        "siteManagement.WebsiteContactUs.fullName"
      ] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => <AppDataGridCell value={params.row?.fullName} />,
    },
    {
      field: "phoneNumber",
      headerName: messages["common.phone"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell value={params.row?.phoneNumber} />
      ),
    },
    {
      field: "suggestType",
      headerName: messages[
        "siteManagement.WebsiteContactUs.suggestType"
      ] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell
          value={
            params.row?.suggestType === 0
              ? String(
                  messages[
                    `siteManagement.WebsiteContactUs.suggestType.${suggestType.Complaint}`
                  ]
                )
              : String(
                  messages[
                    `siteManagement.WebsiteContactUs.suggestType.${suggestType.Suggestion}`
                  ]
                )
          }
        />
      ),
    },
    {
      field: "isRead",
      headerName: messages["siteManagement.websiteNeeds.read"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.1,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <AddDataGridRead
            value={params.row.isRead}
            handleClick={() => {
              if (!params.row.isRead) handleIsRead(params.row);
            }}
            sxProp={{
              padding: 0.3,
              cursor: params.row.isRead ? "default" : "pointer",
            }}
            // For Permissions
            schemaName={SchemaName.UsersManagement}
            entityName={EntityName.Roles}
            action={PermissionActionsTypes.IsRead}
          />
        );
      },
    },
    {
      field: "actions",
      headerName: messages["common.actions"] as string,
      sortable: false,
      align: "center",
      filterable: false,
      headerAlign: "center",
      flex: 0.1,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div>
            {/* Details */}
            <AppIconButton
              label="table.actions.details"
              handleClick={() => {
                handleDetails(params.row);
              }}
              childComp={
                <RemoveRedEyeIcon fontSize="small" sx={{ color: "#007991" }} />
              }
              // For Permissions
              schemaName={SchemaName.SystemConfigurations}
              entityName={EntityName.Countries}
              action={PermissionActionsTypes.Details}
            />

            {/* Delete */}
            <AppIconButton
              label="table.actions.delete"
              handleClick={() => {
                handleDelete(params.row);
              }}
              childComp={
                <DeleteOutlinedIcon
                  fontSize="small"
                  sx={{ color: "#D72638" }}
                />
              }
              // For Permissions
              schemaName={SchemaName.SystemConfigurations}
              entityName={EntityName.Countries}
              action={PermissionActionsTypes.Delete}
            />
          </div>
        );
      },
    },
  ];

  return WebsiteContactUsColumns;
};
