import React from "react";
import { Chip } from "@mui/material";
import { useIntl } from "react-intl";

interface IStatusBadgeProps {
  isActive: boolean;
  transform?: string;
}

const StatusBadge: React.FC<IStatusBadgeProps> = (props) => {
  const { messages } = useIntl();
  const { isActive, transform } = props;

  return (
    <>
      {isActive ? (
        <Chip
          size="small"
          label={messages["columns.active"]}
          color="success"
          variant="filled"
          sx={{ color: "#fff", transform: transform }}
        />
      ) : (
        <Chip
          size="small"
          label={messages["columns.inactive"]}
          color="error"
          variant="filled"
          sx={{ transform: transform }}
        />
      )}
    </>
  );
};

export default StatusBadge;
