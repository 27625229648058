import ReactDOM from "react-dom";
// import "@crema/services";

import App from "./App";
// import "firebase/messaging";
// import { register } from "serviceWorker";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// register();
// reportWebVitals();
