import IntlMessages from "@crema/utility/IntlMessages";
import AddIcon from "@mui/icons-material/Add";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import SearchIcon from "@mui/icons-material/Search";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Fab,
  Grid,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Zoom,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { GridSelectionModel } from "@mui/x-data-grid";
import AppPermissionsGate, {
  IAppPermissionsProps,
} from "components/basicComponents/permessions/AppPermissionsGate";
import { debounce } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { FaFileExcel, FaFilePdf } from "react-icons/fa";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import SlideDown from "react-slidedown";
import "react-slidedown/lib/slidedown.css";
import { emptyExcelData, getExcelData } from "redux/actions/exporting";
import { AppState } from "redux/store";
import { useSetAllService } from "services";
import { AppUrls } from "services/appUrls";
import { BaseActions } from "services/baseActions";
import { DEBOUNCE_TIME } from "shared/constants/AppConst";
import GetPdfTemplate from "shared/constants/GetPdfTemplate";
import { IBaseSetAllDto, IPdfHeader } from "types/appTypes/baseEntity/baseDto";
import {
  FileType,
  OperationTypeForSetAll,
  PermissionActionsTypes,
} from "types/entitiesTypes/enums";
import * as XLSX from "xlsx/xlsx.mjs";
import AppConfirmModal from "../modals/AppConfirmModal";
import "./advanceFilter.css";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ReplayIcon from "@mui/icons-material/Replay";
import CheckIcon from "@mui/icons-material/Check";
import AppAdvanceFilterTextField from "./AppAdvanceFilterTextField";
import AppAdvanceFilterSelect from "./AppAdvanceFilterSelect";
import { useLocation, useNavigate } from "react-router-dom";
import ExportedFile from "components/exportedFile/ExportedFile";
import AppDropDownButton from "@crema/core/AppDropDownButton";
interface IAdvanceFilterProps {
  showAdvanceFiltersButton?: boolean;
  advanceFiltersInputs?: React.ReactNode[];
  setAdvancedFilter?: any;
  advancedFilter?: any;
  isOpenAdvanceFilters?: boolean;
  filterAll?: any;

  setIsOpenAdvanceFilters?: any;
}
interface MenuItem {
  setState: React.Dispatch<React.SetStateAction<boolean>>;
  labelMenuItem: string;
}
interface IAppFiltersProps extends IAppPermissionsProps, IAdvanceFilterProps {
  value?: string;
  onChangeKeywordValue?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectionModel: string[];
  showSearchInput?: boolean;
  addTitle?: string;
  errorFileButtonTile?: string;
  showAddButton?: boolean;
  showDailyUploadButton?: boolean;
  handleDailyUploadButtonClick?: () => void;
  setShowAddModal?: React.Dispatch<React.SetStateAction<boolean>>;
  onChangeAddButton?: () => void;
  showDetailsErrorFileButton?: boolean;
  setShowDetailsErrorFile?: React.Dispatch<React.SetStateAction<boolean>>;
  // Second Button
  addSecondTitle?: string;
  showAddSecondButton?: boolean;
  setShowAddSecondModal?: React.Dispatch<React.SetStateAction<boolean>>;
  schemaNameForSecondButton?: string;
  entityNameForSecondButton?: string;

  // Multiple Select
  successUrl: string;
  setSelectionModel: React.Dispatch<React.SetStateAction<GridSelectionModel>>;
  showMultipleDeactivation?: boolean;
  showMultipleActivation?: boolean;
  showMultipleDeletion?: boolean;
  showCheckAll?: boolean;

  actionForSecondButton?: PermissionActionsTypes;
  pdfHeaders?: IPdfHeader[];
  excelFileTitle?: string;
  callBack?: any;
  keyword?: string;
  isActive?: boolean;
  customQueries?: any;
  totalCount?: number;
  withExportPdfButton?: boolean;
  withExportExcelButton?: boolean;

  showAddButtonWithPage?: boolean;
  pathPage?: string;

  //drop down
  showDropDownButton?: boolean;
  titleDropDownButton?: string;
  menuItemsDropDown?: Array<MenuItem>;

  // For Indicators Values Page
  // Generate Excel
  generateTitle?: string;
  showGenerateButton?: boolean;
  setGenerateModal?: React.Dispatch<React.SetStateAction<boolean>>;
  // Import File
  importTitle?: string;

  showImportButton?: boolean;
  setImportModal?: React.Dispatch<React.SetStateAction<boolean>>;

  // For Permissions Indicators Values Page
  actionForGenerate?: PermissionActionsTypes;
  actionForImport?: PermissionActionsTypes;
}

const AppFilters: React.FC<IAppFiltersProps> = (props) => {
  const {
    onChangeKeywordValue,
    selectionModel,
    addTitle,
    setShowAddModal,
    onChangeAddButton = () => {},
    showAddButton = true,
    showDailyUploadButton = false,
    handleDailyUploadButtonClick = undefined,
    showDetailsErrorFileButton = false,
    setShowDetailsErrorFile,
    errorFileButtonTile,
    successUrl,
    setSelectionModel,
    showSearchInput = true,
    showMultipleDeactivation = true,
    showMultipleActivation = true,
    showMultipleDeletion = true,
    showCheckAll = false,
    // Second Button
    addSecondTitle,
    setShowAddSecondModal,
    showAddSecondButton = false,
    schemaNameForSecondButton,
    entityNameForSecondButton,
    // For Permissions
    schemaName,
    entityName,
    action,
    actionForSecondButton,

    showAddButtonWithPage = false,
    pathPage,
    //for excel pdf

    filterAll,
    // For Advance Filters
    showAdvanceFiltersButton = false,
    advanceFiltersInputs,
    setAdvancedFilter,
    advancedFilter,
    isOpenAdvanceFilters = false,
    setIsOpenAdvanceFilters,
    pdfHeaders,
    excelFileTitle,
    callBack = null,
    keyword,
    isActive,
    customQueries,
    totalCount = 0,
    withExportPdfButton = false,
    withExportExcelButton = false,
    // For drop down  Page
    showDropDownButton = false,
    titleDropDownButton,
    menuItemsDropDown,
    // Generate Excel
    generateTitle,
    showGenerateButton = false,
    setGenerateModal,
    // Import File
    importTitle,
    showImportButton = false,
    setImportModal,
    actionForGenerate,
    actionForImport,
  } = props;

  const { messages } = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    excelPageData,
    isLoadingExcelPage,
    isLoadingExcelData,
    excelDataErrorMessage,
  } = useSelector<AppState, AppState["exporting"]>((state) => state.exporting);
  const [showExcelModal, setShowExcelModal] = useState<boolean>(false);
  const [showPdfModal, setShowPdfModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const [isExcelClicked, setIsExcelClicked] = useState(false);
  const [isPdfClicked, setIsPdfClicked] = useState(false);
  const [excelPage, setExcelPage] = useState(1);
  const [dataType, setDataType] = useState("excel");

  const getValueByType = (value, key, fullItem) => {
    if (value === null || value === undefined) return null;
    else if (key == "isActive")
      return value
        ? String(messages["columns.active"])
        : String(messages["columns.inactive"]);
    else if (typeof value == "boolean" && key != "isActive")
      return value
        ? String(messages["common.yes"])
        : String(messages["common.No"]);
    else if (typeof value == "string" && value.matchDate()) {
      var xx = moment(value);
      return moment(value).format("DD/MM/YYYY");
    }

    var messagesKey = Object.keys(messages).filter((x) => {
      var split = x.split(".");
      if (
        split[split.length - 1].toLocaleLowerCase() ==
        value?.toString()?.toLocaleLowerCase()
      )
        return x;
    });

    var callBackValue = null as any;

    if (callBack) callBackValue = callBack(value, key, fullItem);

    if ((callBackValue || callBackValue >= 0) && callBack) return callBackValue;

    return (messagesKey.length > 0 ? messages[messagesKey[0]] : value) ?? "-";
  };

  const handleExportPdfData = () => {
    var html = GetPdfTemplate(
      pdfHeaders,
      excelPageData,
      excelFileTitle,
      messages,
      callBack
    );
    // console.log(html);
    var newWindow = window.open();
    newWindow?.document.write(html);
    // setIsExcelClicked(false);
    setIsPdfClicked(false);
    setExcelPage(1);
    dispatch(emptyExcelData());
  };
  const handleExportButtonClick = (type) => {
    if (type === "excel") {
      setIsExcelClicked(true);
    } else {
      setIsPdfClicked(true);
    }
    setDataType(type);
  };

  const handleExportExcelData = () => {
    const keys = Object.keys(excelPageData[0]);
    // console.log('header', header);
    let values = (excelPageData as Array<any>).map((item) => {
      var myObj = {};
      if (pdfHeaders) {
        pdfHeaders.map(({ key }) => {
          var finalValue = "";
          if (key.includes(".")) {
            let value = null as any;
            key.split(".").forEach((element, splitIndex) => {
              if (!value) {
                if (splitIndex == 0) value = item[element];
              } else {
                if (value != "-") value = value[element];
              }
            });
            finalValue = value;
          } else {
            finalValue = item[key];
          }
          return (myObj[key] = getValueByType(finalValue, key, item));
        });
      }
      return myObj;
    });
    // console.log('values', values);
    const workSheet = XLSX.utils.json_to_sheet(values);
    XLSX.utils.sheet_add_aoa(
      workSheet,
      pdfHeaders && [pdfHeaders.map((item) => item.value)]
    );
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workBook,
      workSheet,
      excelFileTitle ?? "Excel Data"
    );
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(
      workBook,
      excelFileTitle ? `${excelFileTitle}.xlsx` : "File Data.xlsx"
    );
    setIsExcelClicked(false);
    setExcelPage(1);
    dispatch(emptyExcelData());
  };

  const fetchExcelData = (isFirst = true, isLast = false) => {
    dispatch(
      getExcelData(
        successUrl.buildUrl(BaseActions.Search, "", false),
        {
          pageNumber: excelPage,
          pageSize: 10,
          keyword: advancedFilter ? null : keyword,
          isActive,
          customQueries: {
            ...customQueries,
            // isLawyer: true,
            advancedFilter: advancedFilter ? advancedFilter : null || null,
          },
        },
        isFirst,
        isLast,
        () => {
          setExcelPage(excelPage + 1);
        }
      )
    );
  };

  useEffect(() => {
    // console.log('excelPageData', excelPageData);
    var isLast = excelPage == Math.ceil(totalCount / 10);
    if ((isExcelClicked || isPdfClicked) && !isLoadingExcelData) {
      fetchExcelData(true, isLast);
    } else if (
      (isExcelClicked || isPdfClicked) &&
      isLoadingExcelData &&
      !isLoadingExcelPage
    ) {
      if (excelPage <= Math.ceil(totalCount / 10)) {
        fetchExcelData(false, isLast);
      }
    }
  }, [isExcelClicked, isPdfClicked, excelPage]);

  useEffect(() => {
    // console.log('isLoadingExcelData', isLoadingExcelData);
    // console.log('excelPageData', excelPageData);
    if (!isLoadingExcelData && (excelPageData as Array<any>).length > 0) {
      if (dataType == "excel") handleExportExcelData();
      else handleExportPdfData();
    }
  }, [excelPageData, isLoadingExcelData]);

  //states
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [showConfirmCheckAllModal, setShowConfirmCheckAllModal] =
    useState<boolean>(false);

  const [operationType, setOperationType] = useState<OperationTypeForSetAll>(
    OperationTypeForSetAll.SetAsActive
  );
  const [dialogTitle, setDialogTitle] = useState<string>("");
  const [title, setTitle] = useState<string>("");

  //functions
  const onSuccess = useCallback(() => {
    setShowConfirmModal(false);
    setShowConfirmCheckAllModal(false);

    setSelectionModel([]);
  }, []);

  const onConfirmMultipleDeletion = useCallback(() => {
    setShowConfirmModal(true);
    setOperationType(OperationTypeForSetAll.SetAsDeleted);
    setDialogTitle("table.options.delete");
    setTitle("table.options.delete.title");
  }, []);

  const onConfirmMultipleCheckAll = useCallback(() => {
    setShowConfirmCheckAllModal(true);
    setOperationType(OperationTypeForSetAll.SetChecked);
    setDialogTitle("table.options.checkAll");
    setTitle("table.options.checkAll.title");
  }, []);

  const onConfirmMultipleActivation = useCallback(() => {
    setShowConfirmModal(true);
    setOperationType(OperationTypeForSetAll.SetAsActive);
    setDialogTitle("table.options.activation");
    setTitle("table.options.activation.title");
  }, []);

  const onConfirmMultipleDeactivation = useCallback(() => {
    setShowConfirmModal(true);
    setOperationType(OperationTypeForSetAll.SetAsInActive);
    setDialogTitle("table.options.deactivation");
    setTitle("table.options.deactivation.title");
  }, []);

  const mutation = useSetAllService<IBaseSetAllDto, number>(
    AppUrls.Shared,
    null,
    BaseActions.SetAll,
    onSuccess
  );

  const mutationForCheckAll = useSetAllService<IBaseSetAllDto, number>(
    AppUrls.Shared,
    null,
    BaseActions.SetAll,
    onSuccess
  );

  // Advance Filters
  const handleSearchWithAdvanceFilter = () => {
    var advanceSearchElements: any = document.getElementsByClassName(
      "support-advance-search"
    );
    let filters = {};
    let fromDate = null;
    let toDate = null;
    let betweenConditionPropertyName = "CreatedOn";
    for (let item of advanceSearchElements) {
      if (item.name == "fromDate" && item.value) {
        fromDate = item.value != "Invalid date" ? item.value : null;
      } else if (item.name == "toDate" && item.value) {
        toDate = item.value != "Invalid date" ? item.value : null;
      } else if (item.name == "fromCreatedOn" && item.value) {
        // toDate = item.value != "Invalid date" ? item.value : null;
        filters[item.name] = item.value != "Invalid date" ? item.value : null;
      } else if (item.name == "toCreatedOn" && item.value) {
        // toDate = item.value != "Invalid date" ? item.value : null;
        filters[item.name] = item.value != "Invalid date" ? item.value : null;
      } else if (item.name == "betweenConditionPropertyName" && item.value) {
        betweenConditionPropertyName = item.value;
      } else if (item.value) {
        filters[item.name] = item.value;
      }
      // console.log(item.name);
    }
    setAdvancedFilter(
      Object.keys(filters).length == 0
        ? fromDate != null || toDate != null
          ? { fromDate, toDate, betweenConditionPropertyName }
          : null
        : { filters, fromDate, toDate, betweenConditionPropertyName }
    );
  };

  return (
    <>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid
                alignItems={"center"}
                spacing={3}
                container
                justifyContent={"space-between"}
              >
                {/* Search Input */}
                {showSearchInput && (
                  <Grid item md={3} sm={4} xs={12}>
                    <TextField
                      label={<IntlMessages id="common.search" />}
                      variant="outlined"
                      size="small"
                      disabled={isOpenAdvanceFilters}
                      onChange={debounce(
                        (event: React.ChangeEvent<HTMLInputElement>) => {
                          onChangeKeywordValue && onChangeKeywordValue(event);
                        },
                        DEBOUNCE_TIME
                      )}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                    />
                  </Grid>
                )}

                <Grid
                  item
                  container
                  spacing={4}
                  justifyContent={"end"}
                  sx={{ width: { xs: "100%", sm: "fit-content" } }}
                >
                  {/* Export Excel Button */}
                  {withExportExcelButton && (
                    <Grid item>
                      <Tooltip title={<IntlMessages id="export" />}>
                        <Zoom in style={{ transitionDelay: "300ms" }}>
                          <Button
                            fullWidth
                            disabled={totalCount == 0 || isExcelClicked}
                            variant="contained"
                            sx={{
                              borderRadius: 8,
                              "& .MuiSvgIcon-root": {
                                fontSize: 26,
                              },
                              width: "fit-content",
                            }}
                            // startIcon={<AddIcon />}
                            onClick={() => {
                              handleExportButtonClick("excel");
                            }}
                          >
                            {isExcelClicked && (
                              <CircularProgress
                                size={20}
                                sx={{ color: "#fff" }}
                              />
                            )}
                            <IntlMessages id="export" />
                          </Button>
                        </Zoom>
                      </Tooltip>
                    </Grid>
                  )}

                  {/* Import File */}
                  {showImportButton && (
                    // <AppPermissionsGate
                    //   schemaName={schemaName}
                    //   entityName={entityName}
                    //   action={actionForImport!}
                    // >
                    <Grid item>
                      <Tooltip title={<IntlMessages id="import" />}>
                        <Zoom in style={{ transitionDelay: "300ms" }}>
                          <Button
                            fullWidth
                            variant="contained"
                            sx={{
                              borderRadius: 8,
                              "& .MuiSvgIcon-root": {
                                fontSize: 26,
                              },
                              width: "fit-content",
                            }}
                            // startIcon={<AddIcon />}
                            onClick={() => {
                              setImportModal && setImportModal(true);
                              // onChangeAddButton && onChangeAddButton();
                            }}
                          >
                            {/* {isExcelClicked && (
                            <CircularProgress size={20} color="primary" />
                          )} */}
                            <IntlMessages id="import" />
                          </Button>
                        </Zoom>
                      </Tooltip>
                    </Grid>

                    // </AppPermissionsGate>
                  )}

                  {/* Add Button */}
                  {showAddButton && (
                    <AppPermissionsGate
                      schemaName={schemaName}
                      entityName={entityName}
                      action={action}
                    >
                      <Grid item>
                        <Zoom in style={{ transitionDelay: "300ms" }}>
                          <Button
                            fullWidth
                            variant="contained"
                            sx={{
                              borderRadius: 8,
                              "& .MuiSvgIcon-root": {
                                fontSize: 26,
                              },
                              width: "fit-content",
                            }}
                            startIcon={<AddIcon />}
                            onClick={() => {
                              setShowAddModal && setShowAddModal(true);
                              onChangeAddButton && onChangeAddButton();
                            }}
                          >
                            <IntlMessages id={addTitle} />
                          </Button>
                        </Zoom>
                      </Grid>
                    </AppPermissionsGate>
                  )}

                  <AppPermissionsGate
                    schemaName={schemaName}
                    entityName={entityName}
                    action={action}
                  >
                    {showAddButtonWithPage && (
                      <Grid item>
                        <Zoom in style={{ transitionDelay: "300ms" }}>
                          <Button
                            fullWidth
                            variant="contained"
                            sx={{
                              borderRadius: 8,
                              "& .MuiSvgIcon-root": {
                                fontSize: 26,
                              },
                              width: "fit-content",
                            }}
                            startIcon={<AddIcon />}
                            onClick={() => pathPage && navigate(pathPage)}
                          >
                            <IntlMessages id={addTitle} />
                          </Button>
                        </Zoom>
                      </Grid>
                    )}
                  </AppPermissionsGate>

                  {showDropDownButton && (
                    <AppDropDownButton
                      schemaName={schemaName}
                      entityName={entityName}
                      action={action}
                      titleDropDownButton={titleDropDownButton!}
                      menuItemsDropDown={menuItemsDropDown!}
                    />
                  )}
                  {/* Second Add Button If Exist */}
                  {showAddSecondButton && (
                    <AppPermissionsGate
                      schemaName={schemaNameForSecondButton || ""}
                      entityName={entityNameForSecondButton || ""}
                      action={
                        actionForSecondButton
                          ? actionForSecondButton
                          : PermissionActionsTypes.FreePermissions
                      }
                    >
                      <Zoom in style={{ transitionDelay: "300ms" }}>
                        <Button
                          variant="contained"
                          sx={{
                            borderRadius: 8,
                            ml: 4,
                            mr: 4,
                            "& .MuiSvgIcon-root": {
                              fontSize: 26,
                            },
                          }}
                          startIcon={<AddIcon />}
                          onClick={() =>
                            setShowAddSecondModal && setShowAddSecondModal(true)
                          }
                        >
                          <IntlMessages id={addSecondTitle} />
                        </Button>
                      </Zoom>
                    </AppPermissionsGate>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {/* Advance Filters Inputs */}
            <Grid item xs={12}>
              <Grid container spacing={3}>
                {showAdvanceFiltersButton &&
                  advanceFiltersInputs &&
                  advanceFiltersInputs.map((item, index) => {
                    if (item) {
                      return (
                        <Grid
                          item
                          md={
                            3
                            // (index + 1) % 4 == 0 &&
                            // index == advanceFiltersInputs?.length - 1
                            //   ? 2
                            //   : 3
                          }
                          key={`advanceFiltersInputs${index}`}
                          xs={12}
                          sm={4}
                        >
                          {item}
                        </Grid>
                      );
                    }
                  })}
              </Grid>
              {/* <SlideDown
                className={"my-dropdown-slidedown"}
                closed={!isOpenAdvanceFilters}
              >
                <Grid container spacing={3} style={{ paddingTop: "25px" }}>
                  {advanceFiltersInputs &&
                    advanceFiltersInputs.map((item, index) => {
                      if (item) {
                        return (
                          <>
                            <Grid
                              item
                              md={
                                3
                                // (index + 1) % 4 == 0 &&
                                // index == advanceFiltersInputs?.length - 1
                                //   ? 2
                                //   : 3
                              }
                              key={index}
                            >
                              {item}
                            </Grid>
                          </>
                        );
                      }
                    })}

                  <Grid item md={1}>
                    <Zoom in style={{ transitionDelay: "300ms" }}>
                      <Fab
                        size="small"
                        color="primary"
                        aria-label="add"
                        onClick={handleSearchWithAdvanceFilter}
                      >
                        <SearchIcon />
                      </Fab>
                    </Zoom>
                  </Grid>
                </Grid>
              </SlideDown> */}
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/**excel */}
      {showExcelModal && (
        <ExportedFile
          showAddModal={showExcelModal}
          setShowAddModal={setShowExcelModal}
          entityToExport={entityName!}
          exportedExtension={FileType.Excel}
          filedIds={selectionModel}
          filter={filterAll}
        />
      )}
    </>
  );
};

export default AppFilters;
