import AppTextField from "@crema/core/AppFormComponents/AppTextField";
import IntlMessages from "@crema/utility/IntlMessages";
import { Grid } from "@mui/material";
import AppCustomizedSwitch from "components/basicComponents/inputs/AppCustomizedSwitch";
import AppAddModal from "components/basicComponents/modals/AppAddModal";
import { Form, Formik, FormikProps } from "formik";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useCreateService } from "services";
import { AppUrls } from "services/appUrls";
import { ICreateIRegionDto } from "types/entitiesTypes/regions";
import * as yup from "yup";

interface IAddRegionProps {
  showAddModal: boolean;
  setShowAddModal: React.Dispatch<React.SetStateAction<boolean>>;
  label: string;
  onSuccess?: () => void;
  onFailed?: () => void;
}

const AddRegion: React.FC<IAddRegionProps> = (props) => {
  const { showAddModal, setShowAddModal, label } = props;
  const { messages } = useIntl();
  const refForm = React.useRef<FormikProps<ICreateIRegionDto>>(null);

  const mutation = useCreateService<ICreateIRegionDto, string>(
    AppUrls.Regions,
    null,
    false
  );

  const validationSchema = yup.object({
    nameAr: yup.string().required(String(messages["validation.required"])),
    nameEn: yup.string().required(String(messages["validation.required"])),
  });

  const submit = () => {
    if (refForm.current) {
      refForm.current.submitForm();
    }
  };

  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);

  return (
    <>
      <AppAddModal
        label={label}
        open={showAddModal}
        onClose={() => setShowAddModal(false)}
        closeModalState={setShowAddModal}
        successClose={mutation.isSuccess}
        handleSubmit={submit}
        loadingForAdd={
          (refForm.current?.isSubmitting && !refForm.current?.isValidating) ||
          false
        }
        maxWidth={"sm"}
      >
        <Formik
          validateOnChange={true}
          initialValues={{
            nameAr: "",
            nameEn: "",
            isActive: true,
          }}
          validationSchema={validationSchema}
          innerRef={refForm}
          onSubmit={async (data, { setSubmitting }) => {
            mutation.submitData(data);
          }}
        >
          {({ setFieldValue }) => (
            <Form>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <AppTextField
                    label={<IntlMessages id="common.nameAr" />}
                    name="nameAr"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <AppTextField
                    label={<IntlMessages id="common.nameEn" />}
                    name="nameEn"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  justifyContent={"flex-start"}
                >
                  <AppCustomizedSwitch
                    keyName="isActive"
                    onChange={setFieldValue}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </AppAddModal>
    </>
  );
};

export default AddRegion;
