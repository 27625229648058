import { BaseActions } from "services/baseActions";
import { ApiVersion, BaseFileUrl } from "services/appUrls";

declare global {
  interface String {
    buildUrl(action?: string, payload?: any, setApiVersion?: boolean): string;
    buildReportUrl(setApiVersion?: boolean): string;

    buildImageUrl(): string;
    matchDate(): boolean;
  }
}

String.prototype.buildUrl = function buildUrl(
  action,
  payload,
  setApiVersion = true
): string {
  const currentUrl = this;
  if (!action)
    return "".concat(
      setApiVersion ? `/${ApiVersion}` : "",
      "/",
      currentUrl as string
    );

  return "".concat(
    setApiVersion ? `/${ApiVersion}` : "",
    "/",
    currentUrl as string,
    action?.replace("{id}", payload)
  );
};

String.prototype.buildReportUrl = function buildReportUrl(
  setApiVersion = true
): string {
  const currentUrl = this;

  return "".concat(
    setApiVersion ? `/${ApiVersion}` : "",
    BaseActions.Reports,
    "/",
    currentUrl as string
  );
};

String.prototype.buildImageUrl = function buildImageUrl(): string {
  const currentPath = this;
  return "".concat(BaseFileUrl as string, "/", currentPath as string);
};

String.prototype.matchDate = function matchDate(): boolean {
  var isoDateRegex =
    /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/g;
  const value = this.match(isoDateRegex) as Array<any>;
  if (value) {
    return value.length > 0;
  } else {
    return false;
  }
};
