export const checkPermission = (routeRole: any, userRole: any) => {
  if (!routeRole || !routeRole) {
    return true;
  }

  if (userRole && Array.isArray(userRole) && !Array.isArray(routeRole)) {
    return userRole.indexOf(routeRole) >= 0;
  }
  if (routeRole.length === 0) {
    return !userRole || userRole.length === 0;
  }
  if (userRole && Array.isArray(userRole) && Array.isArray(routeRole)) {
    return routeRole.some((r) => userRole.indexOf(r) >= 0);
  }
  return routeRole.indexOf(userRole) >= 0;
};

// For check permissions with nested arrays
export const customCheckPermission = (routeRole, userRole) => {
  if (!routeRole || !routeRole) {
    return true;
  }

  if (userRole && Array.isArray(userRole) && !Array.isArray(routeRole)) {
    return userRole.some((x) => x.permissions.includes(routeRole));
  }
  if (routeRole.length === 0) {
    return !userRole || userRole.length === 0;
  }
  if (userRole && Array.isArray(userRole) && Array.isArray(routeRole)) {
    return routeRole.some((r) => userRole.indexOf(r) >= 0);
  }
  return routeRole.indexOf(userRole) >= 0;
};

// For check permissions on collapse menu (SideBar)
export const checkPermissionForCollapsibleMenu = (routeRole, userRole) => {
  if (!routeRole || !routeRole) {
    return true;
  }

  if (userRole && Array.isArray(userRole) && Array.isArray(routeRole)) {
    let ifTrue = false;
    routeRole.forEach((per) => {
      if (!ifTrue && userRole.some((x) => x.permissions.includes(per)))
        ifTrue = true;
    });
    return ifTrue;
  }
  if (routeRole.length === 0) {
    return !userRole || userRole.length === 0;
  }

  return routeRole.indexOf(userRole) >= 0;
};
