import { AppInfoView } from "@crema";
import AppLayout from "@crema/core/AppLayout";
import AppContextProvider from "@crema/utility/AppContextProvider";
import AppLocaleProvider from "@crema/utility/AppLocaleProvider";
import AppStyleProvider from "@crema/utility/AppStyleProvider";
import AppThemeProvider from "@crema/utility/AppThemeProvider";
import AuthRoutes from "@crema/utility/AuthRoutes";
import CssBaseline from "@mui/material/CssBaseline";
import "assets/css/global.css";
import "helpers/extensions";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";

import configureStore from "redux/store";
import JWTAuthAuthProvider from "./@crema/services/auth/jwt-auth/JWTAuthProvider";
import ModalsProvider from "@crema/utility/AppContextProvider/ModalsContextProvider";

export const store = configureStore();

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
    },
  },
});

const App = () => (
  <AppContextProvider>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <AppThemeProvider>
          <AppStyleProvider>
            <AppLocaleProvider>
              <HashRouter>
                <JWTAuthAuthProvider>
                  {/* <FirebaseNotificationContextProvider> */}
                  <AuthRoutes>
                    <ModalsProvider>
                      <CssBaseline />
                      <AppLayout />
                      <AppInfoView />
                    </ModalsProvider>
                  </AuthRoutes>
                  {/* </FirebaseNotificationContextProvider> */}
                </JWTAuthAuthProvider>
              </HashRouter>
            </AppLocaleProvider>
          </AppStyleProvider>
        </AppThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Provider>
  </AppContextProvider>
);

export default App;
