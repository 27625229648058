import {
  IApexReportDto,
  IApexReportXYDto,
  IApexStackedReportDto,
  IListReportDto,
  IStackedReportDto,
} from "./../types/entitiesTypes/reports/index";
import { IReportDto } from "types/entitiesTypes/reports";
import { GridColDef } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/core/styles";
import { ILoggedInUserDto } from "types/entitiesTypes/loggedInUser";
import { Language } from "types/entitiesTypes/enums";
import AWS from "aws-sdk";

export const getStoredLanguage = () => {
  const currentLanguage = localStorage.getItem("lan");
  return currentLanguage ? (currentLanguage as Language) : null;
};

export const getValueBasedOnLang = (arValue: string, enValue: string) => {
  const langObj = JSON.parse(localStorage.getItem("lan") || "{}");
  const currentLang = langObj ? langObj?.locale : "ar";
  if (currentLang === "ar") return `${arValue}`;
  else if (currentLang === "en" && enValue) return `${enValue}`;
  return `${arValue}`;
};

export const removeHTMLTags = (text: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(text ?? "-", "text/html");
  return doc.body.textContent;
};
export const getValueBasedOnLangForConfirmModal = (
  arValue: string,
  enValue: string
) => {
  const langObj = JSON.parse(localStorage.getItem("lan") || "{}");
  const currentLang = langObj ? langObj?.locale : "ar";
  if (currentLang === "ar") return `${arValue}؟`;
  else if (currentLang === "en" && enValue) return `${enValue}?`;
  return `${arValue}?`;
};

export const dash = (value) => {
  if (value) {
    return value;
  }
  return " - ";
};

export const getColumnsToShow = (
  columnsToShow: string[],
  columns: GridColDef[],
  withActions: boolean = true
): GridColDef[] => {
  const temp = withActions ? ["isActive", "actions"] : ["isActive"];
  columns = columns.filter((x) => {
    return columnsToShow?.length > 0
      ? [...columnsToShow, ...temp].includes(x.field)
      : x;
  });

  return columns;
};

export const mapReportModelToApex = (report: IReportDto[]) => {
  let apexDto: IApexReportDto = { labels: [], values: [] };

  if (report == null || undefined) return apexDto;

  report?.forEach((x) => {
    apexDto?.labels.push(` ${x?.label}`);
    apexDto?.values.push(Number(x?.value));
  });

  return apexDto;
};

export const mapReportModelToApexStacked = (
  report: IListReportDto[]
): IApexStackedReportDto => {
  let apexSeriesDto: IStackedReportDto[] = [{ label: "", value: [] }];
  let apexDto: IApexStackedReportDto = { data: apexSeriesDto, categories: [] };

  if (report == null || report === undefined || report.length === 0)
    return apexDto;
  apexSeriesDto = report.map((x) => {
    return {
      label: ` ${x.label} `,
      value: x.value.map((e) => e.value),
    };
  });

  let categories: string[];
  categories = Array.from(
    new Set(report.flatMap((x) => x.value).map((e) => e.label))
  );
  return {
    data: apexSeriesDto,
    categories: categories,
  };
};

export const mapReportXYModelToApex = (
  report: IReportDto[]
): IApexReportXYDto[] => {
  let apexXYDto: IApexReportXYDto[] = [{ x: "", y: 0 }];
  if (report == null || undefined) return apexXYDto;

  apexXYDto = report.map((e) => {
    return {
      x: e.label,
      y: Number(e.value),
    };
  });

  return apexXYDto;
};

export const generateYears = (startYear: number = 2000) => {
  var currentYear = new Date().getFullYear(),
    years: number[] = [];
  // startYear = startYear || 2000;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  return years.reverse();
};

export const useStyles = makeStyles({
  select: {
    "& ul": {
      maxHeight: "350px",
    },
  },
});

//For check if loggedIn user is admin
export const checkIfUserIsAdmin = (user: ILoggedInUserDto): boolean => {
  const isAdmin = user?.roles?.some((role) => {
    return role?.name?.toLocaleLowerCase() === "admin";
  });
  return user?.isAdmin;
};

export const get_url_extension = (url: string): string | undefined => {
  if (url) return url?.split(/[#?]/)[0]?.split(".")?.pop()?.trim();
};

// For Notification Path :

const ENTITY_ID = "relatedEntityId";
const PARENT_ENTITY_ID = "parentRelatedEntityId";

const NAVIGATION_MAP = {
  ProductPricesUpdated: [ENTITY_ID, "/systemConfigurations/products"],

  OrderUpdated: [ENTITY_ID, "/systemConfigurations/purchaseOrders/profile/"],

  NewOrder: [ENTITY_ID, "/systemConfigurations/purchaseRequests/profile/"],

  NewPaitentOrder: [
    ENTITY_ID,
    "/systemConfigurations/patinetOrderRequests/profile/",
  ],

  PaitentOrderInProgress: [
    ENTITY_ID,
    "/systemConfigurations/patinetOrderRequests/profile/",
  ],
  PaitentOrderPartiallyApproved: [
    ENTITY_ID,
    "/systemConfigurations/patinetOrderRequests/profile/",
  ],
  PaitentOrderApproved: [
    ENTITY_ID,
    "/systemConfigurations/patinetOrderRequests/profile/",
  ],
  PaitentOrderAssignedToShipper: [
    ENTITY_ID,
    "/systemConfigurations/patinetOrderRequests/profile/",
  ],
  PaitentOrderShipped: [
    ENTITY_ID,
    "/systemConfigurations/patinetOrderRequests/profile/",
  ],
  PaitentOrderDelivered: [
    ENTITY_ID,
    "/systemConfigurations/patinetOrderRequests/profile/",
  ],
  PaitentOrderCanceled: [
    ENTITY_ID,
    "/systemConfigurations/patinetOrderRequests/profile/",
  ],
  PaitentOrderRejected: [
    ENTITY_ID,
    "/systemConfigurations/patinetOrderRequests/profile/",
  ],
  PaitentOrderUndoCanceledRecord: [
    ENTITY_ID,
    "/systemConfigurations/patinetOrderRequests/profile/",
  ],
};

export const getNotificationsFullInfo = (notificationsList) => {
  const modifiedList = notificationsList.map((item) => {
    const navigationArray = NAVIGATION_MAP[item?.notificationType];
    const navigationPath = navigationArray
      ? NAVIGATION_MAP[item?.notificationType][1]
      : "";

    const navigationEntityId = navigationArray
      ? item[NAVIGATION_MAP[item?.notificationType][0]]
      : "";

    let fullPath = `${navigationPath}${navigationEntityId}`;
    return { ...item, fullPath };
  });

  return modifiedList;
};

export const generatePresignedUrl = (key: string, environ: string) => {
  const s3 = new AWS.S3({
    region: "eu-west-2", // e.g., 'us-east-1'
    accessKeyId: "AKIAQJQULM347PD4L5QR",
    secretAccessKey: "qhNHjO/hV1LVHF+CQQb0EA2fvWx01zcbLM/dfEd9",
  });

  const params = {
    Bucket: process.env.REACT_APP_BACKET,
    Key: key,
    Expires: 86400, // URL expiration time in seconds
  };

  return key ? s3.getSignedUrl("getObject", params) : "";
};
