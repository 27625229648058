import saMessages from "../locales/ar_SA.json";
import siteManagementArMessages from "../locales/siteMangemen_ar_SA.json";
const saLang = {
  messages: {
    ...saMessages,
    ...siteManagementArMessages,
  },
  locale: "ar-SA",
};
export default saLang;
