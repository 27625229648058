import IntlMessages from "@crema/utility/IntlMessages";
import { Box } from "@mui/material";
import * as React from "react";

interface IAppBoxBadgeProps {
  label: string;
  backgroundColor: string;
  transform?: string;
}

const AppBoxBadge: React.FC<IAppBoxBadgeProps> = (props) => {
  const { label, backgroundColor, transform } = props;

  return (
    <Box
      sx={{
        color: "white",
        backgroundColor: backgroundColor,
        padding: "3px 10px",
        borderRadius: 3,
        display: "inline-block",
        margin: "2px",
        transform: transform,
      }}
    >
      <IntlMessages id={label} />
    </Box>
  );
};

export default AppBoxBadge;
