import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Pagination,
  Typography,
} from "@mui/material";
import defaultImage from "assets/appDefaultImages/branch.png";
import { AxiosError } from "axios";
import StatusBadge from "components/basicComponents/badge/StatusBadge";
import AddDataGridActivation from "components/basicComponents/dataGrid/AddDataGridActivation";
import DataItem from "components/basicComponents/mobile/DataItem";
import AppIconButton from "components/basicComponents/others/AppIconButton";
import AppViewImage from "components/basicComponents/others/AppViewImage";
import LoadingAndErrorWrapper from "components/basicComponents/others/LoadingAndErrorWrapper";
import moment from "moment";
import { FC } from "react";
import { useIntl } from "react-intl";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import { INotificationListDto } from "types/entitiesTypes/messageEngine";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { IInstanceNotificationsListDto } from "types/entitiesTypes/instanceNotifications";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DoDisturbOffIcon from "@mui/icons-material/DoDisturbOff";
import { dateTimeComparison } from "helpers/string/dateFormatter";
import { getValueBasedOnLang } from "helpers";

interface INotificationCardProps {
  handleCancelSendNotification: (row: IInstanceNotificationsListDto) => void;
  handleResendNotification: (row: IInstanceNotificationsListDto) => void;
  handleDelete: (row: IInstanceNotificationsListDto) => void;
  handleGetDetails: (row: IInstanceNotificationsListDto) => void;
  NotificationList: Array<IInstanceNotificationsListDto>;
  pageNumber?: number;
  setPage?: (event: React.ChangeEvent<unknown>, page: number) => void;
  page?: number;
  loading: boolean;
  errorMessage: AxiosError<any, any> | null;
}
const NotificationCard: FC<INotificationCardProps> = (props) => {
  const {
    handleCancelSendNotification,
    handleResendNotification,
    handleDelete,
    handleGetDetails,
    NotificationList,
    pageNumber,
    setPage,
    page,
    loading,
    errorMessage,
  } = props;
  const { messages, locale } = useIntl();
  return (
    <Grid container spacing={4}>
      <LoadingAndErrorWrapper
        isLoading={loading}
        errorMessage={errorMessage?.response?.data.messages[0]}
      >
        {NotificationList?.length != undefined &&
        NotificationList?.length >= 1 ? (
          NotificationList?.map((item, index) => (
            <Grid key={`NotificationList${index}`} item xs={12}>
              <Card sx={{ width: "100%" }}>
                <Box
                  marginX={4}
                  marginTop={3}
                  display={"flex"}
                  justifyContent={"flex-end"}
                  gap={3}
                >
                  {/* Details */}
                  <AppIconButton
                    label="table.actions.details"
                    childComp={
                      <NotificationsIcon
                        fontSize="small"
                        sx={{ color: "#007991" }}
                      />
                    }
                    handleClick={async () => {
                      handleGetDetails(item);
                    }}
                    // For Permissions
                    schemaName={SchemaName.SystemConfigurations}
                    entityName={EntityName.Branches}
                    action={PermissionActionsTypes.Details}
                  />
                  {/* resend */}
                  {!item?.canceledNotification && !item?.notifyDate && (
                    <AppIconButton
                      label="table.actions.resend"
                      handleClick={() => {
                        handleResendNotification(item);
                      }}
                      childComp={
                        <RestartAltIcon
                          fontSize="small"
                          sx={{ color: "#a57e10" }}
                        />
                      }
                      // For Permissions
                      schemaName={SchemaName.NotificationConfigurations}
                      entityName={EntityName.InstanceNotifications}
                      action={PermissionActionsTypes.Resend}
                    />
                  )}

                  {/* cancel */}
                  {dateTimeComparison(item?.notifyDate) &&
                    !item?.canceledNotification && (
                      <AppIconButton
                        label="table.actions.cancel"
                        handleClick={() => {
                          handleCancelSendNotification(item);
                        }}
                        childComp={
                          <DoDisturbOffIcon
                            fontSize="small"
                            sx={{ color: "#cb3d3d" }}
                          />
                        }
                        // For Permissions
                        schemaName={SchemaName.NotificationConfigurations}
                        entityName={EntityName.InstanceNotifications}
                        action={PermissionActionsTypes.Cancel}
                      />
                    )}

                  {/* Delete */}
                  {
                    <AppIconButton
                      label="table.actions.delete"
                      handleClick={() => {
                        handleDelete(item);
                      }}
                      childComp={
                        <DeleteOutlinedIcon
                          fontSize="small"
                          sx={{ color: "#D72638" }}
                        />
                      }
                      // For Permissions
                      schemaName={SchemaName.NotificationConfigurations}
                      entityName={EntityName.InstanceNotifications}
                      action={PermissionActionsTypes.Delete}
                    />
                  }
                </Box>
                <CardContent
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: 3,
                    paddingTop: "0 !important",
                  }}
                >
                  <DataItem
                    label={messages["notification.titleAr"] as string}
                    value={item?.titleAr}
                  />

                  <DataItem
                    label={messages["notification.titleEn"] as string}
                    value={item?.titleEn}
                  />

                  <DataItem
                    label={messages["notification.message"] as string}
                    value={getValueBasedOnLang(item.messageAr, item.messageEn)}
                  />
                  <DataItem
                    label={messages["notification.notifyDate"] as string}
                    value={
                      item?.notifyDate
                        ? moment
                            .utc(item?.notifyDate)
                            .local()
                            .format("YYYY-DD-MM HH:mm")
                        : "-"
                    }
                  />
                  <DataItem
                    label={
                      messages["notification.notificationStatus"] as string
                    }
                    value={
                      item?.isRead
                        ? messages["notification.notificationStatus.Read"]
                        : messages["notification.notificationStatus.New"]
                    }
                  />
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            item
            xs={12}
            marginTop={10}
          >
            <Typography variant="h5" component={"span"}>
              {messages["common.dataFound"]}
            </Typography>
          </Grid>
        )}
        {pageNumber! > 1 && (
          <Grid
            display={"flex"}
            alignItems={"cneter"}
            justifyContent={"center"}
            marginTop={5}
            item
            xs={12}
          >
            <Pagination
              count={pageNumber}
              page={page}
              color="primary"
              onChange={(event, page) => {
                setPage && setPage(event, page);
              }}
            />
          </Grid>
        )}
      </LoadingAndErrorWrapper>
    </Grid>
  );
};

export default NotificationCard;
