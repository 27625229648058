import { Grid, Hidden } from "@mui/material";
import { GridSelectionModel } from "@mui/x-data-grid";
import AppBreadcrumb from "components/basicComponents/breadcrumb/AppBreadcrumb";
import AppDataGrid from "components/basicComponents/dataGrid/AppDataGrid";
import AppAdvanceFilterTextField from "components/basicComponents/filters/AppAdvanceFilterTextField";
import AppFilters from "components/basicComponents/filters/AppFilters";
import { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { useGetListService } from "services";
import { AppUrls } from "services/appUrls";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import { IStudentListDto, IStudentSearch } from "types/entitiesTypes/student";
import { InvoicesColumns } from "./InvoicesColumns";
import { useParams } from "react-router-dom";

import {
  IInvoicesListDto,
  IInvoicesSearch,
} from "types/entitiesTypes/invoices";
import InvoiceCard from "./InvoiceCard";
import { IPdfHeader } from "types/appTypes/baseEntity/baseDto";

const InvoicesPage = () => {
  const { messages } = useIntl();
  const { subscriptionIdParams } = useParams();

  const [search, setSearch] = useState<IInvoicesSearch>({
    pageNumber: 1,
    pageSize: 10,
    subscriptionId: subscriptionIdParams || null,
  });
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);

  const [selectedRow, setSelectedRow] = useState<IInvoicesListDto>();
  useState<boolean>(false);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  // For hide breadcrumb in country profile
  // For Advance Filters
  const [advancedFilter, setAdvancedFilter] = useState(null);
  const [isOpenAdvanceFilters, setIsOpenAdvanceFilters] =
    useState<boolean>(false);

  const {
    resultData: invoicesList,
    isFetching,
    error,
  } = useGetListService<IInvoicesListDto>(AppUrls.Invoice, {
    ...search,
    advancedFilter,
  });

  const columns = InvoicesColumns();

  const pdfHeaders: IPdfHeader[] = [
    {
      key: "subscription.student.id",
      value: String(messages["student.id"]),
    },
    {
      key: "subscription.student.fullName",
      value: String(messages["student.name"]),
    },
    {
      key: "subscription.student.phoneNumber",
      value: String(messages["common.phone"]),
    },
    {
      key: "invoiceNumber",
      value: String(messages["invoices.invoiceNumber"]),
    },

    {
      key: "subscription.diploma.nameAr",
      value: String(messages["diploma.nameAr"]),
    },
    {
      key: "subscription.diploma.nameEn",
      value: String(messages["diploma.nameEn"]),
    },

    {
      key: "diplomaCost",
      value: String(messages["invoices.diplomaCost"]),
    },
    {
      key: "totalCost",
      value: String(messages["invoices.totalCost"]),
    },
    {
      key: "issueDate",
      value: String(messages["invoices.issueDate"]),
    },
  ];

  return (
    <>
      <Grid container spacing={3}>
        {!subscriptionIdParams && (
          <AppBreadcrumb
            paths={[{ name: messages["sidebar.managePayments.title"] }]}
            currentPathName={"sidebar.invoices"}
          />
        )}

        <Grid item xs={12}>
          <AppFilters
            //excel pdf
            filterAll={{
              ...search,
              advancedFilter,
            }}
            onChangeKeywordValue={(
              event: React.ChangeEvent<HTMLInputElement>
            ) => {
              setSearch({ ...search, keyword: event.target.value });
            }}
            showSearchInput={false}
            selectionModel={selectionModel as string[]}
            setSelectionModel={setSelectionModel}
            successUrl={AppUrls.Invoice}
            keyword={search?.keyword}
            advancedFilter={advancedFilter}
            showAddButton={false}
            totalCount={invoicesList?.totalCount || 0}
            pdfHeaders={pdfHeaders}
            withExportExcelButton
            customQueries={{
              ...search,
            }}
            excelFileTitle={messages["sidebar.invoices"] as string}
            // For Permissions
            schemaName={SchemaName.SystemConfigurations}
            entityName={EntityName.Countries}
            action={PermissionActionsTypes.Add}
            // Advance Filters
            showAdvanceFiltersButton={false}
            isOpenAdvanceFilters={isOpenAdvanceFilters}
            setIsOpenAdvanceFilters={setIsOpenAdvanceFilters}
            setAdvancedFilter={setAdvancedFilter}
            advanceFiltersInputs={[
              <AppAdvanceFilterTextField
                fieldName="email"
                label="common.email"
                setAdvancedFilter={setAdvancedFilter}
                advancedFilter={advancedFilter}
              />,
            ]}
          />
        </Grid>
        <Hidden mdDown>
          <Grid item xs={12}>
            <AppDataGrid
              rowCount={invoicesList?.totalCount ?? 0}
              pageNumber={search?.pageNumber ?? 0}
              //for pagination
              onPageSizeChange={(pageSize: number) => {
                setSearch({ ...search, pageSize: pageSize });
              }}
              onPageChange={(pageNumber: number) => {
                setSearch({ ...search, pageNumber: pageNumber + 1 });
              }}
              pageSize={search?.pageSize ?? 10}
              columns={columns}
              rows={invoicesList?.data ?? []}
              loading={isFetching}
              //for selection
              onSelectionModelChange={(
                newSelectionModel: GridSelectionModel
              ) => {
                setSelectionModel(newSelectionModel);
              }}
              checkboxSelection={false}
              selectionModel={selectionModel}
              //for sorting
              // onSortModelChange={(result: string[] | null) => {
              //   setSearch({
              //     ...search,
              //     orderBy: result,
              //   });
              // }}
            />
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid item xs={12}>
            <InvoiceCard
              Invoice={invoicesList?.data!}
              setPage={(event, page) => {
                setSearch({
                  ...search,
                  pageNumber: page,
                });
              }}
              pageNumber={Math.ceil((invoicesList?.totalCount || 0) / 10)}
              page={search?.pageNumber}
              loading={isFetching}
              errorMessage={error}
            />
          </Grid>
        </Hidden>
      </Grid>
    </>
  );
};

export default InvoicesPage;
