import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Pagination,
  Typography,
} from "@mui/material";
import defaultImage from "assets/appDefaultImages/branch.png";
import { AxiosError } from "axios";
import StatusBadge from "components/basicComponents/badge/StatusBadge";
import RefundStatusBadge from "components/basicComponents/badge/StatusRefund";
import AddDataGridActivation from "components/basicComponents/dataGrid/AddDataGridActivation";
import AppDataGridCellLink from "components/basicComponents/dataGrid/AppDataGridCellLink";
import DataItem from "components/basicComponents/mobile/DataItem";
import AppIconButton from "components/basicComponents/others/AppIconButton";
import AppViewImage from "components/basicComponents/others/AppViewImage";
import LoadingAndErrorWrapper from "components/basicComponents/others/LoadingAndErrorWrapper";
import { generatePresignedUrl, getValueBasedOnLang } from "helpers";
import { dateTimeFormatter } from "helpers/string/dateFormatter";
import { FC } from "react";
import { useIntl } from "react-intl";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import { IStudentAttachmentsList } from "types/entitiesTypes/studentAttachments";
import { IStudentsSubscriptionListDto } from "types/entitiesTypes/studentSubscription";

interface IVirtualSceneRequestsCardProps {
  handleUpdate: (row: IStudentAttachmentsList) => void;
  virtualSceneRequestsList: Array<IStudentAttachmentsList>;
  pageNumber?: number;
  setPage?: (event: React.ChangeEvent<unknown>, page: number) => void;
  page?: number;
  loading: boolean;
  errorMessage: AxiosError<any, any> | null;
}
const VirtualSceneRequestsCard: FC<IVirtualSceneRequestsCardProps> = (
  props
) => {
  const {
    virtualSceneRequestsList,
    pageNumber,
    setPage,
    page,
    loading,
    errorMessage,
    handleUpdate,
  } = props;
  const { messages, locale } = useIntl();
  return (
    <Grid container spacing={4}>
      <LoadingAndErrorWrapper
        isLoading={loading}
        errorMessage={errorMessage?.response?.data.messages[0]}
      >
        {virtualSceneRequestsList?.length != undefined &&
        virtualSceneRequestsList?.length >= 1 ? (
          virtualSceneRequestsList?.map((item, index) => (
            <Grid key={`virtualSceneRequestsList${index}`} item xs={12}>
              <Card sx={{ width: "100%" }}>
                <Box
                  marginX={4}
                  marginTop={3}
                  display={"flex"}
                  justifyContent={"flex-end"}
                  gap={3}
                >
                  {!item?.documentFileKey && (
                    <AppIconButton
                      label="table.actions.edit"
                      handleClick={() => {
                        handleUpdate(item);
                      }}
                      childComp={
                        <EditOutlinedIcon
                          fontSize="small"
                          sx={{ color: "#A0CCDA" }}
                        />
                      }
                      // For Permissions
                      schemaName={SchemaName.SystemConfigurations}
                      entityName={EntityName.Countries}
                      action={PermissionActionsTypes.Edit}
                    />
                  )}
                </Box>
                <CardContent
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: 3,
                    padding: "20px",
                  }}
                >
                  <DataItem
                    label={messages["student.id"] as string}
                    value={item?.subscription?.student?.id}
                  />
                  <DataItem
                    label={messages["student.fullName"] as string}
                    value={item?.subscription?.student?.fullName}
                  />

                  <DataItem
                    label={messages["common.lastName"] as string}
                    value={item?.subscription?.student?.lastName}
                  />
                  <DataItem
                    label={messages["common.id"] as string}
                    value={item?.subscription?.student?.nationalNumber}
                  />
                  <DataItem
                    label={messages["common.phone"] as string}
                    value={item?.subscription?.student?.phoneNumber}
                  />
                  <DataItem
                    label={messages["diplomas.name"] as string}
                    value={getValueBasedOnLang(
                      item?.subscription?.diploma?.nameAr || "-",
                      item?.subscription?.diploma?.nameEn || "-"
                    )}
                  />
                  <DataItem
                    label={messages["diplomas.startDate"] as string}
                    value={dateTimeFormatter(
                      item?.subscription?.diploma?.startDate
                    )}
                  />
                  <DataItem
                    label={messages["diplomas.durationInHours"] as string}
                    value={item?.subscription?.diploma?.durationInHours}
                  />
                  <DataItem
                    label={messages["student.documentFileKey"] as string}
                    value={
                      item.documentFileKey ? (
                        <AppDataGridCellLink
                          // value={`${BaseFileUrl}${item?.documentFileKey}`}
                          value={generatePresignedUrl(
                            item?.documentFileKey,
                            process.env.NODE_ENV
                          )}
                          label="session.attachments.actions.open"
                        />
                      ) : (
                        "-"
                      )
                    }
                  />
                  <DataItem
                    label={messages["diplomas.status"] as string}
                    value={
                      <RefundStatusBadge
                        refundStatusProps={
                          item?.documentFileKey ? "Approved" : "PendingApproval"
                        }
                        label={
                          item.documentFileKey
                            ? "ticketing.currentStatus.approved"
                            : "student.subscriptionStatus.Pending"
                        }
                      />
                    }
                  />
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            item
            xs={12}
            marginTop={10}
          >
            <Typography variant="h5" component={"span"}>
              {messages["common.dataFound"]}
            </Typography>
          </Grid>
        )}
        {pageNumber! > 1 && (
          <Grid
            display={"flex"}
            alignItems={"cneter"}
            justifyContent={"center"}
            marginTop={5}
            item
            xs={12}
          >
            <Pagination
              count={pageNumber}
              page={page}
              color="primary"
              onChange={(event, page) => {
                setPage && setPage(event, page);
              }}
            />
          </Grid>
        )}
      </LoadingAndErrorWrapper>
    </Grid>
  );
};

export default VirtualSceneRequestsCard;
