import AppTextField from "@crema/core/AppFormComponents/AppTextField";
import IntlMessages from "@crema/utility/IntlMessages";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import AppEditModal from "components/basicComponents/modals/AppEditModal";
import { FieldArray, Form, Formik, FormikProps } from "formik";
import { getValueBasedOnLang } from "helpers";
import React from "react";
import { useIntl } from "react-intl";
import { useGetByIdService, useUpdateService } from "services";
import { AppUrls } from "services/appUrls";
import { ISelectedRow } from "types/appTypes/baseEntity/baseDto";
import {
  IExamQuestionDetailsDto,
  IUpdateExamQuestionDto,
} from "types/entitiesTypes/examQuestions";

import AddCircleSharpIcon from "@mui/icons-material/AddCircleSharp";
import DeleteForeverSharpIcon from "@mui/icons-material/DeleteForeverSharp";

import * as yup from "yup";

interface IEditExamQuestionProps {
  showEditModal: boolean;
  setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  label: string;
  selectedRow: ISelectedRow;
  onSuccess?: () => void;
  onFailed?: () => void;
}
const EditExamQuestion: React.FC<IEditExamQuestionProps> = (props) => {
  const { showEditModal, setShowEditModal, label, selectedRow } = props;
  const { messages } = useIntl();
  const refForm = React.useRef<FormikProps<IUpdateExamQuestionDto>>(null);
  const {
    data: examQuestionItem,
    error,
    isLoading,
  } = useGetByIdService<IExamQuestionDetailsDto>(
    AppUrls.ExamQuestions,
    selectedRow?.id
  );

  const mutation = useUpdateService<IUpdateExamQuestionDto, string>(
    AppUrls.ExamQuestions,
    () => {},
    false
  );
  const validationSchema = yup.object({
    textEn: yup.string().required(String(messages["validation.required"])),
    textAr: yup.string().required(String(messages["validation.required"])),
    type: yup.string().required(String(messages["validation.required"])),
    options: yup.array().of(
      yup.object().shape({
        textAr: yup.string().required(String(messages["validation.required"])),
        textEn: yup.string().required(String(messages["validation.required"])),
      })
    ),
  });
  const submit = () => {
    if (refForm.current) {
      refForm.current.submitForm();
    }
  };

  return (
    <>
      <AppEditModal
        label={label}
        itemName={getValueBasedOnLang(
          ` - ${selectedRow?.nameAr}`,
          selectedRow?.nameEn ? ` - ${selectedRow?.nameEn}` : ""
        )}
        open={showEditModal}
        onClose={() => {
          setShowEditModal(false);
        }}
        handleSubmit={submit}
        loading={isLoading}
        errorMessage={(error as any) || null}
        loadingForEdit={
          (refForm.current?.isSubmitting && !refForm.current?.isValidating) ||
          false
        }
        closeModalState={setShowEditModal}
        successClose={mutation.isSuccess}
        maxWidth={"md"}
      >
        <Formik
          validateOnChange={true}
          initialValues={{
            id: selectedRow?.id || "",
            textAr: examQuestionItem?.textAr || "",
            textEn: examQuestionItem?.textEn || "",
            type: examQuestionItem?.type || 2,
            options: examQuestionItem?.options || [
              {
                isCorrect: false,
                textAr: "",
                textEn: "",
              },
            ],
          }}
          innerRef={refForm}
          validationSchema={validationSchema}
          onSubmit={async (data, { setSubmitting }) => {
            mutation.submitData(data);
          }}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={4}>
                  <AppTextField
                    label={<IntlMessages id="exams.question.textAr" />}
                    name="textAr"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <AppTextField
                    label={<IntlMessages id="exams.question.textEn" />}
                    name="textEn"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl sx={{ minWidth: 120 }} size="small" fullWidth>
                    <InputLabel id="demo-select-small">
                      <IntlMessages id="exam.questions.type" />
                    </InputLabel>
                    <Select
                      label={<IntlMessages id="exam.questions.type" />}
                      onChange={(e) => {
                        setFieldValue(`type`, e.target.value);

                        setFieldValue(`options`, [
                          {
                            isCorrect: false,
                            textAr: "",
                            textEn: "",
                          },
                        ]);
                      }}
                      defaultValue={values?.type}
                    >
                      <MenuItem value={2}>
                        <IntlMessages id="exam.questions.type.single" />
                      </MenuItem>

                      <MenuItem value={3}>
                        <IntlMessages id="exam.questions.type.multi" />
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item container rowSpacing={2}>
                  <FieldArray
                    name={`options`}
                    render={(arrayHelpers) => (
                      <>
                        {values?.options?.map((item, indexOption) => (
                          <Grid item container spacing={3}>
                            <Grid item xs={12} sm={3}>
                              <AppTextField
                                label={
                                  <IntlMessages id="exam.questions.option.textAr" />
                                }
                                name={`options[${indexOption}].textAr`}
                                variant="outlined"
                                fullWidth
                                size="small"
                              />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <AppTextField
                                label={
                                  <IntlMessages id="exam.questions.option.textEn" />
                                }
                                name={`options[${indexOption}].textEn`}
                                variant="outlined"
                                size="small"
                                fullWidth
                              />
                            </Grid>

                            <Grid item xs={12} sm={3} sx={{ ml: 1 }}>
                              <FormControlLabel
                                name={`options[${indexOption}].isCorrect`}
                                control={
                                  values?.type === 2 ? (
                                    <span
                                      className="MuiCheckbox-root MuiCheckbox-colorPrimary MuiButtonBase-root MuiCheckbox-root MuiCheckbox-colorPrimary PrivateSwitchBase-root css-1mf11o-MuiButtonBase-root-MuiCheckbox-root"
                                      style={{ padding: 15 }}
                                    >
                                      <input
                                        className="PrivateSwitchBase-input"
                                        type="radio"
                                        name="test"
                                        id={`options[${indexOption}].isCorrect`}
                                        value={`options[${indexOption}].isCorrect`}
                                        defaultChecked={
                                          values?.options![indexOption]
                                            ?.isCorrect
                                        }
                                        onChange={(e) => {
                                          values?.options?.map(
                                            (item, itemIndex) => {
                                              if (itemIndex !== indexOption) {
                                                console.log(`false`, "false");

                                                return setFieldValue(
                                                  `options[${itemIndex}].isCorrect`,
                                                  false
                                                );
                                              } else if (
                                                itemIndex === indexOption
                                              ) {
                                                console.log(`true`, "true");
                                                setFieldValue(
                                                  `options[${indexOption}].isCorrect`,
                                                  true
                                                );
                                              }
                                            }
                                          );
                                        }}
                                      />
                                    </span>
                                  ) : (
                                    <Checkbox
                                      name={`options[${indexOption}].isCorrect`}
                                      defaultChecked={
                                        values?.options![indexOption]?.isCorrect
                                      }
                                      onChange={(e) => {
                                        setFieldValue(
                                          `options[${indexOption}].isCorrect`,
                                          e.target.checked
                                        );
                                      }}
                                    />
                                  )
                                }
                                label={
                                  <IntlMessages id="exam.questions.options.isCorrect" />
                                }
                              />
                            </Grid>
                            <Grid item xs={1.5} sm={1}>
                              <IconButton
                                aria-label="delete"
                                color="error"
                                disabled={values.options!.length === 1}
                                onClick={() => arrayHelpers.remove(indexOption)}
                              >
                                <DeleteForeverSharpIcon />
                              </IconButton>
                            </Grid>
                            {indexOption === values?.options?.length! - 1 ? (
                              <Grid item xs={1.5} sm={1}>
                                <IconButton
                                  aria-label="delete"
                                  color="success"
                                  onClick={() =>
                                    arrayHelpers.push({
                                      isCorrect: false,
                                      textAr: "",
                                      textEn: "",
                                    })
                                  }
                                >
                                  <AddCircleSharpIcon />
                                </IconButton>
                              </Grid>
                            ) : (
                              ""
                            )}
                          </Grid>
                        ))}
                      </>
                    )}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </AppEditModal>
    </>
  );
};

export default EditExamQuestion;
