import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

import { GridColDef } from "@mui/x-data-grid";
import AppDataGridCell from "components/basicComponents/dataGrid/AppDataGridCell";
import AppDataGridCellLink from "components/basicComponents/dataGrid/AppDataGridCellLink";
import AppIconButton from "components/basicComponents/others/AppIconButton";
import { generatePresignedUrl, getValueBasedOnLang } from "helpers";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import { IStudentAttachmentsList } from "types/entitiesTypes/studentAttachments";
export const enumTypeAttachments = (value) => {
  if (value == 1) return "virtualFile";
  else if (value == 2) return "academicRecord";
  else if (value == 3) return "statement";
  return "certificate";
};

export const StudentAttachmentsColumns = (
  handleDelete = (row: IStudentAttachmentsList) => {}
): GridColDef[] => {
  const { messages, locale } = useIntl();
  const { studentIdParams, diplomaIdParams } = useParams();

  const StudentAttachmentsColumns: GridColDef[] = [
    {
      field: "subscription.student.id",
      headerName: messages["student.id"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      minWidth: 200,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => (
        <AppDataGridCell value={params?.row?.subscription?.student?.id} />
      ),
    },
    {
      field: "subscription.student.fullName",
      headerName: messages["student.name"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      sortable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell value={params?.row?.subscription?.student?.fullName} />
      ),
    },
    {
      field: "subscription.student.lastName",
      headerName: messages["student.lastName"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      sortable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell value={params?.row?.subscription?.student?.lastName} />
      ),
    },

    {
      field: "subscription.student.phoneNumber",
      headerName: messages["common.phone"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      sortable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell
          value={params?.row?.subscription?.student?.phoneNumber}
        />
      ),
    },
    {
      field:
        locale === "ar-SA"
          ? "subscription.diploma.nameAr"
          : "subscription.diploma.nameEn",
      headerName: messages["diplomas.name"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      sortable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell
          value={getValueBasedOnLang(
            params.row?.subscription?.diploma?.nameAr ?? "-",
            params.row?.subscription?.diploma?.nameEn ?? "-"
          )}
        />
      ),
    },
    {
      field: "certificateNumber",
      headerName: messages["student.certificateNumber"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell value={params.row?.certificateNumber} />
      ),
    },
    {
      field: "type",
      headerName: messages["student.typeDocument"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell
          value={String(
            messages[
              `student.attachment.${enumTypeAttachments(params.row?.type)}`
            ]
          )}
        />
      ),
    },

    {
      field: "documentFileKey",
      headerName: messages["student.documentFileKey"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      sortable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCellLink
          value={
            params?.row?.documentFileKey
              ? generatePresignedUrl(
                  params?.row?.documentFileKey,
                  process.env.NODE_ENV
                )
              : ""
          }
          label="session.attachments.actions.open"
        />
      ),
    },
    {
      field: "actions",
      headerName: messages["common.actions"] as string,
      sortable: false,
      align: "center",
      filterable: false,
      headerAlign: "center",
      flex: 0.1,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div>
            {/* Delete */}
            <AppIconButton
              label="table.actions.delete"
              handleClick={() => {
                handleDelete(params.row);
              }}
              childComp={
                <DeleteOutlinedIcon
                  fontSize="small"
                  sx={{ color: "#D72638" }}
                />
              }
              // For Permissions
              schemaName={SchemaName.SystemConfigurations}
              entityName={EntityName.Countries}
              action={PermissionActionsTypes.Delete}
            />
          </div>
        );
      },
    },
  ];

  return StudentAttachmentsColumns;
};
