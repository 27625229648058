import IntlMessages from "@crema/utility/IntlMessages";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import AppButton from "components/basicComponents/buttons/AppButton";
import * as React from "react";
import LoadingAndErrorWrapper from "components/basicComponents/others/LoadingAndErrorWrapper";
import { makeStyles } from "@mui/styles";

interface IAppEditModalProps {
  label: string;
  open: boolean;
  onClose: () => void;
  loadingForEdit: boolean;
  loading: boolean;
  errorMessage: string;
  itemName: string;
  maxWidth?: any;
  handleSubmit: () => void;
  closeModalState: (isOpen: boolean) => void;
  successClose: boolean;
  maxWidthAsPixel?: number;
}

const AppEditModal: React.FC<IAppEditModalProps> = (props) => {
  const {
    maxWidthAsPixel,
    label,
    open,
    onClose,
    loadingForEdit,
    loading,
    errorMessage,
    maxWidth = !maxWidthAsPixel ? "md" : undefined,
    handleSubmit,
    children,
    itemName,
    closeModalState,
    successClose,
  } = props;

  React.useEffect(() => {
    if (successClose) closeModalState(false);
  }, [successClose]);

  const useDialogStyles = makeStyles({
    paper: {
      minWidth: `${maxWidthAsPixel}px`,
      zIndex: "9999 !important",
    },
  });
  const dialogClasses = useDialogStyles();

  return (
    <Dialog fullWidth classes={dialogClasses} maxWidth={maxWidth} open={open}>
      <DialogTitle>
        <Typography color="primary" variant="h3">
          <IntlMessages id={label} />
          <span style={{ fontWeight: "bold", textTransform: "capitalize" }}>
            {itemName}
          </span>
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <LoadingAndErrorWrapper errorMessage={errorMessage} isLoading={loading}>
          {children}
        </LoadingAndErrorWrapper>
      </DialogContent>
      <DialogActions>
        <AppButton label="common.cancel" color="warning" onClick={onClose} />
        <AppButton
          label="common.save"
          loading={loadingForEdit}
          onClick={handleSubmit}
        />
      </DialogActions>
    </Dialog>
  );
};

export default AppEditModal;
