import React from "react";
import { Chip } from "@mui/material";
import { useIntl } from "react-intl";
import { attendanceStatus } from "shared/constants/AppEnums";

interface IStatusAttendanceProps {
  status?: number;
  transform?: string;
}

const StatusAttendance: React.FC<IStatusAttendanceProps> = (props) => {
  const { messages } = useIntl();
  const { status, transform } = props;

  return (
    <>
      {status == 1 ? (
        <Chip
          size="small"
          label={
            messages[`session.attendance.status.${attendanceStatus.present}`]
          }
          color="success"
          variant="filled"
          sx={{ color: "#fff", transform: transform }}
        />
      ) : (
        <Chip
          size="small"
          label={
            messages[`session.attendance.status.${attendanceStatus.absent}`]
          }
          color="error"
          variant="filled"
          sx={{ transform: transform }}
        />
      )}
    </>
  );
};

export default StatusAttendance;
