import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Typography } from "@mui/material";
import React, { FC } from "react";
import { useIntl } from "react-intl";
interface DataItem {
  value: any;
  label: any;
  column?: boolean;
  marginY?: number;
}
const DataItem: FC<DataItem> = (props) => {
  const { value, label, column = false, marginY } = props;
  const { messages } = useIntl();
  return (
    <Box
      width={"100%"}
      gap={2}
      display={"flex"}
      alignItems={"start"}
      justifyContent={"start"}
      flexWrap={"wrap"}
      flexDirection={column ? "column" : "row"}
      marginY={marginY}
    >
      <Typography
        sx={{ fontWeight: "bolder" }}
        variant={"h5"}
      >{`${label} : `}</Typography>
      <Typography variant={"h5"}>{value ? value : "-"}</Typography>
    </Box>
  );
};

export default DataItem;
