import moment from "moment";

export const dateFormatter = (strDate: string) => {
  if (strDate) {
    const date = moment(strDate).local().format("DD/MM/yyyy");
    return date;
  }
  return "-";
};

export const timeFormatter = (strDate: string) => {
  if (strDate) {
    const date = moment(strDate).local().format("HH:mm A");
    return date;
  }
  return "-";
};

export const dateTimeFormatter = (strDate: string) => {
  if (strDate) {
    const date = moment.utc(strDate).local().format("DD/MM/yyyy HH:mm A");
    return date;
  }
  return "-";
};

export const dateTimeComparison = (strDate: string) => {
  console.log(
    `moment(moment().utc().local().format("DD/MM/yyyy"))`,
    moment(moment().utc().local().format("DD/MM/yyyy"))
  );
  console.log(
    'moment(moment.utc(strDate).local().format("DD/MM/yyyy"))',
    moment(moment.utc(strDate).local().format("DD/MM/yyyy"))
  );

  console.log(
    `isSameOrAfter`,
    moment.utc(strDate).local().format("DD/MM/yyyy") >=
      moment().utc().local().format("DD/MM/yyyy")
  );

  if (moment(strDate).isAfter(moment(), "dates")) {
    return true;
  }
  return false;
};
