import React, { ReactNode, useEffect } from "react";
import { AppLoader } from "../index";
import PropTypes from "prop-types";
import { useAuthUser } from "./AuthHooks";
import { useDispatch } from "react-redux";
import { getNotificationsList } from "redux/actions/notifications";
import { useJWTAuth } from "@crema/services/auth/jwt-auth/JWTAuthProvider";

interface AuthRoutesProps {
  children: ReactNode;
}

const AuthRoutes: React.FC<AuthRoutesProps> = ({ children }) => {
  const { isLoading } = useAuthUser();
  const { isAuthenticated } = useJWTAuth();

  const dispatch = useDispatch();

  return isLoading ? <AppLoader /> : <>{children}</>;
};

export default AuthRoutes;

AuthRoutes.propTypes = {
  children: PropTypes.node.isRequired,
};
