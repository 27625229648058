import { customCheckPermission } from "@crema/utility/helper/RouteHelper";
import IntlMessages from "@crema/utility/IntlMessages";
import HomeIcon from "@mui/icons-material/Home";
import { Grid, Typography } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { getEntityPermissionCode } from "helpers/permessions";
import * as React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppState } from "redux/store";
import { EntityName, initialUrl, SchemaName } from "shared/constants/AppConst";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";

interface IAppBreadcrumbProps {
  paths?: any[];
  currentPathName: string;
  isShow?: boolean;
  withStatus?: boolean;
  StatusComponent?: React.ReactNode;
}

const AppBreadcrumb: React.FC<IAppBreadcrumbProps> = (props) => {
  const {
    paths,
    currentPathName,
    isShow = true,
    withStatus = false,
    StatusComponent,
  } = props;
  const navigate = useNavigate();
  const { messages } = useIntl();

  const { personalPermissionsList } = useSelector<
    AppState,
    AppState["personal"]
  >((state) => state.personal);

  //check Permission Complete Task
  const hasPermissionDashboard = React.useMemo(
    () =>
      customCheckPermission(
        getEntityPermissionCode(
          SchemaName.SystemConfigurations,
          EntityName.Dashboard,
          PermissionActionsTypes.List
        ),
        personalPermissionsList
      ),
    [personalPermissionsList]
  );

  return (
    <>
      {isShow && (
        <Grid container justifyContent={"space-between"}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {hasPermissionDashboard ? (
              <Link
                underline="hover"
                color="#004A92"
                href=""
                sx={{ display: "flex", alignItems: "center" }}
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault();
                  navigate("/adminDashboard");
                }}
              >
                <HomeIcon sx={{ mr: 0.5 }} fontSize="small" />
                <IntlMessages id="sidebar.dashboard" />
              </Link>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "#004A92",
                }}
              >
                <HomeIcon sx={{ mr: 0.5 }} fontSize="small" />
                <IntlMessages id="sidebar.dashboard" />
              </div>
            )}

            {paths?.map((item, index) => (
              <Link
                key={index}
                underline={item.path ? "hover" : "none"}
                color="#004A92"
                href={`/#${item.path}` || "#"}
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault();
                  if (item.path) navigate(item.path);
                }}
                sx={{ cursor: item.path ? "pointer" : "default" }}
              >
                {item.name}
              </Link>
            ))}
            {currentPathName ? (
              <Typography color="#004A92" fontWeight={"600"}>
                {messages[currentPathName]}
              </Typography>
            ) : null}
          </Breadcrumbs>
          <Grid item>{withStatus ? <>{StatusComponent}</> : null}</Grid>
        </Grid>
      )}
    </>
  );
};

export default AppBreadcrumb;
