import AppTextField from "@crema/core/AppFormComponents/AppTextField";
import IntlMessages from "@crema/utility/IntlMessages";
import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import moment from "moment";
import * as React from "react";

interface IAppDatePickerProps {
  label: string;
  value: string;
  onChange: (field: string, value: any) => void;
  name: string;
  maxDate?: Date;
  disabled?: boolean;
}

const AppDatePicker: React.FC<IAppDatePickerProps> = (props) => {
  const { label, value, onChange, name, maxDate, disabled = false } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={<IntlMessages id={label} />}
        inputFormat="dd/MM/yyyy"
        value={value}
        onChange={(value: any) =>
          onChange(
            name,
            value && value !== "Invalid date"
              ? moment(value).format("YYYY-MM-DD")
              : ""
          )
        }
        disabled={disabled}
        // moment(value).format("YYYY-MM-DD")
        // onChange={(value: any) => onChange(name, value?.toDateString())}
        PopperProps={{
          placement: "left",
        }}
        maxDate={maxDate}
        // PaperProps={{
        //   translate: "yes",
        // }}
        renderInput={(params: any) => (
          <AppTextField
            fullWidth
            name={name}
            size="small"
            autoComplete="off"
            params={params}
            disabled={disabled}
            {...params}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default AppDatePicker;
