import AppTextField from "@crema/core/AppFormComponents/AppTextField";
import IntlMessages from "@crema/utility/IntlMessages";
import * as React from "react";

interface IAppTextAreaFieldProps {
  label: string;
  name: string;
  rows?: number;
}

const AppTextAreaField: React.FC<IAppTextAreaFieldProps> = (props) => {
  const { label, name, rows = 3 } = props;

  return (
    <AppTextField
      label={<IntlMessages id={label} />}
      name={name}
      variant="outlined"
      size="small"
      fullWidth
      multiline
      rows={rows}
    />
  );
};

export default AppTextAreaField;
