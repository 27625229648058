import { Grid } from "@mui/material";
import AppBaseAutoComplete from "components/basicComponents/inputs/autoComplete/AppBaseAutoComplete";
import AppAddModal from "components/basicComponents/modals/AppAddModal";
import { Form, Formik, FormikProps } from "formik";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useAutocompleteService } from "services";
import { useCustomCreateService } from "services/additionalServices";
import { AppUrls } from "services/appUrls";
import { DEBOUNCE_TIME } from "shared/constants/AppConst";
import { ISelectedRow } from "types/appTypes/baseEntity/baseDto";
import { IAssignUserToRoleDto, IUserListDto } from "types/entitiesTypes/users";
import * as yup from "yup";

interface IAddRoleToUserProps {
  showAssignRoleModal: boolean;
  setShowAssignRoleModal: React.Dispatch<React.SetStateAction<boolean>>;
  label: string;
  selectedRow: ISelectedRow;
  onSuccess?: () => void;
  onFailed?: () => void;
}

const AddRoleToUser: React.FC<IAddRoleToUserProps> = (props) => {
  const { showAssignRoleModal, setShowAssignRoleModal, label, selectedRow } =
    props;
  const { messages, locale } = useIntl();

  const refForm = React.useRef<FormikProps<IAssignUserToRoleDto>>(null);
  const [keywordForUserSearch, setKeywordForUserSearch] = useState<string>("");
  const [usersPageNumber, setUsersPageNumber] = useState(1);

  //Get Users
  const {
    data: usersList,
    isFetching,
    refetch: refetchUsers,
  } = useAutocompleteService<IUserListDto>(AppUrls.Users, {
    baseColumnsKeyword: keywordForUserSearch,
    isActive: true,
  });

  const mutation = useCustomCreateService<IAssignUserToRoleDto, string>(
    AppUrls.AssignUserToRole,
    "users-list",
    "createSuccess",
    null,
    false
  );

  const validationSchema = yup.object({
    userId: yup.string().required(String(messages["validation.required"])),
  });
  useEffect(() => {
    refetchUsers();
  }, [usersPageNumber]);
  const submit = () => {
    if (refForm.current) {
      refForm.current.submitForm();
    }
  };

  return (
    <>
      <AppAddModal
        label={label}
        open={showAssignRoleModal}
        onClose={() => setShowAssignRoleModal(false)}
        closeModalState={setShowAssignRoleModal}
        successClose={mutation.isSuccess}
        handleSubmit={submit}
        loadingForAdd={
          (refForm.current?.isSubmitting && !refForm.current?.isValidating) ||
          false
        }
        maxWidth={"xs"}
      >
        <Formik
          validateOnChange={true}
          initialValues={{
            roleId: selectedRow?.id || "",
            userId: "",
          }}
          validationSchema={validationSchema}
          innerRef={refForm}
          onSubmit={async (data, { setSubmitting }) => {
            mutation.submitData(data);
          }}
        >
          {({ setFieldValue, values, errors, touched }) => (
            <Form>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <AppBaseAutoComplete
                    label="users.userName"
                    options={usersList?.data}
                    isLoading={isFetching}
                    onChangeAutoComplete={(event, value) => {
                      setFieldValue("userId", value ? value?.id : "");
                      setUsersPageNumber(1);
                      value && setKeywordForUserSearch("");
                    }}
                    searchText={keywordForUserSearch}
                    onChangeTextField={debounce((event) => {
                      setUsersPageNumber(1);
                      setKeywordForUserSearch(event.target.value);
                    }, DEBOUNCE_TIME)}
                    error={errors.userId}
                    touched={touched.userId}
                    //pagination
                    pageNumber={usersPageNumber}
                    setPageNumber={setUsersPageNumber}
                    hasNextPage={usersList?.hasNextPage}
                    filterKey={locale == "en-US" ? "fullNameEn" : "fullNameAr"}
                    getOptionLabel={(option) => {
                      if (locale == "en-US" && option?.fullNameEn) {
                        return option?.fullNameEn;
                      } else return option?.fullNameAr;
                    }}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </AppAddModal>
    </>
  );
};

export default AddRoleToUser;
