import AppTextField from "@crema/core/AppFormComponents/AppTextField";
import IntlMessages from "@crema/utility/IntlMessages";
import { Grid } from "@mui/material";
import AppEditModal from "components/basicComponents/modals/AppEditModal";
import { Form, Formik, FormikProps } from "formik";
import React from "react";
import { useIntl } from "react-intl";
import { useGetByIdService } from "services";
import { useCustomUpdateService } from "services/additionalServices";
import { AppUrls } from "services/appUrls";
import { ISelectedRow } from "types/appTypes/baseEntity/baseDto";

import {
  IStudentDetailsDto,
  IUpdateStudentDto,
} from "types/entitiesTypes/student";
import * as yup from "yup";

interface IEditAcademicNumberProps {
  showEditModal: boolean;
  setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  label: string;
  selectedRow: ISelectedRow;
  onSuccess?: () => void;
  onFailed?: () => void;
}
const EditAcademicNumber: React.FC<IEditAcademicNumberProps> = (props) => {
  const { showEditModal, setShowEditModal, label, selectedRow } = props;
  const { messages } = useIntl();

  const refForm = React.useRef<FormikProps<IUpdateStudentDto>>(null);
  const {
    data: studentItem,
    error,
    isLoading,
  } = useGetByIdService<IStudentDetailsDto>(AppUrls.Student, selectedRow?.id);

  const mutation = useCustomUpdateService<IUpdateStudentDto, string>(
    AppUrls.studentAcademicNumber,
    AppUrls.Student,
    "updateSuccess",
    false,
    "list"
  );

  const validationSchema = yup.object({
    academicNumber: yup
      .string()
      .required(String(messages["validation.required"])),
  });

  const submit = () => {
    if (refForm.current) {
      refForm.current.submitForm();
    }
  };

  return (
    <>
      <AppEditModal
        label={label}
        itemName={` - ${selectedRow.nameAr}`}
        open={showEditModal}
        onClose={() => {
          setShowEditModal(false);
        }}
        handleSubmit={submit}
        loading={isLoading}
        errorMessage={(error as any) || null}
        loadingForEdit={
          (refForm.current?.isSubmitting && !refForm.current?.isValidating) ||
          false
        }
        closeModalState={setShowEditModal}
        successClose={mutation.isSuccess}
        maxWidth={"sm"}
      >
        <Formik
          validateOnChange={true}
          initialValues={{
            id: selectedRow?.id || "",
            academicNumber: studentItem?.academicNumber || "",
          }}
          innerRef={refForm}
          validationSchema={validationSchema}
          onSubmit={async (data, { setSubmitting }) => {
            mutation.submitData(data);
          }}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={4}>
                  <AppTextField
                    label={<IntlMessages id="student.academicNumber" />}
                    name="academicNumber"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </AppEditModal>
    </>
  );
};

export default EditAcademicNumber;
