import DvrIcon from "@mui/icons-material/Dvr";
import PaidIcon from "@mui/icons-material/Paid";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import LoadingAndErrorWrapper from "components/basicComponents/others/LoadingAndErrorWrapper";
import AppProfile, {
  IProfileComponents,
  IProfileRoutes,
} from "components/basicComponents/profile/AppProfile";
import DiplomasDetails from "components/scientificContentManagement/diplomas/DiplomasDetails";
import { getValueBasedOnLang } from "helpers/index";
import CoursesPages from "pages/trainStructure/courses/CoursesPages";
import StudentPages from "pages/trainStructure/student/StudentPages";
import StudentsSubscriptionPage from "pages/trainStructure/studentsSubscription/StudentsSubscriptionPage";
import { HiOutlineInformationCircle } from "react-icons/hi";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useGetByIdService } from "services";
import { AppUrls } from "services/appUrls";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { IDiplomasDetailsDto } from "types/entitiesTypes/diplomas";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import DiplomaInstallmentPages from "./diplomaInstallment/DiplomaInstallmentPages";
const DiplomaProfile: React.FC = () => {
  const { messages } = useIntl();
  const { diplomaIdParams } = useParams();
  const {
    data: diplomasItem,
    error,
    isLoading,
  } = useGetByIdService<IDiplomasDetailsDto>(
    AppUrls.Diplomas,
    diplomaIdParams || ""
  );
  const sidebarRoutes: IProfileRoutes[] = [
    {
      path: "/",
      routeComponent: <DiplomasDetails diplomasItem={diplomasItem!} />,
    },
    // {
    //   path: "/student",
    //   routeComponent: <StudentPages />,
    // },
    {
      path: "/subscription",
      routeComponent: <StudentsSubscriptionPage />,
    },
    {
      path: "/courses",
      routeComponent: <CoursesPages />,
    },
    {
      path: "/diplomaInstallment",
      routeComponent: <DiplomaInstallmentPages />,
    },
  ];

  const childrenComponents: IProfileComponents[] = [
    {
      toPath: "",
      icon: <HiOutlineInformationCircle size={20} />,
      label: "details",
      schemaName: SchemaName.SystemConfigurations,
      entityName: EntityName.States,
      action: PermissionActionsTypes.Details,
    },
    {
      toPath: "subscription",
      icon: <SubscriptionsIcon fontSize="small" />,
      label: "sidebar.studentSubscription",
      schemaName: SchemaName.SystemConfigurations,
      entityName: EntityName.States,
      action: PermissionActionsTypes.Details,
    },
    // {
    //   toPath: "student",
    //   icon: <PermIdentityIcon fontSize="small" />,
    //   label: "sidebar.students",
    //   schemaName: SchemaName.SystemConfigurations,
    //   entityName: EntityName.States,
    //   action: PermissionActionsTypes.List,
    // },
    {
      toPath: "courses",
      icon: <DvrIcon fontSize="small" />,
      label: "sidebar.courses",
      schemaName: SchemaName.SystemConfigurations,
      entityName: EntityName.States,
      action: PermissionActionsTypes.List,
    },
    {
      toPath: "diplomaInstallment",
      icon: <PaidIcon fontSize="small" />,
      label: "sidebar.DiplomaInstallment",
      schemaName: SchemaName.SystemConfigurations,
      entityName: EntityName.States,
      action: PermissionActionsTypes.List,
    },
    // {
    //   toPath: "installments",
    //   icon: <MdAttachMoney size={20} />,
    //   label: "diplomas.installments",
    //   schemaName: SchemaName.SystemConfigurations,
    //   entityName: EntityName.States,
    //   action: PermissionActionsTypes.Details,
    // },
  ];

  return (
    <>
      <LoadingAndErrorWrapper
        isLoading={isLoading}
        errorMessage={error?.response?.data.messages[0]}
      >
        <AppProfile
          error={error}
          breadcrumbPaths={[
            { name: messages["sidebar.scientificContentManagement.title"] },
            {
              name: messages["sidebar.diplomas"],
              path: "/scientificContentManagement/diplomas",
            },
            {
              name: getValueBasedOnLang(
                diplomasItem?.nameAr || "",
                diplomasItem?.nameEn || ""
              ),
            },
          ]}
          currentBreadcrumbPath="details"
          sidebarRoutes={sidebarRoutes}
          childrenComponents={childrenComponents}
        />
      </LoadingAndErrorWrapper>
    </>
  );
};

export default DiplomaProfile;
