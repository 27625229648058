import { useJWTAuth } from "@crema/services/auth/jwt-auth/JWTAuthProvider";
import Box from "@mui/material/Box";
import { SxProps } from "@mui/system";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useRoutes,
} from "react-router-dom";
import { AppState } from "redux/store";
import {
  anonymousStructure,
  authorizedStructure,
  unAuthorizedStructure,
} from "../../../pages";
import { initialUrl } from "../../../shared/constants/AppConst";
import { AppSuspense } from "../../index";
import generateRoutes from "../../utility/RouteGenerator";
import AppErrorBoundary from "../AppErrorBoundary";
import AppFooter from "../AppLayout/components/AppFooter";
import AppContentViewWrapper from "./AppContentViewWrapper";
import { useAuthMethod } from "@crema/utility/AuthHooks";

interface AppContentViewProps {
  sxStyle?: SxProps;
}

const AppContentView: React.FC<AppContentViewProps> = ({ sxStyle }) => {
  const { user, isAuthenticated } = useJWTAuth();
  const { logout } = useAuthMethod();

  const navigate = useNavigate();

  const { permissionsList } = useSelector<AppState, AppState["permissions"]>(
    (state) => state.permissions
  );

  const routes = useRoutes(
    generateRoutes({
      isAuthenticated: isAuthenticated,
      userRole: user?.permissions,
      unAuthorizedStructure,
      authorizedStructure,
      anonymousStructure,
    })
  );

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/signin", { replace: true });
    }
  }, [isAuthenticated]);

  return (
    <AppContentViewWrapper>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          p: { xs: 5, md: 7.5, xl: 12.5 },
          ...sxStyle,
        }}
        className="app-content"
      >
        <AppSuspense>
          <AppErrorBoundary>
            {routes}
            <Routes>
              <Route path="/" element={<Navigate to={initialUrl} />} />
            </Routes>
          </AppErrorBoundary>
        </AppSuspense>
      </Box>
      <AppFooter />
    </AppContentViewWrapper>
  );
};

export default AppContentView;
