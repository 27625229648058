import { useJWTAuth } from "@crema/services/auth/jwt-auth/JWTAuthProvider";
import IntlMessages from "@crema/utility/IntlMessages";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
} from "@mui/material";
import AppButton from "components/basicComponents/buttons/AppButton";
import LoadingAndErrorWrapper from "components/basicComponents/others/LoadingAndErrorWrapper";
import AppPermissionsGate from "components/basicComponents/permessions/AppPermissionsGate";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetListService } from "services";
import { useUpdateRolePermission } from "services/additionalServices";
import { AppUrls } from "services/appUrls";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import {
  IAllPermissionsTableListDto,
  IAllPermissionsTableSearch,
  IUpdateAllPermissionsTableDto,
} from "types/entitiesTypes/allPermissionsTable";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";

const useStyles = makeStyles((theme) => ({
  tableBorder: {
    width: "100%",
  },
  borderBottom: {
    borderCoolapse: "collapse",
    borderBottom: "1px solid #e0e0e0",
  },
}));

const TablePermissionsUser: React.FC = () => {
  const { userIdParams, moduleParams } = useParams();
  const classes = useStyles();
  const { user } = useJWTAuth();

  const [rolePermissions, setRolePermissions] =
    useState<IAllPermissionsTableListDto[]>();

  const search: IAllPermissionsTableSearch = {
    userId: userIdParams ? userIdParams : user?.id || "",
    moduleId: moduleParams!,
  };

  const {
    resultData: permissionsTableList,
    isFetching,
    error,
    refetch,
  } = useGetListService<IAllPermissionsTableListDto>(
    AppUrls.AllPermissionsTableUser,
    search,
    false
  );

  useEffect(() => {
    if (permissionsTableList) setRolePermissions(permissionsTableList?.data);
  }, [permissionsTableList]);

  // Handle Change Check Box
  const handleChangeCkeckBox = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const checked = e.target.checked;
    const permissionStr = e.target.value;

    const roles = rolePermissions && [...rolePermissions];
    if (roles) {
      const currentEntity = roles && roles.splice(index, 1)[0];
      if (currentEntity) {
        for (let i = 0; i < currentEntity.permissions.length; ++i) {
          const currentPermission = currentEntity.permissions[i];
          if (currentPermission.permission == permissionStr) {
            currentPermission.isSelected = checked;
            break;
          }
        }
      }
      const lastPart = roles.splice(index);
      roles.push(currentEntity);
      roles.push(...lastPart);
      setRolePermissions(roles);
    }
  };

  return (
    <>
      <Card>
        <CardContent>
          <Grid container>
            <LoadingAndErrorWrapper
              isLoading={isFetching}
              errorMessage={error?.response?.data.messages[0]}
            >
              <table className={classes.tableBorder}>
                <tbody>
                  <>
                    {rolePermissions?.map((item, index) => {
                      return (
                        <>
                          <tr className={classes.borderBottom} key={index}>
                            <td
                              style={{ paddingInlineEnd: "25px" }}
                              className={classes.borderBottom}
                            >
                              <IntlMessages
                                id={`permission.table.${item?.table}`}
                              />
                            </td>
                            <td className={classes.borderBottom}>
                              <Grid container>
                                {item?.permissions.map(
                                  (entityPerItem, entityPerIndex) => {
                                    // Get Last Name From Permission
                                    const entityPerName =
                                      entityPerItem.permission.split(".");
                                    const perName =
                                      entityPerName[entityPerName.length - 1];

                                    return (
                                      <Grid item md={4}>
                                        <FormGroup>
                                          <FormControlLabel
                                            style={{
                                              cursor: "default",
                                            }}
                                            control={
                                              <Checkbox
                                                disableRipple
                                                disableTouchRipple
                                                disableFocusRipple
                                                style={{
                                                  cursor: "default",
                                                }}
                                                checked={
                                                  entityPerItem.isSelected
                                                }
                                                readOnly
                                                value={entityPerItem.permission}
                                              />
                                            }
                                            label={
                                              <IntlMessages
                                                id={`permission.${perName}`}
                                              />
                                            }
                                          />
                                        </FormGroup>
                                      </Grid>
                                    );
                                  }
                                )}
                              </Grid>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </>
                </tbody>
              </table>
            </LoadingAndErrorWrapper>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default TablePermissionsUser;
