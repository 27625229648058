import AppLngSwitcher from "@crema/core/AppLngSwitcher";
import MenuIcon from "@mui/icons-material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Hidden from "@mui/material/Hidden";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { alpha } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPermmissionsList } from "redux/actions/permissions/permissions";
import { getPersonalPermmissionsList } from "redux/actions/personal/personal";
import { AppState } from "redux/store";
import { toggleNavCollapsed } from "../../../../../redux/actions";
import AppMessages from "../../../AppMessages";
import AppNotifications from "../../../AppNotifications";
import AppTooltip from "../../../AppTooltip";
import AppLogo from "../../components/AppLogo";
import { useJWTAuthActions } from "@crema/services/auth/jwt-auth/JWTAuthProvider";
import IntlMessages from "@crema/utility/IntlMessages";
import { useGetCustomService } from "services/additionalServices";
import { AppUrls } from "services/appUrls";
import AppButton from "components/basicComponents/buttons/AppButton";
import AppPermissionsGate from "components/basicComponents/permessions/AppPermissionsGate";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import ThemeModes from "@crema/core/AppThemeSetting/ThemeModes";

interface AppHeaderProps {
  isCollapsed?: boolean;
  setCollapsed: (isCollapsed: boolean) => void;
}

const AppHeader: React.FC<AppHeaderProps> = ({ isCollapsed, setCollapsed }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { personalPermissionsList } = useSelector<
    AppState,
    AppState["personal"]
  >((state) => state.personal);

  const { updatePermissions } = useJWTAuthActions();

  // Get personal permissions
  useEffect(() => {
    //to undo when work on permission
    // dispatch(getPersonalPermmissionsList());
    // dispatch(getPermmissionsList());
  }, []);

  useEffect(() => {
    console.log("personalPermissionsList1", personalPermissionsList);
    if (personalPermissionsList.length > 0) {
      var permissionsList: any = [];
      personalPermissionsList.forEach((element) => {
        permissionsList = [...permissionsList, ...element.permissions];
      });
      updatePermissions(permissionsList);
    }
  }, [personalPermissionsList]);

  return (
    <AppBar
      position="relative"
      color="inherit"
      sx={{
        boxShadow: "none",
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        backgroundColor: "background.paper",
        transition: "width 0.5s ease",
        width: "100%",
        zIndex: "999 !important",
      }}
      className="app-bar"
    >
      <Toolbar
        sx={{
          boxSizing: "border-box",
          minHeight: { xs: 56, sm: 70 },
          paddingLeft: { xs: 5 },
          paddingRight: { xs: 5, md: 7.5, xl: 12.5 },
        }}
      >
        <Hidden lgDown>
          <IconButton
            sx={{
              color: "text.secondary",
            }}
            edge="start"
            className="menu-btn"
            color="inherit"
            aria-label="open drawer"
            onClick={() => setCollapsed(!isCollapsed)}
            size="large"
          >
            <MenuIcon
              sx={{
                width: 35,
                height: 35,
              }}
            />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            sx={{
              color: "text.secondary",
            }}
            edge="start"
            className="menu-btn"
            color="inherit"
            aria-label="open drawer"
            onClick={() => dispatch(toggleNavCollapsed())}
            size="large"
          >
            <MenuIcon
              sx={{
                width: 35,
                height: 35,
              }}
            />
          </IconButton>
        </Hidden>
        <Box
          sx={{
            "& .logo-text": {
              display: { xs: "none", sm: "block" },
            },
          }}
        >
          <div
            style={{
              position: "relative",
              color: "#004A92",
              fontSize: "18px",
              fontWeight: "bold",
              width: "6rem",
              height: "2rem",
              display: "flex",
            }}
          >
            {/* <AppLogo withTitle={false} appLogoPadding={0} /> */}
            <img
              style={{
                // objectFit: "contain",
                // position: "absolute",
                // height: "100%",
                width: "100%",
                marginBottom: "0px",
              }}
              src={"/assets/images/logo.png"}
            />
            {/* <IntlMessages id="mainPageTitle" /> */}
          </div>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
          }}
        />

        <Box>
          <AppLngSwitcher iconOnly={true} tooltipPosition="bottom" />
        </Box>
        <Box sx={{ ml: 4 }}>{/* <ThemeModes /> dark mode */}</Box>
        {/* <Hidden smDown>
          <Box
            sx={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              marginLeft: -2,
              marginRight: -2,
            }}
          >
            <Box
              sx={{
                px: 1.85,
              }}
            >
              <AppNotifications />
            </Box>
          </Box>
        </Hidden> */}

        {/* <Box sx={{ ml: 4 }}>
          <Hidden smDown>
            <Box
              sx={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: -2,
                marginRight: -2,
              }}
            >
              <Box
                sx={{
                  px: 1.85,
                }}
              >
                <AppNotifications />
              </Box>
            </Box>
          </Hidden>

          <Hidden smUp>
            <Box
              sx={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: -2,
                marginRight: -2,
              }}
            >
              <Box
                sx={{
                  px: 1.85,
                }}
              >
                <AppTooltip title="More">
                  <IconButton
                    sx={{
                      borderRadius: "50%",
                      width: 40,
                      height: 40,
                      color: (theme) => theme.palette.text.secondary,
                      backgroundColor: (theme) =>
                        theme.palette.background.default,
                      border: 1,
                      borderColor: "transparent",
                      "&:hover, &:focus": {
                        color: (theme) => theme.palette.text.primary,
                        backgroundColor: (theme) =>
                          alpha(theme.palette.background.default, 0.9),
                        borderColor: (theme) =>
                          alpha(theme.palette.text.secondary, 0.25),
                      },
                    }}
                    onClick={handleClick}
                    size="large"
                  >
                    <MoreVertIcon />
                  </IconButton>
                </AppTooltip>
              </Box>
            </Box>
          </Hidden>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem>
              <AppNotifications isMenu />
            </MenuItem>
            <MenuItem>
              <AppMessages isMenu />
            </MenuItem>
            <MenuItem>Setting</MenuItem>
          </Menu>
        </Box> */}
      </Toolbar>
    </AppBar>
  );
};
export default AppHeader;
