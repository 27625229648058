import { GridColDef } from "@mui/x-data-grid";
import SubscriptionStatusBadge from "components/basicComponents/badge/SubscriptionStatusBadge";
import AppDataGridCell from "components/basicComponents/dataGrid/AppDataGridCell";
import AppIconButton from "components/basicComponents/others/AppIconButton";
import { getValueBasedOnLang } from "helpers";
import moment from "moment";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";

export const StudentsMultiSubscriptionColumns = (): GridColDef[] => {
  const { messages, locale } = useIntl();
  const { studentIdParams, diplomaIdParams } = useParams();

  const StudentsMultiSubscriptionColumns: GridColDef[] = [
    {
      field: "student.id",
      headerName: messages["student.id"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      hide: Boolean(studentIdParams),
      sortable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell value={params.row?.student?.id} />
      ),
    },
    {
      field: "student.fullName",
      headerName: messages["student.name"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      hide: Boolean(studentIdParams),
      sortable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell value={params.row?.student?.fullName} />
      ),
    },
    {
      field: locale === "ar-SA" ? "diploma.nameAr" : "diploma.nameEn",
      headerName: messages["diplomas.name"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      hide: Boolean(diplomaIdParams),
      sortable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell
          value={getValueBasedOnLang(
            params.row?.diploma?.nameAr,
            params.row?.diploma?.nameEn
          )}
        />
      ),
    },

    {
      field:
        locale === "ar-SA"
          ? "diploma.educationalCenter.nameAr"
          : "diploma.educationalCenter.nameEn",
      headerName: messages["diplomas.educationalCenter"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      hide: Boolean(diplomaIdParams),
      sortable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell
          value={getValueBasedOnLang(
            params.row?.diploma?.educationalCenter?.nameAr ?? "-",
            params.row?.diploma?.educationalCenter?.nameEn ?? "-"
          )}
        />
      ),
    },

    {
      field:
        locale === "ar-SA"
          ? "diploma.instructor.firstNameAr"
          : "diploma.instructor.firstNameEn",
      headerName: messages["diplomas.instructor"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      hide: Boolean(diplomaIdParams),
      sortable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell
          value={getValueBasedOnLang(
            params.row?.diploma?.instructor?.firstNameAr
              ? `${params.row?.diploma?.instructor?.firstNameAr} ${params.row?.diploma?.instructor?.lastNameAr}`
              : "-",
            params.row?.diploma?.instructor?.firstNameEn
              ? `${params.row?.diploma?.instructor?.firstNameEn} ${params.row?.diploma?.instructor?.lastNameEn}`
              : "-"
          )}
        />
      ),
    },

    {
      field: "remainingPayments",
      headerName: messages["student.remainingPayments"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell value={params.row?.remainingPayments} />
      ),
    },

    {
      field: "nextPayment",
      headerName: messages["student.nextPayment"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell
          value={moment(params.row?.nextPayment).format("YYYY-MM-DD")}
        />
      ),
    },

    {
      field: "subscriptionDate",
      headerName: messages["student.subscriptionDate"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell
          value={moment(params.row?.subscriptionDate).format(
            "YYYY-MM-DD HH:mm"
          )}
        />
      ),
    },

    // {
    //   field: "subscriptionType",
    //   headerName: messages["student.subscriptionType"] as string,
    //   headerAlign: "center",
    //   align: "center",
    //   flex: 0.2,

    //   headerClassName: "super-app-theme--header",
    //   renderCell: (params) => (
    //     <AppDataGridCell
    //       value={
    //         messages[
    //           params.row?.subscriptionType === 1
    //             ? "student.subscriptionType.onePayment"
    //             : "student.subscriptionType.multiPayment"
    //         ] as string
    //       }
    //     />
    //   ),
    // },

    {
      field: "subscriptionStatus",
      headerName: messages["student.subscriptionStatus"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <SubscriptionStatusBadge
          subscriptionStatus={params?.row?.subscriptionStatus}
        />
      ),
    },
    {
      field: "actions",
      headerName: messages["common.actions"] as string,
      sortable: false,
      align: "center",
      filterable: false,
      headerAlign: "center",
      flex: 0.1,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div>
            {/* Details */}
            <AppIconButton
              label="table.actions.details"
              withLink={true}
              toPathLink={`/managePayments/installments/profile/${params?.row?.id}`}
              childComp={
                <RemoveRedEyeIcon fontSize="small" sx={{ color: "#007991" }} />
              }
              // For Permissions trainStructure/subscription/profile
              schemaName={SchemaName.SystemConfigurations}
              entityName={EntityName.Countries}
              action={PermissionActionsTypes.Details}
            />
          </div>
        );
      },
    },
  ];

  return StudentsMultiSubscriptionColumns;
};
