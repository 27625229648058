import StudentsSubscriptionPage from "pages/trainStructure/studentsSubscription/StudentsSubscriptionPage";
import InvoiceProfile from "./invoices/InvoiceProfile";
import InvoicesPage from "./invoices/InvoicesPage";
import PaymentPages from "./invoices/payment/PaymentPages";
import RefundablePaymentsPages from "./refundablePayments/RefundablePaymentsPages";
import InstructorProfile from "pages/trainStructure/instructor/InstructorProfile";
import StudentsSubscriptionProfile from "pages/trainStructure/studentsSubscription/StudentsSubscriptionProfile";
import StudentsMultiSubscriptionPage from "pages/trainStructure/studentsSubscription/StudentsMultiSubscriptionPage";

export const managePayments = [
  {
    path: "/managePayments/refundablePayments",
    element: <RefundablePaymentsPages />,
  },
  {
    path: "/managePayments/invoices",
    element: <InvoicesPage />,
  },
  {
    path: "/managePayments/invoices/profile/:invoiceIdParams/*",
    element: <InvoiceProfile />,
  },
  {
    path: "/managePayments/payments",
    element: <PaymentPages />,
  },
  {
    path: "/managePayments/installments",
    element: <StudentsMultiSubscriptionPage />,
  },
  {
    path: "/managePayments/installments/profile/:subscriptionIdParams/*",
    element: <StudentsSubscriptionProfile />,
  },

  {
    path: "/usersManagement/students/profile/:studentIdParams/subscription/:subscriptionIdParams/*",
    element: <StudentsSubscriptionProfile />,
  },
  //InvoicesPage payment
];
