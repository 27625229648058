import { Grid } from "@mui/material";
import RefundStatusBadge from "components/basicComponents/badge/StatusRefund";
import BaseDetails from "components/basicComponents/details/BaseDetails";
import AppItemView from "components/basicComponents/others/AppItemView";
import { getValueBasedOnLang } from "helpers";
import { useIntl } from "react-intl";
import { refundReason } from "shared/constants/AppEnums";
import {
  IRefundablePaymentsDetailsDto,
  IRefundablePaymentsListDto,
} from "types/entitiesTypes/refundablePayments";

interface IRefundablePaymentsDetailsProps {
  RefundablePaymentsItem: IRefundablePaymentsListDto;
  setShowDetails: React.Dispatch<React.SetStateAction<boolean>>;
  showDetails: boolean;
}

const RefundablePaymentsDetails = (props: IRefundablePaymentsDetailsProps) => {
  const { RefundablePaymentsItem, setShowDetails, showDetails } = props;
  const { messages } = useIntl();

  return (
    <>
      <BaseDetails
        itemName={""}
        label="refundablePayments.details.label"
        open={showDetails}
        onClose={() => setShowDetails(false)}
        loading={false}
        errorMessage={""}
        maxWidth="md"
        columns={12}
        withoutSecoundTab={false}
      >
        <Grid container spacing={5} item xs={12}>
          <AppItemView
            label="student.name"
            value={RefundablePaymentsItem?.supscription?.student?.fullName}
            sm={4}
          />
          <AppItemView
            label="diplomas.name"
            value={getValueBasedOnLang(
              RefundablePaymentsItem?.supscription?.diploma?.nameAr,
              RefundablePaymentsItem?.supscription?.diploma?.nameEn
            )}
            sm={4}
          />
          <AppItemView
            label="diplomas.educationalCenter"
            value={getValueBasedOnLang(
              RefundablePaymentsItem?.supscription?.diploma?.educationalCenter
                ?.nameAr ?? "-",
              RefundablePaymentsItem?.supscription?.diploma?.educationalCenter
                ?.nameEn ?? "-"
            )}
            sm={4}
          />

          <AppItemView
            label="diplomas.instructor"
            value={getValueBasedOnLang(
              RefundablePaymentsItem?.supscription?.diploma?.instructor
                ?.firstNameAr
                ? `${RefundablePaymentsItem?.supscription?.diploma?.instructor?.firstNameAr} ${RefundablePaymentsItem?.supscription?.diploma?.instructor?.lastNameAr}`
                : "-",
              RefundablePaymentsItem?.supscription?.diploma?.instructor
                ?.firstNameEn
                ? `${RefundablePaymentsItem?.supscription?.diploma?.instructor?.firstNameEn} ${RefundablePaymentsItem?.supscription?.diploma?.instructor?.lastNameEn}`
                : "-"
            )}
            sm={4}
          />
          <AppItemView
            label="refundablePayments.amount"
            value={RefundablePaymentsItem.amount}
            sm={4}
          />
          <AppItemView
            label="refundablePayments.payment.amount"
            value={RefundablePaymentsItem?.payment.amount}
            sm={4}
          />
          <AppItemView
            label="refundablePayments.date"
            value={RefundablePaymentsItem?.date}
            sm={4}
            type="dateTime"
          />
          <AppItemView
            label="refundablePayments.refundStatus"
            value={
              <RefundStatusBadge
                refundStatusProps={RefundablePaymentsItem?.refundStatus}
                label={`refundablePayments.refundStatus.${RefundablePaymentsItem?.refundStatus}`}
              />
            }
            sm={4}
          />
          <AppItemView
            label="refundablePayments.refundReason"
            value={
              RefundablePaymentsItem.refundReason ==
              refundReason.RejectVirtualFile
                ? messages[
                    `refundablePayments.${refundReason.RejectVirtualFile}`
                  ]
                : "-"
            }
            sm={4}
          />
          <AppItemView
            label="refundablePayments.refundReasonNote"
            value={RefundablePaymentsItem?.refundReasonNote}
            sm={4}
          />
        </Grid>
      </BaseDetails>
    </>
  );
};

export default RefundablePaymentsDetails;
