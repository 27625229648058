import AppTextField from "@crema/core/AppFormComponents/AppTextField";
import IntlMessages from "@crema/utility/IntlMessages";
import AddCircleSharpIcon from "@mui/icons-material/AddCircleSharp";
import DeleteForeverSharpIcon from "@mui/icons-material/DeleteForeverSharp";
import { Divider, Grid, IconButton } from "@mui/material";
import AppDateTimePicker from "components/basicComponents/inputs/dateTimePickers/AppDateTimePicker";
import AppAddModal from "components/basicComponents/modals/AppAddModal";
import { FieldArray, Form, Formik, FormikProps } from "formik";
import React from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router";
import { useCreateService } from "services";
import { AppUrls } from "services/appUrls";
import { ICreateDiplomaInstallmentDto } from "types/entitiesTypes/DiplomaInstallment";

import * as yup from "yup";

interface IAddDiplomaInstallmentProps {
  showAddModal: boolean;
  setShowAddModal: React.Dispatch<React.SetStateAction<boolean>>;
  label: string;
  onSuccess?: () => void;
  onFailed?: () => void;
}

const AddDiplomaInstallment: React.FC<IAddDiplomaInstallmentProps> = (
  props
) => {
  const { showAddModal, setShowAddModal, label } = props;
  const { messages } = useIntl();

  const { diplomaIdParams } = useParams();

  const refForm = React.useRef<FormikProps<ICreateDiplomaInstallmentDto>>(null);

  const mutation = useCreateService<ICreateDiplomaInstallmentDto, string>(
    AppUrls.DiplomaInstallment,
    null,
    false
  );

  const validationSchema = yup.object({
    installments: yup.array().of(
      yup.object().shape({
        dueDate: yup.string().required(String(messages["validation.required"])),
        amount: yup
          .string()
          .required(String(messages["validation.required"]))
          .typeError(String(messages["validation.required"])),
        daysMargin: yup
          .string()
          .required(String(messages["validation.required"]))
          .typeError(String(messages["validation.required"])),
      })
    ),
  });

  const submit = () => {
    if (refForm.current) {
      refForm.current.submitForm();
    }
  };

  return (
    <>
      <AppAddModal
        label={label}
        open={showAddModal}
        onClose={() => setShowAddModal(false)}
        closeModalState={setShowAddModal}
        successClose={mutation.isSuccess}
        handleSubmit={submit}
        loadingForAdd={
          (refForm.current?.isSubmitting && !refForm.current?.isValidating) ||
          false
        }
        maxWidth={"md"}
      >
        <Formik
          validateOnChange={true}
          initialValues={{
            installments: [
              {
                diplomaInstallmentOrder: 1,
                dueDate: "",
                amount: null!,
                daysMargin: null!,
              },
            ],
            diplomaId: diplomaIdParams!,
          }}
          validationSchema={validationSchema}
          innerRef={refForm}
          onSubmit={async (data, { setSubmitting }) => {
            mutation.submitData(data);
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Divider sx={{ color: "#0A8FDC", fontWeight: "bold" }}>
                    <IntlMessages id="diplomaInstallment" />
                  </Divider>
                </Grid>
                <FieldArray
                  name="installments"
                  render={(arrayHelpers) => (
                    <Grid margin={1} container spacing={4}>
                      {values.installments?.map((item, index) => (
                        <>
                          <Grid item xs={12} sm={3}>
                            <AppDateTimePicker
                              value={values?.installments[index].dueDate}
                              label={"diplomaInstallment.dueDate"}
                              name={`installments[${index}].dueDate`}
                              onChange={setFieldValue}
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <AppTextField
                              label={
                                <IntlMessages id="diplomaInstallment.amount" />
                              }
                              name={`installments[${index}].amount`}
                              variant="outlined"
                              type="number"
                              size="small"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <AppTextField
                              label={
                                <IntlMessages id="diplomaInstallment.daysMargin" />
                              }
                              name={`installments[${index}].daysMargin`}
                              variant="outlined"
                              type="number"
                              size="small"
                              fullWidth
                            />
                          </Grid>
                          {/* Delete */}
                          <Grid item sm={1.5} xs={6}>
                            <IconButton
                              aria-label="delete"
                              color="error"
                              disabled={values.installments.length === 0}
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              <DeleteForeverSharpIcon />
                            </IconButton>
                          </Grid>
                          {index === values.installments.length - 1 && (
                            <Grid item sm={1.5} xs={6}>
                              <IconButton
                                aria-label="add"
                                color="success"
                                onClick={() =>
                                  arrayHelpers.push({
                                    diplomaInstallmentOrder:
                                      item.diplomaInstallmentOrder++,
                                    dueDate: "",
                                    amount: null!,
                                    daysMargin: null!,
                                  })
                                }
                              >
                                <AddCircleSharpIcon />
                              </IconButton>
                            </Grid>
                          )}
                        </>
                      ))}
                    </Grid>
                  )}
                />
              </Grid>
            </Form>
          )}
        </Formik>
      </AppAddModal>
    </>
  );
};

export default AddDiplomaInstallment;
