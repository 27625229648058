const REQUEST_PERMISSIONS = "REQUEST_PERMISSIONS";
export const REQUEST_PERMISSIONS_SUCCESS = "REQUEST_PERMISSIONS_SUCCESS";
const REQUEST_PERMISSIONS_FAILED = "REQUEST_PERMISSIONS_FAILED";

const initialState = {
  permissionsList: [],
  isLoadingPermissionsList: false,
  permissionsListErrorMessage: null,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_PERMISSIONS:
      return {
        ...state,
        isLoadingPermissionsList: true,
      };
    case REQUEST_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissionsList: action.permissionsList,
        isLoadingPermissionsList: false,
        permissionsListErrorMessage: null,
      };
    case REQUEST_PERMISSIONS_FAILED:
      return {
        ...state,
        isLoadingPermissionsList: false,
        permissionsListErrorMessage: action.permissionsListErrorMessage,
      };
    default:
      return state;
  }
};

export const requestPermissionsList = () => {
  return {
    type: REQUEST_PERMISSIONS,
  };
};
export const requestPermissionsListSuccess = (permissionsList) => {
  return {
    type: REQUEST_PERMISSIONS_SUCCESS,
    permissionsList: permissionsList,
  };
};
export const requestPermissionsListFailed = (permissionsListErrorMessage) => {
  return {
    type: REQUEST_PERMISSIONS_FAILED,
    permissionsListErrorMessage: permissionsListErrorMessage,
  };
};
