import LoadingAndErrorWrapper from "components/basicComponents/others/LoadingAndErrorWrapper";
import AppProfile, {
  IProfileComponents,
  IProfileRoutes,
} from "components/basicComponents/profile/AppProfile";
import { HiOutlineInformationCircle } from "react-icons/hi";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useGetByIdService } from "services";
import { AppUrls } from "services/appUrls";
import { EntityName, SchemaName } from "../../../shared/constants/AppConst";
import { PermissionActionsTypes } from "../../../types/entitiesTypes/enums";
import DescriptionIcon from "@mui/icons-material/Description";
import StudentSubscriptionDeatils from "components/trainStructure/studentsSubscription/StudentSubscriptionDeatils";
import { IStudentSubscriptionDetailsDto } from "types/entitiesTypes/studentSubscription";
import InvoicesPage from "pages/managePayments/invoices/InvoicesPage";
import StudentAttachmentsPage from "../student/studentAttachments/StudentAttachmentsPage";
import AttachFileIcon from "@mui/icons-material/AttachFile";
const StudentsSubscriptionProfile: React.FC = () => {
  const { messages } = useIntl();
  const { subscriptionIdParams, studentIdParams } = useParams();
  const {
    data: studentSubscriptionItem,
    error,
    isLoading,
  } = useGetByIdService<IStudentSubscriptionDetailsDto>(
    AppUrls.Subscription,
    subscriptionIdParams || ""
  );
  const sidebarRoutes: IProfileRoutes[] = [
    {
      path: "/",
      routeComponent: (
        <StudentSubscriptionDeatils
          studentSubscriptionItem={studentSubscriptionItem!}
        />
      ),
    },
    {
      path: "/invoices",
      routeComponent: <InvoicesPage />,
    },
    //InvoicesPage
    {
      path: "/studentAttachments",
      routeComponent: (
        <StudentAttachmentsPage
          diplomaId={studentSubscriptionItem?.diploma.id}
          studentId={studentSubscriptionItem?.student.id}
        />
      ),
    },
  ];

  const childrenComponents: IProfileComponents[] = [
    {
      toPath: "",
      icon: <HiOutlineInformationCircle size={20} />,
      label: "details",
      schemaName: SchemaName.SystemConfigurations,
      entityName: EntityName.States,
      action: PermissionActionsTypes.Details,
    },

    {
      toPath: "invoices",
      icon: <DescriptionIcon fontSize="small" />,
      label: "sidebar.invoices",
      schemaName: SchemaName.SystemConfigurations,
      entityName: EntityName.States,
      action: PermissionActionsTypes.Details,
    },
    {
      toPath: "studentAttachments",
      icon: <AttachFileIcon fontSize={"small"} />,
      label: "sidebar.studentAttachments",
      schemaName: SchemaName.SystemConfigurations,
      entityName: EntityName.States,
      action: PermissionActionsTypes.Details,
    },
  ];

  return (
    <>
      <LoadingAndErrorWrapper
        isLoading={isLoading}
        errorMessage={error?.response?.data.messages[0]}
      >
        <AppProfile
          error={error}
          breadcrumbPaths={[
            {
              name:
                studentIdParams && subscriptionIdParams
                  ? messages["sidebar.usersManagement"]
                  : messages["sidebar.managePayments.title"],
            },
            {
              name:
                studentIdParams && subscriptionIdParams
                  ? messages["sidebar.studentSubscription"]
                  : messages["sidebar.installments"],
              path:
                studentIdParams && subscriptionIdParams
                  ? `/usersManagement/students/profile/${studentIdParams}/subscription`
                  : "/managePayments/installments",
            },
          ]}
          currentBreadcrumbPath="details"
          sidebarRoutes={sidebarRoutes}
          childrenComponents={childrenComponents}
          activePath={studentIdParams && subscriptionIdParams ? 7 : 5}
        />
      </LoadingAndErrorWrapper>
    </>
  );
};

export default StudentsSubscriptionProfile;
