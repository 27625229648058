import IntlMessages from "@crema/utility/IntlMessages";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Chip, Grid, Typography } from "@mui/material";
import {
  dateFormatter,
  dateTimeFormatter,
  timeFormatter,
} from "helpers/string/dateFormatter";
import * as React from "react";
import { useIntl } from "react-intl";
import { Fonts } from "shared/constants/AppEnums";

interface IAppItemViewProps {
  column?: number;
  marginX?: number;
  label: string;
  value: any;
  type?:
    | "text"
    | "date"
    | "time"
    | "dateTime"
    | "isActive"
    | "isExist"
    | "isPhoneNumber";
  sm?: number;
}

const AppItemView: React.FC<IAppItemViewProps> = (props) => {
  const {
    column = 12,
    label,
    value,
    type = "text",
    marginX = undefined,
    sm = 6,
  } = props;
  const { messages, locale } = useIntl();

  const ItemView = () => {
    return (
      <>
        {(() => {
          switch (type) {
            case "text":
              return (
                <Grid marginX={marginX} item sm={sm} xs={column}>
                  <Typography
                    sx={{ fontSize: 14, fontWeight: Fonts.SEMI_BOLD }}
                  >
                    <IntlMessages id={label} />
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: Fonts.REGULAR,
                      // textAlign:
                      //   locale === "ar-SA" &&
                      //   label &&
                      //   label?.toLowerCase()?.endsWith("en")
                      //     ? "right !important"
                      //     : locale === "ar-SA" &&
                      //       label &&
                      //       label?.toLowerCase()?.endsWith("ar")
                      //     ? "left !important"
                      //     : locale === "en-US" &&
                      //       label &&
                      //       label?.toLowerCase()?.endsWith("en")
                      //     ? "left !important"
                      //     : locale === "en-US" &&
                      //       label &&
                      //       label?.toLowerCase()?.endsWith("ar")
                      //     ? "right !important"
                      //     : "",
                      // direction:
                      //   locale === "ar-SA" &&
                      //   label &&
                      //   label?.toLowerCase()?.endsWith("en")
                      //     ? "rtl !important"
                      //     : locale === "ar-SA" &&
                      //       label &&
                      //       label?.toLowerCase()?.endsWith("ar")
                      //     ? "ltr !important"
                      //     : locale === "en-US" &&
                      //       label &&
                      //       label?.toLowerCase()?.endsWith("en")
                      //     ? "ltr !important"
                      //     : locale === "en-US" &&
                      //       label &&
                      //       label?.toLowerCase()?.endsWith("ar")
                      //     ? "rtl !important"
                      //     : "",

                      wordBreak: "break-word",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {value}
                  </Typography>
                </Grid>
              );

            case "date":
            case "dateTime":
            case "time":
              return (
                <Grid marginX={marginX} sm={sm} xs={column} item>
                  <Typography
                    sx={{ fontSize: 14, fontWeight: Fonts.SEMI_BOLD }}
                  >
                    <IntlMessages id={label} />
                  </Typography>
                  <Typography sx={{ fontSize: 14, fontWeight: Fonts.REGULAR }}>
                    {type == "date"
                      ? dateFormatter(value)
                      : type == "dateTime"
                      ? dateTimeFormatter(value)
                      : timeFormatter(value)}
                  </Typography>
                </Grid>
              );

            case "isActive":
              return (
                <Grid marginX={marginX} sm={sm} xs={column} item>
                  <Typography
                    sx={{ fontSize: 14, fontWeight: Fonts.SEMI_BOLD }}
                  >
                    <IntlMessages id={label} />
                  </Typography>
                  <Typography sx={{ fontSize: 14, fontWeight: Fonts.REGULAR }}>
                    {value ? (
                      <Chip
                        size="small"
                        label={messages["columns.active"]}
                        color="success"
                        variant="filled"
                        sx={{ color: "#fff" }}
                      />
                    ) : (
                      <Chip
                        size="small"
                        label={messages["columns.inactive"]}
                        color="error"
                        variant="filled"
                      />
                    )}
                  </Typography>
                </Grid>
              );

            case "isPhoneNumber":
              return (
                <Grid marginX={marginX} item sm={sm} xs={column}>
                  <Typography
                    sx={{ fontSize: 14, fontWeight: Fonts.SEMI_BOLD }}
                  >
                    <IntlMessages id={label} />
                  </Typography>
                  <Typography
                    style={{
                      direction: "ltr",
                      textAlign: locale == "ar-SA" ? "right" : "left",
                    }}
                  >
                    {value ? value : "-"}
                  </Typography>
                </Grid>
              );

            case "isExist":
              return (
                <Grid marginX={marginX} sm={sm} xs={column} item>
                  <Typography
                    sx={{ fontSize: 14, fontWeight: Fonts.SEMI_BOLD }}
                  >
                    <IntlMessages id={label} />
                  </Typography>
                  <Typography sx={{ fontSize: 14, fontWeight: Fonts.REGULAR }}>
                    {value ? (
                      <CheckIcon color="info" />
                    ) : (
                      <CloseIcon color="error" />
                    )}
                  </Typography>
                </Grid>
              );

            default:
              return null;
          }
        })()}
      </>
    );
  };

  return <ItemView />;
};

export default AppItemView;
