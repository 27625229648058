import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { GridColDef } from "@mui/x-data-grid";
import AppDataGridCell from "components/basicComponents/dataGrid/AppDataGridCell";
import AppIconButton from "components/basicComponents/others/AppIconButton";
import { useIntl } from "react-intl";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { IExamsListDto } from "types/entitiesTypes/exams";
import {
  dateTimeComparison,
  dateTimeFormatter,
} from "helpers/string/dateFormatter";
import { FaQuestion } from "react-icons/fa";
import moment from "moment";
import { useParams } from "react-router-dom";

export const ExamsColumns = (
  handleDetails = (row: IExamsListDto) => {},
  handleUpdate = (row: IExamsListDto) => {},
  handleDelete = (row: IExamsListDto) => {},
  handleAddQuestionDetails = (row: IExamsListDto) => {}
): GridColDef[] => {
  const { messages, locale } = useIntl();
  const now = moment().utc().local();
  const { courseIdParams } = useParams();

  const ExamsColumns: GridColDef[] = [
    {
      field: locale == "en-US" ? "course.nameEn" : "course.nameAr",
      headerName: messages["session.courseName"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      minWidth: 130,
      hide: Boolean(courseIdParams),
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => (
        <AppDataGridCell
          value={
            locale == "en-US"
              ? params?.row?.course?.nameEn
              : params?.row?.course?.nameAr
          }
        />
      ),
    },
    {
      field: "nameAr",
      headerName: messages["common.nameAr"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => <AppDataGridCell value={params.row?.nameAr} />,
    },
    {
      field: "nameEn",
      headerName: messages["common.nameEn"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => <AppDataGridCell value={params.row?.nameEn} />,
    },

    {
      field: "date",
      headerName: messages["exams.date"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell value={dateTimeFormatter(params.row?.date)} />
      ),
    },
    {
      field: "duration",
      headerName: messages["exams.duration"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      minWidth: 140,
      sortable: false,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell value={params.row?.durationInMin} />
      ),
    },

    {
      field: "actions",
      headerName: messages["common.actions"] as string,
      sortable: false,
      align: "center",
      filterable: false,
      headerAlign: "center",
      flex: 0.1,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div>
            {/* Details */}
            <AppIconButton
              label="table.actions.details"
              handleClick={() => {
                handleDetails(params.row);
              }}
              childComp={
                <RemoveRedEyeIcon fontSize="small" sx={{ color: "#007991" }} />
              }
              // For Permissions
              schemaName={SchemaName.SystemConfigurations}
              entityName={EntityName.Countries}
              action={PermissionActionsTypes.Details}
            />

            {/* Edit FaQuestion */}
            {dateTimeComparison(params?.row?.date) && (
              <>
                <AppIconButton
                  label="table.actions.edit"
                  handleClick={() => {
                    handleUpdate(params.row);
                  }}
                  childComp={
                    <EditOutlinedIcon
                      fontSize="small"
                      sx={{ color: "#A0CCDA" }}
                    />
                  }
                  // For Permissions
                  schemaName={SchemaName.SystemConfigurations}
                  entityName={EntityName.Countries}
                  action={PermissionActionsTypes.Edit}
                />

                <AppIconButton
                  label="table.actions.addQuestion"
                  handleClick={() => {
                    handleAddQuestionDetails(params.row);
                  }}
                  childComp={
                    <FaQuestion fontSize={16} style={{ color: "#004a92" }} />
                  }
                  // For Permissions
                  schemaName={SchemaName.SystemConfigurations}
                  entityName={EntityName.Countries}
                  action={PermissionActionsTypes.Edit}
                />
                {/* Delete */}
                <AppIconButton
                  label="table.actions.delete"
                  handleClick={() => {
                    handleDelete(params.row);
                  }}
                  childComp={
                    <DeleteOutlinedIcon
                      fontSize="small"
                      sx={{ color: "#D72638" }}
                    />
                  }
                  // For Permissions
                  schemaName={SchemaName.SystemConfigurations}
                  entityName={EntityName.Countries}
                  action={PermissionActionsTypes.Delete}
                />
              </>
            )}
          </div>
        );
      },
    },
  ];

  return ExamsColumns;
};
