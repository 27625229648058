import IntlMessages from "@crema/utility/IntlMessages";
import { Grid, Hidden, MenuItem } from "@mui/material";
import { GridSelectionModel } from "@mui/x-data-grid";
import AppBreadcrumb from "components/basicComponents/breadcrumb/AppBreadcrumb";
import AppDataGrid from "components/basicComponents/dataGrid/AppDataGrid";
import AppAdvanceFilterAutoComplete from "components/basicComponents/filters/AppAdvanceFilterAutoComplete";
import AppAdvanceFilterSelect from "components/basicComponents/filters/AppAdvanceFilterSelect";
import AppFilters from "components/basicComponents/filters/AppFilters";
import ApprovePayment from "components/managePayments/invoices/payment/ApprovePayment";
import PaymentDetails from "components/managePayments/invoices/payment/PaymentDetails";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router";
import { useLocation } from "react-router-dom";
import {
  useAdvanceFilterAutocompleteService,
  useGetListService,
} from "services";
import { AppUrls } from "services/appUrls";
import {
  DEBOUNCE_TIME,
  EntityName,
  SchemaName,
} from "shared/constants/AppConst";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import { IInvoicesListDto } from "types/entitiesTypes/invoices";
import { IPaymentListDto, IPaymentSearch } from "types/entitiesTypes/payment";
import { IStudentListDto } from "types/entitiesTypes/student";
import { PaymentColumns } from "./PaymentColumns";
import PaymentCard from "./PaymentCard";
import { IPdfHeader } from "types/appTypes/baseEntity/baseDto";

const PaymentPages = () => {
  const { invoiceIdParams, studentIdParams } = useParams();
  const location = useLocation();
  const { messages, locale } = useIntl();
  const [search, setSearch] = useState<IPaymentSearch>({
    pageNumber: 1,
    pageSize: 10,
    invoiceId: invoiceIdParams!,
    paymentStatus: !invoiceIdParams ? 0 : null,
    withDpiloma: !invoiceIdParams,
  });

  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
  const [showApproveModal, setShowApproveModal] = useState<boolean>(false);

  const [invoiceKeyword, setInvoiceKeyword] = useState<string>("");
  const [invoicePageNumber, setInvoicePageNumber] = useState(1);

  const [studentKeyword, setStudentKeyword] = useState<string>("");
  const [studentPageNumber, setStudentPageNumber] = useState(1);

  const [selectedRow, setSelectedRow] = useState<IPaymentListDto>();

  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  // For hide breadcrumb in country profile
  // For Advance Filters
  const [advancedFilter, setAdvancedFilter] = useState(null);
  const [isOpenAdvanceFilters, setIsOpenAdvanceFilters] =
    useState<boolean>(false);

  const {
    resultData: PaymentList,
    isFetching,
    error,
  } = useGetListService<IPaymentListDto>(AppUrls.Payment, {
    ...search,
    advancedFilter,
  });

  // For details sidebar.requestPayments
  const handleDetails = useCallback((selectedRow: IPaymentListDto) => {
    setSelectedRow(selectedRow);
    setShowDetailsModal(true);
  }, []);

  const handleApprove = useCallback((selectedRow: IPaymentListDto) => {
    setSelectedRow(selectedRow);
    setShowApproveModal(true);
  }, []);

  const columns = PaymentColumns(handleDetails, handleApprove);

  const {
    data: invoicesList,
    isFetching: isFetchingInvoices,
    refetch: refetchInvoice,
  } = useAdvanceFilterAutocompleteService<IInvoicesListDto>(
    AppUrls.Invoice,
    {
      keyword: invoiceKeyword,
      pageSize: 10,
      pageNumber: invoicePageNumber,
    },
    Boolean(!invoiceIdParams)
  );

  useEffect(() => {
    if (Boolean(!invoiceIdParams)) refetchInvoice();
  }, [invoicePageNumber, invoiceIdParams]);

  const {
    data: studentsList,
    isFetching: isFetchingStudents,
    refetch: refetchStudents,
  } = useAdvanceFilterAutocompleteService<IStudentListDto>(
    AppUrls.Student,
    {
      keyword: studentKeyword,
      pageSize: 10,
      pageNumber: studentPageNumber,
    },
    Boolean(!studentIdParams)
  );

  useEffect(() => {
    if (Boolean(!studentIdParams)) refetchStudents();
  }, [studentPageNumber, studentIdParams]);

  const pdfHeaders: IPdfHeader[] = [
    {
      key: "subscription.diploma.nameAr",
      value: String(messages["diploma.nameAr"]),
    },
    {
      key: "subscription.diploma.nameEn",
      value: String(messages["diploma.nameEn"]),
    },
    {
      key: "amount",
      value: String(messages["invoices.payment.amount"]),
    },
    {
      key: "paymentNumberS",
      value: String(messages["invoices.payment.paymentNumberS"]),
    },
    {
      key: "paymentDate",
      value: String(messages["invoices.payment.paymentDate"]),
    },
  ];
  return (
    <>
      <Grid container spacing={3}>
        {!invoiceIdParams && (
          <AppBreadcrumb
            paths={[{ name: messages["sidebar.managePayments.title"] }]}
            currentPathName={"sidebar.requestPayments"}
          />
        )}
        <Grid item xs={12}>
          <AppFilters
            //excel pdf
            filterAll={{
              ...search,
              advancedFilter,
            }}
            onChangeKeywordValue={(
              event: React.ChangeEvent<HTMLInputElement>
            ) => {
              setSearch({ ...search, keyword: event.target.value });
            }}
            showSearchInput={false}
            selectionModel={selectionModel as string[]}
            setSelectionModel={setSelectionModel}
            successUrl={AppUrls.Invoice}
            keyword={search?.keyword}
            advancedFilter={advancedFilter}
            pdfHeaders={pdfHeaders}
            excelFileTitle={messages["sidebar.requestPayments"] as string}
            withExportExcelButton={true}
            customQueries={{
              ...search,
            }}
            showAddButton={false}
            totalCount={PaymentList?.totalCount || 0}
            // For Permissions
            schemaName={SchemaName.SystemConfigurations}
            entityName={EntityName.Countries}
            action={PermissionActionsTypes.Add}
            // Advance Filters
            showAdvanceFiltersButton={true}
            isOpenAdvanceFilters={isOpenAdvanceFilters}
            setIsOpenAdvanceFilters={setIsOpenAdvanceFilters}
            setAdvancedFilter={setAdvancedFilter}
            advanceFiltersInputs={[
              Boolean(!invoiceIdParams) && (
                <AppAdvanceFilterAutoComplete
                  label="invoices.invoiceNumber"
                  fieldName="invoiceId"
                  filterKey="invoiceNumber"
                  getOptionLabel={(option) => option?.invoiceNumber}
                  options={invoicesList?.data}
                  isLoading={isFetchingInvoices}
                  searchText={invoiceKeyword}
                  setSearchText={setInvoiceKeyword}
                  onChangeTextField={debounce((event) => {
                    setInvoiceKeyword(event.target.value);
                  }, DEBOUNCE_TIME)}
                  //pagination
                  pageNumber={invoicePageNumber}
                  setPageNumber={setInvoicePageNumber}
                  hasNextPage={invoicesList?.hasNextPage}
                  setAdvancedFilter={setSearch}
                  advancedFilter={search}
                />
              ),
              Boolean(!studentIdParams) && (
                <AppAdvanceFilterAutoComplete
                  label="session.studentName"
                  fieldName="studentId"
                  options={studentsList?.data}
                  isLoading={isFetchingStudents}
                  searchText={studentKeyword}
                  filterKey="fullName"
                  getOptionLabel={(option) => option.fullName}
                  setSearchText={setStudentKeyword}
                  onChangeTextField={debounce((event) => {
                    setStudentKeyword(event.target.value);
                  }, DEBOUNCE_TIME)}
                  //pagination
                  pageNumber={studentPageNumber}
                  setPageNumber={setStudentPageNumber}
                  hasNextPage={studentsList?.hasNextPage}
                  setAdvancedFilter={setSearch}
                  advancedFilter={search}
                />
              ),
              <AppAdvanceFilterSelect
                fieldName="paymentStatus"
                label={"invoices.payment.paymentStatus"}
                labelForView={locale == "en-US" ? "paymentStatus" : "payments"}
                advancedFilter={search}
                setAdvancedFilter={setSearch}
              >
                <MenuItem value={1}>
                  <IntlMessages id="invoices.payment.paymentStatus.Approved" />
                </MenuItem>
                <MenuItem value={2}>
                  <IntlMessages id="invoices.payment.paymentStatus.Rejected" />
                </MenuItem>
                <MenuItem value={3}>
                  <IntlMessages id="invoices.payment.paymentStatus.Refunded" />
                </MenuItem>

                <MenuItem value={0}>
                  <IntlMessages id="invoices.payment.paymentStatus.Unpaid" />
                </MenuItem>
              </AppAdvanceFilterSelect>,

              <AppAdvanceFilterSelect
                fieldName="paymentType"
                label={"invoices.payment.paymentType"}
                labelForView={locale == "en-US" ? "paymentType" : "paymentT"}
                advancedFilter={search}
                setAdvancedFilter={setSearch}
              >
                <MenuItem value={2}>
                  <IntlMessages id="invoices.payment.paymentType.Cash" />
                </MenuItem>
                <MenuItem value={3}>
                  <IntlMessages id="invoices.payment.paymentType.E_Payment" />
                </MenuItem>
              </AppAdvanceFilterSelect>,
            ]}
          />
        </Grid>
        <Hidden mdDown>
          <Grid item xs={12}>
            <AppDataGrid
              rowCount={PaymentList?.totalCount ?? 0}
              pageNumber={search?.pageNumber ?? 0}
              //for pagination
              onPageSizeChange={(pageSize: number) => {
                setSearch({ ...search, pageSize: pageSize });
              }}
              onPageChange={(pageNumber: number) => {
                setSearch({ ...search, pageNumber: pageNumber + 1 });
              }}
              pageSize={search?.pageSize ?? 10}
              columns={columns}
              rows={PaymentList?.data ?? []}
              loading={isFetching}
              //for selection
              onSelectionModelChange={(
                newSelectionModel: GridSelectionModel
              ) => {
                setSelectionModel(newSelectionModel);
              }}
              checkboxSelection={false}
              selectionModel={selectionModel}
              //for sorting
              // onSortModelChange={(result: string[] | null) => {
              //   setSearch({
              //     ...search,
              //     orderBy: result,
              //   });
              // }}
            />
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid item xs={12}>
            <PaymentCard
              paymentList={PaymentList?.data!}
              setPage={(event, page) => {
                setSearch({
                  ...search,
                  pageNumber: page,
                });
              }}
              pageNumber={Math.ceil((PaymentList?.totalCount || 0) / 10)}
              page={search?.pageNumber}
              loading={isFetching}
              errorMessage={error}
              handleDetails={handleDetails}
              handleApprove={handleApprove}
            />
          </Grid>
        </Hidden>
      </Grid>

      {/* Add State */}
      {showApproveModal && (
        <ApprovePayment
          label="table.actions.approve"
          showAddModal={showApproveModal}
          setShowAddModal={setShowApproveModal}
          selectedRow={selectedRow!}
        />
      )}
      {showDetailsModal && (
        <PaymentDetails
          PaymentId={selectedRow?.id!}
          setShowDetails={setShowDetailsModal}
          showDetails={showDetailsModal}
        />
      )}
    </>
  );
};

export default PaymentPages;
