import { Grid, Hidden } from "@mui/material";
import { GridSelectionModel } from "@mui/x-data-grid";
import AppBreadcrumb from "components/basicComponents/breadcrumb/AppBreadcrumb";
import AppDataGrid from "components/basicComponents/dataGrid/AppDataGrid";
import AppAdvanceFilterTextField from "components/basicComponents/filters/AppAdvanceFilterTextField";
import AppFilters from "components/basicComponents/filters/AppFilters";
import { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { useGetListService, useToggleActiveService } from "services";
import { AppUrls } from "services/appUrls";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import { IStudentListDto, IStudentSearch } from "types/entitiesTypes/student";
import { StudentColumns } from "./StudentColumns";
import { useParams } from "react-router-dom";
import StudentCard from "./StudentCard";
import AppConfirmModal from "components/basicComponents/modals/AppConfirmModal";
import EditAcademicNumber from "components/trainStructure/student/EditAcademicNumber";
import { IPdfHeader } from "types/appTypes/baseEntity/baseDto";

const StudentPages = () => {
  const { messages } = useIntl();
  const { diplomaIdParams } = useParams();

  const [search, setSearch] = useState<IStudentSearch>({
    pageNumber: 1,
    pageSize: 10,
    diplomaId: diplomaIdParams!,
  });
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
  const [showActivationModal, setShowActivationModal] =
    useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<IStudentListDto>();
  useState<boolean>(false);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  // For hide breadcrumb in country profile
  // For Advance Filters
  const [advancedFilter, setAdvancedFilter] = useState(null);
  const [isOpenAdvanceFilters, setIsOpenAdvanceFilters] =
    useState<boolean>(false);

  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const {
    resultData: StudentsList,
    isFetching,
    error,
  } = useGetListService<IStudentListDto>(AppUrls.Student, {
    ...search,
    advancedFilter,
  });

  // For details
  const handleDetails = useCallback((selectedRow: IStudentListDto) => {
    setSelectedRow(selectedRow);
    setShowDetailsModal(true);
  }, []);
  //for Activation
  const handleToggleActive = useCallback((selectedRow: IStudentListDto) => {
    setSelectedRow(selectedRow);
    setShowActivationModal(true);
  }, []);

  // For edit
  const handleUpdate = useCallback((selectedRow: IStudentListDto) => {
    setSelectedRow(selectedRow);
    setShowEditModal(true);
  }, []);

  const columns = StudentColumns(
    handleToggleActive,
    handleDetails,
    handleUpdate
  );
  const mutationForToggleActive = useToggleActiveService<boolean>(
    AppUrls.Student,
    selectedRow?.id || "",
    () => setShowActivationModal(false)
  );

  const pdfHeaders: IPdfHeader[] = [
    { key: "id", value: String(messages["student.id"]) },

    { key: "fullName", value: String(messages["student.name"]) },

    { key: "email", value: String(messages["common.email"]) },
    {
      key: "academicNumber",
      value: String(messages["student.academicNumber"]),
    },
    {
      key: "nationalNumber",
      value: String(messages["common.nationalNumber"]),
    },
    {
      key: "phoneNumber",
      value: String(messages["common.phone"]),
    },
    {
      key: "isActive",
      value: String(messages["common.isActive"]),
    },
  ];
  return (
    <>
      <Grid container spacing={3}>
        {!diplomaIdParams && (
          <AppBreadcrumb
            paths={[{ name: messages["sidebar.usersManagement"] }]}
            currentPathName={"sidebar.students"}
          />
        )}

        <Grid item xs={12}>
          <AppFilters
            //excel pdf
            filterAll={{
              ...search,
              advancedFilter,
            }}
            onChangeKeywordValue={(
              event: React.ChangeEvent<HTMLInputElement>
            ) => {
              setSearch({ ...search, keyword: event.target.value });
            }}
            selectionModel={selectionModel as string[]}
            setSelectionModel={setSelectionModel}
            successUrl={AppUrls.Student}
            keyword={search?.keyword}
            advancedFilter={advancedFilter}
            showAddButton={false}
            totalCount={StudentsList?.totalCount || 0}
            // For Permissions
            schemaName={SchemaName.SystemConfigurations}
            entityName={EntityName.Countries}
            action={PermissionActionsTypes.Add}
            // Advance Filters
            showAdvanceFiltersButton={false}
            isOpenAdvanceFilters={isOpenAdvanceFilters}
            setIsOpenAdvanceFilters={setIsOpenAdvanceFilters}
            setAdvancedFilter={setAdvancedFilter}
            pdfHeaders={pdfHeaders}
            excelFileTitle={String(messages["sidebar.students"])}
            withExportExcelButton
            customQueries={{
              ...search,
            }}
            advanceFiltersInputs={[
              <AppAdvanceFilterTextField
                fieldName="email"
                label="common.email"
                setAdvancedFilter={setAdvancedFilter}
                advancedFilter={advancedFilter}
              />,
            ]}
          />
        </Grid>
        <Hidden mdDown>
          <Grid item xs={12}>
            <AppDataGrid
              rowCount={StudentsList?.totalCount ?? 0}
              pageNumber={search?.pageNumber ?? 0}
              //for pagination
              onPageSizeChange={(pageSize: number) => {
                setSearch({ ...search, pageSize: pageSize });
              }}
              onPageChange={(pageNumber: number) => {
                setSearch({ ...search, pageNumber: pageNumber + 1 });
              }}
              pageSize={search?.pageSize ?? 10}
              columns={columns}
              rows={StudentsList?.data ?? []}
              loading={isFetching}
              //for selection
              onSelectionModelChange={(
                newSelectionModel: GridSelectionModel
              ) => {
                setSelectionModel(newSelectionModel);
              }}
              checkboxSelection={false}
              selectionModel={selectionModel}
              //for sorting
              // onSortModelChange={(result: string[] | null) => {
              //   setSearch({
              //     ...search,
              //     orderBy: result,
              //   });
              // }}
            />
          </Grid>
        </Hidden>

        <Hidden mdUp>
          <Grid item xs={12}>
            <StudentCard
              StudentsList={StudentsList?.data!}
              setPage={(event, page) => {
                setSearch({
                  ...search,
                  pageNumber: page,
                });
              }}
              pageNumber={Math.ceil((StudentsList?.totalCount || 0) / 10)}
              page={search?.pageNumber}
              loading={isFetching}
              errorMessage={error}
            />
          </Grid>
        </Hidden>
      </Grid>

      {showEditModal && (
        <EditAcademicNumber
          showEditModal={showEditModal}
          setShowEditModal={setShowEditModal}
          label="student.editAcademicNumber"
          selectedRow={{
            id: selectedRow?.id || "",
            nameAr: selectedRow?.fullName || "",
            nameEn: selectedRow?.fullName || "",
          }}
        />
      )}

      {showActivationModal && (
        <AppConfirmModal
          dialogTitle={
            selectedRow?.isActive
              ? "student.actions.deactivationTitle"
              : "student.actions.activationTitle"
          }
          open={showActivationModal}
          setOpen={setShowActivationModal}
          itemName={selectedRow?.fullName}
          loading={mutationForToggleActive.isLoading}
          title={
            selectedRow?.isActive
              ? "student.actions.deactivationConfirmation"
              : "student.actions.activationConfirmation"
          }
          onConfirm={() => {
            mutationForToggleActive.mutate();
          }}
        />
      )}
    </>
  );
};

export default StudentPages;
