import AppTextField from "@crema/core/AppFormComponents/AppTextField";
import IntlMessages from "@crema/utility/IntlMessages";
import { Grid } from "@mui/material";
import AppCustomizedSwitch from "components/basicComponents/inputs/AppCustomizedSwitch";
import AppPhoneNumber from "components/basicComponents/inputs/AppPhoneNumber";
import AppEditModal from "components/basicComponents/modals/AppEditModal";
import AppUploadPhoto from "components/basicComponents/uploadFile/uploadPhoto/AppUploadPhoto";
import { Form, Formik, FormikProps } from "formik";
import { generatePresignedUrl, getValueBasedOnLang } from "helpers";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useGetByIdService, useUpdateService } from "services";
import { AppUrls } from "services/appUrls";
import { ISelectedRow } from "types/appTypes/baseEntity/baseDto";
import {
  IInstructorsDetailsDto,
  IUpdateInstructorDto,
} from "types/entitiesTypes/instructors";

import {
  IRegionsDetailsDto,
  IUpdateIRegionDto,
} from "types/entitiesTypes/regions";
import * as yup from "yup";

interface IEditInstructorProps {
  showEditModal: boolean;
  setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  label: string;
  selectedRow: ISelectedRow;
  onSuccess?: () => void;
  onFailed?: () => void;
}
const EditInstructor: React.FC<IEditInstructorProps> = (props) => {
  const { showEditModal, setShowEditModal, label, selectedRow } = props;
  const { messages } = useIntl();
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);

  const refForm = React.useRef<FormikProps<IUpdateInstructorDto>>(null);
  const {
    data: instructorItem,
    error,
    isLoading,
  } = useGetByIdService<IInstructorsDetailsDto>(
    AppUrls.Instructor,
    selectedRow?.id
  );

  const mutation = useUpdateService<IUpdateInstructorDto, string>(
    AppUrls.Instructor,
    () => {}
  );
  const validationSchema = yup.object({
    firstNameAr: yup.string().required(String(messages["validation.required"])),
    firstNameEn: yup.string().required(String(messages["validation.required"])),
    lastNameAr: yup.string().required(String(messages["validation.required"])),
    lastNameEn: yup.string().required(String(messages["validation.required"])),

    email: yup
      .string()
      .nullable()
      .email(String(messages["validation.email"]))
      .required(String(messages["validation.required"])),

    phoneNumber: yup.string().required(String(messages["validation.required"])),

    profilePic: yup
      .array()
      .min(1, String(messages["validation.file"]))
      .required("validation.file"),
  });
  useEffect(() => {
    if (instructorItem && instructorItem.profilePic)
      setUploadedFiles([
        {
          preview: generatePresignedUrl(
            instructorItem.profilePic,
            process.env.NODE_ENV
          ),
          // BaseFileUrl + instructorItem.profilePic,
          originalUrl: instructorItem.profilePic,
        },
      ]);
  }, [instructorItem]);

  useEffect(() => {
    if (uploadedFiles)
      refForm.current?.setFieldValue("profilePic", uploadedFiles);
  }, [uploadedFiles]);
  const submit = () => {
    if (refForm.current) {
      refForm.current.submitForm();
    }
  };

  return (
    <>
      <AppEditModal
        label={label}
        itemName={getValueBasedOnLang(
          ` - ${selectedRow?.nameAr}`,
          selectedRow?.nameEn ? ` - ${selectedRow?.nameEn}` : ""
        )}
        open={showEditModal}
        onClose={() => {
          setShowEditModal(false);
        }}
        handleSubmit={submit}
        loading={isLoading}
        errorMessage={(error as any) || null}
        loadingForEdit={
          (refForm.current?.isSubmitting && !refForm.current?.isValidating) ||
          false
        }
        closeModalState={setShowEditModal}
        successClose={mutation.isSuccess}
        maxWidth={"md"}
      >
        <Formik
          validateOnChange={true}
          initialValues={{
            id: selectedRow?.id || "",
            firstNameAr: instructorItem?.firstNameAr || "",
            firstNameEn: instructorItem?.firstNameEn || "",
            lastNameAr: instructorItem?.lastNameAr || "",
            lastNameEn: instructorItem?.lastNameEn || "",
            phoneNumber: instructorItem?.phoneNumber || "",
            email: instructorItem?.email || "",
            profilePic: instructorItem?.profilePic || "",

            // descriptionAr: instructorItem?.descriptionAr || "",
            // descriptionEn: instructorItem?.descriptionEn || "",

            bioAr: instructorItem?.bioAr || "",
            bioEn: instructorItem?.bioEn || "",
            isActive: instructorItem?.isActive!,
          }}
          innerRef={refForm}
          validationSchema={validationSchema}
          onSubmit={async (data, { setSubmitting }) => {
            const uploadedImage = uploadedFiles[0];
            if (uploadedImage) {
              // Image has not changed
              if (uploadedImage.originalUrl)
                data.profilePic = uploadedFiles[0].profilePic;
              else data.profilePic = uploadedImage;
            }
            mutation.submitData(data);
          }}
        >
          {({ setFieldValue, values, errors, touched }) => (
            <Form>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={4}>
                  <AppTextField
                    label={<IntlMessages id="common.firstNameAr" />}
                    name="firstNameAr"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <AppTextField
                    label={<IntlMessages id="common.lastNameAr" />}
                    name="lastNameAr"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <AppTextField
                    label={<IntlMessages id="common.firstNameEn" />}
                    name="firstNameEn"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <AppTextField
                    label={<IntlMessages id="common.lastNameEn" />}
                    name="lastNameEn"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <AppTextField
                    label={<IntlMessages id="common.email" />}
                    name="email"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <AppPhoneNumber
                    label={"common.phone"}
                    keyName="phoneNumber"
                    variant="outlined"
                    onChange={setFieldValue}
                    value={values.phoneNumber || ""}
                    error={!!(touched.phoneNumber && errors.phoneNumber)}
                    size="small"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <AppTextField
                    label={<IntlMessages id="common.bioAr" />}
                    name="bioAr"
                    variant="outlined"
                    size="small"
                    fullWidth
                    multiline
                    minRows={3}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AppTextField
                    label={<IntlMessages id="common.bioEn" />}
                    name="bioEn"
                    variant="outlined"
                    size="small"
                    fullWidth
                    multiline
                    minRows={3}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  justifyContent={"flex-start"}
                >
                  <AppCustomizedSwitch
                    keyName="isActive"
                    onChange={setFieldValue}
                    defaultChecked={values?.isActive}
                  />
                </Grid>
                <AppUploadPhoto
                  uploadedFiles={uploadedFiles}
                  setUploadedFiles={setUploadedFiles}
                  keyName="profilePic"
                  formRef={refForm}
                  errors={!!errors.profilePic}
                  uploadText="common.uploadPhoto"
                  helperText="validation.file"
                  appGridXs={12}
                  uploadModernXs={12}
                  smUpload={4}
                  smShow={6}
                />
              </Grid>
            </Form>
          )}
        </Formik>
      </AppEditModal>
    </>
  );
};

export default EditInstructor;
