import AppSelectField from "@crema/core/AppFormComponents/AppSelectField";
import AppTextField from "@crema/core/AppFormComponents/AppTextField";
import IntlMessages from "@crema/utility/IntlMessages";
import { Grid, MenuItem } from "@mui/material";
import AppCustomizedSwitch from "components/basicComponents/inputs/AppCustomizedSwitch";
import AppEditor from "components/basicComponents/inputs/AppEditor";
import AppBaseAutoComplete from "components/basicComponents/inputs/autoComplete/AppBaseAutoComplete";
import AppDatePicker from "components/basicComponents/inputs/dateTimePickers/AppDatePicker";
import AppAddModal from "components/basicComponents/modals/AppAddModal";
import { Form, Formik, FormikProps } from "formik";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useAutocompleteService, useCreateService } from "services";
import { AppUrls } from "services/appUrls";
import { DEBOUNCE_TIME } from "shared/constants/AppConst";
import {
  ICoursesDiplomaListDto,
  ICreateCourseDto,
} from "types/entitiesTypes/courses";
import * as yup from "yup";

interface IAddCourseProps {
  showAddModal: boolean;
  setShowAddModal: React.Dispatch<React.SetStateAction<boolean>>;
  label: string;
  onSuccess?: () => void;
  onFailed?: () => void;
}

const AddCourse: React.FC<IAddCourseProps> = (props) => {
  const { showAddModal, setShowAddModal, label } = props;
  const { messages, locale } = useIntl();
  const refForm = React.useRef<FormikProps<ICreateCourseDto>>(null);

  const mutation = useCreateService<ICreateCourseDto, string>(
    AppUrls.Courses,
    null,
    false
  );

  const [diplomaKeyword, setDiplomaKeyword] = useState<string>();
  const [pageNumber, setPageNumber] = useState(1);
  const { diplomaIdParams } = useParams();
  const {
    data: diplomaList,
    isFetching: isFetchingDiploma,
    refetch,
  } = useAutocompleteService<ICoursesDiplomaListDto>(
    AppUrls.DiplomasCourses,
    {
      isActive: true,
      baseColumnsKeyword: diplomaKeyword,
      pageSize: 10,
      pageNumber: pageNumber,
    },
    Boolean(!diplomaIdParams)
  );
  useEffect(() => {
    refetch();
  }, [pageNumber]);

  const validationSchema = yup.object({
    nameAr: yup.string().required(String(messages["validation.required"])),
    nameEn: yup.string().required(String(messages["validation.required"])),
    diplomaId: yup.string().required(String(messages["validation.required"])),
    termId: yup.string().required(String(messages["validation.required"])),
    contentAr: yup.string().required(String(messages["validation.required"])),
    contentEn: yup.string().required(String(messages["validation.required"])),
    startDate: yup
      .date()
      .required(String(messages["validation.required"]))
      .typeError(String(messages["validation.date"])),
  });

  const submit = () => {
    if (refForm.current) {
      refForm.current.submitForm();
    }
  };

  return (
    <>
      <AppAddModal
        label={label}
        open={showAddModal}
        onClose={() => setShowAddModal(false)}
        closeModalState={setShowAddModal}
        successClose={mutation.isSuccess}
        handleSubmit={submit}
        loadingForAdd={
          (refForm.current?.isSubmitting && !refForm.current?.isValidating) ||
          false
        }
        maxWidth={"md"}
      >
        <Formik
          validateOnChange={true}
          initialValues={{
            nameAr: "",
            nameEn: "",
            contentAr: "",
            contentEn: "",
            startDate: "",
            endDate: "",
            termId: 1,
            diplomaId: diplomaIdParams ? diplomaIdParams : "",
            isActive: true,
          }}
          validationSchema={validationSchema}
          innerRef={refForm}
          onSubmit={async (data, { setSubmitting }) => {
            mutation.submitData(data);
          }}
        >
          {({ setFieldValue, values, errors, touched }) => (
            <Form>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={4}>
                  <AppTextField
                    label={<IntlMessages id="common.nameAr" />}
                    name="nameAr"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <AppTextField
                    label={<IntlMessages id="common.nameEn" />}
                    name="nameEn"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <AppSelectField
                    label={<IntlMessages id="course.semester" />}
                    name="termId"
                    variant="outlined"
                    size="small"
                    fullWidth
                    defaultValue={1}
                  >
                    <MenuItem value={1}>
                      <IntlMessages id="course.semester.first" />
                    </MenuItem>
                    <MenuItem value={2}>
                      <IntlMessages id="course.semester.second" />
                    </MenuItem>
                  </AppSelectField>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <AppDatePicker
                    label="common.startDate"
                    name="startDate"
                    value={values?.startDate!}
                    onChange={setFieldValue}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <AppDatePicker
                    label="common.endDate"
                    name="endDate"
                    value={values?.endDate!}
                    onChange={setFieldValue}
                  />
                </Grid>
                {!diplomaIdParams && (
                  <Grid item xs={12} sm={4}>
                    <AppBaseAutoComplete
                      label="course.diploma"
                      getOptionLabel={(option) =>
                        locale == "en-US" ? option.nameEn : option.nameAr
                      }
                      // defaultValue={branchItem?.state ?? null}
                      options={diplomaList?.data}
                      isLoading={isFetchingDiploma}
                      onChangeAutoComplete={(event, value) => {
                        setFieldValue("diplomaId", value ? value?.id : "");
                        !value && setDiplomaKeyword("");
                      }}
                      //pagination
                      pageNumber={pageNumber}
                      setPageNumber={setPageNumber}
                      hasNextPage={diplomaList?.hasNextPage}
                      searchText={diplomaKeyword}
                      onChangeTextField={debounce((event) => {
                        setPageNumber(1);
                        setDiplomaKeyword(event.target.value);
                      }, DEBOUNCE_TIME)}
                      error={errors?.diplomaId}
                      touched={touched?.diplomaId}
                    />
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  justifyContent={"flex-start"}
                >
                  <AppCustomizedSwitch
                    keyName="isActive"
                    onChange={setFieldValue}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <AppEditor
                    label="common.contentAr"
                    name="contentAr"
                    setFieldValue={setFieldValue}
                    value={values?.contentAr || ""}
                    error={!!(errors?.contentAr && touched?.contentAr)}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <AppEditor
                    label="common.contentEn"
                    name="contentEn"
                    setFieldValue={setFieldValue}
                    value={values?.contentEn || ""}
                    error={!!(errors?.contentEn && touched?.contentEn)}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </AppAddModal>
    </>
  );
};

export default AddCourse;
