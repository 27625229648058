import AppTextField from "@crema/core/AppFormComponents/AppTextField";
import IntlMessages from "@crema/utility/IntlMessages";
import { Grid, MenuItem } from "@mui/material";
import AppCustomizedSwitch from "components/basicComponents/inputs/AppCustomizedSwitch";
import AppDropDownList from "components/basicComponents/inputs/AppDropDownList";
import AppTextAreaField from "components/basicComponents/inputs/AppTextAreaField";
import AppAddModal from "components/basicComponents/modals/AppAddModal";
import { Form, Formik, FormikProps } from "formik";
import React from "react";
import { useIntl } from "react-intl";
import { useCreateService } from "services";
import { AppUrls } from "services/appUrls";
import { RoleLevel, UserType } from "types/entitiesTypes/enums";
import { ICreateRoleDto } from "types/entitiesTypes/roles";
import * as yup from "yup";

interface IAddRoleProps {
  showAddModal: boolean;
  setShowAddModal: React.Dispatch<React.SetStateAction<boolean>>;
  label: string;
  onSuccess?: () => void;
  onFailed?: () => void;
}

const AddRole: React.FC<IAddRoleProps> = (props) => {
  const { showAddModal, setShowAddModal, label } = props;
  const { messages } = useIntl();
  const refForm = React.useRef<FormikProps<ICreateRoleDto>>(null);

  const mutation = useCreateService<ICreateRoleDto, string>(
    AppUrls.Roles,
    null,
    false
  );

  const validationSchema = yup.object({
    nameAr: yup.string().required(String(messages["validation.required"])),
    name: yup.string().required(String(messages["validation.required"])),
    staticIdentifier: yup
      .string()
      .required(String(messages["validation.static.required"])),
  });

  const submit = () => {
    if (refForm.current) {
      refForm.current.submitForm();
    }
  };

  return (
    <>
      <AppAddModal
        label={label}
        open={showAddModal}
        onClose={() => setShowAddModal(false)}
        closeModalState={setShowAddModal}
        successClose={mutation.isSuccess}
        handleSubmit={submit}
        loadingForAdd={
          (refForm.current?.isSubmitting && !refForm.current?.isValidating) ||
          false
        }
        maxWidth={"sm"}
      >
        <Formik
          validateOnChange={true}
          initialValues={{
            name: "",
            nameAr: "",
            staticIdentifier: "",

            descriptionAr: "",
            descriptionEn: "",
            userType: "User",
            isActive: true,
          }}
          validationSchema={validationSchema}
          innerRef={refForm}
          onSubmit={async (data, { setSubmitting }) => {
            mutation.submitData(data);
          }}
        >
          {({ setFieldValue, values, touched, errors }) => (
            <Form>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <AppTextField
                    label={<IntlMessages id="common.nameAr" />}
                    name="nameAr"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={6}>
                  <AppTextField
                    label={<IntlMessages id="common.nameEn" />}
                    name="name"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <AppTextField
                    label={<IntlMessages id="common.staticIdentifier" />}
                    name="staticIdentifier"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  display={"flex"}
                  justifyContent={"flex-start"}
                >
                  <AppCustomizedSwitch
                    keyName="isActive"
                    onChange={setFieldValue}
                  />
                </Grid>

                <Grid item xs={6}>
                  <AppTextAreaField
                    label="common.descriptionAr"
                    name="descriptionAr"
                  />
                </Grid>

                <Grid item xs={6}>
                  <AppTextAreaField
                    label="common.descriptionEn"
                    name="descriptionEn"
                  />
                </Grid>

                {/* <Grid xs={6} item>
                  <AppDropDownList
                    keyName="userType"
                    onChange={(field, value) => {
                      setFieldValue("userType", value);
                    }}
                    label={"userTypeType"}
                    labelForShow="users.userType"
                    value={values.userType!}
                    error={touched.userType && Boolean(errors.userType)}
                    helperText={String(touched.userType && errors.userType)}
                  >
                    <MenuItem value={UserType.Teacher}>
                      <IntlMessages id="users.userType.Teacher" />
                    </MenuItem>
                    <MenuItem value={UserType.Employee}>
                      <IntlMessages id="users.userType.Employee" />
                    </MenuItem>
                    <MenuItem value={UserType.Student}>
                      <IntlMessages id="users.userType.Student" />
                    </MenuItem>
                    <MenuItem value={UserType.User}>
                      <IntlMessages id="users.userType.User" />
                    </MenuItem>
                  </AppDropDownList>
                </Grid> */}
              </Grid>
            </Form>
          )}
        </Formik>
      </AppAddModal>
    </>
  );
};

export default AddRole;
