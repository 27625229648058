import {
  Box,
  Card,
  CardContent,
  Grid,
  Pagination,
  Typography,
} from "@mui/material";
import DataItem from "components/basicComponents/mobile/DataItem";
import AppIconButton from "components/basicComponents/others/AppIconButton";
import React, { FC } from "react";
import { useIntl } from "react-intl";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import { IInstructorsListDto } from "types/entitiesTypes/instructors";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import AddDataGridActivation from "components/basicComponents/dataGrid/AddDataGridActivation";
import { AxiosError } from "axios";
import LoadingAndErrorWrapper from "components/basicComponents/others/LoadingAndErrorWrapper";

interface IInstructorCardProps {
  handleUpdate: (row: IInstructorsListDto) => void;
  handleDelete: (row: IInstructorsListDto) => void;
  instructorsList: Array<IInstructorsListDto>;
  handleToggleActive: (row: IInstructorsListDto) => void;
  pageNumber?: number;
  setPage?: (event: React.ChangeEvent<unknown>, page: number) => void;
  page?: number;
  loading: boolean;
  errorMessage: AxiosError<any, any> | null;
}
const InstructorCard: FC<IInstructorCardProps> = (props) => {
  const {
    handleDelete,
    instructorsList,
    handleUpdate,
    handleToggleActive,
    page,
    pageNumber,
    setPage,
    loading,
    errorMessage,
  } = props;
  const { messages } = useIntl();
  return (
    <Grid container spacing={4}>
      <LoadingAndErrorWrapper
        isLoading={loading}
        errorMessage={errorMessage?.response?.data.messages[0]}
      >
        {instructorsList?.length != undefined &&
        instructorsList?.length >= 1 ? (
          instructorsList?.map((item, index) => (
            <Grid key={`instructorsList${index}`} item xs={12}>
              <Card sx={{ width: "100%" }}>
                {/* <CardHeader title={} /> */}
                <Box
                  marginX={4}
                  marginTop={3}
                  display={"flex"}
                  justifyContent={"flex-end"}
                >
                  {/* Details */}
                  <AppIconButton
                    label="table.actions.details"
                    withLink={true}
                    toPathLink={`/trainStructure/instructors/profile/${item?.id}`}
                    childComp={
                      <RemoveRedEyeIcon
                        fontSize="small"
                        sx={{ color: "#007991" }}
                      />
                    }
                    // For Permissions
                    schemaName={SchemaName.SystemConfigurations}
                    entityName={EntityName.Countries}
                    action={PermissionActionsTypes.Details}
                  />

                  {/* Edit */}
                  <AppIconButton
                    label="table.actions.edit"
                    handleClick={() => {
                      handleUpdate(item);
                    }}
                    childComp={
                      <EditOutlinedIcon
                        fontSize="small"
                        sx={{ color: "#A0CCDA" }}
                      />
                    }
                    // For Permissions
                    schemaName={SchemaName.SystemConfigurations}
                    entityName={EntityName.Countries}
                    action={PermissionActionsTypes.Edit}
                  />
                  {/* Activation */}
                  <AddDataGridActivation
                    value={item.isActive}
                    handleClick={() => {
                      handleToggleActive(item);
                    }}
                    // For Permissions
                    schemaName={SchemaName.ClientsManagement}
                    entityName={EntityName.Departments}
                    action={PermissionActionsTypes.Activate}
                  />
                  {/* Delete */}
                  <AppIconButton
                    label="table.actions.delete"
                    handleClick={() => {
                      handleDelete(item);
                    }}
                    childComp={
                      <DeleteOutlinedIcon
                        fontSize="small"
                        sx={{ color: "#D72638" }}
                      />
                    }
                    // For Permissions
                    schemaName={SchemaName.SystemConfigurations}
                    entityName={EntityName.Countries}
                    action={PermissionActionsTypes.Delete}
                  />
                </Box>
                <CardContent
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: 3,
                  }}
                >
                  <DataItem
                    label={messages["common.fullNameAr"] as string}
                    value={`${item.firstNameAr} ${item.lastNameAr}`}
                  />

                  <DataItem
                    label={messages["common.fullNameEn"] as string}
                    value={`${item.firstNameEn} ${item.lastNameEn}`}
                  />

                  <DataItem
                    label={messages["common.email"] as string}
                    value={item.email}
                  />
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            item
            xs={12}
            marginTop={10}
          >
            <Typography variant="h5" component={"span"}>
              {messages["common.dataFound"]}
            </Typography>
          </Grid>
        )}
        {pageNumber! > 1 && (
          <Grid
            display={"flex"}
            alignItems={"cneter"}
            justifyContent={"center"}
            marginTop={5}
            item
            xs={12}
          >
            <Pagination
              count={pageNumber}
              page={page}
              color="primary"
              onChange={(event, page) => {
                setPage && setPage(event, page);
              }}
            />
          </Grid>
        )}
      </LoadingAndErrorWrapper>
    </Grid>
  );
};

export default InstructorCard;
