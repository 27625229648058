import { Grid } from "@mui/material";
import AppItemView from "components/basicComponents/others/AppItemView";
import BaseProfileDetails from "components/basicComponents/profile/BaseProfileDetails";
import { IStudentDetailsDto } from "types/entitiesTypes/student";

interface IStudentDetailsProps {
  StudentsItem: IStudentDetailsDto;
}

const StudentDetails = (props: IStudentDetailsProps) => {
  const { StudentsItem } = props;

  return (
    <>
      <BaseProfileDetails
        columns={12}
        moreDetails={StudentsItem as IStudentDetailsDto}
      >
        <Grid container item xs={16}>
          <Grid container item xs={14} spacing={4}>
            <AppItemView
              label="student.fullName"
              value={StudentsItem?.fullName}
              sm={4}
            />
            <AppItemView
              label="student.lastName"
              value={StudentsItem?.lastName}
              sm={4}
            />

            <AppItemView
              label="common.email"
              value={StudentsItem?.email}
              sm={4}
            />
            <AppItemView
              label="common.nationalNumber"
              value={StudentsItem?.nationalNumber}
              sm={4}
            />
            <AppItemView
              label="student.academicNumber"
              value={StudentsItem?.academicNumber}
              sm={4}
            />

            <AppItemView
              label="common.phone"
              value={StudentsItem?.phoneNumber}
              sm={4}
              type="isPhoneNumber"
            />
            <AppItemView
              label="student.landLineNumber"
              value={StudentsItem?.landLineNumber}
              sm={4}
            />
            <AppItemView
              label="common.isActive"
              value={StudentsItem?.isActive}
              type="isActive"
              sm={4}
            />
          </Grid>
        </Grid>
      </BaseProfileDetails>
    </>
  );
};

export default StudentDetails;
