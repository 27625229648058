import IntlMessages from "@crema/utility/IntlMessages";
import { Typography } from "@mui/material";
import * as React from "react";
import { useIntl } from "react-intl";

interface IAppDataGridCellLinkProps {
  label: string;
  value: string;
  variant?: any;
  style?: React.CSSProperties;
}

const AppDataGridCellLink: React.FC<IAppDataGridCellLinkProps> = (props) => {
  var arabic = /[\u0600-\u06FF]/;
  const { locale } = useIntl();

  const {
    label,
    variant = "span",
    value,
    style = {
      textAlign: "center",
      direction: arabic.test(value) ? "rtl" : "ltr",
      // transform: locale !== "en-US" ? "rotateY(180deg)" : "none",
    },
  } = props;

  return (
    <Typography noWrap={true} variant={variant} style={style}>
      {value ? (
        <a href={value} target="_blank">
          <IntlMessages id={label} />
        </a>
      ) : (
        "-"
      )}
    </Typography>
  );
};

export default AppDataGridCellLink;
