import AppTextField from "@crema/core/AppFormComponents/AppTextField";
import IntlMessages from "@crema/utility/IntlMessages";
import { Grid } from "@mui/material";
import AppAddModal from "components/basicComponents/modals/AppAddModal";
import AppUploadDocument from "components/basicComponents/uploadFile/uploadDocument/AppUploadDocument";
import AppUploadPhoto from "components/basicComponents/uploadFile/uploadPhoto/AppUploadPhoto";
import { Form, Formik, FormikProps } from "formik";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useCreateService } from "services";
import { useCustomCreateService } from "services/additionalServices";
import { AppUrls } from "services/appUrls";
import { IApprovePayment, IPaymentListDto } from "types/entitiesTypes/payment";
import { ICreateIRegionDto } from "types/entitiesTypes/regions";
import * as yup from "yup";

interface IApprovePaymentProps {
  showAddModal: boolean;
  setShowAddModal: React.Dispatch<React.SetStateAction<boolean>>;
  label: string;
  selectedRow: IPaymentListDto;
  onSuccess?: () => void;
  onFailed?: () => void;
}

const ApprovePayment: React.FC<IApprovePaymentProps> = (props) => {
  const { showAddModal, setShowAddModal, label, selectedRow } = props;
  const { messages, locale } = useIntl();
  const refForm = React.useRef<FormikProps<IApprovePayment>>(null);

  const mutation = useCustomCreateService<IApprovePayment, string>(
    AppUrls.PaymentApprovement,
    AppUrls.Payment,
    "setAllSuccessfully",
    null,
    true,
    () => {}
  );

  const validationSchema = yup.object({
    paymentNumberS: yup
      .string()
      .required(String(messages["validation.required"])),
  });

  const submit = () => {
    if (refForm.current) {
      refForm.current.submitForm();
    }
  };

  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);

  return (
    <>
      <AppAddModal
        label={label}
        open={showAddModal}
        itemName={
          locale == "en-US" && selectedRow?.subscription?.diploma?.nameEn
            ? ` - ${selectedRow?.subscription?.diploma?.nameEn!}`
            : ` - ${selectedRow?.subscription?.diploma?.nameAr!}`
        }
        onClose={() => setShowAddModal(false)}
        closeModalState={setShowAddModal}
        successClose={mutation.isSuccess}
        handleSubmit={submit}
        loadingForAdd={
          (refForm.current?.isSubmitting && !refForm.current?.isValidating) ||
          false
        }
        maxWidth={"sm"}
      >
        <Formik
          validateOnChange={true}
          initialValues={{
            paymentNumberS: "",
            paymentStatus: 1,
            paymentId: selectedRow?.id,
          }}
          validationSchema={validationSchema}
          innerRef={refForm}
          onSubmit={async (data, { setSubmitting }) => {
            if (uploadedFiles)
              data = { ...data, paymentNotice: uploadedFiles[0] };

            mutation.submitData(data);
          }}
        >
          {({ setFieldValue }) => (
            <Form>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={12}>
                  <AppTextField
                    label={<IntlMessages id="common.paymentNumberS" />}
                    name="paymentNumberS"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <AppUploadDocument
                  key={"paymentNotice"}
                  appGridXs={6}
                  uploadModernXs={3}
                  uploadedFiles={uploadedFiles}
                  setUploadedFiles={setUploadedFiles}
                />
              </Grid>
            </Form>
          )}
        </Formik>
      </AppAddModal>
    </>
  );
};

export default ApprovePayment;
