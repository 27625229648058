import AppTextField from "@crema/core/AppFormComponents/AppTextField";
import IntlMessages from "@crema/utility/IntlMessages";
import { Grid } from "@mui/material";
import AppCustomizedSwitch from "components/basicComponents/inputs/AppCustomizedSwitch";
import AppEditModal from "components/basicComponents/modals/AppEditModal";
import { Form, Formik, FormikProps } from "formik";
import { getValueBasedOnLang } from "helpers";
import React from "react";
import { useIntl } from "react-intl";
import { useGetByIdService, useUpdateService } from "services";
import { AppUrls } from "services/appUrls";
import { ISelectedRow } from "types/appTypes/baseEntity/baseDto";

import {
  IRegionsDetailsDto,
  IUpdateIRegionDto,
} from "types/entitiesTypes/regions";
import * as yup from "yup";

interface IEditRegionProps {
  showEditModal: boolean;
  setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  label: string;
  selectedRow: ISelectedRow;
  onSuccess?: () => void;
  onFailed?: () => void;
}
const EditRegion: React.FC<IEditRegionProps> = (props) => {
  const { showEditModal, setShowEditModal, label, selectedRow } = props;
  const { messages } = useIntl();
  const refForm = React.useRef<FormikProps<IUpdateIRegionDto>>(null);
  const {
    data: regionItem,
    error,
    isLoading,
  } = useGetByIdService<IRegionsDetailsDto>(AppUrls.Regions, selectedRow?.id);

  const mutation = useUpdateService<IUpdateIRegionDto, string>(
    AppUrls.Regions,
    () => {},
    false
  );
  const validationSchema = yup.object({
    nameAr: yup.string().required(String(messages["validation.required"])),
    nameEn: yup.string().required(String(messages["validation.required"])),
  });
  const submit = () => {
    if (refForm.current) {
      refForm.current.submitForm();
    }
  };

  return (
    <>
      <AppEditModal
        label={label}
        itemName={getValueBasedOnLang(
          ` - ${selectedRow?.nameAr}`,
          selectedRow?.nameEn ? ` - ${selectedRow?.nameEn}` : ""
        )}
        open={showEditModal}
        onClose={() => {
          setShowEditModal(false);
        }}
        handleSubmit={submit}
        loading={isLoading}
        errorMessage={(error as any) || null}
        loadingForEdit={
          (refForm.current?.isSubmitting && !refForm.current?.isValidating) ||
          false
        }
        closeModalState={setShowEditModal}
        successClose={mutation.isSuccess}
        maxWidth={"sm"}
      >
        <Formik
          validateOnChange={true}
          initialValues={{
            id: selectedRow?.id || "",
            nameAr: regionItem?.nameAr || "",
            nameEn: regionItem?.nameEn || "",
            isActive: regionItem?.isActive!,
          }}
          innerRef={refForm}
          validationSchema={validationSchema}
          onSubmit={async (data, { setSubmitting }) => {
            mutation.submitData(data);
          }}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <AppTextField
                    label={<IntlMessages id="common.nameAr" />}
                    name="nameAr"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <AppTextField
                    label={<IntlMessages id="common.nameEn" />}
                    name="nameEn"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  justifyContent={"flex-start"}
                >
                  <AppCustomizedSwitch
                    keyName="isActive"
                    onChange={setFieldValue}
                    defaultChecked={values?.isActive}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </AppEditModal>
    </>
  );
};

export default EditRegion;
