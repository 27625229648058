import AppSelectField from "@crema/core/AppFormComponents/AppSelectField";
import AppTextField from "@crema/core/AppFormComponents/AppTextField";
import IntlMessages from "@crema/utility/IntlMessages";
import { Grid, MenuItem, TextField } from "@mui/material";
import AppEditor from "components/basicComponents/inputs/AppEditor";
import AppBaseAutoComplete from "components/basicComponents/inputs/autoComplete/AppBaseAutoComplete";
import AppDateTimePicker from "components/basicComponents/inputs/dateTimePickers/AppDateTimePicker";
import AppTimePicker from "components/basicComponents/inputs/dateTimePickers/AppTimePicker";
import AppAddModal from "components/basicComponents/modals/AppAddModal";
import dayjs from "dayjs";
import { Form, Formik, FormikProps } from "formik";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useAutocompleteService, useCreateService } from "services";
import { AppUrls } from "services/appUrls";
import { DEBOUNCE_TIME } from "shared/constants/AppConst";
import {
  ICoursesDiplomaListDto,
  ICoursesListDto,
} from "types/entitiesTypes/courses";
import { ICreateExamDto } from "types/entitiesTypes/exams";
import { ICreateIRegionDto } from "types/entitiesTypes/regions";
import * as yup from "yup";

interface IAddExamProps {
  showAddModal: boolean;
  setShowAddModal: React.Dispatch<React.SetStateAction<boolean>>;
  label: string;
  onSuccess?: () => void;
  onFailed?: () => void;
}

const AddExam: React.FC<IAddExamProps> = (props) => {
  const { showAddModal, setShowAddModal, label } = props;
  const { messages, locale } = useIntl();
  const refForm = React.useRef<FormikProps<ICreateExamDto>>(null);
  const { courseIdParams, diplomaIdParams } = useParams();

  const [diplomaKeyword, setDiplomaKeyword] = useState<string>();
  const [pageNumber, setPageNumber] = useState(1);

  const [courseKeyword, setCourseKeyword] = useState<string>();
  const [diplomaItem, setDiplomaItem] = useState<ICoursesListDto>();

  const [coursePageNumber, setCoursePageNumber] = useState(1);

  const {
    data: diplomaList,
    isFetching: isFetchingDiploma,
    refetch,
  } = useAutocompleteService<ICoursesDiplomaListDto>(
    AppUrls.DiplomasCourses,
    {
      isActive: true,
      baseColumnsKeyword: diplomaKeyword,
      pageSize: 10,
      pageNumber: pageNumber,
    },
    Boolean(!diplomaIdParams && !courseIdParams)
  );
  useEffect(() => {
    if (pageNumber > 1) refetch();
  }, [pageNumber]);

  const {
    data: coursesList,
    isFetching: isFetchingCourses,
    refetch: refetchCourses,
  } = useAutocompleteService<ICoursesDiplomaListDto>(
    AppUrls.Courses,
    {
      isActive: true,
      baseColumnsKeyword: courseKeyword,
      pageSize: 10,
      pageNumber: coursePageNumber,
      diplomaId: diplomaItem?.id || null,
    },
    Boolean(!courseIdParams)
  );
  useEffect(() => {
    if (coursePageNumber > 1) refetchCourses();
  }, [coursePageNumber]);

  const mutation = useCreateService<ICreateExamDto, string>(
    AppUrls.Exam,
    null,
    false
  );

  const validationSchema = yup.object({
    nameAr: yup.string().required(String(messages["validation.required"])),
    nameEn: yup.string().required(String(messages["validation.required"])),
    isDiplomaRequire: yup.boolean(),

    courseId: yup.string().when("isDiplomaRequire", {
      is: (isDiplomaRequire) => isDiplomaRequire,
      then: yup.string().required(String(messages["validation.required"])),
      otherwise: yup.string().nullable(),
    }),
    diplomaId: yup.string().when("isDiplomaRequire", {
      is: (isDiplomaRequire) => isDiplomaRequire,
      then: yup.string().required(String(messages["validation.required"])),
      otherwise: yup.string().nullable(),
    }),

    date: yup
      .date()
      .typeError(String(messages["validation.required"]))
      .required(String(messages["validation.required"]))
      .min(new Date(), String(messages["validation.timeGreaterThanFrom"])),
    instructionsAr: yup
      .string()
      .required(String(messages["validation.required"])),
    instructionsEn: yup
      .string()
      .required(String(messages["validation.required"])),

    // duration: yup
    //   .string()
    //   .matches(
    //     /^(0[0-9]|1[0-2]):([0-5][0-9])$/,
    //     String(messages["validation.timesSpan.required"])
    //   )
    //   .typeError(String(messages["validation.required"]))
    //   .required(String(messages["validation.required"])),
    durationInMin: yup
      .number()
      .typeError(String(messages["validation.required"]))
      .required(String(messages["validation.required"])),
    totalMarks: yup
      .number()
      .typeError(String(messages["validation.required"]))
      .required(String(messages["validation.required"])),
    passingScore: yup
      .number()
      .typeError(String(messages["validation.required"]))
      .required(String(messages["validation.required"]))
      .when("totalMarks", (totalMarks, schema) => {
        return totalMarks
          ? schema.lessThan(
              totalMarks,
              String(messages["validation.passingScore"])
            )
          : schema;
      }),
  });

  const submit = () => {
    if (refForm.current) {
      refForm.current.submitForm();
    }
  };

  return (
    <>
      <AppAddModal
        label={label}
        open={showAddModal}
        onClose={() => setShowAddModal(false)}
        closeModalState={setShowAddModal}
        successClose={mutation.isSuccess}
        handleSubmit={submit}
        loadingForAdd={
          (refForm.current?.isSubmitting && !refForm.current?.isValidating) ||
          false
        }
        maxWidth={"md"}
      >
        <Formik
          validateOnChange={true}
          initialValues={{
            nameAr: "",
            nameEn: "",
            instructionsAr: "",
            instructionsEn: "",
            isDiplomaRequire: Boolean(!courseIdParams),
            courseId: courseIdParams || "",
            diplomaId: diplomaIdParams || "",
            date: "",
            durationInMin: null,
            passingScore: null,
            totalMarks: 100,
            type: 1,
          }}
          validationSchema={validationSchema}
          innerRef={refForm}
          onSubmit={async (data, { setSubmitting }) => {
            mutation.submitData(data);
          }}
        >
          {({ setFieldValue, values, errors, touched }) => {
            console.log("errorserrors", errors);

            return (
              <Form>
                <Grid container spacing={4}>
                  {!diplomaIdParams && !courseIdParams && (
                    <Grid item xs={12} sm={3}>
                      <AppBaseAutoComplete
                        label="course.diploma"
                        getOptionLabel={(option) =>
                          locale == "en-US" ? option.nameEn : option.nameAr
                        }
                        // defaultValue={branchItem?.state ?? null}
                        options={diplomaList?.data}
                        isLoading={isFetchingDiploma}
                        onChangeAutoComplete={(event, value) => {
                          setFieldValue("courseId", "");
                          setDiplomaItem(value);
                          setFieldValue("diplomaId", value ? value?.id : "");
                          !value && setDiplomaKeyword("");
                        }}
                        //pagination
                        pageNumber={pageNumber}
                        setPageNumber={setPageNumber}
                        hasNextPage={diplomaList?.hasNextPage}
                        searchText={diplomaKeyword}
                        onChangeTextField={debounce((event) => {
                          setPageNumber(1);
                          setDiplomaKeyword(event.target.value);
                        }, DEBOUNCE_TIME)}
                        error={errors?.diplomaId}
                        touched={touched?.diplomaId}
                      />
                    </Grid>
                  )}

                  {!diplomaIdParams && !courseIdParams && (
                    <Grid item xs={12} sm={3}>
                      <AppBaseAutoComplete
                        key={"diplomaId"}
                        disabled={Boolean(!values?.diplomaId)}
                        label="session.courseName"
                        getOptionLabel={(option) =>
                          locale == "en-US" ? option.nameEn : option.nameAr
                        }
                        // defaultValue={branchItem?.state ?? null}
                        options={coursesList?.data}
                        isLoading={isFetchingCourses}
                        onChangeAutoComplete={(event, value) => {
                          setFieldValue("courseId", value ? value?.id : "");
                          !value && setCourseKeyword("");
                        }}
                        //pagination
                        pageNumber={coursePageNumber}
                        setPageNumber={setCoursePageNumber}
                        hasNextPage={coursesList?.hasNextPage}
                        searchText={courseKeyword}
                        onChangeTextField={debounce((event) => {
                          setCoursePageNumber(1);
                          setCourseKeyword(event.target.value);
                        }, DEBOUNCE_TIME)}
                        error={errors?.courseId}
                        touched={touched?.courseId}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={3}>
                    <AppTextField
                      label={<IntlMessages id="common.nameAr" />}
                      name="nameAr"
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <AppTextField
                      label={<IntlMessages id="common.nameEn" />}
                      name="nameEn"
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <AppDateTimePicker
                      value={values?.date}
                      label={"exams.date"}
                      name="date"
                      onChange={setFieldValue}
                      disablePast={true}
                      minTime={false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <AppTextField
                      label={<IntlMessages id="exams.duration" />}
                      name="durationInMin"
                      variant="outlined"
                      size="small"
                      type="number"
                      fullWidth
                    />
                  </Grid>

                  {/* <Grid item xs={12} sm={3}>
                 <AppTextField
                   label={<IntlMessages id="exams.totalMarks" />}
                   name="totalMarks"
                   variant="outlined"
                   size="small"
                   fullWidth
                   type="number"
                 />
               </Grid> */}
                  <Grid item xs={12} sm={3}>
                    <AppTextField
                      label={<IntlMessages id="exams.passingScore" />}
                      name="passingScore"
                      variant="outlined"
                      size="small"
                      fullWidth
                      type="number"
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <AppSelectField
                      label={<IntlMessages id="exams.type" />}
                      name="type"
                      variant="outlined"
                      size="small"
                      fullWidth
                      defaultValue={1}
                    >
                      <MenuItem value={1}>
                        <IntlMessages id="exams.type.first" />
                      </MenuItem>
                      <MenuItem value={2}>
                        <IntlMessages id="exams.type.second" />
                      </MenuItem>
                    </AppSelectField>
                  </Grid>
                  <Grid item xs={12} />
                  <Grid item xs={12} sm={6}>
                    <AppEditor
                      label="exams.instructionsAr"
                      name="instructionsAr"
                      setFieldValue={setFieldValue}
                      value={values?.instructionsAr}
                      error={Boolean(errors?.instructionsAr)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AppEditor
                      label="exams.instructionsEn"
                      name="instructionsEn"
                      setFieldValue={setFieldValue}
                      value={values?.instructionsEn}
                      error={Boolean(errors?.instructionsEn)}
                    />
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </AppAddModal>
    </>
  );
};

export default AddExam;
