import AppTextField from "@crema/core/AppFormComponents/AppTextField";
import IntlMessages from "@crema/utility/IntlMessages";
import { Grid } from "@mui/material";
import AppCustomizedSwitch from "components/basicComponents/inputs/AppCustomizedSwitch";
import AppAddModal from "components/basicComponents/modals/AppAddModal";
import AppEditModal from "components/basicComponents/modals/AppEditModal";
import AppUploadDocument from "components/basicComponents/uploadFile/uploadDocument/AppUploadDocument";
import { Form, Formik, FormikProps } from "formik";
import { getValueBasedOnLang } from "helpers";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useGetByIdService, useUpdateService } from "services";
import {
  useCustomCreateService,
  useCustomUpdateService,
} from "services/additionalServices";
import { AppUrls } from "services/appUrls";
import { ISelectedRow } from "types/appTypes/baseEntity/baseDto";

import {
  IRegionsDetailsDto,
  IUpdateIRegionDto,
} from "types/entitiesTypes/regions";
import { IStudentAttachmentsList } from "types/entitiesTypes/studentAttachments";
import {
  IStudentsSubscriptionListDto,
  IUpdateVirtualSceneFile,
} from "types/entitiesTypes/studentSubscription";
import * as yup from "yup";

interface IEditvirtualSceneFileProps {
  showEditModal: boolean;
  setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  label: string;
  selectedRow: IStudentAttachmentsList;
  onSuccess?: () => void;
  onFailed?: () => void;
}
const EditvirtualSceneFile: React.FC<IEditvirtualSceneFileProps> = (props) => {
  const { showEditModal, setShowEditModal, label, selectedRow } = props;
  const { messages } = useIntl();
  const refForm = React.useRef<FormikProps<IUpdateVirtualSceneFile>>(null);
  const mutation = useCustomUpdateService<IUpdateVirtualSceneFile, string>(
    AppUrls.StudentDocuments,
    AppUrls.StudentDocuments,
    "setAllSuccessfully",

    true,
    "list"
  );
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);

  const validationSchema = yup.object({
    documentPath: yup
      .array()
      .min(1, String(messages["validation.file"]))
      .required("validation.file"),
  });
  const submit = () => {
    if (refForm.current) {
      refForm.current.submitForm();
    }
  };

  //   useEffect(() => {
  //     if (selectedRow && selectedRow.documentFileKey)
  //       setUploadedFiles([
  //         {
  //           preview: BaseFileUrl + selectedRow.documentFileKey,
  //           originalUrl: selectedRow.documentFileKey,
  //         },
  //       ]);
  //   }, [selectedRow]);

  //   useEffect(() => {
  //     if (uploadedFiles)
  //       refForm.current?.setFieldValue("documentPath", uploadedFiles);
  //   }, [uploadedFiles]);

  return (
    <>
      <AppAddModal
        label={label}
        itemName={""}
        open={showEditModal}
        onClose={() => {
          setShowEditModal(false);
        }}
        handleSubmit={submit}
        loadingForAdd={
          (refForm.current?.isSubmitting && !refForm.current?.isValidating) ||
          false
        }
        closeModalState={setShowEditModal}
        successClose={mutation.isSuccess}
        maxWidth={"sm"}
      >
        <Formik
          validateOnChange={true}
          initialValues={{
            id: selectedRow?.id,
            diplomaId: selectedRow?.subscription?.diploma?.id,
            studentId: selectedRow?.subscription?.student?.id,
            type: 1,
            documentPath: "",
          }}
          innerRef={refForm}
          validationSchema={validationSchema}
          onSubmit={async (data, { setSubmitting }) => {
            const documentPathFile = uploadedFiles[0];
            if (documentPathFile)
              data = { ...data, documentPath: uploadedFiles[0] };

            mutation.submitData(data);
          }}
        >
          {({ setFieldValue, values, errors }) => (
            <Form>
              <Grid container spacing={4}>
                <AppUploadDocument
                  formRef={refForm}
                  keyName={"documentPath"}
                  uploadedFiles={uploadedFiles}
                  setUploadedFiles={setUploadedFiles}
                  errors={!!errors.documentPath}
                  helperText="validation.file"
                  appGridXs={6}
                  uploadModernXs={3}
                  accept="application/pdf,application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation"
                />
              </Grid>
            </Form>
          )}
        </Formik>
      </AppAddModal>
    </>
  );
};

export default EditvirtualSceneFile;
