import AppTextField from "@crema/core/AppFormComponents/AppTextField";
import IntlMessages from "@crema/utility/IntlMessages";
import { Grid } from "@mui/material";
import AppCustomizedSwitch from "components/basicComponents/inputs/AppCustomizedSwitch";
import AppPasswordField from "components/basicComponents/inputs/AppPasswordField";
import AppPhoneNumber from "components/basicComponents/inputs/AppPhoneNumber";
import AppAddModal from "components/basicComponents/modals/AppAddModal";
import AppUploadPhoto from "components/basicComponents/uploadFile/uploadPhoto/AppUploadPhoto";
import { Form, Formik, FormikProps } from "formik";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useCreateService } from "services";
import { AppUrls } from "services/appUrls";
import { ICreateInstructorsDto } from "types/entitiesTypes/instructors";
import * as yup from "yup";

interface IAddInstructorProps {
  showAddModal: boolean;
  setShowAddModal: React.Dispatch<React.SetStateAction<boolean>>;
  label: string;
  onSuccess?: () => void;
  onFailed?: () => void;
}

const AddInstructor: React.FC<IAddInstructorProps> = (props) => {
  const { showAddModal, setShowAddModal, label } = props;
  const { messages } = useIntl();
  const refForm = React.useRef<FormikProps<ICreateInstructorsDto>>(null);
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    React.useState<boolean>(false);
  const mutation = useCreateService<ICreateInstructorsDto, string>(
    AppUrls.Instructor,
    null
  );

  const validationSchema = yup.object({
    firstNameAr: yup.string().required(String(messages["validation.required"])),
    firstNameEn: yup.string().required(String(messages["validation.required"])),
    lastNameAr: yup.string().required(String(messages["validation.required"])),
    lastNameEn: yup.string().required(String(messages["validation.required"])),

    email: yup
      .string()
      .nullable()
      .email(String(messages["validation.email"]))
      .required(String(messages["validation.required"])),

    password: yup
      .string()
      .required(String(messages["validation.required"]))
      .min(6, String(messages["common.password.lengthValidation"])),
    confirmPassword: yup
      .string()
      .required(String(messages["validation.required"]))
      .oneOf(
        [yup.ref("password"), null],
        String(messages["common.cofirmPassword.validation"])
      ),
    phoneNumber: yup.string().required(String(messages["validation.required"])),

    profilePic: yup
      .array()
      .min(1, String(messages["validation.file"]))
      .required("validation.file"),
  });

  const submit = () => {
    if (refForm.current) {
      refForm.current.submitForm();
    }
  };

  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);

  return (
    <>
      <AppAddModal
        label={label}
        open={showAddModal}
        onClose={() => setShowAddModal(false)}
        closeModalState={setShowAddModal}
        successClose={mutation.isSuccess}
        handleSubmit={submit}
        loadingForAdd={
          (refForm.current?.isSubmitting && !refForm.current?.isValidating) ||
          false
        }
        maxWidth={"md"}
      >
        <Formik
          validateOnChange={true}
          initialValues={{
            firstNameAr: "",
            firstNameEn: "",
            lastNameAr: "",
            lastNameEn: "",
            phoneNumber: "",
            email: "",
            password: "",
            confirmPassword: "",
            // descriptionAr: "",
            // descriptionEn: "",
            bioAr: "",
            bioEn: "",
            profilePic: "",
            isActive: true,
          }}
          validationSchema={validationSchema}
          innerRef={refForm}
          onSubmit={async (data, { setSubmitting }) => {
            mutation.submitData(data);
          }}
        >
          {({ setFieldValue, values, errors, touched }) => (
            <Form>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={4} lg={3}>
                  <AppTextField
                    label={<IntlMessages id="common.firstNameAr" />}
                    name="firstNameAr"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                  <AppTextField
                    label={<IntlMessages id="common.lastNameAr" />}
                    name="lastNameAr"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                  <AppTextField
                    label={<IntlMessages id="common.firstNameEn" />}
                    name="firstNameEn"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                  <AppTextField
                    label={<IntlMessages id="common.lastNameEn" />}
                    name="lastNameEn"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                  <AppTextField
                    label={<IntlMessages id="common.email" />}
                    name="email"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                  <AppPasswordField
                    label="common.password"
                    name="password"
                    showPassword={showPassword}
                    setShowPassword={setShowPassword}
                  />
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                  <AppPasswordField
                    label="common.confirmPassword"
                    name="confirmPassword"
                    showPassword={showConfirmPassword}
                    setShowPassword={setShowConfirmPassword}
                  />
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                  <AppPhoneNumber
                    label={"common.phone"}
                    keyName="phoneNumber"
                    variant="outlined"
                    onChange={setFieldValue}
                    value={values.phoneNumber || ""}
                    error={!!(touched.phoneNumber && errors.phoneNumber)}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} />

                <Grid item xs={12} sm={6}>
                  <AppTextField
                    label={<IntlMessages id="common.bioAr" />}
                    name="bioAr"
                    variant="outlined"
                    size="small"
                    fullWidth
                    multiline
                    minRows={3}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AppTextField
                    label={<IntlMessages id="common.bioEn" />}
                    name="bioEn"
                    variant="outlined"
                    size="small"
                    fullWidth
                    multiline
                    minRows={3}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  justifyContent={"flex-start"}
                >
                  <AppCustomizedSwitch
                    keyName="isActive"
                    onChange={setFieldValue}
                  />
                </Grid>
                <AppUploadPhoto
                  uploadedFiles={uploadedFiles}
                  setUploadedFiles={setUploadedFiles}
                  keyName="profilePic"
                  formRef={refForm}
                  errors={!!errors.profilePic}
                  uploadText="common.uploadPhoto"
                  helperText="validation.file"
                  appGridXs={12}
                  uploadModernXs={12}
                  smUpload={4}
                  smShow={6}
                />
              </Grid>
            </Form>
          )}
        </Formik>
      </AppAddModal>
    </>
  );
};

export default AddInstructor;
