import IntlMessages from "@crema/utility/IntlMessages";
import { SxProps, Theme } from "@mui/material";
import MuiPhoneNumber from "material-ui-phone-number";
import { parsePhoneNumberFromString } from "libphonenumber-js";

import * as React from "react";

interface IAppPhoneNumberProps {
  defaultCountry?: string;
  disableCountryCode?: boolean;
  disableDropdown?: boolean;
  label: string;
  variant?: "outlined" | "filled" | "standard";
  size?: "small" | "medium";
  keyName: string;
  error?: boolean;
  helperText?: string;
  sxProp?: SxProps<Theme>;
  style?: React.CSSProperties;
  onChange: (field: string, value: any) => void;
  value: string;
  disalbe?: boolean;
  autoFormat?: boolean;
}

const AppPhoneNumber: React.FC<IAppPhoneNumberProps> = (props) => {
  const {
    size = "small",
    defaultCountry = "sy",
    disableCountryCode = false,
    disableDropdown = false,
    keyName,
    label,
    variant = "outlined",
    helperText = "validation.required",
    onChange,
    value,
    error,
    autoFormat = true,
    disalbe = false,
    style = {
      direction: "ltr",
    },
  } = props;

  // const [phone, setPhone] = React.useState<string>("");

  // React.useEffect(() => {
  //   setPhone(value);
  // }, [value]);

  // const formatPhoneNumber = (phoneNumber: string) => {
  //   const parsedNumber = parsePhoneNumberFromString(phoneNumber);
  //   if (parsedNumber && parsedNumber.isValid()) {
  //     return parsedNumber.formatInternational({ v2: true });
  //   }
  //   return "";
  // };

  return (
    <MuiPhoneNumber
      style={style}
      name={keyName}
      autoFormat={autoFormat}
      disableCountryCode={disableCountryCode}
      disableDropdown={disableDropdown}
      defaultCountry={defaultCountry}
      excludeCountries={["il"]}
      label={<IntlMessages id={label} />}
      variant={variant}
      size={size}
      fullWidth
      onChange={(value: any) => {
        onChange(
          keyName,
          parsePhoneNumberFromString(value ?? "")?.isValid ? value : ""
        );
        // setPhone(value);
      }}
      value={value}
      error={error}
      helperText={error && <IntlMessages id={helperText} />}
      sx={{
        pointerEvents: disalbe ? "none" : "inherit",
        svg: {
          height: "20px",
        },
      }}
    />
  );
};

export default AppPhoneNumber;
