import { Grid, Hidden, MenuItem } from "@mui/material";
import { GridSelectionModel } from "@mui/x-data-grid";
import AppBreadcrumb from "components/basicComponents/breadcrumb/AppBreadcrumb";
import AppDataGrid from "components/basicComponents/dataGrid/AppDataGrid";
import AppAdvanceFilterTextField from "components/basicComponents/filters/AppAdvanceFilterTextField";
import AppFilters from "components/basicComponents/filters/AppFilters";
import AppConfirmModal from "components/basicComponents/modals/AppConfirmModal";
import WebsiteContactUsDetails from "components/siteManagement/websiteContactUs/WebsiteContactUsDetails";
import { getValueBasedOnLangForConfirmModal } from "helpers";
import { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { useDeleteService, useGetListService } from "services";
import { AppUrls } from "services/appUrls";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import {
  IWebsiteContactUsListDto,
  IWebsiteContactUsSearch,
} from "types/entitiesTypes/websiteContactUs";
import { WebsiteContactUsColumns } from "./WebsiteContactUsColumns";
import AppAdvanceFilterSelect from "components/basicComponents/filters/AppAdvanceFilterSelect";
import IntlMessages from "@crema/utility/IntlMessages";
import { useCustomToggleActivationService } from "services/additionalServices";
import WebsiteContactUsCard from "./WebsiteContactUsCard";

const WebsiteContactUsPages = () => {
  const { messages, locale } = useIntl();
  const [search, setSearch] = useState<IWebsiteContactUsSearch>({
    pageNumber: 1,
    pageSize: 10,
  });

  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);

  const [selectedRow, setSelectedRow] = useState<IWebsiteContactUsListDto>();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showActivationModal, setShowActivationModal] =
    useState<boolean>(false);
  const [showReadModal, setShowReadModal] = useState<boolean>(false);

  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  // For hide breadcrumb in country profile

  // For Advance Filters
  const [advancedFilter, setAdvancedFilter] = useState(null);
  const [isOpenAdvanceFilters, setIsOpenAdvanceFilters] =
    useState<boolean>(false);

  const {
    resultData: messageData,
    isFetching,
    error,
  } = useGetListService<IWebsiteContactUsListDto>(AppUrls.WebsiteContactUs, {
    ...search,
    advancedFilter,
  });

  // For details
  const handleDetails = useCallback((selectedRow: IWebsiteContactUsListDto) => {
    setSelectedRow(selectedRow);
    setShowDetailsModal(true);
  }, []);

  // for delete
  const handleDelete = useCallback((selectedRow: IWebsiteContactUsListDto) => {
    setSelectedRow(selectedRow);
    setShowDeleteModal(true);
  }, []);
  const handleIsRead = useCallback((selectedRow: IWebsiteContactUsListDto) => {
    setSelectedRow(selectedRow);
    setShowReadModal(true);
  }, []);

  const columns = WebsiteContactUsColumns(
    handleIsRead,
    handleDetails,
    handleDelete
  );

  const mutationForDelete = useDeleteService<string>(
    AppUrls.WebsiteContactUs,
    selectedRow?.id || "",
    () => setShowDeleteModal(false)
  );
  const mutationForToggleRead = useCustomToggleActivationService<
    string,
    string
  >(
    AppUrls.WebsiteContactUsRead,
    AppUrls.WebsiteContactUs,
    selectedRow?.id || "",
    () => setShowReadModal(false),
    // message { onSuccess }
    "websiteNeed.read.message"
  );
  return (
    <>
      <Grid container spacing={3}>
        <AppBreadcrumb
          paths={[{ name: messages["sidebar.SiteManagement.title"] }]}
          currentPathName={"sidebar.websiteContactUs"}
        />
        <Grid item xs={12}>
          <AppFilters
            //excel pdf
            filterAll={{
              ...search,
              advancedFilter,
            }}
            showAddButton={false}
            onChangeKeywordValue={(
              event: React.ChangeEvent<HTMLInputElement>
            ) => {
              setSearch({ ...search, keyword: event.target.value });
            }}
            selectionModel={selectionModel as string[]}
            setSelectionModel={setSelectionModel}
            successUrl={AppUrls.WebsiteContactUs}
            keyword={search?.keyword}
            isActive={search?.isActive}
            advancedFilter={advancedFilter}
            totalCount={messageData?.totalCount || 0}
            excelFileTitle={String(messages["sidebar.EducationalNeeds"])}
            // For Permissions
            schemaName={SchemaName.SystemConfigurations}
            entityName={EntityName.Countries}
            action={PermissionActionsTypes.Add}
            // Advance Filters
            showAdvanceFiltersButton={true}
            isOpenAdvanceFilters={isOpenAdvanceFilters}
            setIsOpenAdvanceFilters={setIsOpenAdvanceFilters}
            setAdvancedFilter={setAdvancedFilter}
            advanceFiltersInputs={[
              <AppAdvanceFilterSelect
                fieldName="suggestType"
                label={"siteManagement.WebsiteContactUs.suggestType"}
                labelForView={locale == "en-US" ? "suggestTypee" : "suggestTy"}
                advancedFilter={search}
                setAdvancedFilter={setSearch}
              >
                <MenuItem value={0}>
                  <IntlMessages id="siteManagement.WebsiteContactUs.suggestType.complaint" />
                </MenuItem>
                <MenuItem value={1}>
                  <IntlMessages id="siteManagement.WebsiteContactUs.suggestType.suggestion" />
                </MenuItem>
              </AppAdvanceFilterSelect>,
            ]}
          />
        </Grid>
        <Hidden mdDown>
          <Grid item xs={12}>
            <AppDataGrid
              rowCount={messageData?.totalCount ?? 0}
              pageNumber={search?.pageNumber ?? 0}
              //for pagination
              onPageSizeChange={(pageSize: number) => {
                setSearch({ ...search, pageSize: pageSize });
              }}
              onPageChange={(pageNumber: number) => {
                setSearch({ ...search, pageNumber: pageNumber + 1 });
              }}
              pageSize={search?.pageSize ?? 10}
              columns={columns}
              rows={messageData?.data ?? []}
              loading={isFetching}
              //for selection
              onSelectionModelChange={(
                newSelectionModel: GridSelectionModel
              ) => {
                setSelectionModel(newSelectionModel);
              }}
              checkboxSelection={false}
              selectionModel={selectionModel}
              //for sorting
              // onSortModelChange={(result: string[] | null) => {
              //   setSearch({
              //     ...search,
              //     orderBy: result,
              //   });
              // }}
            />
          </Grid>
        </Hidden>

        <Hidden mdUp>
          <Grid item xs={12}>
            <WebsiteContactUsCard
              handleDelete={handleDelete}
              websiteContactUsList={messageData?.data!}
              setPage={(event, page) => {
                setSearch({
                  ...search,
                  pageNumber: page,
                });
              }}
              pageNumber={Math.ceil((messageData?.totalCount || 0) / 10)}
              page={search?.pageNumber}
              loading={isFetching}
              errorMessage={error}
              handleDetails={handleDetails}
              handleIsRead={handleIsRead}
            />
          </Grid>
        </Hidden>
      </Grid>

      {showDetailsModal && (
        <WebsiteContactUsDetails
          WebsiteContactUsitem={selectedRow!}
          setShowDetails={setShowDetailsModal}
          showDetails={showDetailsModal}
        />
      )}
      {showReadModal && (
        <AppConfirmModal
          dialogTitle={"siteManagement.websiteContactUs.read"}
          open={showReadModal}
          setOpen={setShowReadModal}
          itemName={""}
          loading={mutationForToggleRead.isLoading}
          title={"siteManagement.websiteContactUs.read.title"}
          onConfirm={() => {
            mutationForToggleRead.mutate();
          }}
        />
      )}
      {showDeleteModal && (
        <AppConfirmModal
          dialogTitle={
            "siteManagement.WebsiteContactUs.actions.delete.dialogTitle"
          }
          open={showDeleteModal}
          setOpen={setShowDeleteModal}
          loading={mutationForDelete.isLoading}
          itemName={getValueBasedOnLangForConfirmModal(
            selectedRow?.fullName || "",
            selectedRow?.fullName || ""
          )}
          title={"siteManagement.WebsiteContactUs.actions.delete.title"}
          onConfirm={() => {
            mutationForDelete.mutate();
          }}
        />
      )}
    </>
  );
};

export default WebsiteContactUsPages;
