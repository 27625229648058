import LoadingAndErrorWrapper from "components/basicComponents/others/LoadingAndErrorWrapper";
import AppProfile, {
  IProfileComponents,
  IProfileRoutes,
} from "components/basicComponents/profile/AppProfile";
import StudentDetails from "components/trainStructure/student/StudentDetails";
import { HiOutlineInformationCircle } from "react-icons/hi";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useGetByIdService } from "services";
import { AppUrls } from "services/appUrls";
import { IStudentDetailsDto } from "types/entitiesTypes/student";
import { EntityName, SchemaName } from "../../../shared/constants/AppConst";
import { PermissionActionsTypes } from "../../../types/entitiesTypes/enums";
import StudentsSubscriptionPage from "../studentsSubscription/StudentsSubscriptionPage";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import StudentAttachmentsPage from "./studentAttachments/StudentAttachmentsPage";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import StudentsMultiSubscriptionPage from "../studentsSubscription/StudentsMultiSubscriptionPage";
import PaidIcon from "@mui/icons-material/Paid";

const StudentProfile: React.FC = () => {
  const { messages } = useIntl();
  const { studentIdParams } = useParams();

  const {
    data: StudentsItem,
    error,
    isLoading,
  } = useGetByIdService<IStudentDetailsDto>(
    AppUrls.Student,
    studentIdParams || ""
  );
  const sidebarRoutes: IProfileRoutes[] = [
    {
      path: "/",
      routeComponent: <StudentDetails StudentsItem={StudentsItem!} />,
    },
    {
      path: "/subscription",
      routeComponent: <StudentsSubscriptionPage />,
    },
    {
      path: "/multiPayment",
      routeComponent: <StudentsMultiSubscriptionPage />,
    },

    {
      path: "/studentAttachments",
      routeComponent: <StudentAttachmentsPage />,
    },
  ];

  const childrenComponents: IProfileComponents[] = [
    {
      toPath: "",
      icon: <HiOutlineInformationCircle size={20} />,
      label: "details",
      schemaName: SchemaName.SystemConfigurations,
      entityName: EntityName.States,
      action: PermissionActionsTypes.Details,
    },

    {
      toPath: "subscription",
      icon: <SubscriptionsIcon fontSize={"small"} />,
      label: "sidebar.studentSubscription",
      schemaName: SchemaName.SystemConfigurations,
      entityName: EntityName.States,
      action: PermissionActionsTypes.Details,
    },
    {
      toPath: "multiPayment",
      icon: <PaidIcon fontSize={"small"} />,
      label: "sidebar.installments",
      schemaName: SchemaName.SystemConfigurations,
      entityName: EntityName.States,
      action: PermissionActionsTypes.Details,
    },

    {
      toPath: "studentAttachments",
      icon: <AttachFileIcon fontSize={"small"} />,
      label: "sidebar.studentAttachments",
      schemaName: SchemaName.SystemConfigurations,
      entityName: EntityName.States,
      action: PermissionActionsTypes.Details,
    },
  ];

  return (
    <>
      <LoadingAndErrorWrapper
        isLoading={isLoading}
        errorMessage={error?.response?.data.messages[0]}
      >
        <AppProfile
          error={error}
          breadcrumbPaths={[
            { name: messages["sidebar.usersManagement"] },
            {
              name: messages["sidebar.students"],
              path: "/usersManagement/students",
            },
          ]}
          currentBreadcrumbPath="details"
          sidebarRoutes={sidebarRoutes}
          childrenComponents={childrenComponents}
        />
      </LoadingAndErrorWrapper>
    </>
  );
};

export default StudentProfile;
