import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Pagination,
  Typography,
} from "@mui/material";
import defaultImage from "assets/appDefaultImages/branch.png";
import DataItem from "components/basicComponents/mobile/DataItem";
import AppIconButton from "components/basicComponents/others/AppIconButton";
import AppViewImage from "components/basicComponents/others/AppViewImage";
import {
  dateTimeComparison,
  dateTimeFormatter,
} from "helpers/string/dateFormatter";
import { FC } from "react";
import { useIntl } from "react-intl";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import { ISessionsListDto } from "types/entitiesTypes/session";
import AddToDriveIcon from "@mui/icons-material/AddToDrive";
import { AxiosError } from "axios";
import LoadingAndErrorWrapper from "components/basicComponents/others/LoadingAndErrorWrapper";

interface ISessionsCardProps {
  handleAddAttachment: (row: ISessionsListDto) => void;
  handleDetails: (row: ISessionsListDto) => void;
  handleUpdate: (row: ISessionsListDto) => void;
  handleDelete: (row: ISessionsListDto) => void;
  sessionList: Array<ISessionsListDto>;

  pageNumber?: number;
  setPage?: (event: React.ChangeEvent<unknown>, page: number) => void;
  page?: number;
  loading: boolean;
  errorMessage: AxiosError<any, any> | null;
}
const SessionsCard: FC<ISessionsCardProps> = (props) => {
  const {
    handleDelete,
    sessionList,
    handleUpdate,
    handleAddAttachment,
    handleDetails,
    page,
    pageNumber,
    setPage,
    loading,
    errorMessage,
  } = props;
  const { messages, locale } = useIntl();
  return (
    <Grid container spacing={4}>
      <LoadingAndErrorWrapper
        isLoading={loading}
        errorMessage={errorMessage?.response?.data.messages[0]}
      >
        {sessionList?.length != undefined && sessionList?.length >= 1 ? (
          sessionList?.map((item, index) => (
            <Grid key={`sessionList${index}`} item xs={12}>
              <Card sx={{ width: "100%" }}>
                {/* <CardHeader title={} /> */}
                <Box
                  marginX={4}
                  marginTop={3}
                  display={"flex"}
                  justifyContent={"flex-end"}
                  gap={3}
                >
                  {/* Add Attachment */}
                  <AppIconButton
                    label="table.actions.addAttachment"
                    handleClick={() => {
                      handleAddAttachment(item);
                    }}
                    childComp={
                      <AddToDriveIcon
                        fontSize="small"
                        sx={{ color: "#4285F4" }}
                      />
                    }
                    // For Permissions
                    schemaName={SchemaName.SystemConfigurations}
                    entityName={EntityName.Countries}
                    action={PermissionActionsTypes.Details}
                  />

                  {/* Details  */}
                  <AppIconButton
                    label="table.actions.details"
                    handleClick={() => {
                      handleDetails(item);
                    }}
                    childComp={
                      <RemoveRedEyeIcon
                        fontSize="small"
                        sx={{ color: "#007991" }}
                      />
                    }
                    // For Permissions
                    schemaName={SchemaName.SystemConfigurations}
                    entityName={EntityName.Countries}
                    action={PermissionActionsTypes.Details}
                  />

                  {/* Edit */}
                  {dateTimeComparison(item.date) && (
                    <AppIconButton
                      label="table.actions.edit"
                      handleClick={() => {
                        handleUpdate(item);
                      }}
                      childComp={
                        <EditOutlinedIcon
                          fontSize="small"
                          sx={{ color: "#A0CCDA" }}
                        />
                      }
                      // For Permissions
                      schemaName={SchemaName.SystemConfigurations}
                      entityName={EntityName.Countries}
                      action={PermissionActionsTypes.Edit}
                    />
                  )}

                  {/* Delete */}
                  <AppIconButton
                    label="table.actions.delete"
                    handleClick={() => {
                      handleDelete(item);
                    }}
                    childComp={
                      <DeleteOutlinedIcon
                        fontSize="small"
                        sx={{ color: "#D72638" }}
                      />
                    }
                    // For Permissions
                    schemaName={SchemaName.SystemConfigurations}
                    entityName={EntityName.Countries}
                    action={PermissionActionsTypes.Delete}
                  />
                </Box>
                <CardContent
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "center",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    gap: 3,
                  }}
                >
                  <DataItem
                    label={messages["common.nameAr"] as string}
                    value={item?.nameAr}
                  />

                  <DataItem
                    label={messages["common.nameEn"] as string}
                    value={item?.nameEn}
                  />
                  <DataItem
                    label={messages["session.date"] as string}
                    value={dateTimeFormatter(item.date)}
                  />
                  <DataItem
                    label={messages["session.duration"] as string}
                    value={item.duration}
                  />
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            item
            xs={12}
            marginTop={10}
          >
            <Typography variant="h5" component={"span"}>
              {messages["common.dataFound"]}
            </Typography>
          </Grid>
        )}
        {pageNumber! > 1 && (
          <Grid
            display={"flex"}
            alignItems={"cneter"}
            justifyContent={"center"}
            marginTop={5}
            item
            xs={12}
          >
            <Pagination
              count={pageNumber}
              page={page}
              color="primary"
              onChange={(event, page) => {
                setPage && setPage(event, page);
              }}
            />
          </Grid>
        )}
      </LoadingAndErrorWrapper>
    </Grid>
  );
};

export default SessionsCard;
