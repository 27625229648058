import { GridColDef } from "@mui/x-data-grid";
import AppDataGridCell from "components/basicComponents/dataGrid/AppDataGridCell";
import AppIconButton from "components/basicComponents/others/AppIconButton";
import { generatePresignedUrl, getValueBasedOnLang } from "helpers";
import { dateTimeFormatter } from "helpers/string/dateFormatter";
import { useIntl } from "react-intl";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { attendanceStatus } from "shared/constants/AppEnums";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import { IStudentsSubscriptionListDto } from "types/entitiesTypes/studentSubscription";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AppDataGridCellLink from "components/basicComponents/dataGrid/AppDataGridCellLink";
import { IStudentAttachmentsList } from "types/entitiesTypes/studentAttachments";

export const VirtualSceneRequestsColumns = (
  handleUpdate = (row: IStudentAttachmentsList) => {}
): GridColDef[] => {
  const { messages, locale } = useIntl();

  const VirtualSceneRequestsColumns: GridColDef[] = [
    {
      field: "subscription.student.id",
      headerName: messages["student.id"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => (
        <AppDataGridCell value={params.row?.subscription?.student?.id} />
      ),
    },
    {
      field: "subscription.student.fullName",
      headerName: messages["student.fullName"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => (
        <AppDataGridCell value={params.row?.subscription?.student?.fullName} />
      ),
    },
    {
      field: "subscription.student.lastName",
      headerName: messages["common.lastName"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      sortable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell value={params.row?.subscription?.student?.lastName} />
      ),
    },

    {
      field: "subscription.student.nationalNumber",
      headerName: messages["common.id"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => (
        <AppDataGridCell
          value={params.row?.subscription?.student?.nationalNumber}
        />
      ),
    },
    {
      field: "subscription.student.phone",
      headerName: messages["common.phone"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => (
        <AppDataGridCell
          value={params?.row?.subscription?.student?.phoneNumber}
        />
      ),
    },
    {
      field:
        locale === "en-US"
          ? "subscription.diploma.nameEn"
          : "subscription.diploma.nameAr",
      headerName: messages["diplomas.name"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => (
        <AppDataGridCell
          value={getValueBasedOnLang(
            params?.row?.subscription?.diploma?.nameAr || "-",
            params?.row?.subscription?.diploma?.nameEn || "-"
          )}
        />
      ),
    },

    {
      field: "subscription.diploma.startDate",
      headerName: messages["diplomas.startDate"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => (
        <AppDataGridCell
          value={dateTimeFormatter(
            params.row?.subscription?.diploma?.startDate
          )}
        />
      ),
    },

    {
      field: "subscription.diploma.durationInHours",
      headerName: messages["diplomas.hoursNumber"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => (
        <AppDataGridCell
          value={params?.row?.subscription?.diploma?.durationInHours}
        />
      ),
    },
    {
      field: "documentFileKey",
      headerName: messages["student.documentFileKey"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => (
        <>
          {params?.row?.documentFileKey ? (
            <AppDataGridCellLink
              // value={`${BaseFileUrl}${params.row?.documentFileKey}`}
              value={generatePresignedUrl(
                params.row?.documentFileKey,
                process.env.NODE_ENV
              )}
              label="session.attachments.actions.open"
            />
          ) : (
            "-"
          )}
        </>
      ),
    },

    {
      field: "status",
      headerName: messages["diplomas.status"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      sortable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell
          value={
            params?.row?.documentFileKey
              ? (messages["ticketing.currentStatus.approved"] as string)
              : (messages["student.subscriptionStatus.Pending"] as string)
          }
        />
      ),
    },

    {
      field: "actions",
      headerName: messages["common.actions"] as string,
      sortable: false,
      align: "center",
      filterable: false,
      headerAlign: "center",
      flex: 0.1,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div>
            {!params?.row?.documentFileKey && (
              <AppIconButton
                label="table.actions.edit"
                handleClick={() => {
                  handleUpdate(params.row);
                }}
                childComp={
                  <EditOutlinedIcon
                    fontSize="small"
                    sx={{ color: "#A0CCDA" }}
                  />
                }
                // For Permissions
                schemaName={SchemaName.SystemConfigurations}
                entityName={EntityName.Countries}
                action={PermissionActionsTypes.Edit}
              />
            )}
          </div>
        );
      },
    },
  ];
  //documentFileKey
  return VirtualSceneRequestsColumns;
};
