import AppSelectField from "@crema/core/AppFormComponents/AppSelectField";
import AppTextField from "@crema/core/AppFormComponents/AppTextField";
import IntlMessages from "@crema/utility/IntlMessages";
import { Grid, MenuItem } from "@mui/material";
import AppCheckbox from "components/basicComponents/inputs/AppCheckbox";
import { FormikErrors, FormikHelpers, FormikTouched } from "formik";
import { ICreateDiplomasDto } from "types/entitiesTypes/diplomas";
import { DiplomasType } from "types/entitiesTypes/enums";

interface IThirdStepProps {
  setFieldValue: FormikHelpers<ICreateDiplomasDto>["setFieldValue"];
  errors: FormikErrors<ICreateDiplomasDto>;
  touched: FormikTouched<ICreateDiplomasDto>;
  values: ICreateDiplomasDto;
}

const ThirdSteps = (props: IThirdStepProps) => {
  const { errors, setFieldValue, touched, values } = props;
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={12} lg={12}>
        <AppCheckbox
          keyName="isFree"
          onChange={(field, value) => {
            console.log(`value`, value);
            setFieldValue("isFree", value);
            setFieldValue("singlePaymentCost", "");
            setFieldValue("multiplePaymentsCost", "");
            setFieldValue("expenses", "");
            setFieldValue("expensesType", "");
          }}
          defaultChecked={values?.isFree}
          label="diplomas.isFree"
        />
      </Grid>
      {!values?.isFree && (
        <>
          <Grid item xs={12} sm={4} lg={3}>
            <AppTextField
              label={<IntlMessages id="diplomas.singlePaymentCost" />}
              name="singlePaymentCost"
              variant="outlined"
              size="small"
              fullWidth
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <AppTextField
              label={<IntlMessages id="diplomas.multiplePaymentsCost" />}
              name="multiplePaymentsCost"
              variant="outlined"
              size="small"
              fullWidth
              type="number"
            />
          </Grid>

          <Grid item xs={12} sm={4} lg={3}>
            <AppTextField
              label={<IntlMessages id="diplomas.expenses" />}
              name="expenses"
              variant="outlined"
              size="small"
              fullWidth
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <AppSelectField
              label={<IntlMessages id="diplomas.expensesType" />}
              name="expensesType"
              variant="outlined"
              size="small"
              fullWidth
            >
              <MenuItem value={DiplomasType.FixedValue}>
                <IntlMessages id="diplomas.fixedValue" />
              </MenuItem>
              <MenuItem value={DiplomasType.PercentageValue}>
                <IntlMessages id="diplomas.percentageValue" />
              </MenuItem>
            </AppSelectField>
          </Grid>
        </>
      )}

      <Grid item xs={12} sm={4} lg={3}>
        <AppTextField
          label={<IntlMessages id="diplomas.educationalCenterFees" />}
          name="educationalCenterFees"
          variant="outlined"
          size="small"
          fullWidth
          type="number"
        />
      </Grid>
      <Grid item xs={12} sm={4} lg={3}>
        <AppSelectField
          label={<IntlMessages id="diplomas.educationalCenterFeesType" />}
          name="educationalCenterFeesType"
          variant="outlined"
          size="small"
          fullWidth
        >
          <MenuItem value={DiplomasType.FixedValue}>
            <IntlMessages id="diplomas.fixedValue" />
          </MenuItem>
          <MenuItem value={DiplomasType.PercentageValue}>
            <IntlMessages id="diplomas.percentageValue" />
          </MenuItem>
        </AppSelectField>
      </Grid>
    </Grid>
  );
};

export default ThirdSteps;
