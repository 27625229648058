import IntlMessages from "@crema/utility/IntlMessages";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import { IconButton, SxProps, Theme, Tooltip } from "@mui/material";
import AppPermissionsGate, {
  IAppPermissionsProps,
} from "components/basicComponents/permessions/AppPermissionsGate";
import * as React from "react";
import { useIntl } from "react-intl";

interface IAddDataGridActivationProps extends IAppPermissionsProps {
  value: boolean;
  handleClick: () => void;
  sxProp?: SxProps<Theme>;
}

const AddDataGridActivation: React.FC<IAddDataGridActivationProps> = (
  props
) => {
  const {
    value,
    handleClick,
    sxProp = { padding: 0.3 },
    schemaName,
    entityName,
    action,
  } = props;
  const { locale } = useIntl();

  return (
    <AppPermissionsGate
      schemaName={schemaName}
      entityName={entityName}
      action={action}
    >
      <Tooltip
        title={
          <IntlMessages
            id={value ? "table.actions.deactivate" : "table.actions.activate"}
          />
        }
      >
        <IconButton sx={sxProp} onClick={handleClick}>
          {locale == "ar-SA" && value ? (
            <ToggleOffIcon fontSize="small" sx={{ color: "#11c15b" }} />
          ) : locale == "ar-SA" && !value ? (
            <ToggleOnIcon fontSize="small" sx={{ color: "red" }} />
          ) : locale == "en-US" && value ? (
            <ToggleOnIcon fontSize="small" sx={{ color: "#11c15b" }} />
          ) : (
            <ToggleOffIcon fontSize="small" sx={{ color: "red" }} />
          )}
        </IconButton>
      </Tooltip>
    </AppPermissionsGate>
  );
};

export default AddDataGridActivation;
