import { Grid, Hidden } from "@mui/material";
import { GridSelectionModel } from "@mui/x-data-grid";
import AppBreadcrumb from "components/basicComponents/breadcrumb/AppBreadcrumb";
import AppDataGrid from "components/basicComponents/dataGrid/AppDataGrid";
import AppFilters from "components/basicComponents/filters/AppFilters";
import AppConfirmModal from "components/basicComponents/modals/AppConfirmModal";
import { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { useDeleteService, useGetListService } from "services";
import { AppUrls } from "services/appUrls";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import AddRegion from "components/systemConfigurations/regions/AddRegion";
import {
  IAdvertisementSlidersListDto,
  IAdvertisementSlidersSearch,
} from "types/entitiesTypes/advertisementSlider";
import { AdvertisementSlidersColumns } from "./AdvertisementSlidersColumns";
import AddAdvertisementSlider from "components/systemConfigurations/advertisementSliders/AddAdvertisementSlider";
import AdvertisementSlidersCard from "./AdvertisementSlidersCard";

const AdvertisementSlidersPages = () => {
  const { messages } = useIntl();
  const [search, setSearch] = useState<IAdvertisementSlidersSearch>({
    pageNumber: 1,
    pageSize: 10,
  });
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);

  const [selectedRow, setSelectedRow] =
    useState<IAdvertisementSlidersListDto>();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showActivationModal, setShowActivationModal] =
    useState<boolean>(false);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  // For hide breadcrumb in country profile
  const show = window.location.href;
  // For Advance Filters
  const [advancedFilter, setAdvancedFilter] = useState(null);
  const [isOpenAdvanceFilters, setIsOpenAdvanceFilters] =
    useState<boolean>(false);

  const {
    resultData: AdvertisementSlidersList,
    isFetching,
    error,
  } = useGetListService<IAdvertisementSlidersListDto>(
    AppUrls.AdvertisementSlider,
    {
      ...search,
      advancedFilter,
    }
  );

  // for delete
  const handleDelete = useCallback(
    (selectedRow: IAdvertisementSlidersListDto) => {
      setSelectedRow(selectedRow);
      setShowDeleteModal(true);
    },
    []
  );

  const columns = AdvertisementSlidersColumns(handleDelete);

  const mutationForDelete = useDeleteService<string>(
    AppUrls.AdvertisementSlider,
    selectedRow?.id || "",
    () => setShowDeleteModal(false)
  );

  return (
    <>
      <Grid container spacing={3}>
        <AppBreadcrumb
          paths={[{ name: messages["sidebar.systemConfigurations.title"] }]}
          currentPathName={"sidebar.advertisementSliders"}
        />

        <Grid item xs={12}>
          <AppFilters
            //excel pdf
            filterAll={{
              ...search,
              advancedFilter,
            }}
            addTitle="systemConfigurations.advertisementSliders.add"
            setShowAddModal={setShowAddModal}
            onChangeKeywordValue={(
              event: React.ChangeEvent<HTMLInputElement>
            ) => {
              setSearch({ ...search, keyword: event.target.value });
            }}
            selectionModel={selectionModel as string[]}
            setSelectionModel={setSelectionModel}
            successUrl={AppUrls.AdvertisementSlider}
            keyword={search?.keyword}
            isActive={search?.isActive}
            advancedFilter={advancedFilter}
            totalCount={AdvertisementSlidersList?.totalCount || 0}
            excelFileTitle={String(messages["sidebar.advertisementSliders"])}
            // For Permissions
            schemaName={SchemaName.SystemConfigurations}
            entityName={EntityName.Countries}
            action={PermissionActionsTypes.Add}
            // Advance Filters
            showAdvanceFiltersButton={false}
            isOpenAdvanceFilters={isOpenAdvanceFilters}
            setIsOpenAdvanceFilters={setIsOpenAdvanceFilters}
            setAdvancedFilter={setAdvancedFilter}
          />
        </Grid>
        <Hidden mdDown>
          <Grid item xs={12}>
            <AppDataGrid
              rowCount={AdvertisementSlidersList?.totalCount ?? 0}
              pageNumber={search?.pageNumber ?? 0}
              //for pagination
              onPageSizeChange={(pageSize: number) => {
                setSearch({ ...search, pageSize: pageSize });
              }}
              onPageChange={(pageNumber: number) => {
                setSearch({ ...search, pageNumber: pageNumber + 1 });
              }}
              pageSize={search?.pageSize ?? 10}
              columns={columns}
              rows={AdvertisementSlidersList?.data ?? []}
              loading={isFetching}
              //for selection
              onSelectionModelChange={(
                newSelectionModel: GridSelectionModel
              ) => {
                setSelectionModel(newSelectionModel);
              }}
              checkboxSelection={false}
              selectionModel={selectionModel}
              //for sorting
              // onSortModelChange={(result: string[] | null) => {
              //   setSearch({
              //     ...search,
              //     orderBy: result,
              //   });
              // }}
            />
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid item xs={12}>
            <AdvertisementSlidersCard
              handleDelete={handleDelete}
              AdvertisementSlidersList={AdvertisementSlidersList?.data!}
              setPage={(event, page) => {
                setSearch({
                  ...search,
                  pageNumber: page,
                });
              }}
              pageNumber={Math.ceil(
                (AdvertisementSlidersList?.totalCount || 0) / 10
              )}
              page={search?.pageNumber}
              loading={isFetching}
              errorMessage={error}
            />
          </Grid>
        </Hidden>
      </Grid>

      {/* Add State */}
      {showAddModal && (
        <AddAdvertisementSlider
          label="systemConfigurations.advertisementSliders.add"
          showAddModal={showAddModal}
          setShowAddModal={setShowAddModal}
        />
      )}

      {showDeleteModal && (
        <AppConfirmModal
          dialogTitle={
            "systemConfigurations.advertisementSliders.actions.delete.dialogTitle"
          }
          open={showDeleteModal}
          setOpen={setShowDeleteModal}
          loading={mutationForDelete.isLoading}
          itemName={""}
          title={
            "systemConfigurations.advertisementSliders.actions.delete.title"
          }
          onConfirm={() => {
            mutationForDelete.mutate();
          }}
          maxWidth="sm"
        />
      )}
    </>
  );
};

export default AdvertisementSlidersPages;
