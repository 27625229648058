import React from "react";
import { FieldHookConfig, useField } from "formik";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { SelectProps } from "@mui/material/Select/Select";
import { FormControl, InputLabel } from "@mui/material";

const AppSelectField = (props: SelectProps & FieldHookConfig<string>) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";

  return (
    <>
      <FormControl fullWidth size="small">
        <InputLabel error={!!errorText}>{props.label}</InputLabel>
        <Select
          {...props}
          {...field}
          error={!!errorText}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200,
                // width: 250,
              },
            },
          }}
        />
        {!props.disabled && (
          <FormHelperText style={{ color: "#f44336" }}>
            {errorText}
          </FormHelperText>
        )}
      </FormControl>
    </>
  );
};

export default AppSelectField;
