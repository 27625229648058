import {
  requestNotificationsList,
  requestNotificationsListFailed,
  requestNotificationsListSuccess,
  requsetChangeNotificationItemToIsRead,
} from "redux/reducers/notifications";
import {
  changeNotificationItemToIsReadResource,
  getPersonalNotificationRequest,
} from "services/additionalServices";
import { AppUrls } from "services/appUrls";

export const getNotificationsList = (options) => {
  return async (dispatch) => {
    dispatch(requestNotificationsList());
    try {
      const res = await getPersonalNotificationRequest(AppUrls.NOTIFICATIONS, {
        pageSize: options?.pageSize,
        ...options?.customQueries,
      });
      dispatch(requestNotificationsListSuccess(res.data, res?.totalCount));
    } catch (error) {
      dispatch(requestNotificationsListFailed("Notification Error!"));
    }
  };
};

export const changeNotificationItemToIsRead = (id) => {
  return async (dispatch) => {
    try {
      const res = await changeNotificationItemToIsReadResource(
        AppUrls.NOTIFICATIONS,
        id
      );
      dispatch(
        getNotificationsList({
          pageSize: 25,
          customQueries: { status: "New" },
        })
      );
      dispatch(requsetChangeNotificationItemToIsRead());
    } catch (error) {}
  };
};
