import IntlMessages from "@crema/utility/IntlMessages";
import { Divider, Grid, Typography } from "@mui/material";
import SubscriptionStatusBadge from "components/basicComponents/badge/SubscriptionStatusBadge";
import AppItemView from "components/basicComponents/others/AppItemView";
import BaseProfileDetails from "components/basicComponents/profile/BaseProfileDetails";
import { getValueBasedOnLang, removeHTMLTags } from "helpers";
import { useIntl } from "react-intl";
import { Fonts } from "shared/constants/AppEnums";
import { IStudentSubscriptionDetailsDto } from "types/entitiesTypes/studentSubscription";

interface IUserDetailsProps {
  studentSubscriptionItem: IStudentSubscriptionDetailsDto;
}

const StudentSubscriptionDeatils = (props: IUserDetailsProps) => {
  const { studentSubscriptionItem } = props;
  const { messages } = useIntl();

  return (
    <>
      <BaseProfileDetails
        columns={12}
        moreDetails={studentSubscriptionItem as IStudentSubscriptionDetailsDto}
      >
        <Grid container item spacing={5} xs={12}>
          <Grid item xs={12}>
            <Divider sx={{ color: "#0A8FDC", fontWeight: "bold" }}>
              <IntlMessages id="diplomas.BasicInformation" />
            </Divider>
          </Grid>
          <AppItemView
            label="diplomas.educationalCenter"
            value={getValueBasedOnLang(
              studentSubscriptionItem?.diploma?.educationalCenter?.nameAr ??
                "-",
              studentSubscriptionItem?.diploma?.educationalCenter?.nameEn ?? "-"
            )}
            sm={4}
          />

          <AppItemView
            label="diplomas.instructor"
            value={getValueBasedOnLang(
              studentSubscriptionItem?.diploma?.instructor?.firstNameAr
                ? `${studentSubscriptionItem?.diploma?.instructor?.firstNameAr} ${studentSubscriptionItem?.diploma?.instructor?.lastNameAr}`
                : "-",
              studentSubscriptionItem?.diploma?.instructor?.firstNameEn
                ? `${studentSubscriptionItem?.diploma?.instructor?.firstNameEn} ${studentSubscriptionItem?.diploma?.instructor?.lastNameEn}`
                : "-"
            )}
            sm={4}
          />
          <AppItemView
            label="student.subscriptionType"
            value={
              messages[
                studentSubscriptionItem?.subscriptionType === 1
                  ? "student.subscriptionType.onePayment"
                  : "student.subscriptionType.multiPayment"
              ] as string
            }
            sm={4}
          />

          <AppItemView
            label="student.remainingPayments"
            value={studentSubscriptionItem?.remainingPayments}
            sm={4}
          />

          <AppItemView
            label="student.nextPayment"
            value={studentSubscriptionItem?.nextPayment}
            type="date"
            sm={4}
          />
          <AppItemView
            label="student.subscriptionDate"
            value={studentSubscriptionItem?.subscriptionDate}
            type="dateTime"
            sm={4}
          />
          <AppItemView
            label="diplomas.isFree"
            value={studentSubscriptionItem?.isFree}
            type="isExist"
            sm={4}
          />
          <Grid item sm={4} xs={12}>
            <Typography sx={{ fontSize: 14, fontWeight: Fonts.SEMI_BOLD }}>
              <IntlMessages id={"student.subscriptionStatus"} />
            </Typography>
            <SubscriptionStatusBadge
              subscriptionStatus={studentSubscriptionItem?.subscriptionStatus}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ color: "#0A8FDC", fontWeight: "bold" }}>
              <IntlMessages id="diplomas.info" />
            </Divider>
          </Grid>
          <AppItemView
            label="common.nameAr"
            value={studentSubscriptionItem?.diploma?.nameAr}
            sm={4}
          />
          <AppItemView
            label="common.nameEn"
            value={studentSubscriptionItem?.diploma?.nameEn}
            sm={4}
          />
          <AppItemView
            label="diplomas.isCertified"
            value={studentSubscriptionItem?.diploma?.isCertified}
            sm={4}
            type="isExist"
          />
          <AppItemView
            label="diplomas.outcomes"
            value={removeHTMLTags(studentSubscriptionItem?.diploma?.outcomes)}
            sm={12}
          />
          <AppItemView
            label="common.descriptionAr"
            value={removeHTMLTags(
              studentSubscriptionItem?.diploma?.descriptionAr
            )}
            sm={6}
          />
          <AppItemView
            label="common.descriptionEn"
            value={removeHTMLTags(
              studentSubscriptionItem?.diploma?.descriptionEn
            )}
            sm={6}
          />

          <Grid item xs={12}>
            <Divider sx={{ color: "#0A8FDC", fontWeight: "bold" }}>
              <IntlMessages id="student.info" />
            </Divider>
          </Grid>
          <AppItemView
            label="student.name"
            value={studentSubscriptionItem?.student?.fullName}
            sm={4}
          />
          <AppItemView
            label="common.email"
            value={studentSubscriptionItem?.student?.email}
            sm={4}
          />
          <AppItemView
            label="common.nationalNumber"
            value={studentSubscriptionItem?.student?.nationalNumber}
            sm={4}
          />
          <AppItemView
            label="student.academicNumber"
            value={studentSubscriptionItem?.student?.academicNumber}
            sm={4}
            type="isPhoneNumber"
          />
          <AppItemView
            label="common.phone"
            value={studentSubscriptionItem?.student?.phoneNumber}
            sm={4}
            type="isPhoneNumber"
          />
          <AppItemView
            label="student.landLineNumber"
            value={studentSubscriptionItem?.student?.landLineNumber}
            type="isPhoneNumber"
            sm={4}
          />
        </Grid>
      </BaseProfileDetails>
    </>
  );
};

export default StudentSubscriptionDeatils;
