import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Pagination,
  Typography,
} from "@mui/material";
import defaultImage from "assets/appDefaultImages/branch.png";
import { AxiosError } from "axios";
import StatusBadge from "components/basicComponents/badge/StatusBadge";
import AddDataGridActivation from "components/basicComponents/dataGrid/AddDataGridActivation";
import DataItem from "components/basicComponents/mobile/DataItem";
import AppIconButton from "components/basicComponents/others/AppIconButton";
import AppViewImage from "components/basicComponents/others/AppViewImage";
import LoadingAndErrorWrapper from "components/basicComponents/others/LoadingAndErrorWrapper";
import moment from "moment";
import { FC } from "react";
import { useIntl } from "react-intl";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { IDiplomaInstallmentListDto } from "types/entitiesTypes/DiplomaInstallment";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";

interface IDiplomaInstallmentCardProps {
  handleUpdate: (row: IDiplomaInstallmentListDto) => void;
  handleDelete: (row: IDiplomaInstallmentListDto) => void;
  handleDetails: (row: IDiplomaInstallmentListDto) => void;
  diplomaInstallmentList: Array<IDiplomaInstallmentListDto>;
  pageNumber?: number;
  setPage?: (event: React.ChangeEvent<unknown>, page: number) => void;
  page?: number;
  loading: boolean;
  errorMessage: AxiosError<any, any> | null;
}
const DiplomaInstallmentCard: FC<IDiplomaInstallmentCardProps> = (props) => {
  const {
    handleDelete,
    diplomaInstallmentList,
    handleUpdate,
    pageNumber,
    setPage,
    page,
    handleDetails,
    loading,
    errorMessage,
  } = props;
  const { messages, locale } = useIntl();
  return (
    <Grid container spacing={4}>
      <LoadingAndErrorWrapper
        isLoading={loading}
        errorMessage={errorMessage?.response?.data.messages[0]}
      >
        {diplomaInstallmentList?.length != undefined &&
        diplomaInstallmentList?.length >= 1 ? (
          diplomaInstallmentList?.map((item, index) => (
            <Grid key={`DiplomaInstallmentList${index}`} item xs={12}>
              <Card sx={{ width: "100%" }}>
                <Box
                  marginX={4}
                  marginTop={3}
                  display={"flex"}
                  justifyContent={"flex-end"}
                  gap={3}
                >
                  {/* Details */}
                  <AppIconButton
                    label="table.actions.details"
                    handleClick={() => {
                      handleDetails(item);
                    }}
                    childComp={
                      <RemoveRedEyeIcon
                        fontSize="small"
                        sx={{ color: "#007991" }}
                      />
                    }
                    // For Permissions
                    schemaName={SchemaName.SystemConfigurations}
                    entityName={EntityName.Countries}
                    action={PermissionActionsTypes.Details}
                  />

                  {/* Edit */}
                  <AppIconButton
                    label="table.actions.edit"
                    handleClick={() => {
                      handleUpdate(item);
                    }}
                    childComp={
                      <EditOutlinedIcon
                        fontSize="small"
                        sx={{ color: "#A0CCDA" }}
                      />
                    }
                    // For Permissions
                    schemaName={SchemaName.SystemConfigurations}
                    entityName={EntityName.Countries}
                    action={PermissionActionsTypes.Edit}
                  />
                  {/* Delete */}
                  <AppIconButton
                    label="table.actions.delete"
                    handleClick={() => {
                      handleDelete(item);
                    }}
                    childComp={
                      <DeleteOutlinedIcon
                        fontSize="small"
                        sx={{ color: "#D72638" }}
                      />
                    }
                    // For Permissions
                    schemaName={SchemaName.SystemConfigurations}
                    entityName={EntityName.Countries}
                    action={PermissionActionsTypes.Delete}
                  />
                </Box>
                <CardContent
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: 3,
                    paddingTop: "0 !important",
                  }}
                >
                  <DataItem
                    label={messages["diplomaInstallment.dueDate"] as string}
                    value={
                      item?.dueDate
                        ? moment(item?.dueDate).format("YYYY-MM-DD")
                        : "-"
                    }
                  />

                  <DataItem
                    label={messages["amount"] as string}
                    value={item?.amount}
                  />
                  <DataItem
                    label={messages["diplomaInstallment.daysMargin"] as string}
                    value={item?.daysMargin}
                  />
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            item
            xs={12}
            marginTop={10}
          >
            <Typography variant="h5" component={"span"}>
              {messages["common.dataFound"]}
            </Typography>
          </Grid>
        )}
        {pageNumber! > 1 && (
          <Grid
            display={"flex"}
            alignItems={"cneter"}
            justifyContent={"center"}
            marginTop={5}
            item
            xs={12}
          >
            <Pagination
              count={pageNumber}
              page={page}
              color="primary"
              onChange={(event, page) => {
                setPage && setPage(event, page);
              }}
            />
          </Grid>
        )}
      </LoadingAndErrorWrapper>
    </Grid>
  );
};

export default DiplomaInstallmentCard;
