import AppTextField from "@crema/core/AppFormComponents/AppTextField";
import IntlMessages from "@crema/utility/IntlMessages";
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import AppAddModal from "components/basicComponents/modals/AppAddModal";
import { FieldArray, Form, Formik, FormikProps } from "formik";
import React from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useCreateService } from "services";
import { AppUrls } from "services/appUrls";
import { ICreateExamQuestionDto } from "types/entitiesTypes/examQuestions";
import AddCircleSharpIcon from "@mui/icons-material/AddCircleSharp";
import DeleteForeverSharpIcon from "@mui/icons-material/DeleteForeverSharp";
import * as yup from "yup";
import { IExamsListDto } from "types/entitiesTypes/exams";
import { getValueBasedOnLang } from "helpers";

interface IAddExamQuestionProps {
  showAddModal: boolean;
  setShowAddModal: React.Dispatch<React.SetStateAction<boolean>>;
  label: string;
  onSuccess?: () => void;
  onFailed?: () => void;
  selectedRow?: {
    nameAr: string;
    nameEn: string;
    id: string;
  };
}

const AddExamQuestion: React.FC<IAddExamQuestionProps> = (props) => {
  const { showAddModal, setShowAddModal, label, selectedRow } = props;
  const { messages } = useIntl();
  const refForm = React.useRef<FormikProps<ICreateExamQuestionDto>>(null);

  const mutation = useCreateService<ICreateExamQuestionDto, string>(
    AppUrls.ExamQuestions,
    null,
    false
  );

  const validationSchema = yup.object({
    questions: yup.array().of(
      yup.object().shape({
        textEn: yup.string().required(String(messages["validation.required"])),
        textAr: yup.string().required(String(messages["validation.required"])),
        // order: yup
        //   .number()
        //   .typeError(String(messages["validation.required"]))
        //   .required(String(messages["validation.required"])),

        options: yup.array().of(
          yup.object().shape({
            textAr: yup
              .string()
              .required(String(messages["validation.required"])),
            textEn: yup
              .string()
              .required(String(messages["validation.required"])),
          })
        ),
      })
    ),
  });

  const submit = () => {
    if (refForm.current) {
      refForm.current.submitForm();
    }
  };

  return (
    <>
      <AppAddModal
        label={label}
        open={showAddModal}
        onClose={() => setShowAddModal(false)}
        closeModalState={setShowAddModal}
        successClose={mutation.isSuccess}
        handleSubmit={submit}
        loadingForAdd={
          (refForm.current?.isSubmitting && !refForm.current?.isValidating) ||
          false
        }
        itemName={getValueBasedOnLang(
          ` - ${selectedRow?.nameAr}`,
          selectedRow?.nameEn ? ` - ${selectedRow?.nameEn}` : ""
        )}
        maxWidth={"md"}
      >
        <Formik
          validateOnChange={true}
          initialValues={{
            examId: selectedRow?.id || "",
            questions: [
              {
                textAr: "",
                textEn: "",
                type: 2,
                // isActive: false,
                options: [
                  {
                    isCorrect: false,
                    textAr: "",
                    textEn: "",
                  },
                  {
                    isCorrect: false,
                    textAr: "",
                    textEn: "",
                  },
                ],
              },
            ],
          }}
          validationSchema={validationSchema}
          innerRef={refForm}
          onSubmit={async (data, { setSubmitting }) => {
            mutation.submitData(data);
          }}
        >
          {({ setFieldValue, values, errors }) => {
            return (
              <Form>
                <Grid container spacing={4}>
                  <Grid item container rowSpacing={2}>
                    <FieldArray
                      name={`questions`}
                      render={(arrayHelpers) => (
                        <>
                          {values?.questions?.map((item, index) => {
                            return (
                              <Grid item container spacing={3} rowSpacing={3}>
                                {/* Name */}
                                <Grid item xs={12} mb={2}>
                                  <Divider
                                    sx={{
                                      color: "#0A8FDC",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <IntlMessages id="exam.question" />{" "}
                                    {`(${index + 1})`}
                                  </Divider>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                  <AppTextField
                                    label={
                                      <IntlMessages id="exams.question.textAr" />
                                    }
                                    name={`questions[${index}].textAr`}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                  <AppTextField
                                    label={
                                      <IntlMessages id="exams.question.textEn" />
                                    }
                                    name={`questions[${index}].textEn`}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                  />
                                </Grid>

                                {/** question type */}
                                <Grid item xs={12} sm={3}>
                                  <FormControl
                                    sx={{ minWidth: 120 }}
                                    size="small"
                                    fullWidth
                                  >
                                    <InputLabel id="demo-select-small">
                                      <IntlMessages id="exam.questions.type" />
                                    </InputLabel>
                                    <Select
                                      label={
                                        <IntlMessages id="exam.questions.type" />
                                      }
                                      onChange={(e) => {
                                        setFieldValue(
                                          `questions[${index}].type`,
                                          e.target.value
                                        );
                                        setFieldValue(
                                          `questions[${index}].options`,
                                          values?.questions[
                                            index
                                          ]?.options?.map((option) => ({
                                            ...option,
                                            isCorrect: false, // Set isCorrect to false for all existing options
                                          }))
                                        );
                                        // if (
                                        //   e.target.value === 3 &&
                                        //   values?.questions[index]?.options
                                        //     ?.length === 2
                                        // ) {
                                        //   setFieldValue(
                                        //     `questions[${index}].options`,
                                        //     [
                                        //       ...values?.questions[index]
                                        //         ?.options,
                                        //       {
                                        //         isCorrect: false,
                                        //         textAr: "",
                                        //         textEn: "",
                                        //       },
                                        //     ]
                                        //   );
                                        // }
                                      }}
                                      defaultValue={2}
                                    >
                                      <MenuItem value={2}>
                                        <IntlMessages id="exam.questions.type.single" />
                                      </MenuItem>

                                      <MenuItem value={3}>
                                        <IntlMessages id="exam.questions.type.multi" />
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>

                                {/* <Grid item xs={2}>
                                  <AppSelectField
                                    label={<IntlMessages id="columns.status" />}
                                    name={`questions[${index}].isActive`}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                  >
                                    <MenuItem value={"true"}>
                                      <IntlMessages id="columns.active" />
                                    </MenuItem>
                                    <MenuItem value={"false"}>
                                      <IntlMessages id="columns.inactive" />
                                    </MenuItem>
                                  </AppSelectField>
                                </Grid> */}
                                {/* Delete */}
                                <Grid item xs={1.5} sm={0.5}>
                                  <IconButton
                                    aria-label="delete"
                                    color="error"
                                    disabled={values?.questions?.length === 1}
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    <DeleteForeverSharpIcon />
                                  </IconButton>
                                </Grid>
                                {/* Add */}
                                {index === values?.questions?.length! - 1 ? (
                                  <Grid item xs={1.5} sm={0.5}>
                                    <IconButton
                                      aria-label="add"
                                      color="success"
                                      onClick={() => {
                                        arrayHelpers.push({
                                          textAr: "",
                                          textEn: "",
                                          type: 2,
                                          // isActive: false,
                                          options: [
                                            {
                                              isCorrect: false,
                                              textAr: "",
                                              textEn: "",
                                            },
                                          ],
                                        });
                                      }}
                                      // disabled={
                                      //   values?.questions!?.length >= 10
                                      // }
                                    >
                                      <AddCircleSharpIcon />
                                    </IconButton>
                                  </Grid>
                                ) : (
                                  ""
                                )}
                                <Grid item xs={12}>
                                  <Divider
                                    sx={{
                                      color: "#0A8FDC",
                                      fontWeight: "bold",
                                      justifyContent: "flex-start", // Ensure content is aligned to the left
                                    }}
                                    textAlign="left"
                                  >
                                    <IntlMessages id="examQuestion.answers" />{" "}
                                    {`(${index + 1})`}
                                  </Divider>
                                </Grid>

                                <Grid item container rowSpacing={2}>
                                  <FieldArray
                                    name={`questions[${index}].options`}
                                    render={(arrayHelpers) => (
                                      <>
                                        {values?.questions[index]?.options?.map(
                                          (item, indexOption) => (
                                            <Grid item container spacing={3}>
                                              <Grid item xs={12} sm={3}>
                                                <AppTextField
                                                  label={
                                                    <IntlMessages id="exam.questions.option.textAr" />
                                                  }
                                                  name={`questions[${index}].options[${indexOption}].textAr`}
                                                  variant="outlined"
                                                  fullWidth
                                                  size="small"
                                                />
                                              </Grid>
                                              <Grid item xs={12} sm={3}>
                                                <AppTextField
                                                  label={
                                                    <IntlMessages id="exam.questions.option.textEn" />
                                                  }
                                                  name={`questions[${index}].options[${indexOption}].textEn`}
                                                  variant="outlined"
                                                  size="small"
                                                  fullWidth
                                                />
                                              </Grid>

                                              <Grid
                                                item
                                                xs={12}
                                                sm={3}
                                                sx={{ ml: 1 }}
                                              >
                                                <FormControlLabel
                                                  name={`questions[${index}].options[${indexOption}].isCorrect`}
                                                  control={
                                                    values?.questions[index]
                                                      .type === 2 ? (
                                                      <span
                                                        className="MuiCheckbox-root MuiCheckbox-colorPrimary MuiButtonBase-root MuiCheckbox-root MuiCheckbox-colorPrimary PrivateSwitchBase-root css-1mf11o-MuiButtonBase-root-MuiCheckbox-root"
                                                        style={{ padding: 15 }}
                                                      >
                                                        <input
                                                          className="PrivateSwitchBase-input"
                                                          type="radio"
                                                          name="test"
                                                          id={`questions[${index}].options[${indexOption}].isCorrect`}
                                                          value={`questions[${index}].options[${indexOption}].isCorrect`}
                                                          onChange={(e) => {
                                                            values?.questions![
                                                              index
                                                            ].options?.map(
                                                              (
                                                                item,
                                                                itemIndex
                                                              ) => {
                                                                if (
                                                                  itemIndex !==
                                                                  indexOption
                                                                ) {
                                                                  return setFieldValue(
                                                                    `questions[${index}].options[${indexOption}].isCorrect`,
                                                                    false
                                                                  );
                                                                } else {
                                                                  setFieldValue(
                                                                    `questions[${index}].options[${indexOption}].isCorrect`,
                                                                    true
                                                                  );
                                                                }
                                                              }
                                                            );
                                                          }}
                                                        />
                                                      </span>
                                                    ) : (
                                                      <Checkbox
                                                        name={`questions[${index}].options[${indexOption}].isCorrect`}
                                                        onChange={(e) => {
                                                          setFieldValue(
                                                            `questions[${index}].options[${indexOption}].isCorrect`,
                                                            e.target.checked
                                                          );
                                                        }}
                                                      />
                                                    )
                                                  }
                                                  label={
                                                    <IntlMessages id="exam.questions.options.isCorrect" />
                                                  }
                                                />
                                              </Grid>
                                              <Grid item xs={1.5} sm={1}>
                                                <IconButton
                                                  aria-label="delete"
                                                  color="error"
                                                  disabled={
                                                    values.questions[index]
                                                      .options!.length === 2
                                                  }
                                                  onClick={() =>
                                                    arrayHelpers.remove(
                                                      indexOption
                                                    )
                                                  }
                                                >
                                                  <DeleteForeverSharpIcon />
                                                </IconButton>
                                              </Grid>
                                              {indexOption ===
                                              values?.questions[index].options
                                                ?.length! -
                                                1 ? (
                                                <Grid item xs={1.5} sm={1}>
                                                  <IconButton
                                                    aria-label="delete"
                                                    color="success"
                                                    onClick={() =>
                                                      arrayHelpers.push({
                                                        isCorrect: false,
                                                        textAr: "",
                                                        textEn: "",
                                                      })
                                                    }
                                                  >
                                                    <AddCircleSharpIcon />
                                                  </IconButton>
                                                </Grid>
                                              ) : (
                                                ""
                                              )}
                                            </Grid>
                                          )
                                        )}
                                      </>
                                    )}
                                  />
                                </Grid>
                              </Grid>
                            );
                          })}
                        </>
                      )}
                    />
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </AppAddModal>
    </>
  );
};

export default AddExamQuestion;
