import React from "react";

const DashboardPage = React.lazy(() => import("./DashboardPage"));

export const dashboardPagesConfigs = [
  {
    path: "/adminDashboard",
    element: <DashboardPage />,
  },
];
