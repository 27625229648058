import { Grid } from "@mui/material";
import BaseDetails from "components/basicComponents/details/BaseDetails";
import AppItemView from "components/basicComponents/others/AppItemView";

import { IWebsiteNeedListDto } from "types/entitiesTypes/websiteNeed";

interface IWebsiteNeedDetailsProps {
  setShowDetails: React.Dispatch<React.SetStateAction<boolean>>;
  showDetails: boolean;
  websiteNeeditem: IWebsiteNeedListDto;
}
const WebsiteNeedDetails = (props: IWebsiteNeedDetailsProps) => {
  const { setShowDetails, showDetails, websiteNeeditem } = props;

  return (
    <>
      <BaseDetails
        itemName={""}
        label="siteManagement.websiteNeeds.details.label"
        open={showDetails}
        onClose={() => setShowDetails(false)}
        loading={false}
        errorMessage={""}
        maxWidth={"sm"}
        withoutSecoundTab={false}
        columns={12}
      >
        <Grid container item spacing={5}>
          <AppItemView
            label="siteManagement.websiteNeeds.category.nameAr"
            value={websiteNeeditem?.category?.nameAr}
            sm={4}
          />
          <AppItemView
            label="siteManagement.websiteNeeds.category.nameEn"
            value={websiteNeeditem?.category?.nameEn}
            sm={4}
          />
          <AppItemView
            label="siteManagement.websiteNeeds.read"
            value={websiteNeeditem?.isRead}
            type="isExist"
            sm={4}
          />
          <AppItemView
            label="common.description"
            value={websiteNeeditem?.description}
            sm={12}
          />
        </Grid>
      </BaseDetails>
    </>
  );
};

export default WebsiteNeedDetails;
