import { Grid } from "@mui/material";
import BaseDetails from "components/basicComponents/details/BaseDetails";
import AppItemView from "components/basicComponents/others/AppItemView";
import moment from "moment";
import { FC } from "react";
import { useIntl } from "react-intl";
import { useGetByIdService } from "services";
import { AppUrls } from "services/appUrls";
import { ISessionsDetailsDto } from "types/entitiesTypes/session";
import AttachmentsPage from "../attachments/AttachmentsPage";
import AttendancePage from "../attendance/AttendancePage";
import { removeHTMLTags } from "helpers";

interface IComponentOneProps {
  sessionItem: ISessionsDetailsDto;
}

const ComponentOne: FC<IComponentOneProps> = (props) => {
  const { sessionItem } = props;
  const { locale, messages } = useIntl();
  return (
    <>
      <Grid container spacing={5} item xs={12}>
        <AppItemView label="common.nameAr" value={sessionItem?.nameAr} sm={4} />
        <AppItemView label="common.nameEn" value={sessionItem?.nameEn} sm={4} />
        <AppItemView
          label="session.date"
          value={sessionItem?.date}
          sm={4}
          type="dateTime"
        />
        <AppItemView
          label="session.duration"
          value={sessionItem?.duration}
          sm={4}
        />
        <AppItemView
          label="session.courseName"
          value={
            locale == "en-US" && sessionItem?.course?.nameEn
              ? sessionItem?.course?.nameEn
              : sessionItem?.course?.nameAr
          }
          sm={4}
        />
        <AppItemView
          label="common.content"
          value={
            locale == "en-US" && sessionItem?.course?.contentEn
              ? removeHTMLTags(sessionItem?.course?.contentEn)
              : removeHTMLTags(sessionItem?.course?.contentAr)
          }
          sm={4}
        />

        <AppItemView
          label="session.sessionLink"
          value={
            sessionItem?.sessionLink ? (
              <a href={sessionItem?.sessionLink} target="_blank">
                {messages["session.sessionLink.href"]}
              </a>
            ) : (
              "-"
            )
          }
          type="text"
          sm={4}
        />

        {/* <AppItemView
          label="session.fileUrl"
          value={
            sessionItem?.files ? (
              <a
                href={`${APP_BASIC_FILES_URL} ${sessionItem?.files}`}
                target="_blank"
              >
                {messages["session.fileUrl.href"]}
              </a>
            ) : (
              "-"
            )
          }
          type="text"
          column={4}
        /> */}
      </Grid>
    </>
  );
};

interface ISessionsDetailsProps {
  setShowDetails: React.Dispatch<React.SetStateAction<boolean>>;
  showDetails: boolean;
  id: string;
}

const SessionsDetails = (props: ISessionsDetailsProps) => {
  const { showDetails, setShowDetails, id } = props;
  const { locale } = useIntl();

  const {
    data: sessionItem,
    error,
    isLoading,
  } = useGetByIdService<ISessionsDetailsDto>(AppUrls.Session, id);

  return (
    <>
      <BaseDetails
        itemName={
          locale == "en-US" && sessionItem?.nameEn
            ? `- ${sessionItem?.nameEn!}`
            : `- ${sessionItem?.nameAr!}`
        }
        label="session.details.label"
        open={showDetails}
        onClose={() => setShowDetails(false)}
        loading={isLoading}
        errorMessage={error}
        moreDetails={sessionItem as ISessionsDetailsDto}
        maxWidth="md"
        columns={12}
        tabs={[
          {
            id: "1",
            label: "details",
            component: <ComponentOne sessionItem={sessionItem!} />,
          },
          {
            id: "2",
            label: "session.attendance",
            component: <AttendancePage sessionId={sessionItem?.id!} />,
          },
          {
            id: "3",
            label: "session.attchments",
            component: (
              <AttachmentsPage
                setShowDetailsSession={setShowDetails}
                sessionId={sessionItem?.id!}
              />
            ),
          },
        ]}
      />
    </>
  );
};

export default SessionsDetails;
