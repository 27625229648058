import * as React from "react";
import AppTextField from "@crema/core/AppFormComponents/AppTextField";
import IntlMessages from "@crema/utility/IntlMessages";
import { IconButton, InputAdornment } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

interface IAppPasswordFieldProps {
  label: string;
  name: string;
  showPassword: boolean;
  setShowPassword: React.Dispatch<React.SetStateAction<boolean>>;
}

const AppPasswordField: React.FC<IAppPasswordFieldProps> = (props) => {
  const { label, name, showPassword, setShowPassword } = props;

  const handleTogglePassword = (state, setState) => setState(!state);

  return (
    <AppTextField
      label={<IntlMessages id={label} />}
      name={name}
      variant="outlined"
      size="small"
      fullWidth
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment
            onClick={() => handleTogglePassword(showPassword, setShowPassword)}
            position="end"
          >
            <IconButton edge="end">
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default AppPasswordField;
