import { ButtonGroup, Grid } from "@mui/material";
import AppButton from "components/basicComponents/buttons/AppButton";
import LoadingAndErrorWrapper from "components/basicComponents/others/LoadingAndErrorWrapper";
import * as React from "react";

interface IAppEditDiplomaProps {
  loadingForEdit: boolean;

  handleSubmit: () => void;
  labelForEditBtn?: string;
  handleBack?: () => void;
}

const AppEditDiploma: React.FC<IAppEditDiplomaProps> = (props) => {
  const {
    loadingForEdit,

    handleSubmit,
    children,
    labelForEditBtn,
    handleBack,
  } = props;

  return (
    <Grid xs={12} container spacing={2}>
      <Grid item xs={12}>
        {children}
      </Grid>
      <Grid
        marginTop={3}
        display={"flex"}
        justifyContent={"end"}
        alignItems={"center"}
        gap={3}
        item
        xs={12}
      >
        {handleBack !== undefined ? (
          <AppButton label="common.back" color="warning" onClick={handleBack} />
        ) : null}
        <AppButton
          label={
            labelForEditBtn && labelForEditBtn
              ? `${labelForEditBtn}`
              : "common.save"
          }
          loading={loadingForEdit}
          onClick={handleSubmit}
        />
      </Grid>
    </Grid>
  );
};

export default AppEditDiploma;
