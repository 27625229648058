import IntlMessages from "@crema/utility/IntlMessages";
import { Avatar, Grid, SxProps, Theme, Typography } from "@mui/material";
import { generatePresignedUrl } from "helpers";
import * as React from "react";
import { Fonts } from "shared/constants/AppEnums";

interface IAppViewImageProps {
  image: string | null;
  defaultImage: string;
  sxStyle?: SxProps<Theme>;

  column?: number;
  marginTop?: number;
  label?: string;

  sm?: number;
}

const AppViewImage: React.FC<IAppViewImageProps> = (props) => {
  const { image, defaultImage, sxStyle, column, label, marginTop, sm } = props;

  return (
    <>
      {label && (
        <Grid item sm={sm} xs={column}>
          <Typography sx={{ fontSize: 14, fontWeight: Fonts.SEMI_BOLD }}>
            <IntlMessages id={label} />
          </Typography>
        </Grid>
      )}
      <Grid marginTop={marginTop} item sm={sm} xs={column}>
        {image ? (
          <Avatar
            sx={sxStyle}
            src={`${generatePresignedUrl(image, process.env.NODE_ENV)}`}
          />
        ) : (
          <Avatar sx={sxStyle} src={`${defaultImage}`} />
        )}
      </Grid>
    </>
  );
};

export default AppViewImage;
