import { Grid, Hidden } from "@mui/material";
import { GridSelectionModel } from "@mui/x-data-grid";
import AppDataGrid from "components/basicComponents/dataGrid/AppDataGrid";
import AppConfirmModal from "components/basicComponents/modals/AppConfirmModal";
import { getValueBasedOnLangForConfirmModal } from "helpers";
import { FC, useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { useDeleteService, useGetByIdService } from "services";
import { AppUrls } from "services/appUrls";

import AppFilters from "components/basicComponents/filters/AppFilters";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { IAttachmentsList } from "types/entitiesTypes/attachments";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import AddAttachments from "./AddAttachments";
import { AttachmentsColumns } from "./AttachmentsColumns";
import AttachmentsCard from "./AttachmentsCard";
interface IAttachmentsPageProps {
  sessionId: string;
  setShowDetailsSession: React.Dispatch<React.SetStateAction<boolean>>;
}
const AttachmentsPage: FC<IAttachmentsPageProps> = (props) => {
  const { sessionId, setShowDetailsSession } = props;
  const { messages } = useIntl();

  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);

  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  const [selectedRow, setSelectedRow] = useState<IAttachmentsList>();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const {
    data: attachmentItem,
    isFetching,
    refetch,
    error,
  } = useGetByIdService<Array<IAttachmentsList>>(
    AppUrls.Session,
    `${sessionId}/Attachments`
  );

  // For details
  const handleDetails = useCallback((selectedRow: IAttachmentsList) => {
    setSelectedRow(selectedRow);
    setShowDetailsModal(true);
  }, []);

  // for delete
  const handleDelete = useCallback((selectedRow: IAttachmentsList) => {
    setSelectedRow(selectedRow);
    setShowDeleteModal(true);
  }, []);

  const handleDetailsSessionShow = useCallback(() => {
    setShowDetailsSession(false);
  }, []);

  const columns = AttachmentsColumns(handleDetails, handleDelete);

  const mutationForDelete = useDeleteService<string>(
    AppUrls.SessionAttachment,
    selectedRow?.id || "",
    () => {
      refetch();
      setShowDeleteModal(false);
    }
  );

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <AppFilters
            addTitle="table.actions.addAttachment"
            setShowAddModal={setShowAddModal}
            // onChangeKeywordValue={}
            selectionModel={selectionModel as string[]}
            setSelectionModel={setSelectionModel}
            successUrl={AppUrls.Session}
            // For Permissions
            schemaName={SchemaName.SystemConfigurations}
            entityName={EntityName.Countries}
            action={PermissionActionsTypes.Add}
          />
        </Grid>
        <Hidden smDown>
          <Grid item xs={12}>
            <AppDataGrid
              rowCount={attachmentItem?.length ?? 0}
              pageNumber={1}
              //for pagination
              onPageSizeChange={() => {}}
              onPageChange={() => {}}
              pageSize={attachmentItem?.length ?? 0}
              columns={columns}
              rows={attachmentItem ?? []}
              loading={isFetching}
              //for selection
              onSelectionModelChange={(
                newSelectionModel: GridSelectionModel
              ) => {
                setSelectionModel(newSelectionModel);
              }}
              checkboxSelection={false}
              selectionModel={selectionModel}
              //for sorting
              onSortModelChange={() => {}}
            />
          </Grid>
        </Hidden>
        <Hidden smUp>
          <Grid item xs={12}>
            <AttachmentsCard
              AttachmentsList={attachmentItem!}
              loading={isFetching}
              errorMessage={error!}
              handleDelete={handleDelete}
            />
          </Grid>
        </Hidden>
      </Grid>

      {showAddModal && (
        <AddAttachments
          label="table.actions.addAttachment"
          showAddModal={showAddModal}
          setShowAddModal={setShowAddModal}
          sessionId={sessionId}
          refetch={() => refetch()}
        />
      )}

      {/* {showDetailsModal && (
        <AttachmentsDetails
          id={selectedRow?.id!}
          setShowDetails={setShowDetailsModal}
          showDetails={showDetailsModal}
        />
      )} */}

      {showDeleteModal && (
        <AppConfirmModal
          dialogTitle={"session.attachments.actions.delete.dialogTitle"}
          open={showDeleteModal}
          setOpen={setShowDeleteModal}
          loading={mutationForDelete.isLoading}
          itemName={getValueBasedOnLangForConfirmModal(
            selectedRow?.nameAr || "",
            selectedRow?.nameEn || ""
          )}
          title={"session.attachments.actions.delete.title"}
          onConfirm={() => {
            mutationForDelete.mutate();
          }}
        />
      )}
    </>
  );
};

export default AttachmentsPage;
