export const BaseActions = {
  Search: "/search",
  RelationSearch: "/relation-search",
  ById: "/{id}",
  Update: "/{id}",
  Delete: "/{id}",
  ToggleActive: "/active/{id}",
  Unlock: "/unlock/{id}",

  ReadMessage: "/isread/{id}",
  ActivateAll: "/activeall",
  DeleteAll: "/deleteall",
  SetAll: "/setall",
  CheckAll: "/checkall",
  Reports: "/reports",
  IndicatorValueTogglePublic: "/public/{id}",
  IndicatorValueToggleCheck: "/check/{id}",
  Read: "/read/{id}",
};
