import AppTextField from "@crema/core/AppFormComponents/AppTextField";
import IntlMessages from "@crema/utility/IntlMessages";
import { Grid } from "@mui/material";
import AppEditor from "components/basicComponents/inputs/AppEditor";
import AppEditModal from "components/basicComponents/modals/AppEditModal";
import { Form, Formik, FormikProps } from "formik";
import { getValueBasedOnLang } from "helpers";
import React from "react";
import { useIntl } from "react-intl";
import { useGetByIdService, useUpdateService } from "services";
import {
  useCustomToggleActivationService,
  useCustomUpdateService,
} from "services/additionalServices";
import { AppUrls } from "services/appUrls";
import { ISelectedRow } from "types/appTypes/baseEntity/baseDto";

import {
  ISystemSetting,
  IUpdateSystemSettingsDto,
  IUpdateSystemSettingsForm,
} from "types/entitiesTypes/systemSettings";
import * as yup from "yup";

interface IEditSystemSettingsProps {
  showEditModal: boolean;
  setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  label: string;
  selectedRow: ISystemSetting;
  onSuccess?: () => void;
  onFailed?: () => void;
}
const EditSystemSettings: React.FC<IEditSystemSettingsProps> = (props) => {
  const { showEditModal, setShowEditModal, label, selectedRow } = props;
  const { messages } = useIntl();

  const refForm = React.useRef<FormikProps<IUpdateSystemSettingsForm>>(null);

  const mutation = useCustomUpdateService<IUpdateSystemSettingsDto, string>(
    AppUrls.SystemSettings,
    AppUrls.SystemSettings,
    "updateSuccess",
    false
  );
  const validationSchema = yup.object({
    value: yup.string().required(String(messages["validation.required"])),
    key: yup.string().required(String(messages["validation.required"])),
  });
  const submit = () => {
    if (refForm.current) {
      refForm.current.submitForm();
    }
  };

  return (
    <>
      <AppEditModal
        label={label}
        itemName={""}
        open={showEditModal}
        onClose={() => {
          setShowEditModal(false);
        }}
        handleSubmit={submit}
        loading={false}
        errorMessage={null!}
        loadingForEdit={
          (refForm.current?.isSubmitting && !refForm.current?.isValidating) ||
          false
        }
        closeModalState={setShowEditModal}
        successClose={mutation.isSuccess}
        maxWidth={"sm"}
      >
        <Formik
          validateOnChange={true}
          initialValues={{
            value: selectedRow?.value || "",
            key: selectedRow?.key || "",
            type: selectedRow?.type || "",
          }}
          innerRef={refForm}
          validationSchema={validationSchema}
          onSubmit={async (data, { setSubmitting }) => {
            const dataSend = {
              [data.key]: data.value,
            };
            mutation.submitData(dataSend);
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Grid container spacing={4}>
                {values.key === "Privacy Policy" ? (
                  <Grid item xs={12}>
                    <AppEditor
                      label={values.key}
                      name="value"
                      setFieldValue={setFieldValue}
                      value={values.value}
                    />
                  </Grid>
                ) : (
                  <Grid item sm={6} xs={12}>
                    <AppTextField
                      label={values.key}
                      name="value"
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      </AppEditModal>
    </>
  );
};

export default EditSystemSettings;
