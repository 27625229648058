import IntlMessages from "@crema/utility/IntlMessages";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { useIntl } from "react-intl";

interface IAppAdvanceFilterAutoCompleteProps {
  options: any[] | undefined;
  isLoading: boolean;
  label: string;
  onChangeTextField: (value: any) => void;
  defaultValue?: any;
  searchText: string | undefined;
  getOptionLabel?: (option: any) => string;
  fieldName?: string;
  setSearchText?: any;
  pageNumber?: number;
  setPageNumber?: React.Dispatch<React.SetStateAction<number>>;
  hasNextPage?: boolean;
  filterKey?: string;
  setStateValue?: React.Dispatch<React.SetStateAction<string>>;
  setAdvancedFilter?: React.Dispatch<React.SetStateAction<any>>;
  advancedFilter: any;
}

const AppAdvanceFilterAutoComplete: React.FC<
  IAppAdvanceFilterAutoCompleteProps
> = (props) => {
  const { locale } = useIntl();

  const {
    options,
    isLoading,
    label,
    onChangeTextField,
    defaultValue,
    searchText,
    setStateValue,
    // pagination
    pageNumber,
    setPageNumber,
    hasNextPage = false,
    getOptionLabel = (option: any) => {
      if (locale == "en-US" && option?.nameEn) {
        return option?.nameEn;
      } else return option?.nameAr;
    },
    fieldName,
    setSearchText,
    filterKey = locale == "en-US" ? "nameEn" : "nameAr",
    setAdvancedFilter,
    advancedFilter,
  } = props;
  const [state, setState] = React.useState<any>();

  const [dataPagination, setDataPagination] = React.useState<Array<any>>([]);

  //for pagination
  React.useEffect(() => {
    if (options && !searchText && searchText == "") {
      if (pageNumber && pageNumber > 1) {
        setDataPagination([...dataPagination, ...options]);
      } else {
        setDataPagination([...options]);
      }
    } else if (options && searchText != "") {
      if (pageNumber && pageNumber > 1) {
        setDataPagination([...dataPagination, ...options]);
      } else {
        setDataPagination([...options]);
      }
    }
  }, [options]);

  return (
    <>
      <Autocomplete
        size="small"
        options={dataPagination || []}
        onChange={(e, value) => {
          setAdvancedFilter &&
            setAdvancedFilter({
              ...advancedFilter,
              [`${fieldName}`]: value ? value?.id : null,
            });
          setState(value?.id);
          setStateValue && setStateValue(value?.id);
          !value && setSearchText("");
        }}
        getOptionLabel={getOptionLabel}
        loading={isLoading}
        defaultValue={defaultValue}
        loadingText="Loading..."
        autoHighlight
        renderOption={(props, option) => (
          <>
            <li {...props}>
              {filterKey ? option[filterKey]?.toString() : option?.nameAr}
            </li>
            {isLoading &&
              option === dataPagination[dataPagination.length - 1] && (
                <li {...props}>Loading...</li>
              )}
          </>
        )}
        ListboxProps={{
          // itemRef: scrollRef as any,
          onScroll: (event) => {
            const { scrollTop, clientHeight, scrollHeight } =
              event.target as HTMLElement;
            const threshold = 20; // Set your desired threshold here
            const isScrolledToBottom =
              scrollHeight - scrollTop - clientHeight < threshold;

            if (isScrolledToBottom && !isLoading && hasNextPage) {
              // scrollRef?.current && (scrollRef.current.scrollTop = scrollTop);
              setPageNumber && pageNumber && setPageNumber(pageNumber + 1);
            }
          },
          style: {
            maxHeight: "200px", // Set your desired height here
            overflowY: "auto",
          },
        }}
        // For search in mui on arabic and english language
        filterOptions={(options) => {
          return options;

          // !searchText
          //   ? options
          //   : options.filter(
          //       (x) =>
          //         x.nameAr
          //           ?.toLowerCase()
          //           ?.includes(searchText?.trim()?.toLowerCase()) ||
          //         x.nameEn
          //           ?.toLowerCase()
          //           ?.includes(searchText?.trim()?.toLowerCase())
          //     );
        }}
        clearOnBlur={false}
        renderInput={(params) => (
          <TextField
            {...params}
            label={<IntlMessages id={label} />}
            onChange={(e) => {
              setPageNumber && setPageNumber(1);
              onChangeTextField(e);
            }}
            value={searchText}
          />
        )}
      />
      <input
        className="support-advance-search"
        name={fieldName}
        value={state || ""}
      />
    </>
  );
};

export default AppAdvanceFilterAutoComplete;
