import { AuthUser } from "../../types/models/AuthUser";

export const authRole = {
  admin: ["admin"],
  user: ["user", "admin"],
};

export enum RoutePermittedRole {
  Admin = "admin",
  User = "user",
}

export const defaultUser: AuthUser = {
  uid: "john-alex",
  displayName: "John Alex",
  email: "demo@example.com",
  token: "access-token",
  role: "user",
  photoURL: "/assets/images/avatar/A11.jpg",
};
export const initialUrl = "/adminDashboard"; // this url will open after login

export const DEBOUNCE_TIME = 1000;

export const SchemaName = {
  SystemConfigurations: "SystemConfigurations",
  NotificationConfigurations: "NotificationConfigurations",
  UsersManagement: "UsersManagement",
  ClientsManagement: "OtherUsers",
  Reports: "Reports",
  Default: "Default",
  SiteManagement: "SiteManagement",
};

export const EntityName = {
  Countries: "Countries",
  States: "States",
  Branches: "Branches",
  Departments: "Departments",
  InstanceNotifications: "InstanceNotifications",
  ExportedFileReprots: "ExportedFiles",
  UsersReports: "UsersReports",
  ApplicationMessages: "ApplicationMessages",
  Personal: "Personal",
  ApplicationMessageLifeCycles: "ApplicationMessageLifeCycle",
  Users: "Users",
  UserRoles: "UserRoles",
  Roles: "Roles",
  Report: "Report",
  Dashboard: "Dashboard",
  WebsiteNeeds: "WebsiteNeeds",
};
