import IntlMessages from "@crema/utility/IntlMessages";
import { Grid } from "@mui/material";
import BaseDetails from "components/basicComponents/details/BaseDetails";
import AppItemView from "components/basicComponents/others/AppItemView";
import { suggestType } from "shared/constants/AppEnums";

import { IWebsiteContactUsListDto } from "types/entitiesTypes/websiteContactUs";

interface IWebsiteContactUsDetailsProps {
  setShowDetails: React.Dispatch<React.SetStateAction<boolean>>;
  showDetails: boolean;
  WebsiteContactUsitem: IWebsiteContactUsListDto;
}
const WebsiteContactUsDetails = (props: IWebsiteContactUsDetailsProps) => {
  const { setShowDetails, showDetails, WebsiteContactUsitem } = props;

  return (
    <>
      <BaseDetails
        itemName={""}
        label="siteManagement.WebsiteContactUs.details.label"
        open={showDetails}
        onClose={() => setShowDetails(false)}
        loading={false}
        errorMessage={""}
        maxWidth={"sm"}
        withoutSecoundTab={false}
        columns={12}
      >
        <Grid container item spacing={5}>
          <AppItemView
            label="siteManagement.WebsiteContactUs.fullName"
            value={WebsiteContactUsitem?.fullName}
            sm={6}
          />
          <AppItemView
            label="notification.message"
            value={WebsiteContactUsitem?.message}
            sm={6}
          />

          <AppItemView
            label="common.phone"
            value={WebsiteContactUsitem?.phoneNumber}
            sm={6}
          />
          <AppItemView
            label="siteManagement.WebsiteContactUs.suggestType"
            value={
              <IntlMessages
                id={`siteManagement.WebsiteContactUs.suggestType.${
                  WebsiteContactUsitem?.suggestType === 0
                    ? suggestType.Complaint
                    : suggestType.Suggestion
                }`}
              />
            }
            sm={6}
          />
          <AppItemView
            label="siteManagement.websiteNeeds.read"
            value={WebsiteContactUsitem?.isRead}
            type="isExist"
            sm={6}
          />
        </Grid>
      </BaseDetails>
    </>
  );
};

export default WebsiteContactUsDetails;
