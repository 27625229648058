import WebsiteContactUsPages from "./websiteContactUs/WebsiteContactUsPages";
import WebsiteNeedPages from "./websiteNeeds/WebsiteNeedPages";

export const siteManagementPages = [
  {
    path: "/siteManagement/webSiteNeeds",
    element: <WebsiteNeedPages />,
  },
  {
    path: "/siteManagement/websiteContactUs",
    element: <WebsiteContactUsPages />,
  },
];
