import UserPersonalProfile from "pages/usersManagement/users/UserPersonalProfile";
import UserProfileWithRole from "pages/usersManagement/users/UserProfileWithRole";

export const profilePage = [
  {
    path: "/my-profile/*",
    element: <UserPersonalProfile />,
    permittedRole: `Permissions.SystemConfigurations.Personal.Profile`,
  },
];
