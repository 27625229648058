import { Card, CardContent, Grid } from "@mui/material";
import AppBreadcrumb from "components/basicComponents/breadcrumb/AppBreadcrumb";
import AddDiplomas from "components/scientificContentManagement/diplomas/addDiploma/AddDiplomas";
import { useIntl } from "react-intl";

const DiplomaAddPage: React.FC = () => {
  const { messages } = useIntl();
  return (
    <Grid container spacing={3}>
      <AppBreadcrumb
        paths={[
          { name: messages["sidebar.scientificContentManagement.title"] },
          {
            name: messages["sidebar.diplomas"],
            path: "/scientificContentManagement/diplomas",
          },
          {
            name: messages["diplomas.add"],
          },
        ]}
        currentPathName=""
      />

      <Grid item xs={12}>
        <Card>
          <CardContent>
            <AddDiplomas />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default DiplomaAddPage;
