import AppTextField from "@crema/core/AppFormComponents/AppTextField";
import IntlMessages from "@crema/utility/IntlMessages";
import { Grid } from "@mui/material";
import AppCheckbox from "components/basicComponents/inputs/AppCheckbox";
import AppEditor from "components/basicComponents/inputs/AppEditor";
import AppBaseAutoComplete from "components/basicComponents/inputs/autoComplete/AppBaseAutoComplete";
import AppDatePicker from "components/basicComponents/inputs/dateTimePickers/AppDatePicker";
import useGeneralAutoComplete from "components/scientificContentManagement/useGeneralAutoComplete";
import { FormikErrors, FormikHelpers, FormikTouched } from "formik";
import { debounce } from "lodash";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { AppUrls } from "services/appUrls";
import { DEBOUNCE_TIME } from "shared/constants/AppConst";
import { ICategoriesListDto } from "types/entitiesTypes/categories";
import { ICreateDiplomasDto } from "types/entitiesTypes/diplomas";
import { IInstructorsListDto } from "types/entitiesTypes/instructors";

interface ISecondStepProps {
  setFieldValue: FormikHelpers<ICreateDiplomasDto>["setFieldValue"];
  errors: FormikErrors<ICreateDiplomasDto>;
  touched: FormikTouched<ICreateDiplomasDto>;
  values: ICreateDiplomasDto;
}

const SecondStep = (props: ISecondStepProps) => {
  const { errors, setFieldValue, touched, values } = props;
  const { locale } = useIntl();
  const { instructorIdParams, educationalCenterIdParams } = useParams();

  const {
    keyword: instructorKeyword,
    setKeyword: setinstructorKeyword,
    dataList: instructorsList,
    isFetching: isFetchinginstructor,
    pageNumber: instructorPageNumber,
    setPageNumber: setinstructorPageNumber,
  } = useGeneralAutoComplete<IInstructorsListDto>({
    url: AppUrls.Instructor,
  });

  const {
    keyword: categoryKeyword,
    setKeyword: setCategoryKeyword,
    dataList: categorysList,
    isFetching: isFetchingcategory,
    pageNumber: categoryPageNumber,
    setPageNumber: setCategoryPageNumber,
  } = useGeneralAutoComplete<ICategoriesListDto>({
    url: AppUrls.Categories,
  });

  console.log(instructorsList);

  return (
    <Grid container spacing={4}>
      {!instructorIdParams && (
        <Grid item xs={12} sm={3}>
          <AppBaseAutoComplete
            label="diplomas.instructor"
            options={instructorsList?.data}
            isLoading={isFetchinginstructor}
            defaultValue={values.instructor}
            onChangeAutoComplete={(event, value) => {
              setFieldValue("instructor", value ? value : null);
              setFieldValue("instructorId", value ? value?.id : "");

              !value && setinstructorKeyword("");
            }}
            //pagination
            pageNumber={instructorPageNumber}
            setPageNumber={setinstructorPageNumber}
            hasNextPage={instructorsList?.hasNextPage}
            searchText={instructorKeyword}
            onChangeTextField={debounce((event) => {
              setinstructorPageNumber(1);
              setinstructorKeyword(event.target.value);
            }, DEBOUNCE_TIME)}
            error={errors.instructorId}
            touched={touched?.instructorId}
            filterKey={locale === "en-US" ? "firstNameEn" : "firstNameAr"}
            getOptionLabel={(option) =>
              locale === "en-US" && option?.firstNameEn
                ? `${option?.firstNameEn} ${option?.lastNameEn}`
                : `${option?.firstNameAr} ${option?.lastNameAr}`
            }
          />
        </Grid>
      )}

      <Grid item xs={12} sm={3}>
        <AppTextField
          label={<IntlMessages id="diplomas.instructorSalary" />}
          name="instructorSalary"
          variant="outlined"
          size="small"
          fullWidth
          type="number"
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <AppTextField
          label={<IntlMessages id="diplomas.durationInHours" />}
          name="durationInHours"
          variant="outlined"
          size="small"
          fullWidth
          type="number"
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <AppDatePicker
          label="diplomas.startDate"
          name="startDate"
          value={values?.startDate!}
          onChange={setFieldValue}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <AppTextField
          label={<IntlMessages id="diplomas.coursesCount" />}
          name="coursesCount"
          variant="outlined"
          size="small"
          fullWidth
          type="number"
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <AppBaseAutoComplete
          label="diplomas.category"
          options={categorysList?.data}
          isLoading={isFetchingcategory}
          defaultValue={values.category}
          onChangeAutoComplete={(event, value) => {
            setFieldValue("category", value ? value : null);
            setFieldValue("categoryId", value ? value?.id : "");

            !value && setCategoryKeyword("");
          }}
          //pagination
          pageNumber={categoryPageNumber}
          setPageNumber={setCategoryPageNumber}
          hasNextPage={categorysList?.hasNextPage}
          searchText={categoryKeyword}
          onChangeTextField={debounce((event) => {
            setCategoryPageNumber(1);
            setCategoryKeyword(event.target.value);
          }, DEBOUNCE_TIME)}
          error={errors.categoryId}
          touched={touched?.categoryId}
        />
      </Grid>
      <Grid item xs={12} sm={4} lg={3}>
        <AppTextField
          label={<IntlMessages id="diplomas.whatsappChatLink" />}
          name="whatsappChatLink"
          variant="outlined"
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <AppCheckbox
          keyName="isCertified"
          onChange={setFieldValue}
          defaultChecked={values?.isCertified}
          label="diplomas.isCertified"
        />
      </Grid>
      <Grid item xs={12}>
        <AppEditor
          label="diplomas.outcomes"
          name="outcomes"
          setFieldValue={setFieldValue}
          value={values?.outcomes}
        />
      </Grid>
    </Grid>
  );
};

export default SecondStep;
