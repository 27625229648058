import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { GridColDef } from "@mui/x-data-grid";
import AppDataGridCell from "components/basicComponents/dataGrid/AppDataGridCell";
import AppIconButton from "components/basicComponents/others/AppIconButton";
import { useIntl } from "react-intl";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { IAdvertisementSlidersListDto } from "types/entitiesTypes/advertisementSlider";
import AppViewImage from "components/basicComponents/others/AppViewImage";
import defaultImage from "assets/appDefaultImages/branch.png";

export const AdvertisementSlidersColumns = (
  handleDelete = (row: IAdvertisementSlidersListDto) => {}
): GridColDef[] => {
  const { messages } = useIntl();

  const AdvertisementSlidersColumns: GridColDef[] = [
    {
      field: "file",
      type: "image",
      headerName: messages["common.image"] as string,
      headerAlign: "center",
      align: "center",
      filterable: false,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <AppViewImage image={params?.row?.file} defaultImage={defaultImage} />
        );
      },
    },
    {
      field: "link",
      headerName: messages["common.link"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => <AppDataGridCell value={params.row?.link} />,
    },

    {
      field: "actions",
      headerName: messages["common.actions"] as string,
      sortable: false,
      align: "center",
      filterable: false,
      headerAlign: "center",
      flex: 0.1,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div>
            {/* Delete */}
            <AppIconButton
              label="table.actions.delete"
              handleClick={() => {
                handleDelete(params.row);
              }}
              childComp={
                <DeleteOutlinedIcon
                  fontSize="small"
                  sx={{ color: "#D72638" }}
                />
              }
              // For Permissions
              schemaName={SchemaName.SystemConfigurations}
              entityName={EntityName.Countries}
              action={PermissionActionsTypes.Delete}
            />
          </div>
        );
      },
    },
  ];

  return AdvertisementSlidersColumns;
};
