import Settings from "./Setting";
import Common from "./Common";
import personal from "./personal/personal";
import permissions from "./permissions/permissions";
import exporting from "./exporting";
import notifications from "./notifications";

const reducers = {
  settings: Settings,
  common: Common,
  personal: personal,
  permissions: permissions,
  exporting: exporting,
  notifications: notifications,
};

export default reducers;
