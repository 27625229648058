import { initialUrl } from "shared/constants/AppConst";
import { authRouteConfig } from "./auth";
import { errorPagesConfigs } from "./errorPages";
import Error403 from "./errorPages/Error403";
import { profilePage } from "./profile";
import { systemConfigurationsPages } from "./systemConfigurations";
import { usersManagementPages } from "./usersManagement";
import { dashboardPagesConfigs } from "./dashboard";
import { educationalCentersPages } from "./trainStructure";
import { siteManagementPages } from "./siteManagement";
import { scientificContentManagement } from "./scientificContentManagement";
import { managePayments } from "./managePayments";

const authorizedStructure = {
  fallbackPath: "/signin",
  unAuthorizedComponent: <Error403 />,
  routes: [
    ...dashboardPagesConfigs,
    ...profilePage,
    ...systemConfigurationsPages,
    ...usersManagementPages,
    ...dashboardPagesConfigs,
    ...educationalCentersPages,
    ...siteManagementPages,
    ...scientificContentManagement,
    ...managePayments,
  ],
};

const unAuthorizedStructure = {
  fallbackPath: initialUrl,
  routes: authRouteConfig,
};

const anonymousStructure = {
  routes: errorPagesConfigs,
};

export { authorizedStructure, unAuthorizedStructure, anonymousStructure };
