import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { GridColDef } from "@mui/x-data-grid";
import RefundStatusBadge from "components/basicComponents/badge/StatusRefund";
import AppDataGridCell from "components/basicComponents/dataGrid/AppDataGridCell";
import AppIconButton from "components/basicComponents/others/AppIconButton";
import { dateTimeFormatter } from "helpers/string/dateFormatter";
import { useIntl } from "react-intl";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import { IRefundablePaymentsListDto } from "types/entitiesTypes/refundablePayments";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoNotDisturbOffIcon from "@mui/icons-material/DoNotDisturbOff";
import { getValueBasedOnLang } from "helpers";
import { refundReason } from "shared/constants/AppEnums";

export const RefundablePaymentsColumns = (
  handleApprove = (row: IRefundablePaymentsListDto) => {},
  handleReject = (row: IRefundablePaymentsListDto) => {},

  handleDetails = (row: IRefundablePaymentsListDto) => {}
): GridColDef[] => {
  const { messages, locale } = useIntl();

  const RefundablePaymentsColumns: GridColDef[] = [
    // {
    //   field: "supscription.student.id",
    //   headerName: messages["student.id"] as string,
    //   headerAlign: "center",
    //   align: "center",
    //   flex: 0.2,
    //   sortable: false,
    //   headerClassName: "super-app-theme--header",
    //   renderCell: (params) => (
    //     <AppDataGridCell value={params.row?.supscription?.student?.id} />
    //   ),
    // },
    {
      field: "supscription.student.fullName",
      headerName: messages["student.name"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      sortable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell value={params.row?.supscription?.student?.fullName} />
      ),
    },

    {
      field:
        locale === "ar-SA"
          ? "supscription.diploma.nameAr"
          : "supscription.diploma.nameEn",
      headerName: messages["diplomas.name"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      sortable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell
          value={getValueBasedOnLang(
            params.row?.supscription?.diploma?.nameAr,
            params.row?.supscription?.diploma?.nameEn
          )}
        />
      ),
    },

    {
      field:
        locale === "ar-SA"
          ? "supscription.diploma.educationalCenter.nameAr"
          : "supscription.diploma.educationalCenter.nameEn",
      headerName: messages["diplomas.educationalCenter"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      sortable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell
          value={getValueBasedOnLang(
            params.row?.supscription?.diploma?.educationalCenter?.nameAr ?? "-",
            params.row?.supscription?.diploma?.educationalCenter?.nameEn ?? "-"
          )}
        />
      ),
    },

    {
      field:
        locale === "ar-SA"
          ? "supscription.diploma.instructor.firstNameAr"
          : "supscription.diploma.instructor.firstNameEn",
      headerName: messages["diplomas.instructor"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      sortable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell
          value={getValueBasedOnLang(
            params.row?.supscription?.diploma?.instructor?.firstNameAr
              ? `${params.row?.supscription?.diploma?.instructor?.firstNameAr} ${params.row?.supscription?.diploma?.instructor?.lastNameAr}`
              : "-",
            params.row?.supscription?.diploma?.instructor?.firstNameEn
              ? `${params.row?.supscription?.diploma?.instructor?.firstNameEn} ${params.row?.supscription?.diploma?.instructor?.lastNameEn}`
              : "-"
          )}
        />
      ),
    },

    {
      field: "amount",
      headerName: messages["refundablePayments.amount"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => <AppDataGridCell value={params.row?.amount} />,
    },
    {
      field: "payment.amount",
      headerName: messages["refundablePayments.payment.amount"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      sortable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell value={params.row?.payment?.amount} />
      ),
    },
    {
      field: "date",
      headerName: messages["refundablePayments.date"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell value={dateTimeFormatter(params.row?.date)} />
      ),
    },
    {
      field: "refundReason",
      headerName: messages["refundablePayments.refundReason"] as string,
      headerAlign: "center",
      align: "center",

      filterable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) =>
        params.row?.refundReason == refundReason.RejectVirtualFile
          ? messages[`refundablePayments.${refundReason.RejectVirtualFile}`]
          : "-",
    },
    {
      field: "refundStatus",
      headerName: messages["refundablePayments.refundStatus"] as string,
      headerAlign: "center",
      align: "center",

      filterable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <RefundStatusBadge
          refundStatusProps={params.row?.refundStatus}
          label={`refundablePayments.refundStatus.${params.row?.refundStatus}`}
        />
      ),
    },

    {
      field: "actions",
      headerName: messages["common.actions"] as string,
      sortable: false,
      align: "center",
      filterable: false,
      headerAlign: "center",
      flex: 0.1,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div>
            {params?.row?.refundStatus === "PendingApproval" && (
              <>
                <AppIconButton
                  label="refundablePayments.approved"
                  handleClick={() => {
                    handleApprove(params.row);
                  }}
                  childComp={
                    <CheckCircleIcon fontSize="small" sx={{ color: "green" }} />
                  }
                  // For Permissions
                  schemaName={SchemaName.SystemConfigurations}
                  entityName={EntityName.Countries}
                  action={PermissionActionsTypes.Details}
                />
                <AppIconButton
                  label="refundablePayments.rejectedTitle"
                  handleClick={() => {
                    handleReject(params.row);
                  }}
                  childComp={
                    <DoNotDisturbOffIcon
                      fontSize="small"
                      sx={{ color: "red" }}
                    />
                  }
                  // For Permissions
                  schemaName={SchemaName.SystemConfigurations}
                  entityName={EntityName.Countries}
                  action={PermissionActionsTypes.Details}
                />
              </>
            )}
            {/* Details */}
            <AppIconButton
              label="table.actions.details"
              handleClick={() => {
                handleDetails(params.row);
              }}
              childComp={
                <RemoveRedEyeIcon fontSize="small" sx={{ color: "#007991" }} />
              }
              // For Permissions
              schemaName={SchemaName.SystemConfigurations}
              entityName={EntityName.Countries}
              action={PermissionActionsTypes.Details}
            />
          </div>
        );
      },
    },
  ];

  return RefundablePaymentsColumns;
};
