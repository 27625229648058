import { Grid } from "@mui/material";
import AppBreadcrumb from "components/basicComponents/breadcrumb/AppBreadcrumb";
import { useIntl } from "react-intl";
import { useGetByIdService } from "services";
import { AppUrls } from "services/appUrls";

import { ISystemSetting } from "types/entitiesTypes/systemSettings";
import SystemSettingsItem from "./SystemSettingsItem";
import { useCallback, useState } from "react";
import EditSystemSettings from "components/systemConfigurations/systemSettings/EditSystemSettings";

const SystemSettingsPages = () => {
  const { messages } = useIntl();
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<ISystemSetting>();

  // For edit
  const handleUpdate = useCallback((selectedRow: ISystemSetting) => {
    setSelectedRow(selectedRow);
    setShowEditModal(true);
  }, []);

  const {
    data: SystemSettingsList,
    isFetching,
    error,
  } = useGetByIdService<Array<ISystemSetting>>(AppUrls.SystemSettings, "");

  return (
    <>
      <Grid container spacing={3}>
        <AppBreadcrumb
          paths={[{ name: messages["sidebar.systemConfigurations.title"] }]}
          currentPathName={"sidebar.systemSettings"}
        />
        <SystemSettingsItem
          handleUpdate={handleUpdate}
          SystemSettingsList={SystemSettingsList!}
        />
      </Grid>
      {showEditModal && (
        <EditSystemSettings
          showEditModal={showEditModal}
          setShowEditModal={setShowEditModal}
          label="systemConfigurations.systemSettings.edit"
          selectedRow={selectedRow!}
        />
      )}
    </>
  );
};

export default SystemSettingsPages;
