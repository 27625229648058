import { getValueBasedOnLang } from "helpers";
import moment from "moment";
import { useIntl } from "react-intl";

/**
 * header must be in this pattern:
 * {
 *    key: key Of Value From Backend
 *    value: Title in arabic
 * }
 */
const GetPdfTemplate = (
  headers = [],
  body = [],
  fileName,
  messages,
  callBack
) => {

  const baseHtmlTemplate = `<!DOCTYPE html>
  <html>
    <head>
      <style>
        table {
          font-family: arial, sans-serif;
          border-collapse: collapse;
          width: 100%;
          direction: rtl;
        }
        thead {
          background-color: #5b9bd5;
          color: #ffffff;
        }
        td,
        th {
          border: 1px solid #5b9bd5;
          text-align: right;
          padding: 8px;
        }
  
        tr:nth-child(even) {
          background-color: #deeaf6;
        }
        .main-header-container {
          display: flex;
          align-items: center;
          justify-content: end;
          padding: 0 16px;
          flex-direction: row-reverse;
        }
        .header-content {
          display: flex;
          flex-direction: column;
          text-align: center;
          padding: 1em;
        }
        .header-content span {
          padding: 0.2em;
          font-weight: 600;
        }
        .main-header-container .offset {
          flex: 1;
        }
  
        @page {
          size: auto;
        }
  
        .page-header,
        .page-footer {
          display: none;
        }
        table tfoot td,
        table tfoot th {
          border: none;
        }
  
        .main-footer-container {
          display: flex;
          flex-direction: row-reverse;
          padding: 0 16px;
        }
        .footer-content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          align-items: center;
        }
  
        .footer-content-contact {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          flex: 1;
        }
  
        .footer-content-contact span {
          display: flex;
          align-items: center;
          flex-direction: row-reverse;
        }
  
        @media print {
          header {
            position: fixed;
            width: 100%;
            top: 0;
          }
          footer {
            position: fixed;
            bottom: 0;
          }
  
          thead {
            display: table-header-group;
          }
          tfoot {
            display: table-footer-group;
          }
  
          button {
            display: none;
          }
  
          body {
            margin: 0.2in;
          }
          .page-header,
          .page-footer {
            display: initial;
          }
          .page-header,
          .page-header-space {
            height: 80px;
          }
  
          .page-footer,
          .page-footer-space {
            height: 42px;
          }
  
          .page-footer {
            position: fixed;
            bottom: 0;
            width: 100%;
            background: #ffffff;
          }
  
          .page-header {
            position: fixed;
            top: 0mm;
            width: 100%;
            background: #ffffff;
          }
  
          .page {
            page-break-after: always;
          }
  
          @page {
            size: auto;
            margin: 0 5mm 0 5mm;
          }
        }
      </style>
    </head>
    <body onload="onLoad()">
      <header>
        <div class="main-header-container">
          <div class="header-content">
            <span>منظومة الأرشفة الإلكترونية</span>
          </div>
          <div class="offset"></div>
          <div class="header-content">
            <span>Electronic Archiving System</span>
          </div>
        </div>
      </header>
  
      <div class="page-header" style="text-align: center">
        <div class="main-header-container">
          <div class="header-content">
          <span>منظومة الأرشفة الإلكترونية</span>
          </div>
          <div class="offset"></div>
          <div class="header-content">
          <span>Electronic Archiving System</span>
          </div>
        </div>
      </div>
  
      <div class="page-footer">
        <div class="main-footer-container">
          <div class="footer-content-contact">
           <!--
           <span>
              <?xml version="1.0" ?><svg
                height="20"
                viewBox="0 0 48 48"
                width="20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24 4c-7.73 0-14 6.27-14 14 0 10.5 14 26 14 26s14-15.5 14-26c0-7.73-6.27-14-14-14zm0 19c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z"
                />
                <path d="M0 0h48v48h-48z" fill="none" />
              </svg>
              دمشق مقابل فندق الشام&nbsp;
            </span>
             -->
          </div>
          <div class="footer-content-contact">
          <!--
            <span>
              <svg
                width="20"
                height="16"
                viewBox="0 0 20 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 0C19.1 0 20 0.9 20 2V14C20 15.1 19.1 16 18 16H2C0.9 16 0 15.1 0 14V2C0 0.9 0.9 0 2 0H18ZM13.6 9.1L20 14V12L14.9 8.1L20 4V2L10 10L0 2V4L5.1 8.1L0 12V14L6.4 9.1L10 12L13.6 9.1Z"
                  fill="black"
                />
              </svg>
              info@syrianbar.org.sy &nbsp;
            </span>
            -->
          </div>
          <div class="footer-content-contact">
          <!--
            <span>
              <?xml version="1.0" ?><svg
                height="20"
                viewBox="0 0 48 48"
                width="20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M32 2h-16c-3.31 0-6 2.69-6 6v32c0 3.31 2.69 6 6 6h16c3.31 0 6-2.69 6-6v-32c0-3.31-2.69-6-6-6zm-4 40h-8v-2h8v2zm6.5-6h-21v-28h21v28z"
                />
                <path d="M0 0h48v48h-48z" fill="none" />
              </svg>
              +963 11 22227872 &nbsp;
            </span>
            -->
          </div>
        </div>
      </div>
  
      <table id="myTable">
        <thead>
          <tr>
            <td>
              <div class="page-header-space"></div>
              #
            </td>
            ${headers
      .map(
        (item) => `
                <td>
                    <div class="page-header-space"></div>
                    ${item.value}
                </td>`
      )
      .join("")}
          </tr>
        </thead>
        <tbody>
            ${body
      .map((item, index) => {
        return `
                    <tr>
                        <td>${index + 1}</td>
                        ${headers
            .map((headerItem) => {
              var finalValue = "";
              if (headerItem.key.includes(".")) {
                let value = null;
                headerItem.key
                  .split(".")
                  .forEach((element, splitIndex) => {
                    if (!value) {
                      if (splitIndex == 0) value = item[element];
                      else value = "-";
                    } else {
                      if (value != "-") value = value[element];
                    }
                  });
                finalValue = value;
              } else {
                if (headerItem.key == "isActive") {
                  var isActive = item[headerItem.key];
                  if (isActive) finalValue = "active";
                  else finalValue = "inactive";
                } else {
                  if (typeof item[headerItem.key] === "boolean") {
                    if (item[headerItem.key]) {
                      finalValue = getValueBasedOnLang("نعم", "yes")
                    }
                    else {
                      finalValue = getValueBasedOnLang("لا", "No")
                    }
                  }
                  else if (typeof item[headerItem.key] == "string" && item[headerItem.key].matchDate()) {
                    finalValue = moment(item[headerItem.key]).format("DD/MM/YYYY");
                  }
                  else {
                    finalValue = item[headerItem.key];
                  }
                }
              }
              var messagesKey = Object.keys(messages).filter(
                (x) => {
                  var split = x.split(".");
                  if (
                    split[split.length - 1].toLocaleLowerCase() ==
                    finalValue?.toString()?.toLocaleLowerCase()
                  )
                    return x;
                }
              );
              var callBackValue = null;
              if (callBack)
                callBackValue = callBack(
                  item[headerItem.key],
                  headerItem.key,
                  item
                );
              if (
                typeof finalValue == "string" &&
                finalValue.matchDate()
              )
                finalValue = moment(finalValue).format();
              return `
                            <td>${((callBackValue || callBackValue >= 0) && callBack
                  ? callBackValue
                  : messagesKey.length > 0
                    ? messages[messagesKey[0]]
                    : finalValue) ?? "-"
                }</td>
                          `;
            })
            .join("")}
                    </tr>
                `;
      })
      .join("")}
        </tbody>
        <tfoot>
          <tr>
            <td colspan="6">&nbsp;</td>
          </tr>
        </tfoot>
      </table>
  
      <br />
      <script>
        document.title = '${fileName}'
        window.print();
        onafterprint = function () {
          close();
          window.top.close();
        };
      </script>
    </body>
  </html>
  `;
  return baseHtmlTemplate;
};

export default GetPdfTemplate;
